import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "inline-flex items-center ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([_ctx.domClassesPerSize[_ctx.size], "inline-flex items-center mr-1.5 mb-1.5 rounded font-medium"]),
    "data-test-badge-wrapper": ""
  }, [
    (_ctx.withIndicator)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.indicatorDomClasses, "mr-2 rounded-full"]),
          "data-test-indicator": ""
        }, null, 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.$slots.addon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "addon")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}