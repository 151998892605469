import state from './state';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';
import getters from './getters';

const namespaced = true;

const enrollmentModule = {
  state,
  mutations,
  modules,
  getters,
  actions,
  namespaced,
};

export default enrollmentModule;
