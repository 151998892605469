import type { Section } from '@/typings/LMS/section';
import type { State } from './vuex';

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getSection: (state: State) => (sectionId: number): Section | null => {
    return state.sections.find(section => section.id === sectionId) ?? null;
  },

  allSectionIds(state: State): number[] {
    return state.sections.map(section => section.id);
  },

  allAccessibleSectionIds(state: State): number[] {
    return state.sections.filter(section => {
      return section.sectionTypeKey !== 'chapter';
    }).map(section => section.id);
  },

  firstAccessibleSectionId(state: State): number | null {
    return state.sections.find(section => {
      return section.sectionTypeKey !== 'chapter';
    })?.id ?? null;
  },

  allBlockUuids(state: State): string[] {
    return state.sections.flatMap(section => section.blockUuids);
  },

  lastSectionId(state: State): number | null {
    const lastSectionIndex = state.sections.length - 1;

    return state.sections[lastSectionIndex]?.id ?? null;
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  lastBlockUuidBySection: (state: State) => (sectionId: number): string | null => {
    const sectionFound = state.sections.find(section => section.id === sectionId);

    if (sectionFound) {
      const lastSectionBlockIndex = sectionFound.blockUuids.length - 1;

      return sectionFound.blockUuids[lastSectionBlockIndex] ?? null;
    }

    return null;
  },

  firstBlockUuid(state: State): string | null {
    return state.blocks[0]?.uuid ?? null;
  },

  lastBlockUuid(state: State): string | null {
    const lastBlockIndex = state.blocks.length - 1;

    return state.blocks[lastBlockIndex]?.uuid ?? null;
  },
};
