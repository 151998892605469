import { http } from '@/plugins/http';

import type { Enrollment } from '@/views/Enrollments/Show';
import type { EnrollmentResult } from '@/views/Enrollments/WalkThrough/Results';

export default {
  async getEnrollment(enrollmentId: number): Promise<Enrollment> {
    return http.fetch<Enrollment>(`enrollments/${enrollmentId}`);
  },

  async getEnrollmentResult(enrollmentId: number): Promise<EnrollmentResult> {
    return http.fetch<EnrollmentResult>(`enrollments/${enrollmentId}/result`);
  },

  async updateEnrollment(enrollmentId: number, enrollment: Partial<Enrollment>): Promise<null> {
    return http.patch(`enrollments/${enrollmentId}`, enrollment);
  },

  async deleteEnrollment(id: number): Promise<void> {
    await http.delete(`/enrollments/${id}`);
  },
};
