import type { State, Training } from './vuex';
import type { TrainingFilter, Training as StudentTraining } from '@/views/LMS/Users/Show/Trainings/index.d';

export default {
  setTraining(state: State, training: Training): void {
    state.training = training;
  },

  setTrainings(state: State, trainings: StudentTraining[]): void {
    state.trainings = trainings;
  },

  setTrainingAttendancesCompleted(state: State, attendancesCompleted: boolean): void {
    if (state.training) {
      state.training.attendancesCompleted = attendancesCompleted;
    }
  },

  setTrainingDateAttendance(state: State, trainingDateId: number): void {
    const index = state.training?.dates.findIndex(trainingDate => {
      return trainingDate.id === trainingDateId;
    }) ?? -1;

    if (index > -1 && state.training && state.training?.dates?.[index]) {
      state.training.dates[index].hasAttendances = true;
    }
  },

  setTrainingParticipants(state: State, training: Training): void {
    if (state.training?.id === training.id) {
      state.training.participants = training.participants;
    }
  },

  setSearchString(state: State, searchString: string): void {
    state.searchString = searchString;
  },

  setFilter(state: State, filter: TrainingFilter): void {
    state.filter = filter;
  },
};
