
  import { defineComponent } from 'vue';
  import { ChevronRightIcon } from '@heroicons/vue/solid';

  import type { PropType } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  export default defineComponent({
    name: 'AppBreadcrumbItem',

    components: {
      ChevronRightIcon,
    },

    props: {
      title: {
        type: String as PropType<string>,
        default: '',
      },

      route: {
        type: [String, Object] as PropType<RouteLocationRaw>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },
  });
