import type { PropType } from 'vue';
import type { LocationInputModelValue } from './index.d';

export default {
  modelValue: {
    type: Object as PropType<LocationInputModelValue>,
    default: null,
  },

  placeOptions: {
    type: Object as PropType<google.maps.places.AutocompleteOptions>,
    default: null,
  },
};
