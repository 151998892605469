
  import { defineComponent } from 'vue';
  import ResponsiveImageHtmlMixin from '@/mixins/responsive-image-html-mixin';

  import type { PropType } from 'vue';

  export default defineComponent({
    name: 'AppResponsiveImageRender',

    mixins: [
      ResponsiveImageHtmlMixin,
    ],

    props: {
      imageHtml: {
        type: String as PropType<string>,
        required: true,
      },

      imageBindings: {
        type: Object as PropType<Record<string, unknown>>,
        default: undefined,
      },
    },

    render() {
      return this.renderImage(this.imageHtml, this.imageBindings);
    },
  });
