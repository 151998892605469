
  import { defineComponent } from 'vue';
  import { AppInputBase } from '@/components';
  import props from '@/components/AppInputBase/props';

  export default defineComponent({
    name: 'AppInputEmail',

    components: {
      AppInputBase,
    },

    inheritAttrs: false,

    props,
  });
