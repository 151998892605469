import { defineComponent, h } from 'vue';
import { htmlStringToNodes } from '@/helpers';

export default defineComponent({
  methods: {
    renderImage(imageHtml: string, imageBindings?: Record<string, unknown>): ReturnType<typeof h> {
      const image = htmlStringToNodes(imageHtml)[0] as HTMLImageElement;
      const imageAttributes = { ...(imageBindings ?? {}) };

      [...image.attributes].forEach((imageAttribute: Attr) => {
        imageAttributes[imageAttribute.name] = imageAttribute.value;
      });

      return h(image.localName, imageAttributes);
    },
  },
});
