
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';
  import type { Sizes, DomClassesPerSize } from '.';

  const domClassesPerSize: DomClassesPerSize = {
    tiny: ['h-6', 'w-6'],
    small: ['h-8', 'w-8'],
    medium: ['h-10', 'w-10'],
    large: ['h-12', 'w-12'],
    huge: ['h-17', 'w-17'],
    extraHuge: ['h-20', 'w-20'],
  };

  export default defineComponent({
    name: 'AppAvatar',

    props: {
      size: {
        type: String as PropType<Sizes>,
        default: 'medium',
        validator(this: void, size: string): boolean {
          return ['tiny', 'small', 'medium', 'large', 'huge', 'extraHuge'].includes(size);
        },
      },

      src: {
        type: String as PropType<string>,
        default: null,
      },

      name: {
        type: String as PropType<string>,
        required: true,
      },
    },

    data() {
      return {
        domClassesPerSize,
      };
    },

    computed: {
      firstLetter(): string {
        return this.name.charAt(0);
      },
    },
  });
