
  import { upperFirst } from 'lodash';
  import { defineComponent } from 'vue';
  import { mapState } from 'vuex';
  import { MenuButton } from '@headlessui/vue';
  import { ChevronDownIcon } from '@heroicons/vue/outline';
  import { AppDropdownMenu, AppAvatar, AppBadge } from '@/components';
  import { mapGettersTyped } from '@/store/helpers';
  import { navigationItems } from './navigation-items';
  import MainMenuMobile from './MainMenu/Mobile.vue';
  import KennissLogo from '@/assets/KennissLogo.vue';
  import RouterMixin from '@/mixins/router-mixin';
  import CompanyLogo from './CompanyLogo.vue';

  import type { DropdownItem } from '@/components/AppDropdownMenu';
  import type { DynamicDomClassesObject } from '@/typings/class-style-binding';
  import type { RouterPushReturnType } from '@/typings/vue-router.d';
  import type { RootGettersMapped } from '@/typings/vuex';
  import type { NavigationItem } from '.';

  export default defineComponent({
    name: 'PrimaryNav',

    components: {
      MainMenuMobile,
      AppAvatar,
      AppBadge,
      CompanyLogo,
      AppDropdownMenu,
      MenuButton,
      KennissLogo,
      ChevronDownIcon,
    },

    mixins: [
      RouterMixin,
    ],

    emits: [
      'logout',
    ],

    data() {
      return {
        open: false,
      };
    },

    computed: {
      ...mapState([
        'company',
        'user',
      ]),

      ...mapGettersTyped<RootGettersMapped>([
        'companyRole',
        'userHasAnyRole',
        'userHasPermission',
      ]),

      dynamicDomClasses(): DynamicDomClassesObject {
        const { showPrimaryNav } = this.$route.meta;
        const hideNavOnMobile = typeof showPrimaryNav === 'object'
          && !showPrimaryNav.mobile;

        return {
          '!hidden': showPrimaryNav === false || hideNavOnMobile,
          'md:!block': hideNavOnMobile,
        };
      },

      userNavigation(): DropdownItem[] {
        const userNavigationItems: DropdownItem[] = [
          {
            name: 'profile',
            content: this.$t('navigation.profile'),
            permission: 'profile.preferences',
            onSelectCallback: async (): RouterPushReturnType => {
              return this.$router.push({ name: 'profile.preferences' });
            },
          },
          {
            name: 'userManagement',
            content: upperFirst(this.$tc('roles.administrator', 0)),
            permission: 'admin.users.list',
            onSelectCallback: async (): RouterPushReturnType => {
              return this.$router.push({ name: 'admin.users.list' });
            },
          },
          {
            name: 'logout',
            content: this.$t('logout'),
            onSelectCallback: (): void => this.$emit('logout'),
          },
        ];

        return userNavigationItems.filter(nav => {
          if (nav.permission) {
            return this.can(nav.permission);
          }

          return true;
        });
      },

      permittedItems(): NavigationItem[] {
        return navigationItems.filter(nav => {
          if (nav.hideOnProduction && process.env.NODE_ENV === 'production') {
            return false;
          }

          if (nav.roles) {
            if (this.userHasAnyRole(nav.roles)) {
              return nav.permission ? this.can(nav.permission) : true;
            }

            return false;
          }

          return nav.permission ? this.can(nav.permission) : true;
        });
      },
    },
  });
