import { cloneDeep } from 'lodash';

import type { State, EnrollmentGroup, Enrollment } from './vuex';

export default {
  filteredEnrollmentGroups(state: State): EnrollmentGroup[] {
    const { enrollmentGroups, filter } = state;
    const enrollmentGroupsClone = cloneDeep(enrollmentGroups);

    const filterEnrollments = (enrollment: Enrollment): boolean => {
      return state.filter === 'showAll' || enrollment.status === state.filter;
    };

    return enrollmentGroupsClone.filter(enrollmentGroup => {
      if (enrollmentGroup.type === 'default' && enrollmentGroup.enrollments?.length > 0) {
        return enrollmentGroup.enrollments.some(filterEnrollments);
      }

      const { percentage } = enrollmentGroup.progress;

      return filter === 'showAll'
        || (filter === 'open' && percentage === 0)
        || (filter === 'in-progress' && percentage > 0 && percentage < 100)
        || (filter === 'done' && percentage === 100);
    }).map(enrollmentGroup => {
      if (enrollmentGroup.type === 'default' && enrollmentGroup.enrollments?.length > 0) {
        const filteredEnrollments = enrollmentGroup.enrollments.filter(filterEnrollments);

        return { ...enrollmentGroup, enrollments: filteredEnrollments };
      }

      return enrollmentGroup;
    });
  },

  filteredCourseGroupEnrollments(state: State): Enrollment[] {
    const { courseGroup, filter } = state;
    const courseGroupClone = cloneDeep(courseGroup);

    return courseGroupClone?.enrollments?.filter((enrollment: Enrollment) => {
      return filter === 'showAll' || filter === enrollment.status;
    }) ?? [];
  },
};
