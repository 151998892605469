import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, Transition as _Transition } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["title", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_AppDynamicIcon = _resolveComponent("AppDynamicIcon")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_HeadlessUiMenu = _resolveComponent("HeadlessUiMenu")!

  return (_ctx.visibleItems.length > 0)
    ? (_openBlock(), _createBlock(_component_HeadlessUiMenu, {
        key: 0,
        id: _ctx.menuId,
        as: _ctx.renderMenuAs,
        class: "relative inline-block text-left"
      }, {
        default: _withCtx(({ open }) => [
          _createVNode(_component_MenuButton, {
            ref: "trigger",
            class: _normalizeClass(_ctx.extendedMenuButtonDomClasses)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "trigger")
            ]),
            _: 3
          }, 8, ["class"]),
          _createVNode(_Transition, {
            "enter-active-class": "transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-95",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-from-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-95"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_Teleport, {
                disabled: _ctx.disableFloatingTeleport,
                to: "body"
              }, [
                _withDirectives(_createVNode(_component_MenuItems, {
                  ref: "dropdownItems",
                  static: _ctx.multiple,
                  as: _ctx.renderMenuItemsAs,
                  class: _normalizeClass(_ctx.menuItemsDomClasses)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (item, index) => {
                      return (_openBlock(), _createBlock(_component_MenuItem, {
                        key: `${_ctx.menuId}-item-${index}`,
                        disabled: item.disabled ?? false,
                        as: item.renderAs ?? _ctx.renderMenuItemAs
                      }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("a", {
                            href: "#",
                            class: _normalizeClass(_ctx.getMenuItemClasses(item, active)),
                            onClick: _withModifiers(($event: any) => (_ctx.select(item, $event)), ["prevent"])
                          }, [
                            _renderSlot(_ctx.$slots, "item", {
                              item: item,
                              active: active,
                              selected: _ctx.isSelected(item)
                            }, () => [
                              (item.icon)
                                ? (_openBlock(), _createBlock(_component_AppDynamicIcon, {
                                    key: 0,
                                    icon: item.icon,
                                    "extended-dom-classes": _ctx.iconDomClasses
                                  }, null, 8, ["icon", "extended-dom-classes"]))
                                : _createCommentVNode("", true),
                              (typeof item.content === 'object')
                                ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.content), { key: 1 }))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 2,
                                    class: "block overflow-hidden overflow-ellipsis flex-1",
                                    title: item.content.replace(/(<([^>]+)>)/gi, ''),
                                    innerHTML: item.content
                                  }, null, 8, _hoisted_2)),
                              (_ctx.isSelected(item))
                                ? (_openBlock(), _createBlock(_component_CheckIcon, {
                                    key: 3,
                                    class: "ml-auto h-5 w-5",
                                    "aria-hidden": "true"
                                  }))
                                : _createCommentVNode("", true)
                            ])
                          ], 10, _hoisted_1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "as"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["static", "as", "class"]), [
                  [_vShow, open]
                ])
              ], 8, ["disabled"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 3
      }, 8, ["id", "as"]))
    : _createCommentVNode("", true)
}