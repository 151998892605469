import type { PropType } from 'vue';

export default {
  modelValue: {
    type: Number as PropType<number>,
    default: null,
  },

  min: {
    type: Number as PropType<number>,
    default: undefined,
  },

  max: {
    type: Number as PropType<number>,
    default: undefined,
  },

  step: {
    type: [Number, String] as PropType<number | 'any'>,
    default: undefined,
    validator(this: void, value: number | 'any'): boolean {
      if (typeof value === 'string' && value !== 'any') {
        console.error('When providing a string for prop \'step\','
          + `the value must be 'any'. You've provided the value '${value}'.`);

        return false;
      }

      return true;
    },
  },
};
