import type { State } from './vuex';
import type { Enrollment } from '@/views/Enrollments/Show';
import type { EnrollmentResult } from '@/views/Enrollments/WalkThrough/Results';
import type { EnrollmentStatus } from '@/typings/LMS/enrollment';

export default {
  setEnrollment(state: State, enrollment: Enrollment): void {
    state.enrollment = enrollment;
  },

  setEnrollmentResult(state: State, enrollmentResult: EnrollmentResult): void {
    state.enrollmentResult = enrollmentResult;
  },

  setEnrollmentStatus(state: State, status: EnrollmentStatus): void {
    if (state.enrollment) {
      state.enrollment.status = status;
    }
  },

  setEnrollmentProgress(state: State, progress: number): void {
    if (state.enrollment) {
      if (state.enrollment.progress) {
        state.enrollment.progress.percentage = progress;
      } else {
        state.enrollment.progress = {
          sectionId: null,
          blockUuid: null,
          percentage: progress,
        };
      }
    }
  },
};
