
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { mapState } from 'vuex';
  import { defineComponent } from 'vue';
  import { MenuIcon, XIcon as CloseIcon, DesktopComputerIcon } from '@heroicons/vue/outline';
  import DialogHistoryStateMixin from '@/mixins/dialog-history-state-mixin';
  import CompanyLogo from '@/layouts/CompanyLogo.vue';
  import RouterMixin from '@/mixins/router-mixin';
  import { AppBadge } from '@/components';

  import type { PropType } from 'vue';
  import type { NavigationItem } from '@/layouts';

  export default defineComponent({
    name: 'MainMenuMobile',

    components: {
      AppBadge,
      CompanyLogo,
      MenuIcon,
      CloseIcon,
      DesktopComputerIcon,
      HeadlessUiDialog: Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
    },

    mixins: [
      DialogHistoryStateMixin,
      RouterMixin,
    ],

    props: {
      navigationItems: {
        type: Object as PropType<NavigationItem[]>,
        required: true,
      },
    },

    data() {
      return {
        mainMenuOpen: false,
      };
    },

    computed: {
      ...mapState([
        'user',
        'company',
      ]),
    },

    watch: {
      mainMenuOpen(): void {
        this.handleDialogOpenChange('mainMenuOpen', this.mainMenuOpen, this.closeMainMenu);
      },
    },

    methods: {
      openMainMenu(): void {
        this.mainMenuOpen = true;
      },

      closeMainMenu(): void {
        this.mainMenuOpen = false;
      },

      preventHistoryChangeOnMainMenuClose(): void {
        window.history.replaceState({
          mainMenuOpen: false,
        }, '');
      },

      navigationItemIsAuthorTool(navigationItem: NavigationItem): boolean {
        return navigationItem.name === 'navigation.authorTool';
      },

      async navigate(navigationItem: NavigationItem): Promise<void> {
        this.preventHistoryChangeOnMainMenuClose();
        this.closeMainMenu();

        const hasMobilePermission = !navigationItem.mobilePermission
          || this.can(navigationItem.mobilePermission);
        const route = navigationItem.mobileHref && hasMobilePermission
          ? navigationItem.mobileHref : navigationItem.href;

        await this.$router.push(route);
      },

      getNavigationItemDomClasses(navigationItem: NavigationItem, isActive: boolean): string[] {
        const domClasses: string[] = [];

        if (this.navigationItemIsAuthorTool(navigationItem)) {
          domClasses.push('flex', 'justify-between');

          if (!isActive) {
            domClasses.push('group', 'group-hover:bg-gray-900');
          }
        }

        if (isActive || this.navigationItemIsActive(navigationItem.name)) {
          domClasses.push('bg-black', 'text-white', 'primary-nav-link--active');
        } else {
          domClasses.push('text-gray-300', 'hover:bg-gray-700', 'hover:text-white');
        }

        return domClasses;
      },

      mustShowSignDocumentsCount(navigationItem: NavigationItem): boolean {
        return navigationItem.name === 'navigation.myDocuments'
          && this.user?.signDocumentsCount > 0;
      },
    },
  });
