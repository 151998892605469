
  import { defineComponent } from 'vue';
  import { mapState, mapActions } from 'vuex';
  import ScreenDimensionsDialog from '@/layouts/ScreenDimensionsDialog.vue';
  import { mustShowNavigationBars, routeRequiresAuth } from '@/helpers';
  import { mapGettersTyped } from '@/store/helpers';
  import SecondaryNav from '@/layouts/SecondaryNav.vue';
  import InitialLoad from '@/layouts/InitialLoad.vue';
  import TertiaryNav from '@/layouts/TertiaryNav.vue';
  import PrimaryNav from '@/layouts/PrimaryNav.vue';

  import type { Company } from '@/typings/company';
  import type { RootGettersMapped } from '@/typings/vuex';

  const { documentElement } = document;

  export default defineComponent({
    components: {
      PrimaryNav,
      InitialLoad,
      SecondaryNav,
      TertiaryNav,
      ScreenDimensionsDialog,
    },

    data() {
      return {
        offsetTop: '60px',
        offsetBottom: '60px',
        showMainElement: false,
        promises: [] as Array<Promise<unknown>>,
        loaded: false,
      };
    },

    computed: {
      ...mapState([
        'company',
        'user',
      ]),

      ...mapGettersTyped<RootGettersMapped>([
        'userHasAnyRole',
      ]),

      showNavigationBars(): boolean {
        return mustShowNavigationBars(this.$route) && this.loaded;
      },

      onResponsiveRoute(): boolean {
        return this.$route.meta.isResponsive ?? true;
      },

      showScreenDimensionDialog(): boolean {
        const mayVisitPage = this.guardCheck(this.$route);
        const mustNotShowNoAccessOverlay = !this.userHasAnyRole(this.$route.meta?.showNoAccessOverlayForRoles ?? []);

        return !!this.user
          && this.$route.meta.isResponsive === false
          && (mayVisitPage || mustNotShowNoAccessOverlay);
      },
    },

    watch: {
      offsetTop() {
        documentElement.style.setProperty('--header-container-height', this.offsetTop);
      },

      offsetBottom() {
        documentElement.style.setProperty('--footer-container-height', this.offsetBottom);
      },

      loaded() {
        if (this.loaded) {
          this.$nextTick(() => {
            this.showMainElement = true;
          });
        } else {
          this.showMainElement = false;
        }
      },

      'company.name': {
        immediate: true,
        handler(name: string) {
          if (name) {
            window.document.title = this.$t('companyPageTitle', { companyName: name });
          } else {
            window.document.title = this.$t('pageTitle');
          }
        },
      },

      'company.primaryColor': {
        immediate: true,
        handler(primaryColor: string): void {
          this.setPrimaryColor(primaryColor);
        },
      },

      'company.hasDarkPrimaryColor': {
        immediate: true,
        handler(): void {
          this.setPrimaryOppositeColor();
        },
      },

      onResponsiveRoute: {
        immediate: true,
        handler(value) {
          const method = value ? 'add' : 'remove';
          const className = 'is-responsive';

          document.documentElement.classList[method](className);
          document.body.classList[method](className);
        },
      },
    },

    created() {
      this.promises = [
        this.getCurrentCompany(),
      ];

      if (this.$route.meta.withoutGetUser !== true) {
        this.promises.push(this.getUser());
      }
    },

    async mounted() {
      await Promise.all(this.promises);

      if (!this.guardCheck(this.$route, true)) {
        await this.$router.replace(this.dashboardRoute);
      }

      this.loaded = true;

      this.setupRouterGuardWatch();
      this.initHeaderObserver();
      this.initFooterObserver();

      // eslint-disable-next-line unicorn/prefer-add-event-listener
      window.onbeforeunload = (event: BeforeUnloadEvent): string | null => {
        if (this.$queue.isRunning() || this.$queue.enqueuedTasks.length > 0) {
          const bewareMessage = this.$t('bewareQueueIsRunning');
          event.returnValue = bewareMessage;
          return bewareMessage;
        }

        return null;
      };
    },

    methods: {
      ...mapActions([
        'setCompany',
        'setGoBackRoute',
        'getCurrentUser',
      ]),

      async getUser(): Promise<void> {
        try {
          await this.getCurrentUser();

          if (!this.user && this.$route.meta.requiresAuth !== false) {
            await this.redirectToLogin();
          }
        } catch {
          await this.logout();
        }
      },

      async redirectToLogin(): Promise<void> {
        const { $route } = this;
        let requestedUrl: string | undefined = $route.fullPath;

        if (requestedUrl === '/' || requestedUrl === '') {
          requestedUrl = undefined;
        } else {
          requestedUrl = encodeURIComponent(requestedUrl);
        }

        if ($route.path !== '/login' && routeRequiresAuth($route)) {
          await this.$router.replace({
            name: 'auth.login',
            query: {
              redirect: requestedUrl,
            },
          });
        }
      },

      async logout(): Promise<void> {
        await this.$store.dispatch('logout');
        await this.$router.replace({ name: 'auth.login' });
      },

      async getCurrentCompany(): Promise<void> {
        try {
          const company = await this.$http.fetch<Company>('company-by-current-domain');
          this.setCompany(company);
        } catch {
          this.$router.replace({ name: 'page-not-found' });
        }
      },

      setPrimaryColor(primaryColor: string): void {
        document.documentElement.style.setProperty('--color-primary', primaryColor);

        const themeColorMetaElement = document.getElementById('theme-color') as HTMLMetaElement;
        const msTileColorMetaElement = document.getElementById('ms-tile-color') as HTMLMetaElement;

        if (themeColorMetaElement) {
          themeColorMetaElement.setAttribute('content', primaryColor);
        }

        if (msTileColorMetaElement) {
          msTileColorMetaElement.setAttribute('content', primaryColor);
        }
      },

      setPrimaryOppositeColor(): void {
        if (this.company) {
          const { hasDarkPrimaryColor } = this.company;
          const color = hasDarkPrimaryColor ? 'var(--color-white)' : 'var(--color-gray-900)';

          document.documentElement.style.setProperty('--primary-opposite-color', color);
        }
      },

      setupRouterGuardWatch(): void {
        this.$router.beforeEach((to, from, next) => {
          if (routeRequiresAuth(to) && !this.user) {
            next({ name: 'auth.login', replace: true });
            return;
          }

          if (!this.guardCheck(to, true)) {
            this.$toast.error({
              title: this.$t('interceptors.403.title'),
              description: this.$t('interceptors.403.description'),
            });

            next(from);

            return;
          }

          next();
        });
      },

      initHeaderObserver(): void {
        const headerContainer = this.$refs.headerContainer as HTMLElement;
        const observer = new ResizeObserver(entries => {
          window.requestAnimationFrame(() => {
            for (const entry of entries) {
              this.offsetTop = `${entry.contentRect.height}px`;
            }
          });
        });

        observer.observe(headerContainer);
      },

      initFooterObserver(): void {
        const footerContainer = this.$refs.footerContainer as HTMLElement;
        const observer = new ResizeObserver(entries => {
          window.requestAnimationFrame(() => {
            for (const entry of entries) {
              this.offsetBottom = `${entry.contentRect.height}px`;
            }
          });
        });

        observer.observe(footerContainer);
      },
    },
  });
