import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "aria-selected", "aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "radioButton",
    disabled: _ctx.isDisabled,
    "aria-selected": _ctx.isSelected,
    "aria-disabled": _ctx.isDisabled,
    class: _normalizeClass([[
      ..._ctx.buttonDomClassesPerSize[_ctx.size],
      ...(_ctx.nothingIsSelected
        ? _ctx.buttonDomClassesWhenNothingIsSelected
        : _ctx.buttonDomClassesWhenSomethingIsSelected
      ),
      ..._ctx.convertDomClassesPropToArray(_ctx.extendedDomClasses ?? []),
    ], "app-radio-button relative flex-1 flex items-center justify-center font-medium border border-gray-300 first:rounded-l last:rounded-r disabled:cursor-not-allowed"]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select(_ctx.uniqueValue)))
  }, [
    _renderSlot(_ctx.$slots, "default", { selected: _ctx.isSelected })
  ], 10, _hoisted_1))
}