
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { defineComponent } from 'vue';
  import { XIcon as CloseIcon } from '@heroicons/vue/outline';
  import WindowDimensionsMixin from '@/mixins/window-dimensions-mixin';
  import { AppButton, AppLoader } from '@/components';
  import props from './props';

  import type { DialogTransitionProperties, DomClassesPerSize } from '.';

  const domClassesPerSize: DomClassesPerSize = {
    small: ['max-w-xl'],
    medium: ['max-w-4xl'],
    large: ['md:container'],
  };

  export default defineComponent({
    name: 'AppModal',

    components: {
      AppButton,
      AppLoader,
      HeadlessUiDialog: Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      CloseIcon,
    },

    mixins: [
      WindowDimensionsMixin,
    ],

    props,

    emits: [
      'open',
      'close',
      'submit',
    ],

    data() {
      return {
        isOpen: false as boolean,
        domClassesPerSize,
      };
    },

    computed: {
      transitionProperties(): DialogTransitionProperties {
        if (this.breakpointLowerThan('md')) {
          return {
            enter: 'transition ease-in-out duration-300 transform',
            enterFrom: 'translate-y-full',
            enterTo: 'translate-y-0',
            leave: 'transition ease-in-out duration-300 transform',
            leaveFrom: 'translate-y-0',
            leaveTo: 'translate-y-full',
          };
        }

        return {
          enter: 'transition ease-out duration-300',
          enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
          enterTo: 'opacity-100 translate-y-0 sm:scale-100',
          leave: 'transition ease-in duration-200',
          leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
          leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        };
      },
    },

    mounted() {
      if (!this.unmount) {
        document.documentElement.style.overflow = '';
      }
    },

    methods: {
      open(): void {
        this.$emit('open');

        this.isOpen = true;

        if (!this.unmount) {
          document.documentElement.style.overflow = 'hidden';
        }
      },

      close(): void {
        if (!this.unmount) {
          document.documentElement.style.overflow = '';
        }

        if (!this.unmount && !this.isOpen) {
          return;
        }

        this.isOpen = false;

        this.$emit('close');
      },
    },
  });
