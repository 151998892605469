import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBadge = _resolveComponent("AppBadge")!

  return (_openBlock(), _createBlock(_component_AppBadge, {
    class: _normalizeClass(_ctx.badgeColorProfileDomClasses)
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.courseType.name), 1)
    ]),
    _: 1
  }, 8, ["class"]))
}