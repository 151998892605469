import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_2 = { class: "flex flex-col xs:flex-row w-96 max-w-full xs:w-full" }
const _hoisted_3 = {
  ref: "signaturePad",
  class: "relative z-10 w-full h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RefreshIcon = _resolveComponent("RefreshIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.labelText && !_ctx.labelIsHidden) || _ctx.cornerHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            class: _normalizeClass([_ctx.dynamicLabelDomClasses, "block font-semibold text-gray-900"])
          }, _toDisplayString(_ctx.labelText), 3),
          (_ctx.cornerHint)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([{ 'justify-self-right': !_ctx.labelText || _ctx.labelIsHidden }, "text-gray-700"]),
                "data-test-corner-hint": ""
              }, _toDisplayString(_ctx.cornerHint), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _mergeProps({ class: "relative bg-gray-200 border border-gray-400 rounded h-48 max-w-full after:absolute after:block after:bottom-10 after:right-4 after:left-4 after:bg-gray-400 after:h-[1px] after:pointer-events-none w-96 select-none" }, _ctx.canvasWrapperBindings, { "data-test-canvas-wrapper": "" }), [
        _createElementVNode("canvas", _hoisted_3, null, 512)
      ], 16),
      _createVNode(_component_AppButton, {
        disabled: _ctx.disabledResetButton,
        type: "secondary",
        size: "small",
        class: "ml-2 mt-2 self-end",
        onClick: _ctx.clear
      }, {
        leadingIcon: _withCtx(() => [
          _createVNode(_component_RefreshIcon, { class: "w-4 h-4" })
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('again')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}