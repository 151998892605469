
  import autosize from 'autosize';
  import { defineComponent } from 'vue';
  import { ExclamationCircleIcon } from '@heroicons/vue/solid';
  import extendedInputMethodsMixin from '@/components/AppInputBase/methods-mixin';
  import { convertDomClassesPropToArray } from '@/helpers';
  import props from './props';

  import type { DynamicDomStylingObject } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'AppTextarea',

    components: {
      ExclamationCircleIcon,
    },

    mixins: [
      extendedInputMethodsMixin,
    ],

    props,

    emits: [
      'update:modelValue',
      'blur',
    ],

    data() {
      return {
        leadingAddonWidth: null as null | number,
        trailingAddonWidth: null as null | number,
        errorIconWidth: null as null | number,
      };
    },

    computed: {
      textareaId(): string {
        return `app-textarea-${this.componentId}`;
      },

      errorMessageId(): string {
        return `${this.textareaId}-error-message`;
      },

      textareaWrapperDomClasses(): string[] {
        const domClasses = [];

        if (this.labelText && !this.labelIsHidden) {
          domClasses.push('mt-1');
        }

        if (this.extendedTextareaWrapperDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedTextareaWrapperDomClasses,
          ));
        }

        return domClasses;
      },

      textareaDomClasses(): string[] {
        let domClasses = [
          'border-gray-400',
          'focus:ring-blue',
          'focus:border-blue',
        ];

        if (this.hasError) {
          domClasses = [
            'pr-10',
            'border-red-500',
            'placeholder-red-500',
            'focus:border-red-500',
            'focus:ring-red-500',
            'focus:outline-none',
          ];
        }

        if (this.extendedTextareaDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedTextareaDomClasses,
          ));
        }

        return domClasses;
      },

      dynamicLabelDomClasses(): string[] {
        const domClasses = [];

        if (this.labelIsHidden) {
          domClasses.push('sr-only');
        }

        if (this.extendedLabelDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedLabelDomClasses,
          ));
        }

        return domClasses;
      },

      textareaStyling(): DynamicDomStylingObject {
        const styling: Record<string, string> = {};

        if (this.leadingAddonWidth !== null && this.leadingAddonWidth > 0) {
          styling.paddingLeft = `${this.leadingAddonWidth + 8}px`;
        }

        if (this.trailingAddonWidth !== null && this.trailingAddonWidth > 0) {
          const errorIconWidth = this.errorIconWidth ? this.errorIconWidth : 0;
          styling.paddingRight = `${this.trailingAddonWidth + errorIconWidth + 8}px`;
        }

        return styling;
      },

      errorIconStyling(): DynamicDomStylingObject | null {
        if (this.trailingAddonWidth !== null && this.trailingAddonWidth > 0) {
          return { marginRight: `${this.trailingAddonWidth}px` };
        }

        return null;
      },
    },

    mounted() {
      if (this.autosize) {
        autosize(this.$refs.inputField as HTMLTextAreaElement);
      }

      if (this.$slots.leadingAddon) {
        const leadingAddon = this.$refs.leadingAddon as HTMLDivElement;
        this.leadingAddonWidth = leadingAddon.offsetWidth;
      }

      if (this.$slots.trailingAddon) {
        const trailingAddon = this.$refs.trailingAddon as HTMLDivElement;
        this.trailingAddonWidth = trailingAddon.offsetWidth;
      }

      if (this.hasError) {
        const errorIcon = this.$refs.errorIcon as HTMLDivElement;
        this.errorIconWidth = errorIcon.offsetWidth;
      }
    },

    methods: {
      onInput(event: InputEvent): void {
        const textarea = event.target as HTMLTextAreaElement;
        const value = this.modelModifiers?.trim ? textarea.value.trim() : textarea.value;

        this.$emit('update:modelValue', value);
      },

      onBlur(event: FocusEvent): void {
        this.$emit('blur', event);
      },
    },
  });
