import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    focus(): void {
      const inputField = (this?.$refs?.inputField as HTMLInputElement) ?? null;

      if (inputField) {
        inputField.focus();
      }
    },

    select(): void {
      const inputField = (this?.$refs?.inputField as HTMLInputElement) ?? null;

      if (inputField) {
        inputField.select();
      }
    },
  },
});
