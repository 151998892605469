import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sticky top-14 z-10 px-3 py-3 bg-white md:pt-0 md:p-5 md:static md:mt-5 border-b border-gray-300"
}
const _hoisted_2 = {
  key: 1,
  class: "px-3 md:px-5 border-b border-gray-300 mt-4 pb-4"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["id", "onClick"]
const _hoisted_5 = {
  key: 3,
  class: "md:py-2 px-5",
  "data-test-empty-message": ""
}
const _hoisted_6 = {
  key: 4,
  class: "md:py-2 px-5",
  "data-test-loading-message": ""
}
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppInputSearch = _resolveComponent("AppInputSearch")!
  const _component_PlusCircleIcon = _resolveComponent("PlusCircleIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CreateUserForm = _resolveComponent("CreateUserForm")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.selectAllToggle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cursor-pointer select-none mb-2",
          "data-test-toggle-select-all": "",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAllItems && _ctx.toggleAllItems(...args)))
        }, [
          _renderSlot(_ctx.$slots, "selectAllToggle")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{ '!border-red-500': _ctx.hasError }, "rounded border border-gray-300 text-gray-900 bg-white"]),
      "data-test-checklist-wrapper": ""
    }, [
      (_ctx.searchable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_AppInputSearch, {
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchString) = $event)),
                _ctx.search
              ],
              placeholder: _ctx.searchPlaceholder ?? _ctx.$t('searchOnName'),
              "label-is-hidden": true,
              size: "medium",
              "extended-input-dom-classes": "focus:ring-transparent focus:border-black !pr-3",
              class: "w-full",
              onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, {
              trailingAddon: _withCtx(() => [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_AppLoader, {
                      key: 0,
                      size: "xs",
                      class: "mr-3 bg-white"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.canCreateUsers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.formVisible)
              ? (_openBlock(), _createBlock(_component_AppButton, {
                  key: 0,
                  type: "default",
                  size: "large",
                  class: "flex w-full text-center justify-center",
                  "data-test-create-user-button": "",
                  onClick: _ctx.openCreateUserForm
                }, {
                  leadingIcon: _withCtx(() => [
                    _createVNode(_component_PlusCircleIcon, {
                      class: "h-4 w-4 text-gray-500 mt-0.5",
                      "data-test-add-icon": ""
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(_ctx.$tc('assignUsersModal.createUsers.newUser', [_ctx.traineeRole()])), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.formVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_CreateUserForm, {
                    "email-required": _ctx.emailRequired,
                    submitting: _ctx.submitting,
                    "data-test-create-user-form": "",
                    onSubmitUser: _ctx.submitCreateUserForm,
                    onCloseForm: _ctx.closeCreateUserForm
                  }, null, 8, ["email-required", "submitting", "onSubmitUser", "onCloseForm"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.mutableList.length > 0)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 2,
            class: _normalizeClass([_ctx.extendedListDomClasses, "relative divide-y divide-gray-200 md:overflow-y-auto md:max-h-96"]),
            role: "list"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mutableList, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                id: `app-checklist-item-${item.id}`,
                key: item.id,
                class: "flex",
                "data-test-list-item": "",
                onClick: () => _ctx.disabledItems.includes(item.id) ? _ctx.clickDisabledItem(item) : undefined
              }, [
                _createVNode(_component_AppCheckbox, {
                  "model-value": _ctx.selection.includes(item.id),
                  disabled: _ctx.disabledItems.includes(item.id),
                  "extended-label-dom-classes": "flex items-center py-4 pl-3 md:pl-5",
                  class: "!mb-0 w-full px-3 md:px-5 cursor-pointer items-center",
                  "onUpdate:modelValue": value => _ctx.toggleItem(value, item.id)
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "item", { item: item }, () => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["model-value", "disabled", "onUpdate:modelValue"])
              ], 8, _hoisted_4))
            }), 128))
          ], 2))
        : (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _renderSlot(_ctx.$slots, "noResults", { searchString: _ctx.searchString }, () => [
                _createTextVNode(_toDisplayString(_ctx.$t('noResultsFound')), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('loading')), 1)),
      _renderSlot(_ctx.$slots, "footer")
    ], 2),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          id: _ctx.errorMessageId,
          class: "mt-2 text-sm text-red-500",
          "data-test-error-message": ""
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}