
  import { defineComponent } from 'vue';
  import SignaturePad from 'signature_pad';
  import { RefreshIcon } from '@heroicons/vue/solid';
  import { convertDomClassesPropToArray } from '@/helpers';
  import { AppButton } from '@/components';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'AppSignature',

    components: {
      AppButton,
      RefreshIcon,
    },

    props: {
      modelValue: {
        type: String as PropType<string>,
        default: null,
      },

      labelText: {
        type: String as PropType<string>,
        default: null,
      },

      labelIsHidden: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      cornerHint: {
        type: String as PropType<string>,
        default: null,
      },

      extendedLabelDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      disabledResetButton: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      canvasWrapperBindings: {
        type: Object as PropType<Record<string | number | symbol, unknown>>,
        default: undefined,
      },
    },

    emits: [
      'update:modelValue',
    ],

    data() {
      return {
        signature: null as SignaturePad | null,
      };
    },

    computed: {
      dynamicLabelDomClasses(): string[] {
        const domClasses = [];

        if (this.labelIsHidden) {
          domClasses.push('sr-only');
        }

        if (this.extendedLabelDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedLabelDomClasses,
          ));
        }

        return domClasses;
      },
    },

    watch: {
      disabled(value) {
        if (value) {
          this.signature?.off();
        } else {
          this.signature?.on();
        }
      },
    },

    mounted() {
      const canvas = this.$refs.signaturePad as HTMLCanvasElement;

      this.signature = new SignaturePad(canvas);
      this.signature.addEventListener('afterUpdateStroke', this.afterUpdateStroke);

      if (this.disabled) {
        this.signature.off();
      }

      this.resizeCanvas();

      window.addEventListener('resize', this.resizeAndClearCanvas);
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.resizeAndClearCanvas);
    },

    methods: {
      clear(): void {
        this.signature?.clear();

        this.$emit('update:modelValue', null);
      },

      afterUpdateStroke(): void {
        this.$emit('update:modelValue', this.signature?.toDataURL());
      },

      resizeAndClearCanvas(): void {
        this.resizeCanvas();
        this.clear();
      },

      resizeCanvas(): void {
        const canvas = this.$refs.signaturePad as HTMLCanvasElement;
        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) {
          return;
        }

        const ratio = Math.max(window.devicePixelRatio ?? 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvasContext.scale(ratio, ratio);
      },
    },
  });
