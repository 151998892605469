import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisclosureButton = _resolveComponent("DisclosureButton")!

  return (_openBlock(), _createBlock(_component_DisclosureButton, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, { "data-disclosure-button": "" }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}