import { defaultCompanyUserSortFunction, getSortedCockpitCompanyUsers } from '@/views/LMS/Cockpit/List';

import type { State, CockpitCompanyUser } from './vuex';

export default {
  sortedCompanyUsers(state: State): CockpitCompanyUser[] {
    if (state.cockpit) {
      const { cockpit, sortingTarget, targetSort } = state;

      if (sortingTarget && targetSort) {
        return getSortedCockpitCompanyUsers(cockpit, sortingTarget, targetSort);
      }

      return [...cockpit.companyUsers].sort(defaultCompanyUserSortFunction);
    }

    return [];
  },

  cockpitHasCompanyUsers(state: State): boolean {
    return (state.cockpit?.companyUsers ?? []).length > 0;
  },

  cockpitHasTargets(state: State): boolean {
    return (state.cockpit?.targets ?? []).length > 0;
  },

  cockpitHasContent(state: State): boolean {
    return (state.cockpit?.companyUsers ?? []).length > 0
      && (state.cockpit?.targets ?? []).length > 0;
  },
};
