import { orderBy } from 'lodash';

import type {
  State,
  CockpitItem,
  Cockpit,
  CockpitCompanyUser,
  CockpitTarget,
  CockpitTargetSort,
  CockpitPusherItem,
  ChangeTargetOrderPayload,
} from './vuex';

export default {
  setCockpit(state: State, cockpit: Cockpit | null): void {
    state.cockpit = cockpit;
  },

  addCockpitCompanyUsers(state: State, companyUsers: CockpitCompanyUser[]): void {
    if (state.cockpit) {
      state.cockpit.companyUsers = [...state.cockpit.companyUsers, ...companyUsers];
    }
  },

  addCockpitTargets(state: State, cockpitTargets: CockpitTarget[]): void {
    if (state.cockpit) {
      state.cockpit.targets = [...state.cockpit.targets, ...cockpitTargets];
    }
  },

  addCockpitItems(state: State, cockpitItems: CockpitItem[]): void {
    if (state.cockpit) {
      state.cockpit.items = [...state.cockpit.items, ...cockpitItems];
    }
  },

  disableLoadingUser(state: State, companyUserId: number): void {
    if (state.cockpit) {
      const companyUsers = state.cockpit.companyUsers;
      const companyUserIndex = companyUsers.findIndex(companyUser => {
        return companyUser.id === companyUserId;
      });
      const companyUser = companyUsers[companyUserIndex];

      if (companyUserIndex !== undefined && companyUser) {
        state.cockpit.companyUsers[companyUserIndex] = { ...companyUser, loading: false };
      }
    }
  },

  disableLoadingTarget(state: State, cockpitItem: Omit<CockpitPusherItem, 'items'>): void {
    const targetIndex = state.cockpit?.targets.findIndex(target => {
      return target.id === cockpitItem.targetId && target.type === cockpitItem.targetType;
    });

    if (targetIndex !== undefined && state.cockpit && state.cockpit.targets[targetIndex]) {
      state.cockpit.targets[targetIndex] = {
        ...state.cockpit.targets[targetIndex],
        loading: false,
      };
    }
  },

  deleteCockpitCompanyUser(state: State, companyUser: CockpitCompanyUser): void {
    const companyUserIndex = state.cockpit?.companyUsers.indexOf(companyUser);

    if (state.cockpit && companyUserIndex !== undefined && companyUserIndex > -1) {
      state.cockpit.companyUsers.splice(companyUserIndex, 1);

      state.cockpit.items = state.cockpit.items.filter(item => {
        return item.companyUserId !== companyUser.id;
      });
    }
  },

  deleteCockpitTarget(state: State, cockpitTarget: CockpitTarget): void {
    const targetIndex = state.cockpit?.targets.indexOf(cockpitTarget);

    if (state.cockpit && targetIndex !== undefined && targetIndex > -1) {
      state.cockpit.targets.splice(targetIndex, 1);

      state.cockpit.items = state.cockpit.items.filter(item => {
        return ![item.targetId, item.correspondingTargetId].includes(cockpitTarget.id);
      });

      if (state?.sortingTarget?.id === cockpitTarget.id) {
        state.targetSort = null;
        state.sortingTarget = null;
      }
    }
  },

  changeTargetOrder(state: State, payload: ChangeTargetOrderPayload): void {
    if (state.cockpit?.targets) {
      const { direction } = payload;
      const targets = state.cockpit?.targets ?? [];
      const targetToSwitch = payload.cockpitTarget;
      const targetToSwitchWith = targets.find((target, index) => {
        return targets[index - (direction === 'left' ? -1 : 1)] === payload.cockpitTarget;
      });

      if (targetToSwitchWith) {
        targets.forEach((target, index) => {
          target.order = index + 1;
        });

        targetToSwitch.order -= (direction === 'left' ? 1 : -1);
        targetToSwitchWith.order -= (direction === 'left' ? -1 : 1);

        const orderedTargets = orderBy(state.cockpit.targets, 'order');

        state.cockpit = { ...state.cockpit, targets: orderedTargets };
      }
    }
  },

  setSortingTarget(state: State, target: CockpitTarget | null): void {
    state.sortingTarget = target;
  },

  setTargetSort(state: State, sort: CockpitTargetSort): void {
    state.targetSort = sort;

    if (sort === null) {
      state.sortingTarget = null;
    }
  },
};
