import type { State } from './vuex';
import type { DateSlot } from '@/views/Trainings/Create/GeneralInformation';

export default {
  firstTrainingDate(state: State): DateSlot {
    const dates = state.training?.dates ?? [];

    return dates.sort((date1, date2): number => {
      return (date1?.date?.toMillis() ?? 0) - (date2?.date?.toMillis() ?? 0);
    })?.[0];
  },
};
