
  import {
    XCircleIcon as CircleCloseIcon,
    PlusCircleIcon,
    TrashIcon,
    PlayIcon,
    EyeIcon,
    RefreshIcon,
  } from '@heroicons/vue/solid';
  import { S3 } from 'aws-sdk';
  import { v4 as uuid } from 'uuid';
  import { defineComponent } from 'vue';
  import { mapState } from 'vuex';
  import ErrorMixin from '@/mixins/error-mixin';
  import { getHumanFileSize } from '@/helpers';
  import {
    AppAlert,
    AppButton,
    AppLoader,
    AppTabGroup,
    AppTabList,
    AppTab,
    AppTabPanels,
    AppTabPanel,
    AppModal,
    AppInputFile,
    AppVideoPlayer,
  } from '@/components';
  import MediaThumbnail from './MediaThumbnail.vue';
  import FallbackThumbnail from './FallbackThumbnail.vue';

  import type { PropType } from 'vue';
  import type { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Types, Media, VideoPayload } from '.';
  import type { ErrorResponse } from '@/mixins';

  export default defineComponent({
    name: 'AppMediaSelect',

    components: {
      AppAlert,
      AppVideoPlayer,
      AppInputFile,
      AppTabGroup,
      AppTabList,
      AppTab,
      AppTabPanels,
      AppTabPanel,
      AppModal,
      AppLoader,
      AppButton,
      FallbackThumbnail,
      MediaThumbnail,
      EyeIcon,
      PlayIcon,
      PlusCircleIcon,
      CircleCloseIcon,
      RefreshIcon,
      TrashIcon,
    },

    mixins: [
      ErrorMixin,
    ],

    inheritAttrs: false,

    props: {
      addMediaButtonText: {
        type: String as PropType<string>,
        default: null,
      },

      type: {
        type: [Array, String] as PropType<Types|Types[]>,
        default: null,
        validator(this: void, type: Types|Types[]): boolean {
          const types = ['image', 'video', 'document'];

          if (Array.isArray(type)) {
            return type.every(mediaItem => types.includes(mediaItem));
          }

          return types.includes(type);
        },
      },

      modelValue: {
        type: Object as PropType<Media>,
        default: null,
      },

      noPreview: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      showMediaDetails: {
        type: Boolean as PropType<boolean>,
        default: true,
      },

      errorMessage: {
        type: String as PropType<string>,
        default: null,
      },

      inputId: {
        type: String as PropType<string>,
        default: null,
      },

      previewContainerDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: 'bg-white p-6 border border-gray-400 flex rounded-md w-full',
      },

      isRequired: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      canChangeMedia: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },

    emits: [
      'update:modelValue',
      'open',
      'close',
    ],

    data() {
      return {
        loading: true,
        meta: null as Record<string, unknown> | null,
        page: 1,
        mediaList: [] as Media[],
        media: this.modelValue as Media | null,
        fileExtensionsError: null as string | null,
        previewPlaylistUrl: '' as string,
      };
    },

    computed: {
      ...mapState([
        'onAdministrativeDomain',
        'company',
      ]),

      errorMessageId(): string {
        return `${this.inputId}-error-message`;
      },

      acceptedFiles(): string | null {
        if (this.type === 'document') {
          return 'application/pdf';
        }

        if (Array.isArray(this.type)) {
          return this.type.map(type => {
            return `${type}/*`;
          }).join(',');
        }

        return this.type ? `${this.type}/*` : null;
      },

      hasNextPage(): boolean {
        return !!(this.meta?.links as Record<string, string> | null)?.next;
      },
    },

    watch: {
      modelValue(value) {
        this.media = value;
      },
    },

    mounted() {
      this.$echo.$on('media', 'video.transcoded', this.onMediaEchoEvent);
      this.$echo.$on('media', 'thumbnail.generated', this.onMediaEchoEvent);
    },

    beforeUnmount() {
      this.$echo.$off('media', 'video.transcoded');
      this.$echo.$off('media', 'thumbnail.generated');
    },

    methods: {
      addMedia(): void {
        if (this.canChangeMedia) {
          if (this.meta === null) {
            this.fetchMedia();
          }

          (this.$refs.mediaModal as typeof AppModal).open();
        }
      },

      async fetchMedia(): Promise<void> {
        this.loading = true;

        const media = await this.$http.fetch<Media[]>('/media', {
          filter: {
            type: this.type,
          },
          page: this.page,
        }, undefined, 0);

        this.meta = media.meta ?? null;
        this.mediaList = [...this.mediaList, ...media];

        this.loading = false;

        const tabPanels = this.$refs.tabPanels as typeof AppTabPanels;
        (tabPanels.$el as HTMLDivElement).dispatchEvent(new CustomEvent('scroll'));
      },

      selectMedia(media: Media): void {
        this.media = media;

        (this.$refs.mediaModal as typeof AppModal).close();
        this.$emit('update:modelValue', media);
      },

      onMediaEchoEvent(mediaData: Media): void {
        if (this.media && this.media.id === mediaData.id) {
          this.media = mediaData;

          this.$emit('update:modelValue', this.media);
        }

        this.mediaList = this.mediaList.map(media => {
          if (media.id === mediaData.id) {
            return mediaData;
          }

          return media;
        });
      },

      destroyMedia(mediaItem: Media): void {
        this.$http.delete(`/media/${mediaItem.id}`);

        const index = this.mediaList.findIndex(media => {
          return media.id === mediaItem.id;
        });

        if (index > -1) {
          this.mediaList.splice(index, 1);
        }
      },

      removeMedia(): void {
        if (this.canChangeMedia) {
          this.media = null;

          this.$emit('update:modelValue', null);
        }
      },

      closeModal(): void {
        this.errors = {};
        this.$emit('close');
      },

      uploadMedia(fileList: FileList): void {
        if (this.type === 'image' || this.type === 'document') {
          this.uploadFile(fileList);
        } else if (this.type === 'video') {
          this.uploadVideo(fileList);
        } else {
          const images = new DataTransfer();
          const videos = new DataTransfer();

          [...fileList].forEach(file => {
            if (file.type.match('image.*')) {
              images.items.add(file);
            } else if (file.type.match('video.*')) {
              videos.items.add(file);
            }
          });

          if (images.files.length > 0) {
            this.uploadFile(images.files);
          }

          if (videos.files.length > 0) {
            this.uploadVideo(videos.files);
          }
        }
      },

      async uploadFile(fileList: FileList): Promise<void> {
        const formData = new FormData();
        const fileNames: string[] = [];
        const uploaded = [] as Media[];

        [...fileList].forEach(file => {
          const id = uuid();
          let fileType = 'image';

          if (file.type.match('image.*')) {
            formData.append(`images[${id}]`, file);
          } else {
            formData.append(`documents[${id}]`, file);
            fileType = 'document';
          }
          fileNames.push(file.name);

          uploaded.push({
            id,
            name: file.name,
            url: '',
            size: getHumanFileSize(file.size),
            type: fileType,
            progress: 0,
          } as Media);
        });

        ((this.$refs.mediaTabMedia as typeof AppTab).$el as HTMLElement).click();
        this.mediaList = [...uploaded, ...this.mediaList];

        try {
          const uploadedPhotoList = await this.$http.post<Media[]>('media', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progress: { loaded: number; total: number }) => {
              const percentage = Math.round((progress.loaded / progress.total) * 100);

              this.mediaList.map(media => {
                if (fileNames.includes(media.name)) {
                  media.progress = percentage;
                }

                return media;
              });
            },
          });

          this.selectMedia(Object.values(uploadedPhotoList)[0]);
        } catch (error: unknown) {
          const errorResponse = error as ErrorResponse;
          this.errors = errorResponse.response.data.errors;

          const mediaErrors = Object.keys(this.errors).flatMap(errorKey => {
            return errorKey.split('.')[1];
          });

          this.mediaList = this.mediaList.filter(media => {
            return !mediaErrors.includes(media.id as string);
          });

          this.loading = false;
        }
      },

      async uploadVideo(fileList: FileList): Promise<void> {
        const promises = new Array<Promise<ManagedUpload.SendData>>();
        const uploadedVideos = [] as Media[];

        [...fileList].forEach(file => {
          const id = uuid();
          const upload = new S3.ManagedUpload({
            params: {
              Bucket: process.env.VUE_APP_AWS_BUCKET as string,
              Key: `${id}/${file.name}`,
              Body: file,
            },
          });

          upload.on('httpUploadProgress', progress => {
            const percentage = Math.round((progress.loaded / progress.total) * 100);
            const specificMedia = this.mediaList.find(media => {
              return media.id === id;
            });

            if (specificMedia) {
              specificMedia.progress = percentage;
            }
          });

          uploadedVideos.push({
            id,
            name: file.name,
            size: getHumanFileSize(file.size),
            progress: 0,
            type: 'video',
          } as Media);

          promises.push(upload.promise());
        });

        ((this.$refs.mediaTabMedia as typeof AppTab).$el as HTMLElement).click();
        this.mediaList = [...uploadedVideos, ...this.mediaList];

        const videosFromS3 = await Promise.all(promises);
        const postData = videosFromS3.map(s3Video => {
          const id = s3Video.Key.split('/')[0];
          const name = s3Video.Key.split('/').pop();

          return {
            id,
            name,
            location: s3Video.Key,
          } as VideoPayload;
        });

        const uploadedMediaList = await this.$http.post<Media[]>('media', {
          videos: postData,
        });

        uploadedMediaList.forEach(uploadedMedia => {
          this.mediaList.map(media => {
            if (media.id === uploadedMedia.id) {
              return uploadedMedia;
            }

            return media;
          });
        });

        this.selectMedia(uploadedMediaList[0]);
      },

      previewMedia(media: Media, ref = 'previewVideoModal'): void {
        if (media.type === 'document') {
          this.downloadMedia(media);
        } else {
          this.previewVideo(media, ref);
        }
      },

      previewVideo(video: Media, ref = 'previewVideoModal'): void {
        this.previewPlaylistUrl = video.url;
        (this.$refs[ref] as typeof AppModal).open();
      },

      downloadMedia(media: Media): void {
        window.open(media.url, '_blank');
      },

      scrollInPanels(event: Event): void {
        if (!this.loading && this.hasNextPage && event.currentTarget) {
          const { scrollHeight, scrollTop, clientHeight } = event.currentTarget as HTMLDivElement;

          if (scrollTop + clientHeight > (scrollHeight - 100)) {
            this.page += 1;

            this.fetchMedia();
          }
        }
      },
    },
  });
