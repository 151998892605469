import state from './state';
import mutations from './mutations';
import modules from './modules';
import actions from './actions';
import getters from './getters';

const namespaced = true;

const trainingModule = {
  state,
  mutations,
  modules,
  actions,
  getters,
  namespaced,
};

export default trainingModule;
