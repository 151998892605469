import type { RouteLocationRaw } from 'vue-router';
import type { User } from '@/typings/user';
import type { Company } from '@/typings/company';

export default {
  user: null as User | null,
  onAdministrativeDomain: false as boolean,
  company: null as Company | null,
  goBackRoute: '/' as RouteLocationRaw,
};
