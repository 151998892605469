
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToArray } from '@/helpers';
  import { AppButton, AppDynamicIcon, AppLoader } from '@/components';

  import type { PropType } from 'vue';
  import type { DomClassesProp, DynamicDomClassesObject } from '@/typings/class-style-binding';
  import type { ConfirmType, OnCancel, OnConfirm } from './index.d';
  import type { DynamicIcon } from '@/components/AppDynamicIcon';

  export default defineComponent({
    name: 'Confirm',

    components: {
      AppLoader,
      AppDynamicIcon,
      AppButton,
      HeadlessUiDialog: Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
    },

    props: {
      title: {
        type: String as PropType<string>,
        required: true,
      },

      content: {
        type: String as PropType<string>,
        required: true,
      },

      preventBackdropClose: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      confirmButtonText: {
        type: String as PropType<string>,
        default: window.$t('yesUnderstood'),
      },

      cancelButtonText: {
        type: String as PropType<string>,
        default: '',
      },

      type: {
        type: String as PropType<ConfirmType>,
        default: 'success',
        validator(this: void, type: ConfirmType): boolean {
          return ['success', 'error'].includes(type);
        },
      },

      onConfirm: {
        type: Function as PropType<OnConfirm>,
        default: null,
      },

      onCancel: {
        type: Function as PropType<OnCancel>,
        default: null,
      },

      icon: {
        type: String as PropType<string>,
        default: null,
      },

      html: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      extendedIconDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    emits: [
      'close',
      'confirmed',
    ],

    data() {
      return {
        isOpen: true,
        confirmLoading: false,
        cancelLoading: false,
      };
    },

    computed: {
      cancelButtonContent(): string {
        if (this.cancelButtonText) {
          return this.cancelButtonText;
        }

        return this.$t('cancel');
      },

      dynamicIconProperties(): DynamicIcon {
        let name = this.icon ?? 'CheckIcon';

        if (!this.icon && this.type === 'error') {
          name = 'ExclamationIcon';
        }

        return {
          type: 'solid',
          name,
        };
      },

      iconWrapperClasses(): DynamicDomClassesObject {
        return {
          'bg-red-100': this.type === 'error',
          'bg-green-100': this.type === 'success',
        };
      },

      iconDomClasses(): string[] {
        const defaultClasses = convertDomClassesPropToArray({
          'text-red-500': this.type === 'error',
          'text-green-500': this.type === 'success',
        });

        const extendedClasses = this.extendedIconDomClasses
          ? convertDomClassesPropToArray(this.extendedIconDomClasses)
          : [];

        return [...defaultClasses, ...extendedClasses];
      },

      buttonType(): string {
        return this.type === 'error' ? 'error' : 'primary';
      },
    },

    methods: {
      async close(confirmed: boolean): Promise<void> {
        if (confirmed && typeof this.onConfirm === 'function') {
          this.confirmLoading = true;

          await Promise.resolve(this.onConfirm());
        } else if (!confirmed && typeof this.onCancel === 'function') {
          this.cancelLoading = true;

          await Promise.resolve(this.onCancel());
        }

        this.isOpen = false;
        this.$emit('close', confirmed);
      },
    },
  });
