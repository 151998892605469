import { defineComponent } from 'vue';

import type { CourseTypeColors } from '@/typings/course';

export default defineComponent({
  methods: {
    getColors(courseTypeColorProfile: string): CourseTypeColors {
      switch (courseTypeColorProfile) {
        case 'blue':
          return { backgroundColor: 'bg-blue-200', textColor: 'text-blue-900' };
        case 'green':
          return { backgroundColor: 'bg-green-200', textColor: 'text-green-900' };
        case 'purple':
          return { backgroundColor: 'bg-purple-200', textColor: 'text-purple-900' };
        case 'pink':
          return { backgroundColor: 'bg-pink-200', textColor: 'text-pink-900' };
        case 'red':
          return { backgroundColor: 'bg-red-100', textColor: 'text-red-900' };
        default:
          return { backgroundColor: 'bg-gray-200', textColor: 'text-gray-900' };
      }
    },
  },
});
