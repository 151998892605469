import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, Transition as _Transition } from "vue"

const _hoisted_1 = ["data-open"]
const _hoisted_2 = { class: "pr-3 text-gray-700" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxLabel = _resolveComponent("ListboxLabel")!
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_AppInputText = _resolveComponent("AppInputText")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_AppDynamicIcon = _resolveComponent("AppDynamicIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    id: _ctx.selectId,
    as: _ctx.renderSelectWrapperAs,
    "model-value": _ctx.modelValue,
    disabled: _ctx.disabled,
    "onUpdate:modelValue": _ctx.select
  }, {
    default: _withCtx(({ open }) => [
      (_ctx.labelText)
        ? (_openBlock(), _createBlock(_component_ListboxLabel, {
            key: 0,
            class: _normalizeClass([_ctx.extendedLabelDomClasses, "block font-semibold text-gray-900"]),
            onClick: _ctx.openSelect
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.labelText), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        ref: "selectWrapper",
        "data-open": open,
        class: _normalizeClass([{ 'mt-1': _ctx.labelText }, "relative"])
      }, [
        _createVNode(_component_ListboxButton, {
          ref: "triggerButton",
          class: "group relative w-full rounded"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInputText, {
              "has-error": _ctx.hasError,
              "error-message": _ctx.errorMessage,
              "extended-input-dom-classes": _ctx.dynamicInputDomClasses,
              "model-value": _ctx.formattedSelected,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              readonly: true
            }, _createSlots({
              trailingAddon: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_SelectorIcon, { class: "h-5 w-5" })
                ])
              ]),
              _: 2
            }, [
              (_ctx.$slots.leadingAddon)
                ? {
                    name: "leadingAddon",
                    fn: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "leadingAddon")
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["has-error", "error-message", "extended-input-dom-classes", "model-value", "placeholder", "disabled"])
          ]),
          _: 3
        }, 512),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_Teleport, {
              to: "body",
              disabled: _ctx.disableFloatingTeleport
            }, [
              _withDirectives(_createVNode(_component_ListboxOptions, {
                ref: "listBoxOptions",
                static: _ctx.multiple,
                as: _ctx.renderOptionsWrapperAs,
                class: "options-wrapper absolute top-12 right-0 mt-2 w-full rounded shadow-lg bg-white ring-1 ring-gray-400 ring-opacity-5 focus:outline-none z-60 max-h-60 overflow-auto"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                    return (_openBlock(), _createBlock(_component_ListboxOption, {
                      key: `${_ctx.selectId}-option-${index}`,
                      disabled: option?.disabled,
                      value: option,
                      as: "template"
                    }, {
                      default: _withCtx(({ active }) => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.renderOptionsAs), {
                          "data-option-active": active,
                          class: _normalizeClass([_ctx.getDynamicOptionDomClasses(option, active), "relative flex items-center px-4 py-2 cursor-pointer select-none"]),
                          onClick: ($event: any) => (_ctx.optionClick(option, $event))
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "option", {
                              option: option,
                              active: active,
                              selected: _ctx.isSelected(option)
                            }, () => [
                              (option.icon)
                                ? (_openBlock(), _createBlock(_component_AppDynamicIcon, {
                                    key: 0,
                                    icon: option.icon,
                                    "extended-dom-classes": "mr-3 text-gray-900 group-hover:text-black"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                innerHTML: _ctx.getOptionLabel(option, true)
                              }, null, 8, _hoisted_3),
                              (_ctx.isSelected(option))
                                ? (_openBlock(), _createBlock(_component_AppDynamicIcon, {
                                    key: 1,
                                    icon: _ctx.selectedIcon,
                                    class: "ml-auto"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["data-option-active", "class", "onClick"]))
                      ]),
                      _: 2
                    }, 1032, ["disabled", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["static", "as"]), [
                [_vShow, open]
              ])
            ], 8, ["disabled"]))
          ]),
          _: 2
        }, 1024)
      ], 10, _hoisted_1)
    ]),
    _: 3
  }, 8, ["id", "as", "model-value", "disabled", "onUpdate:modelValue"]))
}