
  import { defineComponent } from 'vue';
  import { LocationMarkerIcon } from '@heroicons/vue/outline';
  import inputMethods from '@/components/AppInputBase/methods-mixin';
  import baseInputProps from '@/components/AppInputBase/props';
  import { AppInputBase } from '@/components';
  import props from './props';
  import { formatPlace } from '.';

  export default defineComponent({
    name: 'AppInputLocation',

    components: {
      AppInputBase,
      LocationMarkerIcon,
    },

    mixins: [
      inputMethods,
    ],

    inheritAttrs: false,

    props: {
      ...baseInputProps,
      ...props,
    },

    emits: AppInputBase.emits,

    data() {
      return {
        input: null as HTMLInputElement | null,
      };
    },

    computed: {
      inputId(): string {
        return `app-input-${this.componentId}`;
      },

      formattedPlace(): string {
        if (this.modelValue) {
          return formatPlace(this.modelValue);
        }

        return '';
      },
    },

    async mounted() {
      if (!(window?.google ?? false)) {
        console.error('Google API is not loaded. Load Google API before initializing AppInputLocation.');

        return;
      }

      const baseInputComponent = this.$refs.inputField as typeof AppInputBase;
      this.input = baseInputComponent.$refs.inputField as HTMLInputElement;

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { Autocomplete } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary;

      const autocomplete = new Autocomplete(this.input, {
        strictBounds: false,
        componentRestrictions: {
          country: 'nl',
        },
        ...this.placeOptions ?? {},
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const geoLocation = place.geometry?.location;
        const addressComponents = place.address_components ?? [];

        const lat = geoLocation?.lat();
        const lng = geoLocation?.lng();

        const street = addressComponents.find(component => {
          return component.types.includes('route');
        })?.long_name;
        const houseNumber = parseInt(addressComponents.find(component => {
          return component.types.includes('street_number');
        })?.long_name.split(/(\d*)(.*)/s)?.[1] ?? '', 10);
        const houseNumberSuffix = addressComponents.find(component => {
          return component.types.includes('street_number');
        })?.long_name.split(/(\d*)(.*)/s)?.[2];
        const postalCode = addressComponents.find(component => {
          return component.types.includes('postal_code');
        })?.long_name;
        const city = addressComponents.find(component => {
          return component.types.includes('locality');
        })?.long_name;
        const province = addressComponents.find(component => {
          return component.types.includes('administrative_area_level_1');
        })?.long_name;
        let establishmentName: string | undefined;

        if (place.name && place.types?.includes('establishment')) {
          const nameIsPossibleAddress = addressComponents.some(addressComponent => {
            return addressComponent.long_name.includes(place.name ?? '');
          });

          if (!nameIsPossibleAddress) {
            establishmentName = place.name;
          }
        }

        this.input?.blur();

        this.$emit('update:modelValue', {
          number: houseNumber > 0 ? houseNumber : undefined,
          numberSuffix: houseNumberSuffix,
          placeId: place.place_id,
          establishmentName,
          postalCode,
          province,
          street,
          city,
          lat,
          lng,
        });
      });
    },

    methods: {
      inputClick(): void {
        if (this.input) {
          this.input.value = '';
        }
      },

      inputBlur(): void {
        if (this.input && this.input.value !== this.formattedPlace) {
          this.input.value = this.formattedPlace;
        }
      },
    },
  });
