import Echo from 'laravel-echo';
import { post } from '@/plugins/http';

import type { App } from 'vue';
import type { Authorizer, AuthorizerCallback, Channel } from 'pusher-js';
import type { UserAuthenticationData } from 'pusher-js/types/src/core/auth/options';
import type { BroadcastPayload, EchoChannel } from './index.d';

// eslint-disable-next-line @typescript-eslint/no-require-imports
window.Pusher = require('pusher-js');

const echoWrapper = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  authorizer: (channel: Channel): Authorizer => {
    return {
      async authorize(socketId: string, callback: AuthorizerCallback): Promise<void> {
        try {
          const response = await post<BroadcastPayload, UserAuthenticationData>(
            '/broadcasting/auth',
            { socketId, channelName: channel.name },
          );

          callback(null, response);
        } catch (error: unknown) {
          console.error(error);
          callback(error as Error, {} as UserAuthenticationData);
        }
      },
    };
  },
  cluster: 'eu',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  forceTLS: true,
  encrypted: true,
});

const channels: EchoChannel[] = [];

export default {
  install: (app: App): void => {
    const $bus = app.config.globalProperties.$bus;

    const on = (
      channel: string,
      event: string,
      handler: (data: unknown) => void,
      isPrivateChannel?: boolean,
    ): void => {
      const key = `${channel}-${event}`;
      const isSubscribed = $bus.all.has(key);

      if (!isSubscribed) {
        let channelConnection = channels.find(connectedChannel => {
          return connectedChannel.name === channel;
        })?.channel;

        if (!channelConnection) {
          channelConnection = isPrivateChannel
            ? echoWrapper.private(channel)
            : echoWrapper.channel(channel);

          channels.push({
            name: channel,
            channel: channelConnection,
          });
        }

        channelConnection
          .listen(`.${event}`, (data: unknown) => {
            $bus.emit(key, data);
          });
      }

      $bus.on(key, handler);
    };

    const off = (channel: string, event: string, handler: (data: unknown) => void): void => {
      const key = `${channel}-${event}`;
      const listenerAmount = $bus.all.get(key)?.length ?? 0;

      $bus.off(key, handler);

      if (listenerAmount === 1) {
        echoWrapper.channel(channel)
          .stopListening(event, (data: unknown) => $bus.emit(key, data));
      }
    };

    app.config.globalProperties.$echo = {
      $on: on,
      $off: off,
    };
  },
};
