import { http } from '@/plugins/http';

import type { Attendances, Attendance, UpdateAttendancePayload, StoreCertificatePayload } from './vuex';

export default {
  async fetchAttendances(trainingId: number): Promise<Attendances> {
    return http.fetch<Attendances>(`/trainings/${trainingId}/attendance`);
  },

  async fetchAttendance(trainingId: number, dateId: number): Promise<Attendance> {
    return http.fetch<Attendance>(`/trainings/${trainingId}/attendance/${dateId}`);
  },

  async updateAttendance(payload: UpdateAttendancePayload): Promise<{ attendancesCompleted: boolean }> {
    const url = `/trainings/${payload.trainingId}/attendance/${payload.trainingDateId}`;
    const body = { participants: payload.participants, saveTemporarily: payload.saveTemporarily };

    return http.patch<typeof body, { attendancesCompleted: boolean }>(url, body);
  },

  async storeDocument(payload: StoreCertificatePayload): Promise<Attendances['participants'][number]> {
    const formData = new FormData();
    formData.append('certificate', payload.certificate);

    return http.post<typeof formData, Attendances['participants'][number]>(
      `/trainings/${payload.trainingId}/attendance/${payload.trainingUserId}/document`,
      formData,
    );
  },

  async deleteDocument(trainingId: number, trainingUserId: number): Promise<void> {
    return http.delete(`/trainings/${trainingId}/attendance/${trainingUserId}/document`);
  },
};
