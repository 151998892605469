import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const namespaced = true;

const trainingBuildModule = {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};

export default trainingBuildModule;
