
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToObject } from '@/helpers';
  import RadioLabel from './RadioLabel.vue';

  import type { PropType } from 'vue';
  import type { DomClassesProp, DynamicDomClassesObject } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'AppRadio',

    components: {
      RadioLabel,
    },

    props: {
      modelValue: {
        type: [String, Number, Boolean] as PropType<string | number | boolean>,
        default: null,
      },

      value: {
        type: [String, Number, Boolean] as PropType<string | number | boolean>,
        default: true,
      },

      name: {
        type: String as PropType<string>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      extendedInputDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedLabelDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    emits: [
      'update:modelValue',
    ],

    computed: {
      radioId(): string {
        return `app-radio-${this.componentId}`;
      },

      dynamicInputDomClasses(): DynamicDomClassesObject {
        const extendedDomClasses = this.extendedInputDomClasses ?? {};

        return {
          'cursor-not-allowed': this.disabled,
          ...convertDomClassesPropToObject(extendedDomClasses),
        };
      },
    },

    methods: {
      select(withFocus = true): void {
        this.$emit('update:modelValue', this.value);

        if (withFocus) {
          (this.$refs.radioInput as HTMLInputElement)?.focus();
        }
      },
    },
  });
