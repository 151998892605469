import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    handleDialogOpenChange(
      dialogOpenedHistoryStateKey: string,
      isOpen: boolean,
      closeDialogMethod: () => void,
    ): void {
      setTimeout(() => {
        if (isOpen) {
          window.history.pushState({
            [dialogOpenedHistoryStateKey]: true,
          }, '');

          window.addEventListener('popstate', () => {
            closeDialogMethod();
          }, {
            once: true,
          });
        } else if (window.history.state[dialogOpenedHistoryStateKey]) {
          window.history.go(-1);
        }
      }, 0);
    },
  },
});
