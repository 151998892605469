import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { State as EnrollmentState } from './vuex.d';
import type { Enrollment, UpdateEnrollmentPayload } from '@/views/Enrollments/Show';
import type { EnrollmentResult } from '@/views/Enrollments/WalkThrough/Results';
import type { EnrollmentStatus } from '@/typings/LMS/enrollment';

export default {
  async getEnrollment(
    context: ActionContext<EnrollmentState, State>,
    enrollmentId: number,
  ): Promise<Enrollment> {
    const enrollment = await api.getEnrollment(enrollmentId);

    context.commit('setEnrollment', enrollment);

    return enrollment;
  },

  async getEnrollmentResult(
    context: ActionContext<EnrollmentState, State>,
    enrollmentId: number,
  ): Promise<EnrollmentResult> {
    const enrollmentResult = await api.getEnrollmentResult(enrollmentId);

    context.commit('setEnrollmentResult', enrollmentResult);

    return enrollmentResult;
  },

  updateEnrollment(
    context: ActionContext<EnrollmentState, State>,
    payload: UpdateEnrollmentPayload,
  ): void {
    api.updateEnrollment(payload.enrollmentId, payload.enrollment);
  },

  setEnrollmentStatus(
    context: ActionContext<EnrollmentState, State>,
    status: EnrollmentStatus,
  ): void {
    context.commit('setEnrollmentStatus', status);
  },

  setEnrollmentProgress(
    context: ActionContext<EnrollmentState, State>,
    progress: number,
  ): void {
    context.commit('setEnrollmentProgress', progress);
  },

  async deleteEnrollment(
    context: ActionContext<EnrollmentState, State>,
    id: number,
  ): Promise<void> {
    await api.deleteEnrollment(id);
  },
};
