
  import { defineComponent } from 'vue';
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
  import { AppPaginationItem } from '@/components';

  import type { PropType } from 'vue';
  import type { PaginationMeta } from '.';

  export default defineComponent({
    name: 'AppPagination',

    components: {
      AppPaginationItem,
      ChevronLeftIcon,
      ChevronRightIcon,
    },

    props: {
      meta: {
        type: Object as PropType<PaginationMeta>,
        required: true,
      },

      loaded: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },

    emits: [
      'paginate',
    ],

    data() {
      return {
        pageRange: 2,
      };
    },

    computed: {
      pages(): number[] {
        const pages = [];

        if (this.rangeStart > 1) {
          pages.push(1);

          if (this.rangeStart > 2) {
            pages.push(0);
          }
        }

        for (let number = this.rangeStart; number <= this.rangeEnd; number += 1) {
          pages.push(number);
        }

        if (this.rangeEnd < this.meta.meta.lastPage) {
          if ((this.rangeEnd + 1) !== this.meta.meta.lastPage) {
            pages.push(0);
          }

          pages.push(this.meta.meta.lastPage);
        }

        return pages;
      },

      rangeStart(): number {
        const start = this.meta.meta.currentPage - this.pageRange;

        return start > 0 ? start : 1;
      },

      rangeEnd(): number {
        const end = this.meta.meta.currentPage + this.pageRange;

        return end < this.meta.meta.lastPage ? end : this.meta.meta.lastPage;
      },

      previousPage(): number | null {
        const previousPage = this.meta.meta.currentPage - 1;

        return previousPage > 0 ? previousPage : null;
      },

      nextPage(): number | null {
        const nextPage = this.meta.meta.currentPage + 1;

        return nextPage <= this.meta.meta.lastPage ? nextPage : null;
      },
    },

    methods: {
      paginate(page: number | null): void {
        if (page) {
          this.$emit('paginate', page);
        }
      },
    },
  });
