
  import { defineComponent } from 'vue';
  import { DisclosurePanel } from '@headlessui/vue';
  import DisclosureMixin from './disclosure-mixin';

  export default defineComponent({
    name: 'AppDisclosurePanel',

    components: {
      DisclosurePanel,
    },

    mixins: [
      DisclosureMixin,
    ],

    inheritAttrs: false,
  });
