import { defineComponent, inject } from 'vue';

export default defineComponent({
  data() {
    return {
      isOpen: inject<boolean>('isOpen', false),
      openMethod: inject<() => void>('open', () => { /* */ }, true),
      closeMethod: inject<() => void>('close', () => { /* */ }, true),
      toggleMethod: inject<() => void>('toggle', () => { /* */ }, true),
    };
  },

  methods: {
    open(): void {
      this.openMethod();
    },

    close(): void {
      this.closeMethod();
    },

    toggle(): void {
      this.toggleMethod();
    },
  },
});
