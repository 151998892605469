import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const namespaced = true;

const enrollmentGroupModule = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default enrollmentGroupModule;
