import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05e4bb85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 1,
  class: "mt-2 text-sm text-gray-700",
  "data-test-leading-text": ""
}
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 0,
  ref: "errorIcon",
  class: "absolute inset-y-0 top-10 right-0 pr-3 flex items-center pointer-events-none",
  "data-test-error-icon": ""
}
const _hoisted_7 = {
  key: 2,
  class: "mt-2 text-sm text-gray-700",
  "data-test-trailing-text": ""
}
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TinyMceEditor = _resolveComponent("TinyMceEditor")!
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.labelText && !_ctx.labelIsHidden) || _ctx.cornerHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: _ctx.richTextId,
            class: _normalizeClass([_ctx.dynamicLabelDomClasses, "block font-semibold text-gray-900"]),
            "data-test-label": "",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
          }, _toDisplayString(_ctx.labelText), 11, _hoisted_3),
          (_ctx.cornerHint)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([{ 'justify-self-right': !_ctx.labelText || _ctx.labelIsHidden }, "text-gray-700"]),
                "data-test-corner-hint": ""
              }, _toDisplayString(_ctx.cornerHint), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.leadingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.leadingHelpText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "tinyMceContainer",
      class: _normalizeClass([_ctx.richTextWrapperDomClasses, "relative rounded border border-gray-400 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue focus-within:border-blue"]),
      "data-test-rich-text-wrapper": ""
    }, [
      _createElementVNode("div", {
        id: _ctx.toolbarId,
        class: "tox-custom-toolbar"
      }, null, 8, _hoisted_5),
      _createVNode(_component_TinyMceEditor, {
        id: _ctx.richTextId,
        "initial-value": _ctx.content,
        "api-key": _ctx.apiKey,
        init: _ctx.tinyMCEInit,
        disabled: _ctx.disabled || _ctx.readonly,
        class: _normalizeClass([_ctx.richTextDomClasses, "prose focus:outline-none text-md shadow-sm block w-full overflow-ellipsis disabled:cursor-not-allowed placeholder-gray-700 placeholder-opacity-100 bg-white p-3 overflow-auto min-h-[8rem] tinymce-editor"]),
        "data-test-editor": ""
      }, null, 8, ["id", "initial-value", "api-key", "init", "disabled", "class"]),
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_ExclamationCircleIcon, {
              class: "h-5 w-5 text-red-500",
              "aria-hidden": "true"
            })
          ], 512))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.trailingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.trailingHelpText), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", {
          key: 3,
          id: _ctx.errorMessageId,
          class: "mt-2 text-sm text-red-500",
          "data-test-error-message": ""
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_8))
      : _createCommentVNode("", true)
  ]))
}