
  import { defineComponent, inject } from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { upperFirst } from 'lodash';
  import ErrorMixin from '@/mixins/error-mixin';
  import AppButton from '@/components/AppButton/AppButton.vue';
  import { AppInputEmail, AppInputText, AppLoader, AppSelect } from '@/components';

  import type { PropType } from 'vue';
  import type { Errors } from '@/mixins';
  import type { FormUser, SimpleCompany } from './index.d';

  export default defineComponent({
    name: 'CreateUserForm',

    components: {
      AppSelect,
      AppLoader,
      AppInputEmail,
      AppInputText,
      AppButton,
    },

    mixins: [
      ErrorMixin,
    ],

    props: {
      emailRequired: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      submitting: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    emits: [
      'closeForm',
      'submitUser',
    ],

    data() {
      return {
        newUser: {} as FormUser,
        errors: inject<Errors>('errors', {}),
        subcompanies: [] as SimpleCompany[],
        loadingCompanies: false,
      };
    },

    computed: {
      ...mapState([
        'company',
      ]),

      ...mapState('Company', [
        'companies',
      ]),

      ...mapGetters([
        'companyRole',
        'traineeRole',
      ]),

      placeholderTextFirstName(): string {
        return `${this.$t('lms.users.form.firstName.placeholder')}*`;
      },

      placeholderTextLastName(): string {
        return `${this.$t('lms.users.form.lastName.placeholder')}*`;
      },

      placeholderTextEmail(): string {
        return this.$t('lms.users.form.email.label') + (this.emailRequired ? '*' : '');
      },

      role(): string {
        return this.traineeRole();
      },

      filteredCompanies(): SimpleCompany[] {
        return this.subcompanies.filter(company => {
          return company.type !== 'administrator';
        });
      },

      canSelectCompany(): boolean {
        return this.company.hasSubCompanies;
      },

      selectCompanyType(): string {
        return this.$t('lms.users.form.companySelect.type.subCompany');
      },

      selectCompanyPlaceholder(): string {
        return this.$t('lms.users.form.companySelect.placeholder', {
          type: upperFirst(this.selectCompanyType),
        });
      },
    },

    created() {
      this.initialize();
    },

    methods: {
      ...mapActions('Company', [
        'getCompanies',
      ]),

      async fetchCompanies(): Promise<void> {
        this.loadingCompanies = true;

        try {
          this.subcompanies = await this.getCompanies({ paginate: false });
        } catch {
          this.subcompanies = [];

          this.$toast.error({
            title: this.$t(
              'lms.users.notifications.listCompaniesError.title',
              [this.role],
            ),
            description: this.$t(
              'lms.users.notifications.listCompaniesError.description',
              [this.role],
            ),
          });
        } finally {
          this.loadingCompanies = false;
        }
      },

      async initialize(): Promise<void> {
        if (this.companies.length <= 0 && this.canSelectCompany) {
          await this.fetchCompanies();
        } else {
          this.subcompanies = this.companies;
        }
      },

      submitUser(): void {
        this.$emit('submitUser', this.newUser);
      },
    },
  });
