import { createI18n } from 'vue-i18n';
import nlMessage from './locales/nl';
import nlDatetime from './datetimes/nl';

export const messages = {
  nl: nlMessage,
};

export const datetimeFormats = {
  nl: nlDatetime,
};

const i18n = createI18n({
  locale: 'nl',
  messages,
  datetimeFormats,
});

window.$t = i18n.global.t;
window.$rt = i18n.global.rt;
window.$tc = i18n.global.tc;
window.$te = i18n.global.te;
window.$tm = i18n.global.tm;
window.$d = i18n.global.d;
window.$n = i18n.global.n;

export default i18n;
