import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-selected"]
const _hoisted_2 = { class: "flex items-center h-6" }
const _hoisted_3 = ["id", "value", "disabled", "checked"]
const _hoisted_4 = {
  key: 0,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioLabel = _resolveComponent("RadioLabel")!

  return (_openBlock(), _createElementBlock("div", {
    "aria-selected": _ctx.modelValue === _ctx.value,
    class: "relative flex items-start mb-2"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        id: _ctx.radioId,
        ref: "radioInput",
        value: _ctx.value,
        disabled: _ctx.disabled,
        checked: _ctx.modelValue === _ctx.value,
        class: _normalizeClass([_ctx.dynamicInputDomClasses, "focus:ring-blue h-5 w-5 text-blue border-gray-400 rounded-full"]),
        type: "radio",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)))
      }, null, 42, _hoisted_3)
    ]),
    (_ctx.$slots.default || _ctx.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_RadioLabel, {
            "radio-id": _ctx.radioId,
            "radio-disabled": _ctx.disabled,
            "extended-dom-classes": _ctx.extendedLabelDomClasses
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.name), 1)
              ])
            ]),
            _: 3
          }, 8, ["radio-id", "radio-disabled", "extended-dom-classes"])
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}