import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "m-auto font-bold" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.domClassesPerSize[_ctx.size], "app-avatar relative flex items-center rounded-full bg-primary text-primary-opposite-color"])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.firstLetter), 1),
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src,
          alt: _ctx.name,
          class: "absolute inset-0 rounded-full"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}