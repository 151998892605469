import { ref } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

export const googleApiLoaded = ref(false);

export default async function registerGoogleApi(apiKey: string): Promise<void> {
  if (googleApiLoaded.value) {
    return;
  }

  await new Loader({ apiKey, version: 'weekly' }).load();

  googleApiLoaded.value = true;
}
