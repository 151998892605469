
  import { defineComponent } from 'vue';
  import { AppDynamicIcon } from '@/components';
  import DocumentIcon from '@/assets/DocumentIcon.vue';

  import type { PropType } from 'vue';
  import type { Media } from '.';
  import type { DynamicIcon } from '@/components/AppDynamicIcon';

  export default defineComponent({
    name: 'FallbackThumbnail',

    components: {
      DocumentIcon,
      AppDynamicIcon,
    },

    props: {
      media: {
        type: Object as PropType<Media>,
        required: true,
      },
    },

    computed: {
      mediaIcon(): DynamicIcon {
        let icon = 'VideoCameraIcon';

        if (this.media.type === 'image') {
          icon = 'PhotographIcon';
        } else if (this.media.type === 'document') {
          icon = 'DocumentIcon';
        }

        return {
          type: 'outline',
          name: icon,
          extendedDomClasses: 'text-gray-400 w-12 !h-auto inline',
        };
      },
    },
  });
