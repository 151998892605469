
  import { defineComponent } from 'vue';
  import { ExclamationCircleIcon } from '@heroicons/vue/solid';
  import { domClassesPerSize, horizontalPaddingInput } from './input-sizes';
  import { convertDomClassesPropToArray } from '@/helpers';
  import methods from './methods-mixin';
  import props from './props';

  import type { DynamicDomStylingObject } from '@/typings/class-style-binding';
  import type { DatalistItem } from '.';

  export default defineComponent({
    name: 'AppInputBase',

    components: {
      ExclamationCircleIcon,
    },

    mixins: [
      methods,
    ],

    props,

    emits: [
      'update:modelValue',
      'click',
      'blur',
    ],

    data() {
      return {
        leadingAddonWidth: null as null | number,
        trailingAddonWidth: null as null | number,
        errorIconWidth: null as null | number,
      };
    },

    computed: {
      inputId(): string {
        return `app-input-${this.componentId}`;
      },

      hasDatalist(): boolean {
        return Array.isArray(this.datalist) && this.datalist.length > 0;
      },

      datalistId(): string | undefined {
        return this.hasDatalist ? `${this.inputId}-datalist` : undefined;
      },

      errorMessageId(): string {
        return `${this.inputId}-error-message`;
      },

      inputWrapperDomClasses(): string[] {
        const domClasses = [];

        if (this.labelText && !this.labelIsHidden) {
          domClasses.push('mt-1');
        }

        if (this.extendedInputWrapperDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedInputWrapperDomClasses,
          ));
        }

        return domClasses;
      },

      inputDomClasses(): string[] {
        const domClasses: string[] = [
          `input--${this.type}`,
          ...domClassesPerSize[this.size],
        ];

        if (this.hasError) {
          domClasses.push(
            'pr-10',
            '!text-red-500',
            'border-red-500',
            'placeholder-red-500',
            'focus:border-red-500',
            'focus:ring-red-500',
            'focus:outline-none',
          );
        } else {
          domClasses.push(
            'border-gray-400',
            'focus:ring-blue',
            'focus:border-blue',
          );
        }

        if (this.extendedInputDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedInputDomClasses,
          ));
        }

        return domClasses;
      },

      dynamicLabelDomClasses(): string[] {
        const domClasses = [];

        if (this.labelIsHidden) {
          domClasses.push('sr-only');
        }

        if (this.extendedLabelDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedLabelDomClasses,
          ));
        }

        return domClasses;
      },

      inputStyling(): DynamicDomStylingObject {
        const styling: Record<string, string> = {};
        const inputPadding = horizontalPaddingInput[this.size];

        if (this.leadingAddonWidth !== null && this.leadingAddonWidth > 0) {
          styling.paddingLeft = `${this.leadingAddonWidth + inputPadding}px`;
        }

        if (this.trailingAddonWidth !== null && this.trailingAddonWidth > 0) {
          const errorIconWidth = this.errorIconWidth ? this.errorIconWidth : 0;
          styling.paddingRight = `${this.trailingAddonWidth + errorIconWidth + inputPadding}px`;
        }

        return styling;
      },

      errorIconStyling(): DynamicDomStylingObject | null {
        if (this.trailingAddonWidth !== null && this.trailingAddonWidth > 0) {
          return { marginRight: `${this.trailingAddonWidth}px` };
        }

        return null;
      },
    },

    mounted() {
      this.$nextTick(() => {
        if (this.$slots.leadingAddon) {
          const leadingAddon = this.$refs.leadingAddon as HTMLDivElement;
          this.leadingAddonWidth = leadingAddon.offsetWidth;
        }

        if (this.$slots.trailingAddon) {
          const trailingAddon = this.$refs.trailingAddon as HTMLDivElement;
          this.trailingAddonWidth = trailingAddon.offsetWidth;
        }

        if (this.hasError) {
          const errorIcon = this.$refs.errorIcon as HTMLDivElement;
          this.errorIconWidth = errorIcon.offsetWidth;
        }
      });
    },

    methods: {
      getDatalistOptionValue(item: DatalistItem): string {
        return typeof item === 'object' ? item.value : item;
      },

      getDatalistOptionLabel(item: DatalistItem): string | undefined {
        return typeof item === 'object' ? item.label : undefined;
      },
    },
  });
