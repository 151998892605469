import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisclosurePanel = _resolveComponent("DisclosurePanel")!

  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "transition duration-100 ease-out",
    "enter-from-class": "transform scale-95 opacity-0",
    "enter-to-class": "transform scale-100 opacity-100",
    "leave-active-class": "transition duration-75 ease-out",
    "leave-from-class": "transform scale-100 opacity-100",
    "leave-to-class": "transform scale-95 opacity-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DisclosurePanel, _normalizeProps(_guardReactiveProps({ ..._ctx.$props, ..._ctx.$attrs })), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16)
    ]),
    _: 3
  }))
}