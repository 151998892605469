
  import { defineComponent } from 'vue';
  import { PlusIcon } from '@heroicons/vue/solid';
  import { AppButton, AppLoader, AppProgressBar } from '@/components';

  import type { PropType } from 'vue';
  import type { PaginationMeta } from '.';

  export default defineComponent({
    name: 'AppLoadMore',

    components: {
      AppButton,
      AppLoader,
      AppProgressBar,
      PlusIcon,
    },

    props: {
      resultsName: {
        type: String as PropType<string>,
        required: true,
      },

      meta: {
        type: Object as PropType<PaginationMeta>,
        required: true,
      },

      isLoaded: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },

    emits: [
      'paginate',
    ],

    data() {
      return {
        hasPaginated: false,
      };
    },

    computed: {
      currentResults(): number {
        return this.meta.meta.to;
      },

      totalResults(): number {
        return this.meta.meta.total;
      },

      hasNextPage(): boolean {
        return !!this.meta.links.next;
      },

      progress(): number {
        return (this.currentResults / this.totalResults) * 100;
      },
    },

    methods: {
      paginate(): void {
        this.$emit('paginate');
        this.hasPaginated = true;
      },
    },
  });
