
  import { defineComponent } from 'vue';
  import { SelectorIcon } from '@heroicons/vue/solid';
  import {
    xor,
    isArray,
    isString,
    isPlainObject,
    get,
  } from 'lodash';
  import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    TransitionRoot,
  } from '@headlessui/vue';
  import ComputedPositionMixin from '@/mixins/computed-position-mixin';
  import props from './props';
  import { convertDomClassesPropToArray, stripTags } from '@/helpers';
  import { AppInputText, AppDynamicIcon } from '@/components';

  import type { Option, OptionObject, ModelValue } from '.';

  const defaultInputDomClasses = [
    'select-none',
    'group-focus:ring-1',
    'group-focus:ring-blue',
    'group-focus:border-blue',
  ];

  export default defineComponent({
    name: 'AppSelect',

    components: {
      AppInputText,
      AppDynamicIcon,
      Listbox,
      ListboxLabel,
      ListboxButton,
      ListboxOptions,
      ListboxOption,
      TransitionRoot,
      SelectorIcon,
    },

    mixins: [
      ComputedPositionMixin,
    ],

    props,

    emits: [
      'update:modelValue',
    ],

    data() {
      return {
        isOpen: false as boolean,
        observer: null as MutationObserver | null,
        selectWrapper: null as HTMLDivElement | null,
      };
    },

    computed: {
      selectId(): string {
        return `app-select-${this.componentId}`;
      },

      hasValidTrackBy(): boolean {
        if (this.trackBy) {
          return (this.options as Option[]).every(option => {
            return typeof option === 'object' && option[this.trackBy];
          });
        }

        return false;
      },

      mustBeClearable(): boolean {
        return this.clearable || this.multiple;
      },

      formattedSelected(): string | null {
        if (this.modelValue) {
          let formattedSelected: string | null = null;

          if (Array.isArray(this.modelValue)) {
            const mappedValues = this.modelValue.map(value => {
              const option = this.hasValidTrackBy
                ? this.getOptionByTrackBy(value)
                : value;

              return this.getOptionLabel(option as Option);
            });

            formattedSelected = mappedValues.join(', ');
          } else {
            const option = this.hasValidTrackBy
              ? this.getOptionByTrackBy(this.modelValue)
              : this.modelValue;

            formattedSelected = this.getOptionLabel(option as Option);
          }

          return formattedSelected;
        }

        return null;
      },

      dynamicInputDomClasses(): string[] {
        const domClasses = [...defaultInputDomClasses];

        if (this.disabled === false) {
          domClasses.push('!cursor-pointer');
        }

        if (this.extendedInputDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(this.extendedInputDomClasses));
        }

        return domClasses;
      },
    },

    watch: {
      isOpen(value) {
        this.setComputedPosition(
          value,
          (this.$refs.triggerButton as typeof ListboxButton).$el,
          (this.$refs.listBoxOptions as typeof ListboxOptions).$el,
          {
            autoWidth: true,
          },
        );
      },
    },

    mounted() {
      this.startObserver();
      this.addKeyDownListener();
    },

    beforeUnmount() {
      this.stopObserver();
      this.removeKeyDownListener();
    },

    methods: {
      select(option: Option): void {
        const value = this.hasValidTrackBy
          ? (option as OptionObject)[this.trackBy]
          : option;
        let payload: ModelValue | null = null;

        if (this.multiple && isArray(this.modelValue ?? [])) {
          payload = xor((this.modelValue ?? []) as ModelValue[], [value as ModelValue]);
        } else if (this.modelValue !== value || !this.mustBeClearable) {
          payload = value as ModelValue;
        }

        this.$emit('update:modelValue', payload);
      },

      isSelected(option: Option): boolean {
        if (this.modelValue) {
          return isArray(this.modelValue)
            ? this.modelValue.includes(option)
            : (this.modelValue === option || this.modelValue === get(option, this.trackBy));
        }

        return false;
      },

      getOptionLabel(option: Option, withHtml = false): string | null {
        let label: string | null = null;

        if (typeof option === 'object' && this.optionLabelKey in option) {
          label = option[this.optionLabelKey] as string;
        } else if (isString(option)) {
          label = option;
        }

        if (label) {
          return withHtml ? label : stripTags(label);
        }

        return null;
      },

      getOptionByTrackBy(value: ModelValue): Option | null {
        return (this.options as Option[]).find(optionObject => {
          return (optionObject as OptionObject)[this.trackBy] === value;
        }) ?? null;
      },

      optionClick(option: Option, event: MouseEvent): void {
        if (this.multiple) {
          event.stopImmediatePropagation();

          this.select(option);
        }
      },

      openSelect(): void {
        (this.$refs.triggerButton as typeof ListboxButton).el.click();
      },

      startObserver(): void {
        this.selectWrapper = this.$refs.selectWrapper as HTMLDivElement;

        this.observer = new MutationObserver(mutations => {
          this.isOpen = (mutations[0].target as HTMLDivElement).dataset.open !== 'false';
        });

        this.observer.observe(this.selectWrapper, {
          attributeFilter: ['data-open'],
          attributes: true,
        });
      },

      stopObserver(): void {
        this.observer?.disconnect();
      },

      onKeyDown(event: KeyboardEvent): void {
        if (this.isOpen && this.multiple && event.key === 'Enter') {
          event.preventDefault();
          event.stopImmediatePropagation();

          const selector = '[data-option-active="true"]';
          const activeOption = this.selectWrapper?.querySelector(selector);

          if (activeOption) {
            (activeOption as HTMLElement).click();
          }
        }
      },

      addKeyDownListener(): void {
        this.selectWrapper?.addEventListener('keydown', this.onKeyDown, true);
      },

      removeKeyDownListener(): void {
        this.selectWrapper?.removeEventListener('keydown', this.onKeyDown, true);
      },

      getDynamicOptionDomClasses(option: Option, isActive: boolean): string[] {
        const domClasses = ['text-gray-900'];

        if (isActive) {
          domClasses.push('bg-gray-100', '!text-black');
        }

        if (isPlainObject(option) && (option as OptionObject).disabled) {
          domClasses.push('!cursor-not-allowed', '!text-gray-500');
        }

        return domClasses;
      },
    },
  });
