import { createQueue } from '@programic/queue';

import type { App } from 'vue';

export default {
  install: (app: App): void => {
    const queue = createQueue();

    app.config.globalProperties.$queue = queue;

    window.$queue = queue;
  },
};
