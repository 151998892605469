import { createStore } from 'vuex';
import localforage from 'localforage';
import VuexPersistence from 'vuex-persist';
import persistedItems from './persisted-items';
import state from './state';
import * as getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';

import type { Store } from 'vuex';
import type { AsyncStorage } from 'vuex-persist';
import type { RootState, State, PersistedItems } from '@/typings/vuex';

const vuexLocal = new VuexPersistence<RootState>({
  reducer: (vuexState: State): PersistedItems => {
    return persistedItems(vuexState);
  },
  storage: localforage as AsyncStorage,
  asyncStorage: true,
});

const initializeStore = (
  initialState: Partial<State> = {},
  withModules = true,
): Store<State> => {
  return createStore<State>({
    state: {
      ...state,
      ...initialState,
    },
    getters,
    mutations,
    actions,
    modules: withModules ? modules : {},
    plugins: [
      vuexLocal.plugin,
    ],
  });
};

export { initializeStore };

export default initializeStore;
