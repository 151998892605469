
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToObject } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp, DynamicDomClassesObject } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'CheckboxLabel',

    props: {
      checkboxId: {
        type: String as PropType<string>,
        required: true,
      },

      checkboxDisabled: {
        type: Boolean as PropType<boolean>,
        required: true,
      },

      extendedDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    computed: {
      dynamicDomClasses(): DynamicDomClassesObject {
        return {
          'text-red-500': this.hasError,
          'text-gray-900': !this.hasError,
          'cursor-pointer': !this.checkboxDisabled,
          'cursor-not-allowed': this.checkboxDisabled,
          ...convertDomClassesPropToObject(this.extendedDomClasses ?? {}),
        };
      },
    },
  });
