import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd86e7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "mt-2 text-sm text-gray-700",
  "data-test-leading-text": ""
}
const _hoisted_4 = {
  key: 0,
  ref: "leadingAddon",
  class: "absolute inset-y-0 left-0 flex items-center"
}
const _hoisted_5 = ["id", "type", "name", "value", "disabled", "readonly", "required", "placeholder", "minlength", "maxlength", "autofocus", "autocomplete", "tabindex", "list", "aria-invalid", "aria-describedby"]
const _hoisted_6 = {
  key: 1,
  ref: "trailingAddon",
  class: "absolute inset-y-0 right-0 flex items-center"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["value", "label"]
const _hoisted_9 = {
  key: 2,
  class: "mt-2 text-sm text-gray-700",
  "data-test-trailing-text": ""
}
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.labelText && !_ctx.labelIsHidden) || _ctx.cornerHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.inputId,
            class: _normalizeClass([_ctx.dynamicLabelDomClasses, "block font-semibold text-gray-900"])
          }, _toDisplayString(_ctx.labelText), 11, _hoisted_2),
          (_ctx.cornerHint)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([{ 'justify-self-right': !_ctx.labelText || _ctx.labelIsHidden }, "text-gray-700"]),
                "data-test-corner-hint": ""
              }, _toDisplayString(_ctx.cornerHint), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.leadingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.leadingHelpText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.inputWrapperDomClasses, "relative"]),
      "data-test-input-wrapper": ""
    }, [
      (_ctx.$slots.leadingAddon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "leadingAddon", {}, undefined, true)
          ], 512))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({
        id: _ctx.inputId,
        ref: "inputField",
        type: _ctx.type,
        name: _ctx.name,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        required: _ctx.required,
        placeholder: _ctx.placeholder,
        minlength: _ctx.minlength,
        maxlength: _ctx.maxlength,
        autofocus: _ctx.autofocus,
        autocomplete: _ctx.autocomplete,
        tabindex: _ctx.tabindex,
        list: _ctx.datalistId,
        "aria-invalid": _ctx.hasError,
        "aria-describedby": _ctx.hasError && _ctx.errorMessageId,
        class: _ctx.inputDomClasses,
        style: _ctx.inputStyling
      }, _ctx.extendedInputBindings, {
        class: "shadow-sm block w-full rounded overflow-ellipsis overflow-hidden disabled:cursor-not-allowed placeholder-gray-700 placeholder-opacity-100",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', $event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event)))
      }), null, 16, _hoisted_5),
      (_ctx.$slots.trailingAddon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "trailingAddon", {}, undefined, true)
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            ref: "errorIcon",
            style: _normalizeStyle(_ctx.errorIconStyling),
            class: _normalizeClass([_ctx.extendedErrorIconDomClasses, "error-icon absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"]),
            "data-test-error-icon": ""
          }, [
            _createVNode(_component_ExclamationCircleIcon, {
              class: "h-5 w-5 text-red-500",
              "aria-hidden": "true"
            })
          ], 6))
        : _createCommentVNode("", true),
      (_ctx.hasDatalist)
        ? (_openBlock(), _createElementBlock("datalist", {
            key: 3,
            id: _ctx.datalistId
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datalist, (item, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: `datalist-item-${index}`,
                value: _ctx.getDatalistOptionValue(item),
                label: _ctx.getDatalistOptionLabel(item)
              }, null, 8, _hoisted_8))
            }), 128))
          ], 8, _hoisted_7))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.trailingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.trailingHelpText), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", {
          key: 3,
          id: _ctx.errorMessageId,
          class: _normalizeClass([_ctx.extendedErrorMessageDomClasses, "mt-2 text-sm text-red-500 text-left"]),
          "data-test-error-message": ""
        }, _toDisplayString(_ctx.errorMessage), 11, _hoisted_10))
      : _createCommentVNode("", true)
  ]))
}