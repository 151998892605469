import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" }
const _hoisted_2 = { class: "flex-1 flex justify-between sm:hidden" }
const _hoisted_3 = { class: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPaginationItem = _resolveComponent("AppPaginationItem")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.previousPage)
        ? (_openBlock(), _createBlock(_component_AppPaginationItem, {
            key: 0,
            active: true,
            href: "#",
            class: "rounded-md",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.paginate(_ctx.previousPage)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('previous')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.nextPage)
        ? (_openBlock(), _createBlock(_component_AppPaginationItem, {
            key: 1,
            active: true,
            href: "#",
            class: "rounded-md ml-auto",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.paginate(_ctx.nextPage)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('next')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(_component_i18n_t, {
          keypath: "pagination.metaResults",
          tag: "p",
          class: "text-sm text-gray-700"
        }, {
          from: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(!_ctx.loaded ? '...' : _ctx.meta.meta.from), 1)
          ]),
          to: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(!_ctx.loaded ? '...' : _ctx.meta.meta.to), 1)
          ]),
          total: _withCtx(() => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(!_ctx.loaded ? '...' : _ctx.meta.meta.total), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("nav", {
          class: _normalizeClass([{ 'opacity-50 pointer-events-none': !_ctx.loaded }, "relative z-0 inline-flex rounded-md shadow-sm -space-x-px"]),
          "aria-label": "Pagination"
        }, [
          _createVNode(_component_AppPaginationItem, {
            disabled: _ctx.previousPage === null,
            href: "#",
            class: "rounded-l-md",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.paginate(_ctx.previousPage)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('previous')), 1),
              _createVNode(_component_ChevronLeftIcon, {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]),
            _: 1
          }, 8, ["disabled"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
            return (_openBlock(), _createBlock(_component_AppPaginationItem, {
              key: `pagination-${page}`,
              as: page ? 'a' : 'span',
              active: page === _ctx.meta.meta.currentPage,
              href: "#",
              onClick: _withModifiers(($event: any) => (_ctx.paginate(page)), ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(page ? page : '...'), 1)
              ]),
              _: 2
            }, 1032, ["as", "active", "onClick"]))
          }), 128)),
          _createVNode(_component_AppPaginationItem, {
            class: _normalizeClass([{ 'cursor-not-allowed': _ctx.nextPage === null }, "rounded-r-md"]),
            disabled: _ctx.nextPage === null,
            href: "#",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.paginate(_ctx.nextPage)), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('next')), 1),
              _createVNode(_component_ChevronRightIcon, {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]),
            _: 1
          }, 8, ["class", "disabled"])
        ], 2)
      ])
    ])
  ]))
}