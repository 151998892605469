import { createApp } from 'vue';
import Confirm from './Confirm.vue';
import lang from '../lang';

import type { App } from 'vue';
import type { ConfirmOptions, ConfirmActions, ConfirmInstance } from './index.d';

export default {
  install: (app: App): void => {
    const confirms: ConfirmInstance[] = [];
    const properties = app.config.globalProperties;
    const closeConfirm = (confirmInstance: ConfirmInstance): void => {
      confirmInstance?.instance?.unmount();
      confirmInstance?.element?.remove();
    };

    properties.$confirm = async (options: ConfirmOptions | ConfirmActions): Promise<unknown> => {
      if (options === 'close') {
       confirms.forEach(confirm => closeConfirm(confirm));

       return Promise.resolve();
      }

      const confirmRootElement = document.createElement('div');
      document.body.append(confirmRootElement);

      let confirmInstance = null as null | App;

      return new Promise(resolve => {
        confirmInstance = createApp(Confirm, {
          ...options,
          onClose: resolve,
        });

        confirmInstance.use(lang)
          .mount(confirmRootElement);

        confirms.push({
          instance: confirmInstance,
          element: confirmRootElement,
        });
      }).finally(() => {
        if (confirmInstance) {
          closeConfirm({
            instance: confirmInstance,
            element: confirmRootElement,
          });
        }
      });
    };
  },
};
