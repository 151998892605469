import qs from 'qs';
import axios from 'axios';
import { cloneDeep, findLast, set as objectSet } from 'lodash';
import interceptors from './interceptors';

import type { App } from 'vue';
import type { AxiosResponse, Canceler } from 'axios';
import type {
  Cache,
  HttpPlugin,
  CustomCancelToken,
  FetchParams,
  PatchParams,
  PostParams,
  PutParams,
  PastRequest,
  PastRequestsByMethod,
  RequestConfig,
  RequestMethod,
} from './index.d';

export function paramsSerializer(
  params: FetchParams,
  config: qs.IStringifyOptions = { skipNulls: true },
): string {
  return qs.stringify(params, config);
}

const defaultTimeToLive = 30_000;
const axiosInstance = axios.create({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  paramsSerializer,
});
const pastRequestsThatMustBeUnique: PastRequestsByMethod = {
  get: [],
  post: [],
  patch: [],
  put: [],
  delete: [],
};
let cache: Cache = {};

export function getUrlWithQuery(url: string, params?: FetchParams): string {
  return params ? `${url}?${paramsSerializer(params)}` : url;
}

export function getUrlWithoutQuery(url: string): string {
  return url.split('?')[0];
}

export function clearCache(url?: string, excludeQuery = false): void {
  if (excludeQuery && url) {
    Object.keys(cache).forEach(cacheKey => {
      if (cacheKey.includes('?')) {
        const cacheKeyWithoutQuery = cacheKey.slice(0, cacheKey.indexOf('?'));
        const cacheKeyPrefix = cacheKeyWithoutQuery.startsWith('/') ? '' : '/';
        const urlPrefix = url.startsWith('/') ? '' : '/';

        if ((cacheKeyPrefix + cacheKeyWithoutQuery) === (urlPrefix + url)) {
          delete cache[cacheKey];
        }
      }
    });
  }

  if (url) {
    const urlPrefix = url.startsWith('/') ? '' : '/';
    delete cache[urlPrefix + url];
  } else {
    cache = {};
  }
}

export function createCancelToken(): CustomCancelToken {
  let cancelMethod: Canceler | null = null;
  const cancelToken = new axios.CancelToken((canceler: Canceler) => {
    cancelMethod = canceler;
  });

  return { cancelToken, canceler: cancelMethod as unknown as Canceler };
}

function getPastRequestThatMustBeUnique(
  url: string,
  method: RequestMethod,
  config?: RequestConfig,
  params?: FetchParams,
): PastRequest | null {
  if (config?.unique || config?.uniqueWithQuery) {
    if (config?.isSameUrlCallback) {
      return findLast(pastRequestsThatMustBeUnique[method], config.isSameUrlCallback) ?? null;
    }

    if (config?.uniqueWithQuery) {
      const currentUrlWithQuery = getUrlWithQuery(url, params);
      const urlsWithQuery = pastRequestsThatMustBeUnique[method]
        .map(request => getUrlWithQuery(request.url, request.params));
      const matchIndex = urlsWithQuery.lastIndexOf(currentUrlWithQuery);

      if (matchIndex > -1) {
        return Object.values(pastRequestsThatMustBeUnique[method])[matchIndex] ?? null;
      }

      return null;
    }

    return findLast(pastRequestsThatMustBeUnique[method], ['url', url]) ?? null;
  }

  return null;
}

function cancelPreviousRequestIfNecessary(
  url: string,
  method: RequestMethod,
  config?: RequestConfig,
  params?: FetchParams,
): void {
  if (config?.unique || config?.uniqueWithQuery) {
    const customCancelToken = createCancelToken();
    const { cancelToken } = customCancelToken;

    getPastRequestThatMustBeUnique(url, method, config, params)?.customCancelToken.canceler();

    pastRequestsThatMustBeUnique[method].push({ url, params, customCancelToken });
    config.cancelToken = cancelToken;
  }
}

function saveInCache(
  url: string,
  response: AxiosResponse,
  timeToLive = defaultTimeToLive,
): void {
  cache[url] = {
    timestamp: Date.now() + timeToLive,
    response,
  };
}

function saveResponseInCacheIfNecessary(response: AxiosResponse, config?: RequestConfig): void {
  if (config?.saveResponseInCacheForUrl) {
    const url = config.saveResponseInCacheForUrl;
    const prefix = url.startsWith('/') ? '' : '/';

    saveInCache(prefix + url, response);
  }
}

function setAdditionalHeaders(config?: RequestConfig): RequestConfig | undefined {
  if (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Precognition: config.precognition ?? false,
      },
    };
  }

  return config;
}

export async function fetch<T>(
  url: string,
  params?: FetchParams,
  config?: RequestConfig,
  timeToLive = defaultTimeToLive,
  forceFresh = false,
): Promise<T> {
  const paramsAsString = qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  const mergedUrlPrefix = url.startsWith('/') ? '' : '/';
  const mergedUrl = mergedUrlPrefix + url + paramsAsString;
  const isSavedInCache = mergedUrl in cache;
  const isValid = isSavedInCache
    && !forceFresh
    && cache[mergedUrl].timestamp > Date.now();
  let response;

  // Is saved in cache and is still valid
  if (isSavedInCache && isValid) {
    response = cache[mergedUrl].response;
  } else {
    cancelPreviousRequestIfNecessary(url, 'get', config, params);

    // Fire request and get response
    response = await axiosInstance.get(url, setAdditionalHeaders({ ...config, params }));

    if (timeToLive > 0) {
      // Save response in cache
      saveInCache(mergedUrl, response, timeToLive);
    }
  }

  if (response.data.data) {
    const { data, ...metaData } = cloneDeep(response.data);

    if (Array.isArray(data)) {
      Object.defineProperty(data, 'meta', {
        value: Object.freeze(metaData),
        enumerable: false,
        writable: false,
      });
    }

    return data;
  }

  return response.data;
}

export async function post<P, R>(
  url: string,
  data?: P | PostParams | FormData,
  config?: RequestConfig,
): Promise<R> {
  cancelPreviousRequestIfNecessary(url, 'post', config);
  const response = await axiosInstance.post(url, data, setAdditionalHeaders(config));
  saveResponseInCacheIfNecessary(response, config);

  return response.data?.data ?? response.data;
}

export async function patch<P, R>(
  url: string,
  data?: P | PatchParams | FormData,
  config?: RequestConfig,
): Promise<R> {
  let method: RequestMethod = 'patch';

  if (data instanceof FormData) {
    data.append('_method', method);
    method = 'post';
  }

  cancelPreviousRequestIfNecessary(url, method, config);
  const response = await axiosInstance[method](url, data, setAdditionalHeaders(config));
  saveResponseInCacheIfNecessary(response, config);

  return response.data.data ?? response.data;
}

export async function put<P, R>(
  url: string,
  data?: P | PutParams | FormData,
  config?: RequestConfig,
): Promise<R> {
  let method: RequestMethod = 'put';

  if (data instanceof FormData) {
    data.append('_method', method);
    method = 'post';
  }

  cancelPreviousRequestIfNecessary(url, method, config);
  const response = await axiosInstance[method](url, data, setAdditionalHeaders(config));
  saveResponseInCacheIfNecessary(response, config);

  return response.data.data ?? response.data;
}

export async function deleteRequest<T = void>(
  url: string,
  config?: RequestConfig,
): Promise<T> {
  cancelPreviousRequestIfNecessary(url, 'delete', config);
  const response = await axiosInstance.delete(url, setAdditionalHeaders(config));
  saveResponseInCacheIfNecessary(response, config);

  return response.data.data ?? response.data;
}

const $http = {
  ...axiosInstance,
  fetch,
  post,
  patch,
  put,
  delete: deleteRequest,
  clearCache,
};

export const http = $http;

export default {
  install: (app: App): void => {
    interceptors(axiosInstance, app);

    // TODO: Use correct typings for http plugin, instead of ignoring errors by using 'as'
    app.config.globalProperties.$http = $http as HttpPlugin;

    objectSet(window, '$http', app.config.globalProperties.$http);
  },
};
