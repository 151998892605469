import type { ToastID } from 'vue-toastification/dist/types/types';
import type { State } from './vuex';
import type { Course } from '@/views/Courses/Create';

export default {
  setCourse(state: State, course: Course): void {
    state.course = course;
  },

  setActionDeniedToastId(state: State, toastId: ToastID | null): void {
    state.actionDeniedToastId = toastId;
  },
};
