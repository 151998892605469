import type { DomClassesPerSize, HorizontalPaddingInput } from '.';

export const domClassesPerSize: DomClassesPerSize = {
  small: ['text-md'],
  medium: ['text-md', 'py-2.75'],
  large: ['text-lg', 'py-5'],
};

export const horizontalPaddingInput: HorizontalPaddingInput = {
  small: 8,
  medium: 12,
  large: 16,
};

export default [
  'small',
  'medium',
  'large',
];
