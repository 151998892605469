import { createRouter, createWebHistory, RouterView } from 'vue-router';

import type { Component } from 'vue';
import type { RouteLocation, RouteRecordRaw } from 'vue-router';
import type { ScreenDimensionsDialogTexts } from '@/layouts';
import type EnrollmentResult from '@/views/LMS/Users/Show/Enrollments/Results/EnrollmentResult.vue';
import type TrainingWalkThrough from '@/views/Enrollments/WalkThrough/Training.vue';
import type EditDocument from '@/views/LMS/Users/Show/Documents/Edit/Edit.vue';
import type Section from '@/views/Enrollments/WalkThrough/Section/Section.vue';
import type CourseGroup from '@/views/LMS/Users/CourseGroup/CourseGroup.vue';
import type CourseLibraryShow from '@/views/CourseLibrary/Show/Course.vue';
import type OnboardingShow from '@/views/Onboarding/Show/Show.vue';
import type EnrollmentShow from '@/views/Enrollments/Show/Training.vue';
import type TrainingCreate from '@/views/Trainings/Create/Index.vue';
import type TrainingShow from '@/views/Trainings/Show/Index.vue';
import type UserShow from '@/views/LMS/Users/Show.vue';
import type Invite from '@/views/Auth/Invite.vue';

const authorToolSmallScreenTexts: ScreenDimensionsDialogTexts = {
  title: 'useAnotherDevice',
  description: 'useAnotherDeviceHelpText',
  rotateTitle: 'rotateYourScreen',
  rotateDescription: 'rotateYourScreenHelpText',
};

const cockpitSmallScreenDialogTexts: ScreenDimensionsDialogTexts = {
  title: 'lms.cockpit.smallScreenDialogTexts.title',
  description: 'lms.cockpit.smallScreenDialogTexts.description',
  rotateTitle: 'lms.cockpit.smallScreenDialogTexts.rotateTitle',
  rotateDescription: 'lms.cockpit.smallScreenDialogTexts.rotateDescription',
};

export const routes: RouteRecordRaw[] = [
  {
    path: '/ls/click',
    name: 'auth.deeplink',
    component: (): Component => import(
      /* webpackChunkName: "auth" */
      '../views/Auth/DeepLink.vue'
    ),
    meta: {
      showNavigationBars: false,
      requiresAuth: false,
      withoutGetUser: true,
    },
  },
  {
    path: '/login',
    name: 'auth.login',
    component: (): Component => import(
      /* webpackChunkName: "auth" */
      '../views/Auth/Login.vue'
    ),
    meta: {
      showNavigationBars: false,
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-auth',
    name: 'auth.forgot-auth',
    component: (): Component => import(
      /* webpackChunkName: "auth" */
      '../views/Auth/ForgotAuth.vue'
    ),
    meta: {
      showNavigationBars: false,
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'auth.reset-password',
    component: (): Component => import(
      /* webpackChunkName: "auth" */
      '../views/Auth/ResetPassword.vue'
    ),
    meta: {
      showNavigationBars: false,
      withoutGetUser: true,
      requiresAuth: false,
    },
  },
  {
    path: '/invite/:inviteId/:inviteToken',
    name: 'auth.invite',
    component: (): Component => import(
      /* webpackChunkName: "auth" */
      '../views/Auth/Invite.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof Invite>> => {
      return {
        ...route.params,
        inviteId: parseInt(route.params.inviteId as string, 10),
      };
    },
    meta: {
      showNavigationBars: false,
      withoutGetUser: true,
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'dashboard',
    component: (): Component => import(
      /* webpackChunkName: "dashboard" */
      '../views/Courses/List/Index.vue'
    ),
    meta: {
      isResponsive: false,
      activeMainMenuItems: ['authorTool'],
      permissions: ['educator.author_tool.list'],
      showNoAccessOverlayForRoles: ['sub-admin-group-leader', 'sub-admin-trainer'],
      smallScreenDialogTexts: authorToolSmallScreenTexts,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    redirect: 'profile.preferences',
    component: (): Component => import(
      /* webpackChunkName: "profile" */
      '../views/Profile/Index.vue'
    ),
    meta: {
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
      showUserHeader: true,
      permissions: ['profile.preferences'],
    },
    children: [
      {
        name: 'profile.preferences',
        path: 'preferences',
        component: (): Component => import(
          /* webpackChunkName: "profile-preferences" */
          '../views/Profile/Preferences/Index.vue'
        ),
        meta: {
          showPrimaryNav: { mobile: false },
          showSecondaryNav: { mobile: false },
          showUserHeader: true,
          permissions: ['profile.preferences'],
        },
      },
    ],
  },
  {
    path: '/courses',
    name: 'courses',
    redirect: 'courses.create',
    component: RouterView,
    meta: {
      isResponsive: false,
      activeMainMenuItems: ['authorTool'],
      permissions: ['educator.author_tool.create'],
      smallScreenDialogTexts: authorToolSmallScreenTexts,
    },
    children: [
      {
        name: 'courses.create',
        path: 'create',
        component: (): Component => import(
          /* webpackChunkName: "choose-course-type" */
          '../views/Courses/Create/ChooseCourseType/Index.vue'
        ),
        props: true,
        meta: {
          isResponsive: false,
          activeMainMenuItems: ['authorTool'],
          smallScreenDialogTexts: authorToolSmallScreenTexts,
        },
      },
      {
        name: 'courses.create.step',
        path: 'create/:stepNumber',
        component: (): Component => import(
          /* webpackChunkName: "create-course-index" */
          '../views/Courses/Create/Index.vue'
        ),
        props: true,
        meta: {
          isResponsive: false,
          activeMainMenuItems: ['authorTool'],
          smallScreenDialogTexts: authorToolSmallScreenTexts,
        },
      },
      {
        name: 'courses.review',
        path: ':courseId/versions/:versionId/review',
        component: (): Component => import(
          /* webpackChunkName: "review-course-index" */
          '../views/Courses/Review/Index.vue'
        ),
        props: true,
        meta: {
          isResponsive: false,
          activeMainMenuItems: ['authorTool'],
          permissions: ['educator.author_tool.review'],
          smallScreenDialogTexts: authorToolSmallScreenTexts,
        },
      },
      {
        name: 'courses.edit',
        path: ':courseId/versions/:versionId/edit/:stepNumber?',
        component: (): Component => import(
          /* webpackChunkName: "create-course-index" */
          '../views/Courses/Create/Index.vue'
        ),
        meta: {
          isResponsive: false,
          activeMainMenuItems: ['authorTool'],
          permissions: ['educator.author_tool.edit'],
          smallScreenDialogTexts: authorToolSmallScreenTexts,
        },
        props: true,
        children: [
          {
            name: 'courses.edit.build',
            path: 'section',
            component: (): Component => import(
              /* webpackChunkName: "create-course-index" */
              '../views/Courses/Create/Build/Form.vue'
            ),
            props: true,
            meta: {
              isResponsive: false,
              activeMainMenuItems: ['authorTool'],
              smallScreenDialogTexts: authorToolSmallScreenTexts,
            },
            children: [
              {
                name: 'courses.edit.build.section',
                path: ':sectionId',
                component: (): Component => import(
                  /* webpackChunkName: "create-course-index" */
                  '../views/Courses/Create/Build/PageBuilder.vue'
                ),
                props: true,
                meta: {
                  isResponsive: false,
                  activeMainMenuItems: ['authorTool'],
                  smallScreenDialogTexts: authorToolSmallScreenTexts,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'courses.preview',
        path: ':enrollmentId/preview',
        component: (): Component => import(
          /* webpackChunkName: "preview-course" */
          '../views/Enrollments/WalkThrough/Training.vue'
        ),
        props: (route: RouteLocation): Partial<InstanceType<typeof TrainingWalkThrough>> => {
          return {
            ...route.params,
            enrollmentId: parseInt(route.params.enrollmentId as string, 10),
          };
        },
        meta: {
          permissions: ['educator.author_tool.create'],
          activeMainMenuItems: ['authorTool'],
          isResponsive: true,
          isPreview: true,
        },
        children: [
          {
            name: 'courses.preview.section',
            path: ':sectionId/:blockUuid?',
            component: (): Component => import(
              /* webpackChunkName: "preview-course" */
              '../views/Enrollments/WalkThrough/Section/Section.vue'
            ),
            props: (route: RouteLocation): Partial<InstanceType<typeof Section>> => {
              return {
                ...route.params,
                enrollmentId: parseInt(route.params.enrollmentId as string, 10),
                sectionId: parseInt(route.params.sectionId as string, 10),
              };
            },
            meta: {
              isPreview: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/documents',
    name: 'my-documents',
    component: (): Component => import(
      /* webpackChunkName: "my-documents" */
      '../views/Documents/List/MyDocuments.vue'
    ),
    meta: {
      permissions: ['document.my_documents.list'],
      activeMainMenuItems: ['myDocuments'],
    },
  },
  {
    path: '/my-trainings',
    name: 'my-trainings',
    component: (): Component => import(
      /* webpackChunkName: "my-trainings" */
      '../views/Enrollments/List/MyTrainings.vue'
    ),
    meta: {
      permissions: ['training.my_trainings.list'],
      activeMainMenuItems: ['myTrainings'],
    },
  },
  {
    path: '/my-trainings/:trainingId',
    name: 'my-trainings.trainings.show',
    component: (): Component => import(
      /* webpackChunkName: "my-trainings-trainings-show" */
      '../views/Trainings/Show/Index.vue'
      ),
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingShow>> => {
      return {
        ...route.params,
        trainingId: parseInt(route.params.trainingId as string, 10),
      };
    },
    meta: {
      permissions: ['training.my_trainings.show'],
      activeMainMenuItems: ['myTrainings'],
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
    },
  },
  {
    path: '/enrollments/:enrollmentId',
    name: 'my-trainings.enrollments.show',
    component: (): Component => import(
      /* webpackChunkName: "my-trainings-enrollments-show" */
      '../views/Enrollments/Show/Training.vue'
    ),
    meta: {
      permissions: ['training.my_trainings.show'],
      activeMainMenuItems: ['myTrainings'],
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
    },
    props: (route: RouteLocation): Partial<InstanceType<typeof EnrollmentShow>> => {
      return {
        ...route.params,
        enrollmentId: parseInt(route.params.enrollmentId as string, 10),
      };
    },
  },
  {
    path: '/enrollments/:enrollmentId/walk-through',
    name: 'my-trainings.walk-through',
    component: (): Component => import(
      /* webpackChunkName: "my-trainings" */
      '../views/Enrollments/WalkThrough/Training.vue'
    ),
    meta: {
      permissions: ['training.my_trainings.walk_through'],
      activeMainMenuItems: ['myTrainings'],
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
    },
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingWalkThrough>> => {
      return {
        ...route.params,
        enrollmentId: parseInt(route.params.enrollmentId as string, 10),
      };
    },
    children: [
      {
        name: 'my-trainings.walk-through.section',
        path: 'finish',
        component: (): Component => import(
          /* webpackChunkName: "my-trainings" */
          '../views/Enrollments/WalkThrough/Section/Section.vue'
        ),
        props: (route: RouteLocation): Partial<InstanceType<typeof Section>> => {
          return {
            ...route.params,
            enrollmentId: parseInt(route.params.enrollmentId as string, 10),
          };
        },
      },
      {
        name: 'my-trainings.walk-through.section',
        path: ':sectionId/:blockUuid?',
        component: (): Component => import(
          /* webpackChunkName: "my-trainings" */
          '../views/Enrollments/WalkThrough/Section/Section.vue'
        ),
        props: (route: RouteLocation): Partial<InstanceType<typeof Section>> => {
          return {
            ...route.params,
            enrollmentId: parseInt(route.params.enrollmentId as string, 10),
            sectionId: parseInt(route.params.sectionId as string, 10),
          };
        },
      },
    ],
  },
  {
    path: '/course-library',
    name: 'course-library.list',
    component: (): Component => import(
      /* webpackChunkName: "course-library" */
      '../views/CourseLibrary/List/Courses.vue'
    ),
    meta: {
      permissions: ['educator.courses.list'],
      activeMainMenuItems: ['courseLibrary'],
      isCourseLibrary: true,
    },
  },
  {
    path: '/course-library/:courseId',
    name: 'course-library.show',
    component: (): Component => import(
      /* webpackChunkName: "course-library" */
      '../views/CourseLibrary/Show/Course.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof CourseLibraryShow>> => {
      return {
        ...route.params,
        courseId: parseInt(route.params.courseId as string, 10),
      };
    },
    meta: {
      permissions: ['educator.courses.show'],
      activeMainMenuItems: ['courseLibrary'],
      showPrimaryNav: { mobile: false },
      isCourseLibrary: true,
    },
  },
  {
    name: 'course-library.preview',
    path: '/course-library/:enrollmentId/preview',
    component: (): Component => import(
      /* webpackChunkName: "preview-course-library" */
      '../views/Enrollments/WalkThrough/Training.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingWalkThrough>> => {
      return {
        ...route.params,
        enrollmentId: parseInt(route.params.enrollmentId as string, 10),
      };
    },
    meta: {
      permissions: ['educator.course_library.preview'],
      activeMainMenuItems: ['courseLibrary'],
      isResponsive: true,
      isCourseLibrary: true,
      isPreview: true,
      showPrimaryNav: { mobile: false },
    },
    children: [
      {
        name: 'course-library.preview.section',
        path: ':sectionId/:blockUuid?',
        component: (): Component => import(
          /* webpackChunkName: "preview-course-library" */
          '../views/Enrollments/WalkThrough/Section/Section.vue'
        ),
        props: (route: RouteLocation): Partial<InstanceType<typeof Section>> => {
          return {
            ...route.params,
            enrollmentId: parseInt(route.params.enrollmentId as string, 10),
            sectionId: parseInt(route.params.sectionId as string, 10),
          };
        },
        meta: {
          isPreview: true,
          isCourseLibrary: true,
          showPrimaryNav: { mobile: false },
        },
      },
    ],
  },
  {
    path: '/trainings',
    name: 'trainings.list',
    component: (): Component => import(
      /* webpackChunkName: "trainings" */
      '../views/Trainings/List/Index.vue'
      ),
    meta: {
      permissions: ['training.trainings.list'],
      activeMainMenuItems: ['trainings'],
    },
  },
  {
    path: '/trainings/:trainingId',
    name: 'training.show',
    component: (): Component => import(
      /* webpackChunkName: "training-show" */
      '../views/Trainings/Show/Index.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingShow>> => {
      return {
        ...route.params,
        trainingId: parseInt(route.params.trainingId as string, 10),
      };
    },
    meta: {
      permissions: ['training.trainings.show'],
      activeMainMenuItems: ['trainings'],
      showPrimaryNav: { mobile: false },
    },
  },
  {
    path: '/trainings/create/:stepNumber',
    name: 'trainings.create',
    component: (): Component => import(
      /* webpackChunkName: "trainings" */
      '../views/Trainings/Create/Index.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingCreate>> => {
      return {
        ...route.params,
        trainingId: route.params.trainingId ? parseInt(route.params.trainingId as string, 10) : undefined,
        stepNumber: route.params.stepNumber ? parseInt(route.params.stepNumber as string, 10) : undefined,
      };
    },
    meta: {
      permissions: ['training.trainings.create'],
      activeMainMenuItems: ['trainings'],
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
      mobileHeaderButton: 'close',
    },
  },
  {
    path: '/trainings/:trainingId/edit/:stepNumber',
    name: 'trainings.edit',
    component: (): Component => import(
      /* webpackChunkName: "trainings" */
      '../views/Trainings/Create/Index.vue'
    ),
    props: (route: RouteLocation): Partial<InstanceType<typeof TrainingCreate>> => {
      return {
        ...route.params,
        trainingId: parseInt(route.params.trainingId as string, 10),
        stepNumber: route.params.stepNumber ? parseInt(route.params.stepNumber as string, 10) : undefined,
        edit: true,
      };
    },
    meta: {
      permissions: ['training.trainings.edit'],
      activeMainMenuItems: ['trainings'],
      showPrimaryNav: { mobile: false },
      showSecondaryNav: { mobile: false },
      mobileHeaderButton: 'close',
    },
  },
  {
    path: '/lms',
    component: (): Component => import(
      /* webpackChunkName: "lms-users" */
      '../views/LMS/Index.vue'
    ),
    children: [
      {
        path: 'dashboard',
        name: 'lms.cockpit.list',
        component: (): Component => import(
          /* webpackChunkName: "lms-cockpit" */
          '../views/LMS/Cockpit/List/CockpitIndex.vue'
        ),
        meta: {
          isResponsive: false,
          activeMainMenuItems: ['lms'],
          permissions: ['lms.cockpits.list'],
          showNoAccessOverlayForRoles: ['sub-admin-group-leader', 'sub-admin-trainer'],
          smallScreenDialogTexts: cockpitSmallScreenDialogTexts,
        },
      },
      {
        path: 'users',
        name: 'lms.users',
        component: RouterView,
        meta: {
          isResponsive: true,
          activeMainMenuItems: ['lms'],
        },
        children: [
          {
            path: '',
            name: 'lms.users.list',
            component: (): Component => import(
              /* webpackChunkName: "lms-users" */
              '../views/LMS/Users/Index.vue'
            ),
            meta: {
              activeMainMenuItems: ['lms'],
              permissions: ['lms.users.list'],
              showNoAccessOverlayForRoles: ['sub-admin-group-leader', 'sub-admin-trainer'],
            },
          },
          {
            path: 'create',
            name: 'lms.users.create',
            component: (): Component => import(
              /* webpackChunkName: "lms-create-users" */
              '../views/LMS/Users/Create.vue'
            ),
            meta: {
              activeMainMenuItems: ['lms'],
              permissions: ['lms.users.create'],
              showPrimaryNav: { mobile: false },
              showSecondaryNav: { mobile: false },
              mobileHeaderButton: 'close',
            },
          },
          {
            path: ':userId',
            component: (): Component => import(
              /* webpackChunkName: "lms-users" */
              '../views/LMS/Users/Show.vue'
            ),
            props: (route: RouteLocation): Partial<InstanceType<typeof UserShow>> => {
              return {
                ...route.params,
                userId: parseInt(route.params.userId as string, 10),
              };
            },
            children: [
              {
                path: 'edit',
                name: 'lms.users.edit',
                component: (): Component => import(
                  /* webpackChunkName: "lms-edit-user" */
                  '../views/LMS/Users/Edit.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.users.edit'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  mobileHeaderButton: 'close',
                },
              },
              {
                path: 'enrollments',
                name: 'lms.users.show',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Enrollments/EnrollmentsIndex.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.users.show'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  showUserHeader: true,
                },
              },
              {
                path: 'trainings',
                name: 'lms.users.trainings',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Trainings/Index.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.users.show'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  showUserHeader: true,
                },
              },
              {
                path: 'documents',
                name: 'lms.users.documents',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Documents/Index.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.documents.list'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  showUserHeader: true,
                },
              },
              {
                path: 'documents/create',
                name: 'lms.users.documents.create',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Documents/Create/Create.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.documents.create'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  mobileHeaderButton: 'close',
                },
              },
              {
                path: 'documents/:documentId',
                name: 'lms.users.documents.edit',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Documents/Edit/Edit.vue'
                ),
                props: (route: RouteLocation): Partial<InstanceType<typeof EditDocument>> => {
                  return {
                    ...route.params,
                    documentId: parseInt(route.params.documentId as string, 10),
                  };
                },
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.documents.edit'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                  mobileHeaderButton: 'close',
                },
              },
              {
                path: 'enrollments/:enrollmentId',
                name: 'lms.users.enrollment.results',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users" */
                  '../views/LMS/Users/Show/Enrollments/Results/EnrollmentResults.vue'
                ),
                props: (route: RouteLocation): Partial<InstanceType<typeof EnrollmentResult>> => {
                  return {
                    ...route.params,
                    enrollmentId: parseInt(route.params.enrollmentId as string, 10),
                  };
                },
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.users.show'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                },
              },
              {
                path: 'course-group/:courseGroupId',
                name: 'lms.users.course-group',
                component: (): Component => import(
                  /* webpackChunkName: "lms-users-course-group" */
                  '../views/LMS/Users/Show/CourseGroup/CourseGroup.vue'
                ),
                props: (route: RouteLocation): Partial<InstanceType<typeof CourseGroup>> => {
                  return {
                    ...route.params,
                    courseGroupId: parseInt(route.params.courseGroupId as string, 10),
                  };
                },
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.users.show'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'groups',
        name: 'lms.groups',
        component: RouterView,
        meta: {
          isResponsive: true,
          activeMainMenuItems: ['lms'],
        },
        children: [
          {
            path: '',
            name: 'lms.groups.list',
            component: (): Component => import(
              /* webpackChunkName: "lms-groups" */
              '../views/LMS/Groups/List/GroupsIndex.vue'
            ),
            meta: {
              activeMainMenuItems: ['lms'],
              permissions: ['lms.groups.list'],
              showNoAccessOverlayForRoles: ['sub-admin-group-leader', 'sub-admin-trainer'],
            },
          },
          {
            path: ':groupId',
            component: RouterView,
            children: [
              {
                path: '',
                name: 'lms.groups.show',
                component: (): Component => import(
                  /* webpackChunkName: "lms-groups" */
                  '../views/LMS/Groups/Show/Show.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.groups.show'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                },
              },
              {
                path: 'edit',
                name: 'lms.groups.edit',
                component: (): Component => import(
                  /* webpackChunkName: "lms-edit-groups" */
                  '../views/LMS/Groups/Edit/Edit.vue'
                ),
                meta: {
                  activeMainMenuItems: ['lms'],
                  permissions: ['lms.groups.edit'],
                  showPrimaryNav: { mobile: false },
                  showSecondaryNav: { mobile: false },
                },
              },
            ],
          },
          {
            path: 'create',
            name: 'lms.groups.create',
            component: (): Component => import(
              /* webpackChunkName: "lms-create-groups" */
              '../views/LMS/Groups/Create/Create.vue'
            ),
            meta: {
              activeMainMenuItems: ['lms'],
              permissions: ['lms.groups.create'],
              showPrimaryNav: { mobile: false },
              showSecondaryNav: { mobile: false },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: RouterView,
    meta: {
      isResponsive: true,
      activeMainMenuItems: ['onboarding'],
    },
    children: [
      {
        path: '',
        name: 'onboarding.list',
        component: (): Component => import(
          /* webpackChunkName: "onboarding" */
          '../views/Onboarding/List/Index.vue'
        ),
        meta: {
          activeMainMenuItems: ['onboarding'],
          permissions: ['course_groups.list'],
          showNoAccessOverlayForRoles: ['sub-admin-group-leader'],
        },
      },
      {
        path: 'create',
        name: 'onboarding.create',
        component: (): Component => import(
          /* webpackChunkName: "onboarding-create" */
          '../views/Onboarding/Form/Create.vue'
        ),
        meta: {
          activeMainMenuItems: ['onboarding'],
          showPrimaryNav: { mobile: false },
          showSecondaryNav: { mobile: false },
          permissions: ['course_groups.create'],
        },
      },
      {
        path: ':courseGroupId',
        component: RouterView,
        props: (route: RouteLocation): Partial<InstanceType<typeof OnboardingShow>> => {
          return {
            ...route.params,
            courseGroupId: parseInt(route.params.courseGroupId as string, 10),
          };
        },
        meta: {
          isResponsive: true,
          activeMainMenuItems: ['onboarding'],
        },
        children: [
          {
            path: '',
            name: 'onboarding.show',
            component: (): Component => import(
              /* webpackChunkName: "onboarding" */
              '../views/Onboarding/Show/Show.vue'
            ),
            meta: {
              activeMainMenuItems: ['onboarding'],
              showPrimaryNav: { mobile: false },
              showSecondaryNav: { mobile: false },
              permissions: ['course_groups.show'],
            },
          },
          {
            path: 'edit',
            name: 'onboarding.edit',
            component: (): Component => import(
              /* webpackChunkName: "onboarding-edit" */
              '../views/Onboarding/Form/Edit.vue'
            ),
            meta: {
              activeMainMenuItems: ['onboarding'],
              showPrimaryNav: { mobile: false },
              showSecondaryNav: { mobile: false },
              permissions: ['course_groups.edit'],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: RouterView,
    meta: {
      isResponsive: true,
    },
    children: [
      {
        path: '',
        name: 'admin.users.list',
        component: (): Component => import(
          /* webpackChunkName: "admin-users" */
          '../views/LMS/Users/Admin/Index.vue'
        ),
        meta: {
          permissions: ['admin.users.list'],
        },
      },
      {
        path: ':userId',
        name: 'admin.users.show',
        component: (): Component => import(
          /* webpackChunkName: "lms-users" */
          '../views/LMS/Users/Show.vue'
        ),
        props: (route: RouteLocation): Partial<InstanceType<typeof UserShow>> => {
          return {
            userId: parseInt(route.params.userId as string, 10),
            admin: true,
          };
        },
        meta: {
          permissions: ['admin.users.show'],
          showPrimaryNav: { mobile: false },
          showSecondaryNav: { mobile: false },
          showUserHeader: true,
        },
      },
      {
        path: '/admin/users/create',
        name: 'admin.users.create',
        component: (): Component => import(
          /* webpackChunkName: "admin-create-users" */
          '../views/LMS/Users/Admin/Create.vue'
        ),
        meta: {
          permissions: ['admin.users.create'],
          showPrimaryNav: { mobile: false },
          showSecondaryNav: { mobile: false },
          mobileHeaderButton: 'close',
        },
      },
      {
        path: '/admin/users/:userId/edit',
        name: 'admin.users.edit',
        component: (): Component => import(
          /* webpackChunkName: "admin-edit-users" */
          '../views/LMS/Users/Admin/Edit.vue'
        ),
        meta: {
          permissions: ['admin.users.edit'],
          showPrimaryNav: { mobile: false },
          showSecondaryNav: { mobile: false },
          mobileHeaderButton: 'close',
        },
      },
    ],
  },
  {
    path: '/generic-components-overview',
    name: 'generic-components-overview',
    component: (): Component => import(
      /* webpackChunkName: "generic-components-overview" */
      '../views/GenericComponentsOverview/Index.vue'
    ),
    meta: {
      permissions: ['educator.components_overview.show'],
    },
  },
  {
    path: '/not-found',
    name: 'page-not-found',
    component: (): Component => import(
      /* webpackChunkName: "errors-pages" */
      '../views/Errors/PageNotFound/PageNotFound.vue'
    ),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: { name: 'page-not-found' },
    meta: {
      isCatchAllRoute: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return savedPosition ?? { top: 0 };
  },
});

export default router;
