
  import { defineComponent } from 'vue';
  import UploadIcon from './UploadIcon.vue';
  import props from './props';
  import { convertDomClassesPropToArray } from '@/helpers';

  export default defineComponent({
    name: 'AppInputFile',

    components: {
      UploadIcon,
    },

    props,

    emits: [
      'fileExtensionsError',
      'update:modelValue',
      'change',
    ],

    data() {
      return {
        dragEntered: false,
        fileList: [] as File[],
        fileExtensionsError: null as string | null,
      };
    },

    computed: {
      hasError(): boolean {
        return !!this.errorMessage || (
          this.mustShowFileExtensionsError && !!this.fileExtensionsError
        );
      },

      dynamicDropContainerClasses(): string[] {
        let domClasses = [] as string[];

        if (this.errorMessage) {
          domClasses = [
            ...domClasses,
            'border-red-500',
            'hover:border-red-700',
          ];
        }

        if (this.disabled) {
          domClasses.push('cursor-not-allowed');
        } else {
          domClasses.push('cursor-pointer');
        }

        if (this.dragEntered) {
          domClasses.push('border-gray-500');
        } else if (!this.errorMessage) {
          domClasses.push('border-gray-300');
        }

        return domClasses;
      },

      dynamicLabelClasses(): string[] {
        const domClasses: string[] = [];

        if (this.disabled) {
          domClasses.push('cursor-not-allowed');
        } else {
          domClasses.push('cursor-pointer');
        }

        if (this.extendedLabelDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedLabelDomClasses,
          ));
        }

        return domClasses;
      },

      errorMessageId(): string {
        return `${this.inputId}-error-message`;
      },

      acceptedExtensions(): string[] {
        const support = [];

        if (this.accept?.includes('image/*')) {
          support.push('JPG', 'JPEG', 'PNG');
        }

        if (this.accept?.includes('video/*')) {
          support.push('MP4', 'MOV', 'AVI', 'Quicktime');
        }

        if (this.accept?.includes('application/pdf')) {
          support.push('PDF');
        }

        return support;
      },

      extensions(): string {
        if (this.extensionsText) {
          return this.extensionsText;
        }

        return this.acceptedExtensions
          .filter(extension => extension !== 'Quicktime')
          .join(', ');
      },
    },

    watch: {
      fileExtensionsError(message: string | null): void {
        this.$emit('fileExtensionsError', message);
      },
    },

    methods: {
      dropMedia(event: DragEvent): void {
        event.preventDefault();

        if (event.dataTransfer?.files.length) {
          const fileInput = this.$refs.fileInput as HTMLInputElement;
          fileInput.files = event.dataTransfer.files as FileList;

          const uploadedFiles = [...fileInput.files].filter(file => {
            let accept: string | null = this.accept;

            if (accept?.includes(',')) {
              accept = accept.split(',').find(split => {
                return split.includes(file.type.split('/')[0]);
              }) ?? null;
            }

            if (!accept || !new RegExp(accept.replace('/', '.')).test(file.type)) {
              this.fileExtensionsError = this.$t('extensionAreNotAllowed.description', {
                extensions: this.acceptedExtensions.join(', ').toLowerCase(),
              });

              return false;
            }

            return true;
          });

          if (uploadedFiles.length > 0) {
            this.fileList = [...fileInput.files];

            this.$emit('update:modelValue', this.fileList);
            this.$emit('change', this.fileList);
          }
        }
      },

      onFileChanged(event: Event): void {
        const inputElement = event.target as HTMLInputElement;

        if (inputElement?.files) {
          const files = [...inputElement.files] as unknown as File[];

          const filteredFiles = files.filter(file => {
            return this.acceptedExtensions.some(extension => {
              return file.type.toLowerCase().endsWith(`/${extension.toLowerCase()}`);
            });
          });

          if (filteredFiles.length > 0) {
            this.fileList = filteredFiles;

            this.$emit('update:modelValue', this.fileList);
            this.$emit('change', this.fileList);
          } else {
            this.fileExtensionsError = this.$t('extensionAreNotAllowed.description', {
              extensions: this.acceptedExtensions.join(', ').toLowerCase(),
            });
          }
        }
      },
    },
  });
