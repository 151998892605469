
  import { defineComponent } from 'vue';
  import { AppInputBase } from '@/components';
  import methods from '@/components/AppInputBase/methods-mixin';
  import props from './props';

  export default defineComponent({
    name: 'AppInputNumber',

    components: {
      AppInputBase,
    },

    mixins: [
      methods,
    ],

    inheritAttrs: false,

    props: {
      ...AppInputBase.props,
      ...props,
    },

    emits: AppInputBase.emits,

    computed: {
      inputBindings(): Record<string, unknown> {
        return {
          min: this.min,
          max: this.max,
          step: this.step,
        };
      },

      modelValueStringCasted(): string {
        return this.modelValue !== null ? String(this.modelValue) : '';
      },
    },

    methods: {
      update(value: number | null): void {
        this.$emit('update:modelValue', value);
      },

      parseModelValueToFloat(value: string): void {
        if (value.trim().length === 0) {
          this.update(null);
          return;
        }

        const parsedValue = Number.parseFloat(value);

        if (!Number.isNaN(parsedValue)) {
          this.update(parsedValue);
        }
      },
    },
  });
