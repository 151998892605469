
  import { defineComponent, inject } from 'vue';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Size, Select, SetUniqueValue } from '.';

  const buttonDomClassesPerSize = {
    small: ['px-4', 'py-0.75', 'text-sm', 'leading-6'],
    medium: ['px-5', 'py-2.75', 'text-sm', 'leading-6'],
    large: ['px-7', 'py-4', 'text-lg'],
  };
  const buttonDomClassesWhenNothingIsSelected = [
    'bg-white',
    'text-gray-900',
  ];
  const buttonDomClassesWhenSomethingIsSelected = [
    'aria-selected:bg-blue',
    'aria-selected:text-white',
    'aria-not-selected:bg-black-50',
    'aria-not-selected:text-gray-700',
    'aria-selected:inset-shadow-blue-900',
  ];

  export default defineComponent({
    name: 'AppRadioButton',

    props: {
      value: {
        type: [String, Number, Boolean, Array, Object, Function] as PropType<unknown>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    data() {
      return {
        size: inject<Size>('size', 'medium'),
        selected: inject<unknown>('selected', null),
        allRadioButtonsDisabled: inject<boolean>('disabled', false),
        nothingIsSelected: inject<boolean>('nothingIsSelected', true),
        select: inject<Select>('select', () => { /* */ }, true),
        setUniqueValue: inject<SetUniqueValue>('setUniqueValue', () => { /* */ }, true),
        extendedDomClasses: inject<DomClassesProp | null>('buttonDomClasses', null),
        buttonElement: null as HTMLButtonElement | null,
        buttonDomClassesWhenSomethingIsSelected,
        buttonDomClassesWhenNothingIsSelected,
        buttonDomClassesPerSize,
      };
    },

    computed: {
      buttonText(): string | null {
        return this.buttonElement?.textContent?.trim() ?? null;
      },

      uniqueValue(): unknown {
        return this.value ?? this.buttonText;
      },

      isSelected(): boolean {
        return this.selected === this.uniqueValue;
      },

      isDisabled(): boolean {
        return this.disabled || this.allRadioButtonsDisabled;
      },
    },

    watch: {
      uniqueValue() {
        this.setUniqueValue(this.uniqueValue, this.buttonElement);
      },
    },

    mounted() {
      this.buttonElement = this.$refs.radioButton as HTMLButtonElement;
      this.setUniqueValue(this.uniqueValue, this.buttonElement);
    },

    methods: {
      convertDomClassesPropToArray,
    },
  });
