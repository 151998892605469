import { createApp } from 'vue';
import { Settings as LuxonSettings } from 'luxon';
import store from './store';
import router from './router';
import App from './views/App.vue';
import lang from './plugins/lang';
import http from './plugins/http';
import confirm from './plugins/confirm';
import eventBus from './plugins/eventBus';
import echo from './plugins/echo';
import queue from './plugins/queue';
import toasts from './plugins/toasts';
import GlobalMixins from './mixins/global';
import GuardMixin from './mixins/guard-mixin';
import updateAwsConfig from './update-aws-config';
import registerGoogleApi from './register-google';
import registerSentry from './register-sentry';
import './register-service-worker';
import './css/main.css';

LuxonSettings.defaultLocale = 'nl-NL';

updateAwsConfig();

const app = createApp(App);

registerGoogleApi(process.env.VUE_APP_GOOGLE_API_KEY);

if (process.env.NODE_ENV !== 'local') {
  registerSentry(app, router);
}

app.use(store())
  .use(router)
  .use(lang)
  .use(queue)
  .use(eventBus)
  .use(echo)
  .use(toasts)
  .use(confirm)
  .use(http)
  .mixin(GlobalMixins)
  .mixin(GuardMixin);

router.isReady().then(() => {
  app.mount('#app');
});
