
  import { defineComponent } from 'vue';
  import { DisclosureButton } from '@headlessui/vue';
  import DisclosureMixin from './disclosure-mixin';

  export default defineComponent({
    name: 'AppDisclosureButton',

    components: {
      DisclosureButton,
    },

    mixins: [
      DisclosureMixin,
    ],

    inheritAttrs: false,
  });
