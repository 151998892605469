
  import videojs from 'video.js';
  import hlsQualitySelector from 'videojs-hls-quality-selector';
  import qualityLevels from 'videojs-contrib-quality-levels';
  import { defineComponent } from 'vue';
  import props from './props';

  import type { Source } from '.';

  // Quality selector not working on Safari
  // Safari issue: https://github.com/chrisboustead/videojs-hls-quality-selector/issues/80
  if (!videojs.getPlugin('qualityLevels')) {
    videojs.registerPlugin('qualityLevels', qualityLevels);
  }

  if (!videojs.getPlugin('hlsQualitySelector')) {
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
  }

  export default defineComponent({
    name: 'AppVideoPlayer',

    props,

    emits: [
      'ready',
      'play',
      'end',
      'pause',
      'seeking',
      'timeupdate',
      'finish',
      'error',
    ],

    data() {
      return {
        player: null as videojs.Player | null,
        sources: [] as Source[],
        time: 0,
        duration: 0,
      };
    },

    mounted() {
      const player = this.$refs.videoPlayer as Element;
      const playerOptions: videojs.PlayerOptions = {
        controls: this.controls,
        autoplay: this.autoplay,
        loop: this.loop,
        preload: this.preload,
        poster: this.poster,
        fluid: this.fluid,
        plugins: {
          hlsQualitySelector: {
            displayCurrentQuality: true,
          },
        },
        ...this.options,
      };

      if (this.playlist) {
        this.sources = [
          {
            src: this.playlist,
            type: 'application/x-mpegURL',
          },
        ];
      } else if (this.source) {
        this.sources = this.source;
      }

      this.$nextTick(() => {
        this.player = videojs(player, playerOptions, () => {
          if (this.autoplay) {
            this.player?.play();
          }

          this.ready();
        });
      });
    },

    beforeUnmount() {
      this.player?.dispose();
    },

    methods: {
      ready(): void {
        if (this.player) {
          this.player.on('play', () => {
            this.$emit('play', this.player);
          });

          this.player.on('ended', () => {
            this.$emit('end', this.player);

            const currentTime = this.player?.currentTime() ?? 0;
            const percentProgress = Math.round((currentTime / this.duration) * 100);

            if (percentProgress >= this.finishedAtPercentage) {
              this.$emit('finish', this.player);
            }
          });

          this.player.on('pause', () => {
            this.$emit('pause', this.player);
          });

          this.player.on('timeupdate', () => {
            const currentTime = this.player?.currentTime() ?? 0;

            this.time = Math.floor(currentTime);
            const percentProgress = Math.round((currentTime / this.duration) * 100);

            this.$emit('timeupdate', {
              time: this.time,
              percentage: percentProgress,
              duration: this.duration,
            });

            if (percentProgress >= this.finishedAtPercentage) {
              this.$emit('finish', this.player);
            }
          });

          this.player.on('error', () => {
            this.$emit('error', this.player);
          });

          this.player.on('seeking', () => {
            this.$emit('seeking', this.player);
          });

          this.player.on('loadedmetadata', () => {
            this.duration = this.player?.duration() ?? 0;

            this.$emit('ready', this.player);
          });
        }
      },

      play(fullscreen: false): void {
        if (fullscreen) {
          this.player?.requestFullscreen();
        }

        this.player?.play();
      },
    },
  });
