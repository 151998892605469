import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "data-open"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclosure = _resolveComponent("Disclosure")!

  return (_openBlock(), _createBlock(_component_Disclosure, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
    "aria-expanded": _ctx.isOpen,
    as: "div"
  }), {
    default: _withCtx(({ open: disclosureOpen }) => [
      _createElementVNode("div", {
        id: _ctx.disclosureInnerId,
        "data-open": disclosureOpen
      }, [
        _renderSlot(_ctx.$slots, "default", { open: disclosureOpen })
      ], 8, _hoisted_1)
    ]),
    _: 3
  }, 16, ["aria-expanded"]))
}