
  import { pick } from 'lodash';
  import { defineComponent } from 'vue';
  import { AppBadge } from '@/components';

  import type { PropType } from 'vue';
  import type { BadgeCollection, BadgeItem } from '.';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  const defaultTrackBy = 'name';

  export default defineComponent({
    name: 'AppBadgeCollection',

    components: {
      AppBadge,
    },

    props: {
      badgeCollection: {
        type: Array as PropType<BadgeCollection>,
        required: true,
        validator(this: void, badgeCollection: BadgeCollection): boolean {
          const acceptedTypes = ['string', 'number', 'boolean', 'object'];

          return badgeCollection.every(badgeItem => {
            return !Array.isArray(badgeItem)
              && acceptedTypes.includes(typeof badgeItem);
          });
        },
      },

      trackBy: {
        type: String as PropType<string>,
        default: defaultTrackBy,
      },

      badgeSize: AppBadge.props.size,

      extendedBadgeDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      ...pick(AppBadge.props, 'withIndicator', 'extendedIndicatorDomClasses'),
    },

    methods: {
      badgeItemToString(item: BadgeItem): string {
        if (typeof item === 'object') {
          const tracker = item[this.trackBy] ?? item;

          return typeof tracker === 'object' ? JSON.stringify(tracker) : String(tracker);
        }

        return String(item);
      },
    },
  });
