import type { State } from './vuex';

import type { CourseGroup } from '@/views/Onboarding/Show';
import type { CourseGroup as CourseGroupList } from '@/views/Onboarding/List';

export default {
  setCourseGroups(state: State, courseGroups: CourseGroupList[]): void {
    state.courseGroups = courseGroups;
  },

  deleteCourseGroup(state: State, courseGroupId: CourseGroup['id']): void {
    const courseGroupIndex = state.courseGroups?.findIndex(courseGroup => {
      return courseGroup.id === courseGroupId;
    });

    if (state.courseGroups && courseGroupIndex !== undefined && courseGroupIndex > -1) {
      state.courseGroups.splice(courseGroupIndex, 1);
    }
  },

  setCourseGroup(state: State, courseGroup: CourseGroup): void {
    state.courseGroup = courseGroup;
  },
};
