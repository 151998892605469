
  import { defineComponent } from 'vue';
  import { TabPanel } from '@headlessui/vue';

  export default defineComponent({
    name: 'AppTabPanel',

    components: {
      TabPanel,
    },

    inheritAttrs: false,
  });
