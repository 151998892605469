import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import type { App } from 'vue';
import type { Router } from 'vue-router';

export default function registerSentry(app: App, router: Router): void {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN as string,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT as string,
    release: process.env.VUE_APP_SENTRY_RELEASE as string,

    initialScope: {
      tags: {
        platform: 'educator',
        language: 'vue',
      },
    },

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: process.env.npm_package_version,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          '.pro.test',
          (process.env.VUE_APP_API_URL as string).replace('https://', ''),
          /^\//,
        ],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE as string),
  });
}
