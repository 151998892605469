import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type {
  Attendances,
  Attendance,
  Participant,
  State as TrainingAttendanceState,
  UpdateAttendanceActionPayload,
  StoreCertificatePayload,
} from './vuex.d';

function applyCurrentAttendanceStates(
  attendance: Attendance,
  context: ActionContext<TrainingAttendanceState, State>,
): void {
  const oldAttendanceMap = {} as Record<string, Participant>;

  for (const participant of (context.state.attendance?.participants ?? [])) {
    oldAttendanceMap[participant.companyUserId] = participant;
  }

  attendance.participants.forEach((participant, index) => {
    if (oldAttendanceMap[participant.companyUserId]) {
      attendance.participants[index] = oldAttendanceMap[participant.companyUserId];
    }
  });
}

export default {
  async fetchAttendances(
    context: ActionContext<TrainingAttendanceState, State>,
    trainingId?: number,
  ): Promise<Attendances> {
    const id = trainingId ?? context.rootState.Training?.training?.id;

    if (typeof id !== 'number') {
      throw new TypeError('Invalid training id when fetching training attendances');
    }

    const attendances = await api.fetchAttendances(id);

    context.commit('setAttendances', attendances);

    return attendances;
  },

  async fetchAttendance(
    context: ActionContext<TrainingAttendanceState, State>,
    payload: { trainingId: number; dateId: number; keepCurrentStates?: boolean },
  ): Promise<Attendance> {
    const attendance = await api.fetchAttendance(payload.trainingId, payload.dateId);

    if (payload.keepCurrentStates === true && payload.dateId === context.state.attendance?.id) {
      applyCurrentAttendanceStates(attendance, context);
    }

    context.commit('setAttendance', attendance);

    return attendance;
  },

  async updateAttendance(
    context: ActionContext<TrainingAttendanceState, State>,
    payload: UpdateAttendanceActionPayload,
  ): Promise<void> {
    const { attendancesCompleted } = await api.updateAttendance({
      trainingId: context.rootState.Training?.training?.id ?? 0,
      participants: context.getters.updateAttendancePayload(payload.withCertifications),
      trainingDateId: context.state.attendance?.id ?? 0,
      saveTemporarily: payload.saveTemporarily,
    });

    context.commit('Training/setTrainingAttendancesCompleted', attendancesCompleted, { root: true });
  },

  async storeDocument(
    context: ActionContext<TrainingAttendanceState, State>,
    payload: StoreCertificatePayload,
  ): Promise<Attendances['participants'][number]> {
    const participant = await api.storeDocument(payload);

    context.commit('replaceParticipant', participant);

    return participant;
  },

  async deleteDocument(
    context: ActionContext<TrainingAttendanceState, State>,
    payload: { trainingId: number; trainingUserId: number },
  ): Promise<void> {
    const trainingUser = context.state.attendances.participants.find(participant => {
      return participant.trainingUserId === payload.trainingUserId;
    });

    if (trainingUser) {
      trainingUser.certificateUrl = null;
      trainingUser.certificateTypeKey = null;
    }

    return api.deleteDocument(payload.trainingId, payload.trainingUserId);
  },
};
