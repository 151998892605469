
  import { defineComponent } from 'vue';
  import { AppAvatar } from '@/components';

  import type { PropType } from 'vue';
  import type { Sizes as AvatarSizes } from '@/components/AppAvatar';
  import type { Avatar } from '.';

  export default defineComponent({
    name: 'AppAvatarCluster',

    components: {
      AppAvatar,
    },

    props: {
      avatars: {
        type: Object as PropType<Avatar[]>,
        required: true,
      },

      avatarSize: {
        type: String as PropType<AvatarSizes>,
        default: AppAvatar.props.size.default,
        validator: AppAvatar.props.size.validator,
      },
    },
  });
