import type { State, Attendances, UpdateAttendancePayload } from './vuex';
import type { TrainingDate } from '@/store/modules/Training/vuex';

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getAttendances: (state: State) => (
    participant: Attendances['participants'][number],
  ): Attendances['attendances'] => {
    return state.attendances.attendances.filter(attendance => {
      return attendance.companyUserId === participant.companyUserId;
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getAttendance: (state: State) => (
    date: TrainingDate,
    participant: Attendances['participants'][number],
  ): Attendances['attendances'][number] | undefined => {
    return state.attendances.attendances.find(attendance => {
      return attendance.date === date.date && attendance.companyUserId === participant.companyUserId;
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateAttendancePayload: (state: State) => (
    withCertifications = true,
  ): UpdateAttendancePayload['participants'] => {
    const payload: UpdateAttendancePayload['participants'] = {};

    state.attendance?.participants.forEach(participant => {
      payload[participant.companyUserId] = {
        companyUserId: participant.companyUserId,
        isPresent: participant.isPresent,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        remarks: participant.remarks || null,
        ...(withCertifications ? {
          passed: participant.passed,
          certificateTypeKey: participant.passed ? participant.certificateTypeKey : null,
          workUnderSupervision: participant.passed ? participant.workUnderSupervision : null,
        } : {}),
      };
    });

    return payload;
  },
};
