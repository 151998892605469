
  import { defineComponent } from 'vue';
  import { TabGroup } from '@headlessui/vue';

  export default defineComponent({
    name: 'AppTabGroup',

    components: {
      TabGroup,
    },

    inheritAttrs: false,
  });
