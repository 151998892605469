
  import { computed, defineComponent } from 'vue';
  import { PlusCircleIcon } from '@heroicons/vue/solid';
  import { mapGetters } from 'vuex';
  import { uniqBy, upperFirst } from 'lodash';
  import { AppCheckbox, AppInputSearch, AppLoader } from '@/components';
  import AppButton from '@/components/AppButton/AppButton.vue';
  import CreateUserForm from '@/views/Components/SelectGroupsAndUsers/CreateUserForm.vue';

  import type { PropType } from 'vue';
  import type { SimpleUser } from '@/views/LMS/Groups';
  import type { ErrorResponse, Errors } from '@/mixins';
  import type { ChecklistValue, CheckListItem, FormUser } from '.';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'AppChecklist',

    components: {
      CreateUserForm,
      PlusCircleIcon,
      AppButton,
      AppLoader,
      AppCheckbox,
      AppInputSearch,
    },

    provide() {
      return {
        errors: computed(() => this.errors),
        submitting: computed(() => this.submitting),
      };
    },

    props: {
      list: {
        type: Array as PropType<CheckListItem[]>,
        default: () => [],
      },

      selected: {
        type: Array as PropType<number[]>,
        default: () => [],
      },

      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      modelValue: {
        type: Object as PropType<ChecklistValue>,
        default: null,
      },

      searchable: {
        type: Boolean as PropType<boolean>,
        default: true,
      },

      disabledItems: {
        type: Array as PropType<number[]>,
        default: () => [],
      },

      searchPlaceholder: {
        type: String as PropType<string>,
        default: null,
      },

      extendedListDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      showSelectAllToggle: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      canCreateUsers: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      emailRequired: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      errorMessage: {
        type: String as PropType<string>,
        default: null,
      },

      selectedFirst: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    emits: [
      'clickDisabledItem',
      'search',
      'update:modelValue',
    ],

    data() {
      return {
        searchString: '',
        selection: [] as number[],
        initialSelection: [] as number[],
        selectedAll: false,
        formVisible: false,
        submitting: false,
        errors: {} as Errors,
        addedNewUsers: [] as CheckListItem[],
      };
    },

    computed: {
      ...mapGetters([
        'isCompany',
        'traineeRole',
        'isKennissAdmin',
      ]),

      mutableList(): CheckListItem[] {
        const users = uniqBy([...this.list, ...this.addedNewUsers], 'id');

        if (this.selectedFirst) {
          const selectedUsers = users.filter((user: SimpleUser) => {
            return this.initialSelection.includes(user.id);
          }).sort((resultA, resultB) => {
            return resultA.name.localeCompare(resultB.name);
          });

          const unselectedUsers = users.filter((user: SimpleUser) => {
            return !this.initialSelection.includes(user.id);
          }).sort((resultA, resultB) => {
            return resultA.name.localeCompare(resultB.name);
          });

          return [...selectedUsers, ...unselectedUsers];
        }

        return users.sort((resultA, resultB) => {
          return resultA.name.localeCompare(resultB.name);
        });
      },

      checkListId(): string {
        return `app-checklist-${this.componentId}`;
      },

      errorMessageId(): string {
        return `${this.checkListId}-error-message`;
      },
    },

    created() {
      this.selection = this.selected;
      this.initialSelection = [...this.selection];
    },

    methods: {
      search(searchString: string): void {
        this.addedNewUsers = [];

        this.$emit('search', searchString);
      },

      reset(): void {
        this.selection = [];

        this.$emit('update:modelValue', this.selection);
      },

      toggleItem(checked: boolean, id: number): void {
        if (checked) {
          this.selection.push(id);
        } else {
          const itemIndex = this.selection.indexOf(id);

          this.selection.splice(itemIndex, 1);
        }

        this.selectedAll = this.list.length === this.selection.length;

        this.$emit('update:modelValue', this.selection);
      },

      toggleAllItems(): void {
        this.selectedAll = !this.selectedAll;
        const selectionBefore = [...this.selection];
        this.selection = [];

        if (this.selectedAll) {
          this.list?.forEach(listItem => {
            this.selection.push(listItem.id);
          });
        } else if (this.disabledItems.length > 0) {
          this.selection.push(...selectionBefore.filter(selectedItem => {
            return this.disabledItems.includes(selectedItem);
          }));
        }

        this.$emit('update:modelValue', this.selection);
      },

      openCreateUserForm(): void {
        this.submitting = false;
        this.formVisible = true;
      },

      closeCreateUserForm(): void {
        this.submitting = false;
        this.formVisible = false;
        this.errors = {};
      },

      async submitCreateUserForm(newUser: FormUser): Promise<void> {
        this.errors = {};
        this.submitting = true;

        try {
          const payloadUser = { ...newUser };
          payloadUser.emailRequired = this.emailRequired;
          payloadUser.role = this.isCompany ? 'company-student' : 'training-student';

          const newUsers = await this.$http.post<SimpleUser[]>('/users', payloadUser);

          if (newUsers[0]) {
            this.toggleItem(true, newUsers[0].id);

            this.addedNewUsers.push({
              id: newUsers[0].id,
              name: newUsers[0].name,
              deletedAt: null,
            });
          }

          const newElementId = `app-checklist-item-${newUsers[0].id}`;

          this.$nextTick(() => {
            document.getElementById(newElementId)?.scrollIntoView({
              behavior: 'smooth',
            });
          });

          this.closeCreateUserForm();

          this.showToast(!!payloadUser.email);
        } catch (error: unknown) {
          const errorResponse = error as ErrorResponse;
          this.errors = errorResponse.response?.data.errors;
          this.submitting = false;
        }
      },

      showToast(userHasEmail: boolean): void {
        const baseTranslationPath = `lms.users.notifications.userCreated${userHasEmail ? 'Success' : 'NoEmail'}`;

        this.$toast.success({
          title: upperFirst(this.$t(`${baseTranslationPath}.title`, [this.traineeRole()])),
          description: this.$t(`${baseTranslationPath}.description`, [this.traineeRole()]),
        });
      },

      clickDisabledItem(item: CheckListItem): void {
        this.$emit('clickDisabledItem', item);
      },
    },
  });
