
  import { defineComponent } from 'vue';
  import { AppBadge } from '@/components';
  import CourseTypeMixin from '@/mixins/course-type-mixin';

  import type { PropType } from 'vue';
  import type { CourseType } from '@/typings/course';

  export default defineComponent({
    name: 'CourseTypeBadge',

    components: {
      AppBadge,
    },

    mixins: [
      CourseTypeMixin,
    ],

    props: {
      courseType: {
        type: Object as PropType<CourseType>,
        required: true,
      },
    },

    computed: {
      badgeColorProfileDomClasses(): string[] {
        const colors = this.getColors(this.courseType.colorProfile);

        return [colors.backgroundColor, colors.textColor];
      },
    },
  });
