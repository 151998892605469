import { map, orderBy } from 'lodash';
import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { SelectedUsersAndGroups } from '@/views/CourseLibrary/Show';
import type { SelectedTarget } from '@/views/Components/SelectDocumentModal';
import type {
  Cockpit,
  CockpitItem,
  CockpitTarget,
  CockpitCompanyUser,
  ChangeTargetOrderPayload,
  State as CockpitState,
} from './vuex.d';

export default {
  async getCockpit(context: ActionContext<CockpitState, State>): Promise<Cockpit> {
    const cockpit = await api.getCockpit();

    context.commit('setCockpit', cockpit);

    return cockpit;
  },

  async addCockpitCompanyUsers(
    context: ActionContext<CockpitState, State>,
    payload: SelectedUsersAndGroups,
  ): Promise<CockpitCompanyUser[]> {
    if (context.state.cockpit === null) {
      return [];
    }

    const companyUsers = await api.addCockpitCompanyUsers(context.state.cockpit.id, payload);

    context.commit('addCockpitCompanyUsers', companyUsers);

    return companyUsers;
  },

  async addCockpitTargets(
    context: ActionContext<CockpitState, State>,
    payload: SelectedTarget[],
  ): Promise<CockpitTarget[]> {
    if (context.state.cockpit === null) {
      return [];
    }

    const targets = await api.addCockpitTargets(context.state.cockpit.id, payload);

    context.commit('addCockpitTargets', targets);

    return targets;
  },

  addCockpitItems(
    context: ActionContext<CockpitState, State>,
    payload: CockpitItem[],
  ): void {
    context.commit('addCockpitItems', payload);
  },

  deleteCockpitCompanyUser(
    context: ActionContext<CockpitState, State>,
    cockpitCompanyUser: CockpitCompanyUser,
  ): void {
    if (context.state.cockpit) {
      context.commit('deleteCockpitCompanyUser', cockpitCompanyUser);

      api.deleteCockpitCompanyUser(context.state.cockpit.id, cockpitCompanyUser.id);
    }
  },

  deleteCockpitTarget(
    context: ActionContext<CockpitState, State>,
    cockpitTarget: CockpitTarget,
  ): void {
    if (context.state.cockpit) {
      context.commit('deleteCockpitTarget', cockpitTarget);

      api.deleteCockpitTarget(context.state.cockpit.id, cockpitTarget.id);
    }
  },

  changeTargetOrder(
    context: ActionContext<CockpitState, State>,
    payload: ChangeTargetOrderPayload,
  ): void {
    const { cockpit } = context.state;

    if (cockpit) {
      context.commit('changeTargetOrder', payload);

      if (cockpit.targets.length > 0) {
        const sortedTargetIds = map(orderBy(cockpit.targets, 'order'), 'id')
          .filter(targetId => payload.cockpitTargetDatabaseIds.includes(targetId));

        api.changeTargetOrder(cockpit.id, sortedTargetIds);
      }
    }
  },
};
