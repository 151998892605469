
  import { defineComponent } from 'vue';

  import type { DynamicDomClassesObject } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'SecondaryNav',

    data() {
      return {
        hasContent: false,
        observer: null as MutationObserver | null,
      };
    },

    computed: {
      dynamicDomClasses(): DynamicDomClassesObject {
        const { showSecondaryNav } = this.$route.meta;
        const hideNavOnMobile = typeof showSecondaryNav === 'object'
          && !showSecondaryNav.mobile;

        return {
          '!hidden': showSecondaryNav === false || hideNavOnMobile || !this.hasContent,
          'md:!flex': hideNavOnMobile && this.hasContent,
        };
      },
    },

    mounted() {
      this.updateHasContent();
      this.initObserver();
    },

    beforeUnmount() {
      this.observer?.disconnect();
    },

    methods: {
      updateHasContent(): void {
        const header = this.$refs.header as HTMLElement;

        this.hasContent = header
          && typeof header.textContent === 'string'
          && header.textContent.length > 0;
      },

      initObserver(): void {
        const header = this.$refs.header as HTMLElement;
        this.observer = new MutationObserver(() => {
          this.updateHasContent();
        });

        this.observer.observe(header, {
          childList: true,
          subtree: true,
        });
      },

      scrollToTop(): void {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      },
    },
  });
