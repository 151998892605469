import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full overflow-x-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabListComponent), _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, { class: "flex space-x-8 border-b border-gray-200" }), {
      default: _withCtx((slotProps) => [
        _renderSlot(_ctx.$slots, "default", {
          selectedIndex: slotProps?.selectedIndex
        })
      ]),
      _: 3
    }, 16))
  ]))
}