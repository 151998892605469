
  import { defineComponent } from 'vue';
  import FallbackThumbnail from './FallbackThumbnail.vue';

  import type { PropType } from 'vue';
  import type { Media, Types } from '.';

  export default defineComponent({
    name: 'MediaThumbnail',

    components: {
      FallbackThumbnail,
    },

    inheritAttrs: false,

    props: {
      media: {
        type: Object as PropType<Media>,
        required: true,
      },

      mediaTypesToOpen: {
        type: Array as PropType<Types[]>,
        default: null,
      },
    },

    computed: {
      canOpenMedia(): boolean {
        return this.media.url !== '' && this.mediaTypesToOpen?.includes(this.media.type);
      },
    },

    methods: {
      openMedia(): void {
        if (this.canOpenMedia) {
          window.open(this.media.url, '_blank');
        }
      },
    },
  });
