import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-test-screen-reader-text": "",
  class: "sr-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")!
  const _component_HeadlessUiSwitch = _resolveComponent("HeadlessUiSwitch")!
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")!

  return (_openBlock(), _createBlock(_component_SwitchGroup, _mergeProps(_ctx.hasLabel ? _ctx.$attrs : {}, {
    as: _ctx.hasLabel ? 'div' : 'template',
    class: _ctx.hasLabel ? ['flex', 'align-items'] : undefined
  }), {
    default: _withCtx(() => [
      (_ctx.$slots.leadingLabel)
        ? (_openBlock(), _createBlock(_component_SwitchLabel, {
            key: 0,
            class: _normalizeClass([_ctx.labelDomClasses, "mr-3"]),
            as: "span"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "leadingLabel")
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_HeadlessUiSwitch, _mergeProps({ "model-value": _ctx.modelValue }, _ctx.hasLabel ? {} : _ctx.$attrs, {
        class: [_ctx.dynamicDomClasses, "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full bg-gray-300 transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"],
        "onUpdate:modelValue": _ctx.updateModelValue
      }), {
        default: _withCtx(() => [
          (_ctx.screenReaderText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.screenReaderText), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.modelValue ? 'translate-x-5' : 'translate-x-0', "pointer-events-none inline-flex justify-center items-center h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"]),
            "data-test-indicator": ""
          }, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              _createTextVNode(" ")
            ])
          ], 2)
        ]),
        _: 3
      }, 16, ["model-value", "class", "onUpdate:modelValue"]),
      (_ctx.$slots.trailingLabel)
        ? (_openBlock(), _createBlock(_component_SwitchLabel, {
            key: 1,
            class: _normalizeClass([_ctx.labelDomClasses, "ml-3"]),
            as: "span"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "trailingLabel")
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["as", "class"]))
}