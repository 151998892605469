import { http } from '@/plugins/http';

import type { EnrollmentGroup, CourseGroup } from './vuex';

export default {
  async getEnrollmentGroups(userId: number, searchString: string): Promise<EnrollmentGroup[]> {
    return http.fetch(`users/${userId}/enrollments`, {
      filter: { queryString: searchString },
      sort: 'status',
    }, undefined, undefined, true);
  },

  async getUserCourseGroup(
    userId: number,
    courseGroupId: number,
    searchString: string,
  ): Promise<CourseGroup> {
    return http.fetch(`/users/${userId}/course-group/${courseGroupId}`, {
      filter: { queryString: searchString },
    }, undefined, undefined, true);
  },
};
