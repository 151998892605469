import type { PropType } from 'vue';
import type { DomClassesProp } from '@/typings/class-style-binding';
import type { Sizes } from '@/components/AppModal/index';

export const footerProps = {
  withCloseButton: {
    type: Boolean as PropType<boolean>,
    default: true,
  },

  withSubmitButton: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  closeButtonText: {
    type: String as PropType<string>,
    default: null,
  },

  submitButtonText: {
    type: String as PropType<string>,
    default: null,
  },

  closeButtonDisabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  submitButtonDisabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  submitting: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  extendedSubmitButtonDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },
};

export default {
  title: {
    type: String as PropType<string>,
    default: null,
  },

  unmount: {
    type: Boolean as PropType<boolean>,
    default: true,
  },

  static: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  extendedHeaderDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedFooterDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedBodyDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  size: {
    type: String as PropType<Sizes>,
    default: 'large',
    validator(this: void, size: string): boolean {
      return ['small', 'medium', 'large'].includes(size);
    },
  },

  ...footerProps,
} as const;
