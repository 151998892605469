import { defineComponent } from 'vue';
import { isArray } from 'lodash';
import { mapState } from 'vuex';

export default defineComponent({
  computed: {
    ...mapState([
      'user',
    ]),
  },

  methods: {
    redirectToRequestedPage(): void {
      const redirectTo = this.$route.query?.redirect ?? null;
      const newRoute = typeof redirectTo === 'string' && redirectTo.length > 0
        ? decodeURIComponent(redirectTo) : this.dashboardRoute;

      this.$router.replace(newRoute);
    },

    redirectIfAlreadyLoggedIn(): void {
      if (this.user) {
        this.redirectToRequestedPage();
      }
    },

    navigationItemIsActive(navigationName: string): boolean {
      const navigationItem = navigationName.split('.')[1];
      const routeActiveMainMenuItems = this.$route.meta.activeMainMenuItems;

      if (navigationItem && isArray(routeActiveMainMenuItems)) {
        return routeActiveMainMenuItems.includes(navigationItem);
      }

      return false;
    },
  },
});
