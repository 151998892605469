import type {
  State,
  EnrollmentGroup,
  EnrollmentFilter,
  CourseGroup,
} from './vuex';

export default {
  setEnrollmentGroups(state: State, enrollmentGroups: EnrollmentGroup[]): void {
    state.enrollmentGroups = enrollmentGroups;
  },

  setCourseGroup(state: State, courseGroup: CourseGroup): void {
    state.courseGroup = courseGroup;
  },

  setSearchString(state: State, searchString: string): void {
    state.searchString = searchString;
  },

  setFilter(state: State, filter: EnrollmentFilter): void {
    state.filter = filter;
  },

  deleteEnrollmentFromGroup(state: State, enrollmentId: number): void {
    const enrollment = state.enrollmentGroups
      .flatMap(group => group.enrollments)
      .find(enrollmentItem => enrollmentItem?.id === enrollmentId);

    if (enrollment) {
      enrollment.deletedAt = new Date().toISOString();
    }
  },

  deleteGroupEnrollments(state: State, groupId: number): void {
    const courseGroup = state.enrollmentGroups
      .find(group => group.id === groupId);

    if (courseGroup) {
      courseGroup.deletedAt = new Date().toISOString();

      courseGroup.enrollments?.forEach(enrollment => {
        enrollment.deletedAt = new Date().toISOString();
      });
    }
  },

  deleteCourseGroupEnrollments(state: State): void {
    if (state.courseGroup) {
      state.courseGroup.deletedAt = new Date().toISOString();

      state.courseGroup.enrollments?.forEach(enrollment => {
        enrollment.deletedAt = new Date().toISOString();
      });
    }
  },
};
