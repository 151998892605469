
  import { defineComponent } from 'vue';
  import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
  import { AppInputBase } from '@/components';
  import props from './props';

  import type { Component } from 'vue';
  import type { InputType } from '@/components/AppInputBase';

  export default defineComponent({
    name: 'AppInputPassword',

    components: {
      AppInputBase,
    },

    inheritAttrs: false,

    props: {
      ...AppInputBase.props,
      ...props,
    },

    data() {
      return {
        showPassword: false,
      };
    },

    computed: {
      inputType(): Extract<InputType, 'password' | 'text'> {
        return this.showPassword ? 'text' : 'password';
      },

      passwordIconComponent(): Component {
        return this.showPassword ? EyeIcon : EyeOffIcon;
      },
    },

    methods: {
      toggleInputType(): void {
        this.showPassword = !this.showPassword;
      },
    },
  });
