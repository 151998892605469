
  import { defineComponent } from 'vue';
  import ButtonIconSlot from './ButtonIconSlot.vue';

  import type { PropType } from 'vue';
  import type {
    Types,
    Sizes,
    DomClassesPerType,
    DomClassesPerSize,
  } from '.';

  // Button DOM classes
  const buttonDomClassesPerType: DomClassesPerType = {
    primary: [
      'border-transparent',
      'text-white',
      'bg-gray-900',
      'hover:bg-black',
      'inset-shadow-black',
      'hover:inset-shadow-black-black',
      'disabled:bg-gray-200',
      'disabled:text-gray-500',
      'disabled:inset-shadow-gray-300',
    ],
    secondary: [
      'border-gray-900',
      'text-gray-900',
      'bg-white',
      'hover:bg-gray-100',
      'inset-shadow-gray-200',
      'hover:inset-shadow-gray-300',
      'disabled:bg-gray-200',
      'disabled:text-gray-500',
      'disabled:border-transparent',
      'disabled:inset-shadow-gray-300',
    ],
    default: [
      'bg-gray-100',
      'border-gray-400',
      'text-gray-900',
      'hover:bg-gray-200',
      'disabled:bg-gray-200',
      'disabled:text-gray-500',
      'disabled:border-transparent',
      'disabled:inset-shadow-gray-300',
    ],
    info: [
      'border-transparent',
      'text-white',
      'bg-blue-500',
      'hover:bg-blue-700',
      'inset-shadow-blue-700',
      'hover:inset-shadow-blue-900',
      'disabled:bg-gray-200',
      'disabled:text-gray-500',
      'disabled:inset-shadow-gray-300',
    ],
    error: [
      'border-transparent',
      'text-white',
      'bg-red-500',
      'hover:bg-red-700',
      'disabled:bg-gray-200',
      'disabled:text-gray-500',
      'disabled:inset-shadow-gray-300',
    ],
  };
  const buttonDomClassesPerSize: DomClassesPerSize = {
    small: ['px-2.5', 'py-1.5', 'text-xs'],
    medium: ['px-4', 'py-2', 'text-sm', 'leading-6'],
    large: ['px-6', 'py-2.75', 'text-base'],
  };

  export default defineComponent({
    name: 'AppButton',

    components: {
      ButtonIconSlot,
    },

    props: {
      type: {
        type: String as PropType<Types>,
        default: 'primary',
        validator(type: string): boolean {
          return ['primary', 'secondary', 'default', 'info', 'error'].includes(type);
        },
      },

      size: {
        type: String as PropType<Sizes>,
        default: 'medium',
        validator(size: string): boolean {
          return ['small', 'medium', 'large'].includes(size);
        },
      },

      isSubmit: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      disabledWithEvents: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    computed: {
      buttonType(): string {
        return this.isSubmit ? 'submit' : 'button';
      },

      buttonDynamicDomClasses(): string[] {
        return [
          `app-button--${this.type}`,
          ...buttonDomClassesPerType[this.type],
          ...buttonDomClassesPerSize[this.size],
        ];
      },
    },
  });
