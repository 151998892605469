import { stringToLuxon } from '@/helpers';

import type { State, Training } from './vuex';
import type { Training as TrainingShow } from '../../vuex';

export default {
  setTraining(state: State, training: TrainingShow): void {
    state.training = {
      id: training.id,
      hasNotifiedParticipants: training.hasNotifiedParticipants,
      hasTrainingAttendances: training.hasTrainingAttendances,
      registrarId: training.registrarId,
      registrarManagerIds: training.registrarManagerIds,
      name: training.name,
      description: training.description,
      location: training.location,
      dates: training.dates.map(date => ({
        id: date.id,
        date: stringToLuxon(date.date),
        timeFrom: date.timeFrom,
        timeTo: date.timeTo,
        hasAttendances: date.hasAttendances,
      })),
      image: training.image,
      trainers: training.instructors.map(instructor => instructor.id),
      participants: {
        groupIds: [],
        companyUserIds: training.participants.map(participant => participant.id),
      },
      settings: {
        inviteParticipants: training.settings.inviteParticipants,
        daysBeforeInvite: training.settings.daysBeforeInvite ?? undefined,
        attendanceList: training.settings.attendanceList,
        generateCertificates: training.settings.generateCertificates,
        certificateExpiryDate: training.settings.certificateExpiryDate
          ? stringToLuxon(training.settings.certificateExpiryDate)
          : undefined,
        certificateReminderDate: training.settings.certificateReminderDate
          ? stringToLuxon(training.settings.certificateReminderDate)
          : undefined,
      },
    };
  },

  updateTraining(state: State, data: Partial<Training>): void {
    state.training = { ...state.training ?? {}, ...data ?? {} };
  },

  resetTraining(state: State): void {
    state.training = null;
  },
};
