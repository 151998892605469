import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { State as EnrollmentGroupState, EnrollmentGroup, CourseGroup } from './vuex.d';
import type { GetUserCourseGroupPayload } from '@/views/LMS/Users/Show/CourseGroup';

export default {
  async getEnrollmentGroups(
    context: ActionContext<EnrollmentGroupState, State>,
    userId: number,
  ): Promise<EnrollmentGroup[]> {
    const { searchString } = context.state;

    const enrollmentGroups = await api.getEnrollmentGroups(userId, searchString);

    context.commit('setEnrollmentGroups', enrollmentGroups);

    return enrollmentGroups;
  },

  async getUserCourseGroup(
    context: ActionContext<EnrollmentGroupState, State>,
    payload: GetUserCourseGroupPayload,
  ): Promise<CourseGroup> {
    const { searchString } = context.state;
    const { userId, courseGroupId } = payload;

    const courseGroup = await api.getUserCourseGroup(userId, courseGroupId, searchString);

    context.commit('setCourseGroup', courseGroup);

    return courseGroup;
  },

  resetSearchAndFilter(context: ActionContext<EnrollmentGroupState, State>): void {
    context.commit('setSearchString', '');
    context.commit('setFilter', 'showAll');
  },
};
