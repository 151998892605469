import type { Attendances, Attendance, Participant, State } from './vuex';

export default {
  setAttendances(state: State, attendances: Attendances): void {
    state.attendances = attendances;
  },

  setAttendance(state: State, attendance: Attendance): void {
    state.attendance = attendance;
  },

  replaceParticipant(state: State, participant: Attendances['participants'][number]): void {
    const index = state.attendances.participants.findIndex(attendanceParticipant => {
      return attendanceParticipant.companyUserId === participant.companyUserId;
    });

    if (index > -1) {
      state.attendances.participants.splice(index, 1, participant);
    }
  },

  updateParticipant(state: State, participant: Participant): void {
    const index = state.attendance?.participants.findIndex(attendanceParticipant => {
      return attendanceParticipant.companyUserId === participant.companyUserId;
    }) ?? -1;

    if (index > -1 && state.attendance && state.attendance?.participants?.[index]) {
      state.attendance.participants.splice(index, 1, participant);
    }
  },
};
