import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { State as CompanyState, ListedCompany, GetCompaniesParams } from './vuex';

export default {
  async getCompanies(
    context: ActionContext<CompanyState, State>,
    params?: GetCompaniesParams,
  ): Promise<ListedCompany[]> {
    const companies = await api.getCompanies(params);

    context.commit('setCompanies', companies);

    return companies;
  },
};
