import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center gap-x-3" }
const _hoisted_2 = {
  key: 0,
  class: "flex-shrink-0 self-start mt-0.5"
}
const _hoisted_3 = {
  key: 1,
  class: "flex-shrink-0 ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "alertWrapper",
    class: _normalizeClass([_ctx.domClassesPerType[_ctx.type], "rounded border px-3 py-4 font-medium"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$slots.leadingIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "leadingIcon")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.trailingButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "trailingButton")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}