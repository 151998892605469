import type { LocationInputModelValue } from './index.d';

export function formatPlace(place: LocationInputModelValue): string {
  const { establishmentName, street, number, numberSuffix, postalCode, city } = place;
  const houseNumber = [number, numberSuffix].filter(Boolean).join('');

  return [establishmentName, [street, houseNumber], [postalCode, city]].map(placePart => {
    return Array.isArray(placePart) ? placePart.filter(Boolean).join(' ') : placePart;
  }).filter(Boolean).join(', ');
}
