import { omit } from 'lodash';
import { http } from '@/plugins/http';

import type { CourseGroup } from '@/views/Onboarding/Show';
import type { CreateCourseGroupPayload, UpdateCourseGroupPayload } from '@/views/Onboarding/Form';
import type {
  CourseGroup as CourseGroupList,
  GetOnboardingPayload,
  DeleteUserFromCourseGroupPayload,
} from '@/views/Onboarding/List';

export default {
  async getCourseGroups(payload: GetOnboardingPayload): Promise<CourseGroupList[]> {
    return http.fetch('course-groups', {
      page: payload.page,
      filter: omit(payload, 'page'),
    });
  },

  async createCourseGroup(payload: CreateCourseGroupPayload): Promise<CourseGroup> {
    return http.post<CreateCourseGroupPayload, CourseGroup>('course-groups', payload);
  },

  async updateCourseGroup(payload: UpdateCourseGroupPayload): Promise<CourseGroup> {
    const { id: courseGroupId, ...body } = payload;

    return http.patch<Omit<UpdateCourseGroupPayload, 'id'>, CourseGroup>(
      `course-groups/${courseGroupId}`,
      body,
    );
  },

  async getCourseGroup(courseGroupId: number): Promise<CourseGroup> {
    return http.fetch(`course-groups/${courseGroupId}`);
  },

  async deleteCourseGroup(courseGroupId: number): Promise<void> {
    return http.delete(`/course-groups/${courseGroupId}`);
  },

  async deleteUserFromCourseGroup(payload: DeleteUserFromCourseGroupPayload): Promise<void> {
    await http.delete(`/users/${payload.userId}/course-group/${payload.courseGroupId}`);
  },
};
