
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';
  import type {
    Types,
    Sizes,
    IconPositions,
    DomClassesPerSize,
  } from '.';

  // Icon DOM classes
  const iconDomClassesPerSize: DomClassesPerSize = {
    small: ['h-4', 'w-4', 'mx-2'],
    medium: ['h-4', 'w-4', 'mx-2'],
    large: ['h-5', 'w-5', 'mx-2'],
  };

  export default defineComponent({
    name: 'ButtonIconSlot',

    props: {
      iconPosition: {
        type: String as PropType<IconPositions>,
        required: true,
      },

      buttonType: {
        type: String as PropType<Types>,
        required: true,
      },

      buttonSize: {
        type: String as PropType<Sizes>,
        required: true,
      },
    },

    computed: {
      iconDynamicDomClasses(): string[] {
        return iconDomClassesPerSize[this.buttonSize];
      },
    },
  });
