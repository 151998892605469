
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'RadioLabel',

    props: {
      radioId: {
        type: String as PropType<string>,
        required: true,
      },

      radioDisabled: {
        type: Boolean as PropType<boolean>,
        required: true,
      },

      extendedDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    computed: {
      dynamicDomClasses(): string[] {
        const domClasses = [];

        if (this.radioDisabled) {
          domClasses.push('cursor-not-allowed');
        } else {
          domClasses.push('cursor-pointer');
        }

        if (this.extendedDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedDomClasses,
          ));
        }

        return domClasses;
      },
    },
  });
