import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "flex gap-4" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInputText = _resolveComponent("AppInputText")!
  const _component_AppInputEmail = _resolveComponent("AppInputEmail")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitUser && _ctx.submitUser(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AppInputText, {
      ref: "inputFirstName",
      modelValue: _ctx.newUser.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser.firstName) = $event)),
      placeholder: _ctx.placeholderTextFirstName,
      "has-error": !!_ctx.getError('first_name'),
      "error-message": _ctx.getError('first_name'),
      maxlength: 255,
      autofocus: true,
      size: "medium",
      class: "mb-4",
      "extended-label-dom-classes": "mb-2 w-full",
      "data-test-form-user-first-name": ""
    }, null, 8, ["modelValue", "placeholder", "has-error", "error-message"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppInputText, {
        modelValue: _ctx.newUser.middleName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUser.middleName) = $event)),
        placeholder: _ctx.$t('lms.users.form.middleName.placeholder'),
        "has-error": !!_ctx.getError('middle_name'),
        "error-message": _ctx.getError('middle_name'),
        maxlength: 255,
        size: "medium",
        class: "w-full mb-3 md:mb-4 md:w-2/5 md:mr-1",
        "data-test-form-user-middle-name": ""
      }, null, 8, ["modelValue", "placeholder", "has-error", "error-message"]),
      _createVNode(_component_AppInputText, {
        modelValue: _ctx.newUser.lastName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.lastName) = $event)),
        placeholder: _ctx.placeholderTextLastName,
        "has-error": !!_ctx.getError('last_name'),
        "error-message": _ctx.getError('last_name'),
        maxlength: 255,
        size: "medium",
        class: "w-full mb-3 md:mb-4 md:w-3/4 md:ml-2",
        "data-test-form-user-last-name": ""
      }, null, 8, ["modelValue", "placeholder", "has-error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppInputEmail, {
        modelValue: _ctx.newUser.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newUser.email) = $event)),
        placeholder: _ctx.placeholderTextEmail,
        "has-error": !!_ctx.getError('email'),
        "error-message": _ctx.getError('email'),
        maxlength: 255,
        size: "medium",
        "extended-label-dom-classes": "mb-2 w-full",
        "data-test-form-user-email": ""
      }, null, 8, ["modelValue", "placeholder", "has-error", "error-message"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.canSelectCompany)
        ? (_openBlock(), _createBlock(_component_AppSelect, {
            key: 0,
            modelValue: _ctx.newUser.companyId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUser.companyId) = $event)),
            "has-error": !!_ctx.getError('company_id'),
            "error-message": _ctx.getError('company_id'),
            placeholder: _ctx.selectCompanyPlaceholder,
            options: _ctx.filteredCompanies,
            clearable: true,
            disabled: _ctx.loadingCompanies,
            "option-label-key": "name",
            "track-by": "id",
            "extended-input-dom-classes": "py-2.75 z-60",
            "data-test-form-company-select": ""
          }, null, 8, ["modelValue", "has-error", "error-message", "placeholder", "options", "disabled"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppButton, {
        type: "secondary",
        size: "large",
        class: "flex w-1/2 justify-center",
        "data-test-close-button": "",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeForm')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AppButton, {
        disabled: _ctx.submitting,
        size: "large",
        class: "flex w-1/2 justify-center",
        "data-test-submit-button": "",
        onClick: _ctx.submitUser
      }, _createSlots({
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('add')), 1)
        ]),
        _: 2
      }, [
        (_ctx.submitting)
          ? {
              name: "leadingIcon",
              fn: _withCtx(() => [
                _createVNode(_component_AppLoader, {
                  type: "secondary",
                  size: "xs"
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["disabled", "onClick"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        ref: "formSubmitButton",
        disabled: _ctx.submitting,
        class: "invisible hidden",
        type: "submit"
      }, null, 8, _hoisted_5)
    ])
  ], 32))
}