
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Sizes, DomClassesPerSize } from '.';

  const domClassesPerSize: DomClassesPerSize = {
    small: ['h-1'],
    medium: ['h-2'],
    large: ['h-4'],
  };

  export default defineComponent({
    name: 'AppProgressBar',

    props: {
      size: {
        type: String as PropType<Sizes>,
        default: 'medium',
        validator(this: void, size: string): boolean {
          return ['small', 'medium', 'large'].includes(size);
        },
      },

      progress: {
        type: Number as PropType<number>,
        default: 0,
        validator(this: void, progress: number): boolean {
          return progress >= 0 && progress <= 100;
        },
      },

      extendedBarDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    data() {
      return {
        domClassesPerSize,
      };
    },
  });
