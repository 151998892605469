import { stringToLuxon } from '@/helpers';
import { defaultInputDateFormat } from '.';

import type { PropType } from 'vue';
import type { DateTime } from 'luxon';

const minMaxValidator = (value: string | DateTime): boolean => {
  const date = typeof value === 'string'
    ? stringToLuxon(value, defaultInputDateFormat)
    : value;

  return date.isValid ?? false;
};

export default {
  modelValue: {
    type: Object as PropType<DateTime>,
    default: null,
  },

  min: {
    type: [String, Object] as PropType<string | DateTime>,
    default: undefined,
    validator(this: void, value: string | DateTime): boolean {
      return minMaxValidator(value);
    },
  },

  max: {
    type: [String, Object] as PropType<string | DateTime>,
    default: undefined,
    validator(this: void, value: string | DateTime): boolean {
      return minMaxValidator(value);
    },
  },

  step: {
    type: [Number, String] as PropType<number | 'any'>,
    default: undefined,
    validator(this: void, value: number | 'any'): boolean {
      if (typeof value === 'string' && value !== 'any') {
        console.error('When providing a string for prop \'step\','
          + `the value must be 'any'. You've provided the value '${value}'.`);

        return false;
      }

      return true;
    },
  },
};
