
  import { defineComponent } from 'vue';
  import { TabList } from '@headlessui/vue';

  import type { Component } from 'vue';

  export default defineComponent({
    name: 'AppTabList',

    components: {
      TabList,
    },

    inheritAttrs: false,

    computed: {
      isCustom(): boolean {
        return this.$attrs.custom !== undefined;
      },

      tabListComponent(): Component | string {
        return this.isCustom ? 'div' : TabList;
      },
    },
  });
