
  import { Tab } from '@headlessui/vue';
  import { defineComponent, h } from 'vue';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType, VNode } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  const getTabClasses = (active: boolean): string[] => {
    const buttonClasses = [
      'whitespace-nowrap',
      '-mb-px',
      'py-4',
      'px-1',
      'border-b-2',
      'outline-none',
      'font-medium',
      'text-sm',
      'md:text-base',
    ];

    if (active) {
      return [...buttonClasses, 'border-primary'];
    }

    return [
      ...buttonClasses,
      'border-transparent',
      'text-gray-500',
      'hover:text-gray-700',
      'hover:border-gray-300',
    ];
  };

  export default defineComponent({
    name: 'AppTab',

    components: {
      Tab,
    },

    inheritAttrs: false,

    props: {
      isActive: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    methods: {
      getButtonRender(isActive: boolean): VNode {
        const buttonClasses = [...getTabClasses(isActive), ...convertDomClassesPropToArray(
          (this.$attrs?.class ?? []) as unknown as DomClassesProp,
        )];

        return h(
          'button',
          { ...this.$attrs, class: buttonClasses },
          this.$slots.default?.({ selected: isActive }),
        );
      },
    },

    render() {
      if (this.$attrs.custom !== undefined) {
        return this.getButtonRender(this.isActive);
      }

      return h(Tab, { as: 'template', ...this.$props }, {
        default: (slotProps: { selected: boolean }) => {
          return this.getButtonRender(slotProps.selected);
        },
      });
    },
  });
