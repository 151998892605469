import type { Section, SectionTree, Block } from '@/typings/LMS/section';
import type { BlockUserInput, BlockSubmitTriggeredBy, BlockTransition } from '@/views/Enrollments/WalkThrough';

export default {
  sections: [] as Section[],
  sectionTree: [] as SectionTree,
  blocks: [] as Block[],
  loadingBlocks: false,
  blockUserInput: null as BlockUserInput | null,
  blockSubmitting: false,
  blockSubmittingDisabled: false,
  blockSubmitTriggeredBy: null as BlockSubmitTriggeredBy,
  isCheckAnswer: false,
  blockTransition: null as BlockTransition | null,
};
