import DocumentIcon from '@/assets/DocumentIcon.vue';

import type { Component } from 'vue';
import type { DynamicIcon } from '@/components/AppDynamicIcon';

const defaultIconDomClasses = 'block w-24 h-24 text-gray-400 drop-shadow-sm';

export const courseTypeIcons: Record<string, DynamicIcon | Component> = {
  elearning: {
    type: 'outline',
    name: 'AcademicCapIcon',
    extendedDomClasses: defaultIconDomClasses,
  },
  microlearning: {
    type: 'outline',
    name: 'AcademicCapIcon',
    extendedDomClasses: defaultIconDomClasses,
  },
  skill: {
    type: 'outline',
    name: 'AcademicCapIcon',
    extendedDomClasses: defaultIconDomClasses,
  },
  test: {
    type: 'outline',
    name: 'ClipboardListIcon',
    extendedDomClasses: defaultIconDomClasses,
  },
  video: {
    type: 'outline',
    name: 'VideoCameraIcon',
    extendedDomClasses: defaultIconDomClasses,
  },
  document: DocumentIcon,
};

export default {};
