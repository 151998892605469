
  import { defineComponent } from 'vue';
  import { TabPanels } from '@headlessui/vue';

  export default defineComponent({
    name: 'AppTabPanels',

    components: {
      TabPanels,
    },

    inheritAttrs: false,
  });
