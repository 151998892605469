import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocationMarkerIcon = _resolveComponent("LocationMarkerIcon")!
  const _component_AppInputBase = _resolveComponent("AppInputBase")!

  return (_openBlock(), _createBlock(_component_AppInputBase, _mergeProps({ ref: "inputField" }, { ..._ctx.$props, ..._ctx.$attrs }, {
    "model-value": _ctx.formattedPlace,
    onClick: _ctx.inputClick,
    onBlur: _ctx.inputBlur
  }), _createSlots({
    trailingAddon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trailingAddon"),
      _createVNode(_component_LocationMarkerIcon, {
        class: "w-5 h-5 mr-4 text-gray-900",
        "aria-hidden": "true"
      })
    ]),
    _: 2
  }, [
    (_ctx.$slots.leadingAddon)
      ? {
          name: "leadingAddon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "leadingAddon")
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["model-value", "onClick", "onBlur"]))
}