
  import { defineComponent } from 'vue';
  import domClassesPerType from '@/components/AppAlert/alert-types';

  import type { PropType } from 'vue';
  import type { Types } from '.';

  export default defineComponent({
    name: 'AppAlert',

    props: {
      type: {
        type: String as PropType<Types>,
        default: 'info',
        validator(this: void, size: string): boolean {
          return ['info', 'warning', 'danger', 'success'].includes(size);
        },
      },

      scrollIntoView: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    data() {
      return {
        domClassesPerType,
      };
    },

    mounted() {
      if (this.scrollIntoView) {
        this.scrollToAlert();
      }
    },

    methods: {
      scrollToAlert(): void {
        (this.$refs.alertWrapper as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
        });
      },
    },
  });
