
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';

  export default defineComponent({
    name: 'AppTableCell',

    props: {
      responsive: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    computed: {
      dynamicClasses(): string | undefined {
        if (this.responsive) {
          return 'bg-white block md:table-cell !p-0 md:!px-6 md:!py-4';
        }

        return undefined;
      },
    },
  });
