import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "videoPlayer",
  class: "video-js vjs-theme-kenniss"
}
const _hoisted_2 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (videoSource, index) => {
      return (_openBlock(), _createElementBlock("source", {
        key: `source-${index}`,
        src: videoSource.src,
        type: videoSource.type
      }, null, 8, _hoisted_2))
    }), 128))
  ], 512))
}