import { defineComponent } from 'vue';
import {
  autoUpdate,
  computePosition,
  flip,
  offset,
} from '@floating-ui/dom';

import type { PropType, CSSProperties } from 'vue';
import type {
  Boundary,
  CleanupAutoUpdate,
  ComputePositionOptions,
  ComputePosition,
  Placement,
} from '@/components/AppDropdownMenu';

export default defineComponent({
  props: {
    disableFloatingTeleport: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    computePosition: {
      type: [Object, Boolean] as PropType<ComputePosition>,
      default: null,
    },
  },

  data() {
    return {
      cleanupAutoUpdate: null as CleanupAutoUpdate | null,
    };
  },

  computed: {
    computePositionPlacement(): Placement {
      if (
        this.computePosition
        && typeof this.computePosition === 'object'
        && this.computePosition.placement
      ) {
        return this.computePosition.placement;
      }

      return 'bottom-start';
    },

    computePositionBoundary(): Boundary {
      if (
        this.computePosition
        && typeof this.computePosition === 'object'
        && this.computePosition.boundary
      ) {
        return document.querySelector(this.computePosition.boundary) as HTMLElement;
      }

      if (document.querySelector('#footer-container:not(:empty)') !== null) {
        return document.querySelector('main') as HTMLElement;
      }

      return 'clippingAncestors';
    },
  },

  methods: {
    setComputedPosition(
      enable: boolean,
      trigger: HTMLElement | null = null,
      container: HTMLElement | null = null,
      options: ComputePositionOptions = {},
    ): void {
      if (this.computePosition === false) {
        this.cleanupAutoUpdate?.();

        return;
      }

      if (enable && trigger && container) {
        this.cleanupAutoUpdate = autoUpdate(trigger, container, async () => {
          const boundary = options?.boundary
              ? document.querySelector(options.boundary)
              : this.computePositionBoundary;

          const position = await computePosition(trigger, container, {
            placement: options?.placement ?? this.computePositionPlacement,
            middleware: [
              flip({
                boundary: boundary ?? this.computePositionBoundary,
              }),
              offset(() => ({
                mainAxis: 2,
              })),
            ],
          });

          const style: CSSProperties = {
            left: '0',
            top: '0',
            transform: `translate(${Math.round(position.x)}px,${Math.round(position.y)}px)`,
          };

          if (options?.autoWidth === true) {
            style.width = `${trigger.clientWidth}px`;
          }

          Object.assign(container.style, style);
          container.dataset.position = position.placement;
          trigger.dataset.position = position.placement;
        });
      } else {
        delete container?.dataset.position;
        delete trigger?.dataset.position;

        this.cleanupAutoUpdate?.();
      }
    },
  },
});
