import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex w-full h-full items-center" }
const _hoisted_2 = { class: "block w-full text-center leading-tight" }
const _hoisted_3 = { class: "inline-block w-full" }
const _hoisted_4 = {
  key: 0,
  "data-test-progress-info": ""
}
const _hoisted_5 = { class: "text-sm font-normal" }
const _hoisted_6 = { class: "text-xs text-gray-700" }
const _hoisted_7 = {
  key: 1,
  "data-test-progress-info": ""
}
const _hoisted_8 = { class: "text-sm font-normal mb-1" }
const _hoisted_9 = { class: "text-xs text-gray-700" }
const _hoisted_10 = {
  key: 0,
  class: "absolute bottom-0 left-0 w-full h-1 bg-gray-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentIcon = _resolveComponent("DocumentIcon")!
  const _component_AppDynamicIcon = _resolveComponent("AppDynamicIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.media.type === 'document')
          ? (_openBlock(), _createBlock(_component_DocumentIcon, {
              key: 0,
              class: _normalizeClass(`${_ctx.mediaIcon.extendedDomClasses} !w-6`)
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_AppDynamicIcon, {
              key: 1,
              icon: _ctx.mediaIcon
            }, null, 8, ["icon"]))
      ]),
      (_ctx.media.progress < 100)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('uploading')) + "... ", 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.media.progress) + "% ", 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('processing')) + "... ", 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$tc('mediaBeingProcessed', { mediaType: _ctx.$tc(`mediaTypesWithPrefix.${_ctx.media.type}`)})), 1)
          ]))
    ]),
    (_ctx.media.progress < 100)
      ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
          _createElementVNode("span", {
            style: _normalizeStyle({ width: `${_ctx.media.progress}%` }),
            class: "block bg-primary h-1",
            "data-test-progress-bar": ""
          }, null, 4)
        ]))
      : _createCommentVNode("", true)
  ]))
}