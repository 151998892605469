
  import { defineComponent } from 'vue';
  import { mapState } from 'vuex';
  import qs from 'qs';
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'NoAccessModal',

    components: {
      HeadlessUiDialog: Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
    },

    props: {
      title: {
        type: String as PropType<string>,
        default: null,
      },

      description: {
        type: String as PropType<string>,
        default: null,
      },

      bookTitle: {
        type: String as PropType<string>,
        default: null,
      },

      bookDescription: {
        type: String as PropType<string>,
        default: null,
      },

      extendedDialogDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    emits: [
      'close',
    ],

    data() {
      return {
        isOpen: false,
        iframeSource: 'https://kenniss-demo.youcanbook.me',
      };
    },

    computed: {
      ...mapState([
        'user',
        'company',
      ]),

      titleWithFallback(): string {
        return this.title ?? this.$t('NoAccessOverlay.title');
      },

      bookTitleWithFallback(): string {
        return this.bookTitle ?? this.$t('NoAccessOverlay.book.title');
      },
    },

    created() {
      window.addEventListener('message', this.updateIframeHeight, false);

      const iframeQueryParams = qs.stringify({
        noframe: true,
        embed: true,
        skipHeaderFooter: true,
        Q4: this.company.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FNAME: this.user.firstName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        LNAME: (this.user.middleName ? `${this.user.middleName} ` : '') + this.user.lastName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EMAIL: this.user.email,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        MOBILE: this.user.phoneNumber,
      }, { addQueryPrefix: true });

      this.iframeSource = `${this.iframeSource}${iframeQueryParams}`;
    },

    beforeUnmount() {
      window.removeEventListener('message', this.updateIframeHeight);
    },

    methods: {
      open(): void {
        this.isOpen = true;
      },

      close(): void {
        this.isOpen = false;

        this.$emit('close');
      },

      updateIframeHeight(event: MessageEvent): void {
        const ycbmIframe = this.$refs.ycbmIframe as HTMLIFrameElement | null;

        if (ycbmIframe) {
          ycbmIframe.style.height = `${event.data}px`;
        }
      },
    },
  });
