import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDynamicIcon = _resolveComponent("AppDynamicIcon")!

  return (_ctx.courseType === 'document')
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.courseTypeIcon), {
        key: 0,
        class: "block w-24 h-24 text-gray-400 drop-shadow-sm"
      }))
    : (_openBlock(), _createBlock(_component_AppDynamicIcon, {
        key: 1,
        icon: _ctx.courseTypeIcon
      }, null, 8, ["icon"]))
}