import { http } from '@/plugins/http';

import type { AxiosResponse } from 'axios';
import type { FetchParams, PostParams } from '@/plugins/http/index.d';
import type { User } from '@/typings/user';
import type { LoginPayload, ResetPasswordPayload, GetUserByPasswordResetTokenPayload } from '@/views/Auth';

export default {
  async getCurrentUser(): Promise<AxiosResponse<{ data: User }>> {
    return http.get<{ data: User }>('/users/me');
  },

  async login(payload: LoginPayload | string): Promise<unknown> {
    if (typeof payload === 'string') {
      return http.post(`/login?token=${payload}`);
    }

    return http.post('/login', payload as unknown as PostParams);
  },

  async logout(): Promise<AxiosResponse<void>> {
    return http.post('/logout');
  },

  async sendPasswordResetEmail(toEmail: string): Promise<AxiosResponse<unknown>> {
    return http.post('/password/email', { email: toEmail });
  },

  async getUserByPasswordResetToken(payload: GetUserByPasswordResetTokenPayload): Promise<User> {
    const url = '/password/user-by-token';
    const params = payload as unknown as FetchParams;

    return http.fetch<User>(url, params, undefined, 0, true);
  },

  async resetPassword(payload: ResetPasswordPayload): Promise<AxiosResponse<unknown>> {
    return http.post('/password/reset', payload as unknown as PostParams);
  },
};
