import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative mr-6 pr-2" }
const _hoisted_2 = {
  key: 0,
  "data-test-media-details": ""
}
const _hoisted_3 = { class: "text-black mt-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-xs text-gray-700"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 1,
  role: "list",
  class: "grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mb-4"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "group flex items-center h-full w-full aspect-w-10 aspect-h-7 bg-gray-100 border border-gray-300 relative hover:border-primary" }
const _hoisted_9 = { class: "flex-grow w-full h-full" }
const _hoisted_10 = { class: "absolute right-1 top-0 z-30 top-2 gap-2 right-2 transition-transform translate-x-16 group-hover:translate-x-0 flex flex-col" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = {
  key: 2,
  class: "text-center"
}
const _hoisted_15 = {
  key: 3,
  class: "text-gray-700 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusCircleIcon = _resolveComponent("PlusCircleIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_MediaThumbnail = _resolveComponent("MediaThumbnail")!
  const _component_RefreshIcon = _resolveComponent("RefreshIcon")!
  const _component_CircleCloseIcon = _resolveComponent("CircleCloseIcon")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_AppTab = _resolveComponent("AppTab")!
  const _component_AppTabList = _resolveComponent("AppTabList")!
  const _component_AppAlert = _resolveComponent("AppAlert")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_FallbackThumbnail = _resolveComponent("FallbackThumbnail")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppTabPanel = _resolveComponent("AppTabPanel")!
  const _component_AppInputFile = _resolveComponent("AppInputFile")!
  const _component_AppTabPanels = _resolveComponent("AppTabPanels")!
  const _component_AppVideoPlayer = _resolveComponent("AppVideoPlayer")!
  const _component_AppModal = _resolveComponent("AppModal")!
  const _component_AppTabGroup = _resolveComponent("AppTabGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.media)
      ? (_openBlock(), _createBlock(_component_AppButton, _mergeProps({ key: 0 }, _ctx.$attrs, {
          class: [{ '!mb-0 !border-red-500': _ctx.errorMessage }, "font-normal"],
          type: "default",
          onClick: _withModifiers(_ctx.addMedia, ["prevent"])
        }), {
          leadingIcon: _withCtx(() => [
            _createVNode(_component_PlusCircleIcon, { class: "text-gray-500" })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.addMediaButtonText ?? _ctx.$t('addMedia')), 1)
          ]),
          _: 1
        }, 16, ["class", "onClick"]))
      : (!_ctx.noPreview)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.previewContainerDomClasses)
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_MediaThumbnail, {
                media: _ctx.media,
                "media-types-to-open": ['document'],
                class: "bg-gray-100 media-image object-cover h-32 w-56"
              }, null, 8, ["media"]),
              (_ctx.isRequired)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([{ '!bg-gray-400': !_ctx.canChangeMedia }, "change-media-button w-6 h-6 absolute right-0 top-0 flex justify-center -top-2 align-middle border-white rounded-full cursor-pointer bg-black border-white"]),
                    style: {"border":"3px solid #fff"},
                    "data-test-change-media-icon": "",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addMedia && _ctx.addMedia(...args)))
                  }, [
                    _createVNode(_component_RefreshIcon, { class: "pointer-events-none w-3 text-white" })
                  ], 2))
                : (_openBlock(), _createBlock(_component_CircleCloseIcon, {
                    key: 1,
                    class: _normalizeClass([{ '!bg-gray-400': !_ctx.canChangeMedia }, "remove-icon absolute right-0 top-0 border-2 border-white w-6 rounded-full cursor-pointer -top-2"]),
                    style: {"box-shadow":"0 0 15px 10px white inset"},
                    "data-test-remove-media-icon": "",
                    onClick: _withModifiers(_ctx.removeMedia, ["prevent"])
                  }, null, 8, ["class", "onClick"])),
              (!_ctx.showMediaDetails && _ctx.media.type === 'video' && _ctx.media.url)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "absolute text-white align-middle top-8 left-20 hover:scale-125 transition-transform ml-2",
                    "data-test-preview-button": "",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.previewVideo(_ctx.media, 'previewSelectedVideoModal')), ["prevent"]))
                  }, [
                    _createVNode(_component_PlayIcon, { class: "w-16 inline pointer-events-none" })
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.showMediaDetails)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.media.name), 1),
                  (_ctx.media['size'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.media.size), 1))
                    : _createCommentVNode("", true),
                  (_ctx.media.type === 'video' && _ctx.media.url)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "text-xs text-gray-700",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.previewVideo(_ctx.media, 'previewSelectedVideoModal')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('watchVideo')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.media.type === 'document' && _ctx.media.url)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        class: "text-xs text-gray-700",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.downloadMedia(_ctx.media)), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('downloadDocument')), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          id: _ctx.errorMessageId,
          class: "mt-2 text-sm text-red-500"
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_5))
      : _createCommentVNode("", true),
    _createVNode(_component_AppModal, {
      ref: "mediaModal",
      onOpen: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('open'))),
      onClose: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppTabGroup, null, {
          default: _withCtx(() => [
            _createVNode(_component_AppTabList, { class: "mb-8" }, {
              default: _withCtx(() => [
                _createVNode(_component_AppTab, { ref: "mediaTabMedia" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('media')), 1)
                  ]),
                  _: 1
                }, 512),
                _createVNode(_component_AppTab, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('upload')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_AppTabPanels, {
              ref: "tabPanels",
              class: "overflow-y-scroll md:max-h-[calc(100vh-20rem)] max-h-[calc(100vh-1rem)] md:-mx-16 md:px-16 -mb-4 md:-mb-16 pb-4 md:pb-16 px-2 -mx-2",
              onScroll: _ctx.scrollInPanels
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppTabPanel, { class: "mt-8" }, {
                  default: _withCtx(() => [
                    (_ctx.hasError)
                      ? (_openBlock(), _createBlock(_component_AppAlert, {
                          key: 0,
                          type: "danger",
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.firstError), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.mediaList.length > 0)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaList, (mediaItem) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: `app-media-${mediaItem.id}`,
                              class: "relative cursor-pointer overflow-hidden",
                              style: {"height":"174px"},
                              onClick: ($event: any) => (_ctx.selectMedia(mediaItem))
                            }, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                  _createElementVNode("div", _hoisted_10, [
                                    (_ctx.onAdministrativeDomain || mediaItem.companyId === _ctx.company.id)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "text-gray-700 bg-white py-1.5 px-1.5 rounded-full opacity-80 hover:text-gray-900 hover:opacity-100",
                                          "data-test-destroy-media-button": "",
                                          onClick: _withModifiers(($event: any) => (_ctx.destroyMedia(mediaItem)), ["stop"])
                                        }, [
                                          _createVNode(_component_TrashIcon, { class: "w-5 h-5 pointer-events-none" })
                                        ], 8, _hoisted_11))
                                      : _createCommentVNode("", true),
                                    (['video', 'document'].includes(mediaItem.type) && mediaItem.url)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          class: "text-gray-700 bg-white py-1.5 px-1.5 rounded-full opacity-80 hover:text-gray-900 hover:opacity-100",
                                          "data-test-preview-media-button": "",
                                          onClick: _withModifiers(($event: any) => (_ctx.previewMedia(mediaItem)), ["stop"])
                                        }, [
                                          _createVNode(_component_EyeIcon, { class: "w-5 h-5 pointer-events-none" })
                                        ], 8, _hoisted_12))
                                      : _createCommentVNode("", true)
                                  ]),
                                  (mediaItem.thumb)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: mediaItem.thumb,
                                        alt: mediaItem.name,
                                        class: _normalizeClass([{ 'drop-shadow': mediaItem.thumb.toLowerCase().endsWith('.svg') }, "w-full h-full object-cover object-center group-hover:opacity-75"]),
                                        loading: "lazy"
                                      }, null, 10, _hoisted_13))
                                    : (_openBlock(), _createBlock(_component_FallbackThumbnail, {
                                        key: 1,
                                        media: mediaItem
                                      }, null, 8, ["media"]))
                                ])
                              ])
                            ], 8, _hoisted_7))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createVNode(_component_AppLoader, {
                            size: "m",
                            class: "mr-4"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.loading && _ctx.mediaList.length === 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t('noMediaUploaded')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_AppTabPanel, { class: "mt-8" }, {
                  default: _withCtx(() => [
                    (_ctx.fileExtensionsError)
                      ? (_openBlock(), _createBlock(_component_AppAlert, {
                          key: 0,
                          type: "danger",
                          class: "mb-6"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.fileExtensionsError), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_AppInputFile, {
                      accept: _ctx.acceptedFiles,
                      "must-show-file-extensions-error": false,
                      "extended-label-dom-classes": "bg-transparent",
                      onFileExtensionsError: _cache[4] || (_cache[4] = event => _ctx.fileExtensionsError = event),
                      onChange: _ctx.uploadMedia
                    }, null, 8, ["accept", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onScroll"]),
            _createVNode(_component_AppModal, { ref: "previewVideoModal" }, {
              default: _withCtx(() => [
                _createVNode(_component_AppVideoPlayer, {
                  playlist: _ctx.previewPlaylistUrl,
                  class: "w-full",
                  style: {"height":"70vh"}
                }, null, 8, ["playlist"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClose"]),
    _createVNode(_component_AppModal, { ref: "previewSelectedVideoModal" }, {
      default: _withCtx(() => [
        _createVNode(_component_AppVideoPlayer, {
          playlist: _ctx.previewPlaylistUrl,
          class: "w-full",
          style: {"height":"70vh"}
        }, null, 8, ["playlist"])
      ]),
      _: 1
    }, 512)
  ], 64))
}