import type { PropType } from 'vue';
import type { ModelModifiers } from '@/typings/v-model';
import type { DomClassesProp } from '@/typings/class-style-binding';

export default {
  modelValue: {
    type: String as PropType<string>,
    default: '',
  },

  modelModifiers: {
    type: Object as PropType<ModelModifiers>,
    default: null,
  },

  labelText: {
    type: String as PropType<string>,
    default: null,
  },

  labelIsHidden: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  cornerHint: {
    type: String as PropType<string>,
    default: null,
  },

  leadingHelpText: {
    type: String as PropType<string>,
    default: null,
  },

  trailingHelpText: {
    type: String as PropType<string>,
    default: null,
  },

  hasError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  errorMessage: {
    type: String as PropType<string>,
    default: null,
  },

  extendedTextareaDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedTextareaWrapperDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedLabelDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  placeholder: {
    type: String as PropType<string>,
    default: null,
  },

  name: {
    type: String as PropType<string>,
    default: null,
  },

  required: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  readonly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  minlength: {
    type: Number as PropType<number>,
    default: null,
    validator(minlength: number): boolean {
      return minlength > -1;
    },
  },

  maxlength: {
    type: Number as PropType<number>,
    default: null,
  },

  autosize: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  autofocus: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  tabindex: {
    type: Number as PropType<number>,
    default: null,
  },

  cols: {
    type: Number as PropType<number>,
    default: null,
  },

  rows: {
    type: Number as PropType<number>,
    default: null,
  },

  dirname: {
    type: String as PropType<string>,
    default: null,
  },

  wrap: {
    type: String as PropType<string>,
    default: null,
  },
};
