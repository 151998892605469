import type { NavigationItem } from '@/layouts';

export const navigationItems: NavigationItem[] = [
  {
    name: 'navigation.authorTool',
    href: { name: 'dashboard' },
    roles: [
      'admin-kenniss',
      'admin-group-leader',
      'admin-trainer',
      'sub-admin-group-leader',
      'sub-admin-trainer',
    ],
  },
  {
    name: 'navigation.courseLibrary',
    href: { name: 'course-library.list' },
    permission: 'educator.courses.list',
    roles: [
      'admin-kenniss',
      'admin-group-leader',
      'admin-trainer',
      'sub-admin-group-leader',
      'sub-admin-trainer',
    ],
  },
  {
    name: 'navigation.trainings',
    href: { name: 'trainings.list' },
    permission: 'training.trainings.list',
    roles: [
      'admin-group-leader',
      'admin-trainer',
      'sub-admin-group-leader',
      'sub-admin-trainer',
    ],
  },
  {
    name: 'navigation.lms',
    permission: 'lms.cockpits.list',
    mobilePermission: 'lms.users.list',
    href: { name: 'lms.cockpit.list' },
    mobileHref: { name: 'lms.users.list' },
    roles: [
      'admin-group-leader',
      'admin-trainer',
    ],
  },
  {
    name: 'navigation.lms',
    permission: 'lms.users.list',
    mobilePermission: 'lms.users.list',
    href: { name: 'lms.users.list' },
    mobileHref: { name: 'lms.users.list' },
    roles: [
      'admin-kenniss',
      'sub-admin-group-leader',
      'sub-admin-trainer',
    ],
  },
  {
    name: 'navigation.onboarding',
    href: { name: 'onboarding.list' },
    mobileHref: { name: 'onboarding.list' },
    roles: [
      'admin-kenniss',
      'admin-group-leader',
      'sub-admin-group-leader',
    ],
  },
  {
    name: 'navigation.myTrainings',
    href: { name: 'my-trainings' },
    roles: ['company-student', 'training-student'],
    permission: 'training.my_trainings.list',
  },
  {
    name: 'navigation.myDocuments',
    href: { name: 'my-documents' },
    roles: ['company-student', 'training-student'],
    permission: 'document.my_documents.list',
  },
  {
    name: 'navigation.components',
    href: { name: 'generic-components-overview' },
    permission: 'educator.components_overview.show',
  },
];

export default {};
