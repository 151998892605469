import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { State as CourseGroupState } from './vuex.d';
import type { CourseGroup } from '@/views/Onboarding/Show';
import type { CreateCourseGroupPayload, UpdateCourseGroupPayload } from '@/views/Onboarding/Form';
import type {
  CourseGroup as CourseGroupList,
  GetOnboardingPayload,
  DeleteUserFromCourseGroupPayload,
} from '@/views/Onboarding/List';

export default {
  async getCourseGroups(
    context: ActionContext<CourseGroupState, State>,
    payload: GetOnboardingPayload,
  ): Promise<CourseGroupList[]> {
    const courseGroups = await api.getCourseGroups(payload);

    context.commit('setCourseGroups', courseGroups);

    return courseGroups;
  },

  async createCourseGroup(
    context: ActionContext<CourseGroupState, State>,
    payload: CreateCourseGroupPayload,
  ): Promise<void> {
    await api.createCourseGroup(payload);
  },

  async updateCourseGroup(
    context: ActionContext<CourseGroupState, State>,
    payload: UpdateCourseGroupPayload,
  ): Promise<void> {
    await api.updateCourseGroup(payload);
  },

  async deleteCourseGroup(
    context: ActionContext<CourseGroupState, State>,
    courseGroupId: number,
  ): Promise<void> {
    await api.deleteCourseGroup(courseGroupId);
  },

  async getCourseGroup(
    context: ActionContext<CourseGroupState, State>,
    courseGroupId: number,
  ): Promise<CourseGroup> {
    const courseGroup = await api.getCourseGroup(courseGroupId);

    context.commit('setCourseGroup', courseGroup);

    return courseGroup;
  },

  async deleteUserFromCourseGroup(
    context: ActionContext<CourseGroupState, State>,
    payload: DeleteUserFromCourseGroupPayload,
  ): Promise<void> {
    await api.deleteUserFromCourseGroup(payload);
  },
};
