import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'p-1': ['tiny', 'small'].includes(_ctx.avatarSize),
      'p-1.5': _ctx.avatarSize === 'medium',
      'p-2': _ctx.avatarSize === 'large',
      'p-3.5': _ctx.avatarSize === 'huge',
    }, "relative text-center whitespace-normal"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: `${avatar.url}-${avatar.name}-${index}`,
        class: _normalizeClass([{
        '-m-1': ['tiny', 'small'].includes(_ctx.avatarSize),
        '-m-1.5': _ctx.avatarSize === 'medium',
        '-m-2': _ctx.avatarSize === 'large',
        '-m-3.5': _ctx.avatarSize === 'huge',
      }, "inline-block"])
      }, [
        _createVNode(_component_AppAvatar, {
          src: avatar.url,
          name: avatar.name,
          size: _ctx.avatarSize
        }, null, 8, ["src", "name", "size"])
      ], 2))
    }), 128))
  ], 2))
}