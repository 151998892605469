import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-breadcrumb-item flex items-center text-sm text-gray-700 last:text-gray-900 last:font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: _ctx.route ?? '#',
      class: _normalizeClass([{ 'pointer-events-none': !_ctx.route || _ctx.disabled }, "flex items-center focus-visible:outline-none"])
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ])
      ]),
      _: 3
    }, 8, ["to", "class"]),
    _createVNode(_component_ChevronRightIcon, { class: "breadcrumb-chain-icon h-5 w-5 mx-1.5 text-gray-400" })
  ]))
}