import { http } from '@/plugins/http';

import type { Cockpit, CockpitTarget, CockpitCompanyUser } from './vuex';
import type { SelectedUsersAndGroups } from '@/views/CourseLibrary/Show';
import type { SelectedTarget } from '@/views/Components/SelectDocumentModal';

export default {
  async getCockpit(): Promise<Cockpit> {
    return http.fetch<Cockpit>('cockpit', undefined, undefined, undefined, true);
  },

  async addCockpitCompanyUsers(
    cockpitId: number,
    companyUsers: SelectedUsersAndGroups,
  ): Promise<CockpitCompanyUser[]> {
    return http.post(`/cockpits/${cockpitId}/company-users`, companyUsers);
  },

  async addCockpitTargets(cockpitId: number, targets: SelectedTarget[]): Promise<CockpitTarget[]> {
    return http.post(`/cockpits/${cockpitId}/targets`, { targets });
  },

  async deleteCockpitCompanyUser(cockpitId: number, companyUserId: number): Promise<void> {
    await http.delete(`cockpits/${cockpitId}/company-users/${companyUserId}`);
  },

  async deleteCockpitTarget(cockpitId: number, cockpitTargetId: number): Promise<void> {
    await http.delete(`cockpits/${cockpitId}/targets/${cockpitTargetId}`);
  },

  async changeTargetOrder(cockpitId: number, targetsOrder: number[]): Promise<void> {
    await http.patch(
      `cockpits/${cockpitId}/targets/order`,
      { order: targetsOrder },
      { unique: true },
    );
  },
};
