import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-selected"]
const _hoisted_2 = {
  key: 0,
  class: "w-full"
}
const _hoisted_3 = { class: "flex items-center h-6" }
const _hoisted_4 = ["id", "checked", "disabled"]
const _hoisted_5 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxLabel = _resolveComponent("CheckboxLabel")!

  return (_openBlock(), _createElementBlock("div", {
    "aria-selected": _ctx.modelValue,
    class: "relative flex items-start mb-2"
  }, [
    (_ctx.$slots.default && _ctx.checkboxPosition === 'right')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CheckboxLabel, {
            "checkbox-id": _ctx.checkboxId,
            "checkbox-disabled": _ctx.disabled,
            "extended-dom-classes": _ctx.extendedLabelDomClasses,
            "has-error": _ctx.hasError,
            class: "pr-4"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["checkbox-id", "checkbox-disabled", "extended-dom-classes", "has-error"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.checkboxId,
        ref: "checkboxInput",
        checked: _ctx.modelValue,
        disabled: _ctx.disabled,
        class: _normalizeClass([_ctx.dynamicInputDomClasses, "h-5 w-5 rounded-sm"]),
        type: "checkbox",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)))
      }, null, 42, _hoisted_4)
    ]),
    (_ctx.$slots.default && _ctx.checkboxPosition === 'left')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_CheckboxLabel, {
            "checkbox-id": _ctx.checkboxId,
            "checkbox-disabled": _ctx.disabled,
            "extended-dom-classes": _ctx.extendedLabelDomClasses,
            "has-error": _ctx.hasError,
            class: "pl-4"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["checkbox-id", "checkbox-disabled", "extended-dom-classes", "has-error"])
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}