
  import { defineComponent } from 'vue';
  import { AppDynamicIcon } from '@/components';
  import { courseTypeIcons } from '.';

  import type { Component, PropType } from 'vue';
  import type { DynamicIcon } from '@/components/AppDynamicIcon';

  export default defineComponent({
    name: 'AppCourseTypeIcon',

    components: {
      AppDynamicIcon,
    },

    props: {
      courseType: {
        type: String as PropType<string>,
        required: true,
      },
    },

    computed: {
      courseTypeIcon(): DynamicIcon | Component | undefined {
        return courseTypeIcons[this.courseType];
      },
    },
  });
