
  import { defineComponent } from 'vue';
  import { AppInputBase } from '@/components';
  import inputMethods from '@/components/AppInputBase/methods-mixin';
  import props from './props';

  export default defineComponent({
    name: 'AppInputTime',

    components: {
      AppInputBase,
    },

    mixins: [
      inputMethods,
    ],

    inheritAttrs: false,

    props: {
      ...AppInputBase.props,
      ...props,
    },

    emits: AppInputBase.emits,

    computed: {
      inputBindings(): Record<string, unknown> {
        return {
          min: this.min ? this.min : undefined,
          max: this.max ? this.max : undefined,
          step: this.step,
        };
      },
    },

    methods: {
      update(value: string): void {
        this.$emit('update:modelValue', value);
      },
    },
  });
