
  import { defineComponent, computed } from 'vue';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Size, ButtonValue } from '.';

  export default defineComponent({
    name: 'AppRadioButtonGroup',

    provide() {
      return {
        select: this.select,
        setUniqueValue: this.setUniqueValue,
        size: computed(() => this.size),
        disabled: computed(() => this.disabled),
        selected: computed(() => this.modelValue),
        buttonDomClasses: computed(() => this.buttonDomClasses),
        nothingIsSelected: computed(() => this.nothingIsSelected),
      };
    },

    props: {
      modelValue: {
        type: [String, Number, Boolean, Array, Object, Function] as PropType<unknown>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      size: {
        type: String as PropType<Size>,
        default: 'medium',
      },

      buttonDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    emits: [
      'update:modelValue',
    ],

    data() {
      return {
        buttonUniqueValues: [] as ButtonValue[],
      };
    },

    computed: {
      buttonValues(): unknown[] {
        return this.buttonUniqueValues.map(({ buttonValue }) => buttonValue);
      },

      buttonElements(): HTMLButtonElement[] {
        return this.buttonUniqueValues.map(({ buttonElement }) => buttonElement);
      },

      nothingIsSelected(): boolean {
        return !this.buttonValues.includes(this.modelValue);
      },
    },

    methods: {
      select(value: unknown): void {
        this.$emit('update:modelValue', value);
      },

      setUniqueValue(value: unknown, buttonElement: HTMLButtonElement): void {
        if (buttonElement) {
          const buttonIndex = this.buttonElements.indexOf(buttonElement);
          const newValue = { buttonValue: value, buttonElement };

          if (buttonIndex > -1) {
            this.buttonUniqueValues.splice(buttonIndex, 1, newValue);
          } else {
            this.buttonUniqueValues.push(newValue);
          }
        }
      },
    },
  });
