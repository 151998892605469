
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';
  import type {
    Type,
    Size,
    DomClassesPerSize,
    DomClassesPerType,
  } from '.';

  const domClassesPerType: DomClassesPerType = {
    primary: ['text-gray-700'],
    secondary: ['text-white'],
  };
  const domClassesPerSize: DomClassesPerSize = {
    xxs: ['h-4', 'w-4'],
    xs: ['h-5', 'w-5'],
    // eslint-disable-next-line id-length
    s: ['h-6', 'w-6'],
    // eslint-disable-next-line id-length
    m: ['h-7', 'w-7'],
    // eslint-disable-next-line id-length
    l: ['h-8', 'w-8'],
    xl: ['h-9', 'w-9'],
    xxl: ['h-10', 'w-10'],
  };

  export default defineComponent({
    name: 'AppLoader',

    props: {
      type: {
        type: String as PropType<Type>,
        default: 'primary',
        validator(this: void, type: Type): boolean {
          return ['primary', 'secondary'].includes(type);
        },
      },

      size: {
        type: String as PropType<Size>,
        default: 'm',
        validator(this: void, size: Size): boolean {
          return ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'].includes(size);
        },
      },
    },

    computed: {
      dynamicDomClasses(): string[] {
        return [
          ...domClassesPerType[this.type],
          ...domClassesPerSize[this.size],
        ];
      },
    },
  });
