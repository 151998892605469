import type { PropType } from 'vue';
import type videojs from 'video.js';
import type { AutoplayOptions, PreloadOptions, Source } from '.';

export default {
  playlist: {
    type: String as PropType<string>,
    default: null,
  },

  source: {
    type: Object as PropType<Source[]>,
    default: null,
  },

  controls: {
    type: Boolean as PropType<boolean>,
    default: true,
  },

  autoplay: {
    type: [String, Boolean] as PropType<AutoplayOptions>,
    default: false,
    validator(this: void, autoplay: AutoplayOptions): boolean {
      return [false, true, 'muted', 'play', 'any'].includes(autoplay);
    },
  },

  loop: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  preload: {
    type: String as PropType<PreloadOptions>,
    default: 'auto',
  },

  poster: {
    type: String as PropType<string>,
    default: null,
  },

  fluid: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  finishedAtPercentage: {
    type: Number as PropType<number>,
    default: 100,
  },

  options: {
    type: Object as PropType<videojs.PlayerOptions>,
    default: null,
  },
};
