import state from './state';
import mutations from './mutations';
import actions from './actions';

const namespaced = true;

const courseGroupModule = {
  state,
  mutations,
  actions,
  namespaced,
};

export default courseGroupModule;
