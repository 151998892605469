import inputTypes from './input-types';
import inputSizes from './input-sizes';

import type { PropType } from 'vue';
import type { DomClassesProp } from '@/typings/class-style-binding';
import type {
  Datalist,
  InputSize,
  InputType,
  ExtendedInputBindings,
} from '.';

export default {
  modelValue: {
    type: String as PropType<string>,
    default: '',
  },

  type: {
    type: String as PropType<InputType>,
    default: 'text',
    validator(type: string): boolean {
      return inputTypes.includes(type);
    },
  },

  size: {
    type: String as PropType<InputSize>,
    default: 'small',
    validator(size: string): boolean {
      return inputSizes.includes(size);
    },
  },

  labelText: {
    type: String as PropType<string>,
    default: null,
  },

  labelIsHidden: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  cornerHint: {
    type: String as PropType<string>,
    default: null,
  },

  leadingHelpText: {
    type: String as PropType<string>,
    default: null,
  },

  trailingHelpText: {
    type: String as PropType<string>,
    default: null,
  },

  hasError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  errorMessage: {
    type: String as PropType<string>,
    default: null,
  },

  extendedInputBindings: {
    type: Object as PropType<ExtendedInputBindings>,
    default(this: void): ExtendedInputBindings {
      return {};
    },
  },

  extendedInputDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedInputWrapperDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedLabelDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedErrorIconDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedErrorMessageDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  placeholder: {
    type: String as PropType<string>,
    default: null,
  },

  name: {
    type: String as PropType<string>,
    default: null,
  },

  required: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  readonly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  minlength: {
    type: Number as PropType<number>,
    default: null,
    validator(minlength: number): boolean {
      return minlength > -1;
    },
  },

  maxlength: {
    type: Number as PropType<number>,
    default: null,
  },

  autofocus: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  autocomplete: {
    type: String as PropType<string>,
    default: 'off',
  },

  tabindex: {
    type: Number as PropType<number>,
    default: null,
  },

  datalist: {
    type: Array as PropType<Datalist>,
    default: null,
  },
};
