import { defineComponent } from 'vue';

import type { Errors } from '.';

export default defineComponent({
  data() {
    return {
      errors: {} as Errors,
    };
  },

  computed: {
    hasError(): boolean {
      return Object.values(this.errors).length > 0;
    },

    firstError(): string | null {
      return Object.values(this.errors)?.[0]?.[0] ?? null;
    },
  },

  methods: {
    getError(field: string): string | undefined {
      return field in (this.errors ?? {}) ? this.errors[field][0] : undefined;
    },

    resetErrors(): void {
      this.errors = {};
    },
  },
});
