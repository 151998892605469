import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "text-xs text-gray-700 mb-4",
  "data-test-result-description": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppProgressBar = _resolveComponent("AppProgressBar")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_ctx.hasPaginated || _ctx.hasNextPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppProgressBar, {
          progress: _ctx.progress,
          "extended-bar-dom-classes": "rounded-full",
          size: "small",
          class: "mb-3 rounded-full"
        }, null, 8, ["progress"]),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('resultsCount', [_ctx.currentResults, _ctx.totalResults])) + " " + _toDisplayString(_ctx.resultsName), 1),
        (_ctx.hasNextPage)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              disabled: !_ctx.isLoaded,
              type: "secondary",
              size: "small",
              onClick: _ctx.paginate
            }, {
              leadingIcon: _withCtx(() => [
                (!_ctx.isLoaded)
                  ? (_openBlock(), _createBlock(_component_AppLoader, {
                      key: 0,
                      size: "xs",
                      class: "self-center"
                    }))
                  : (_openBlock(), _createBlock(_component_PlusIcon, { key: 1 }))
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t('loadMore')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}