
  import { defineComponent } from 'vue';
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Types } from '.';

  export default defineComponent({
    name: 'AppToggleSwitch',

    components: {
      SwitchGroup,
      SwitchLabel,
      HeadlessUiSwitch: Switch,
    },

    inheritAttrs: false,

    props: {
      modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      type: {
        type: String as PropType<Types>,
        default: 'primary',
        validator(type: string): boolean {
          return ['primary', 'secondary'].includes(type);
        },
      },

      switchDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      labelDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      screenReaderText: {
        type: String as PropType<string>,
        default: null,
      },
    },

    emits: [
      'update:modelValue',
    ],

    computed: {
      hasLabel(): boolean {
        return Boolean(this.$slots.leadingLabel)
          || Boolean(this.$slots.trailingLabel);
      },

      dynamicDomClasses(): string[] {
        const domClasses = convertDomClassesPropToArray({
          'cursor-pointer': !this.disabled,
          'cursor-not-allowed': this.disabled,
          'focus:ring-transparent': this.disabled,
        });

        if (this.type === 'primary') {
          domClasses.push(...convertDomClassesPropToArray({
            '!bg-blue': this.modelValue,
            'focus:ring-blue': !this.disabled,
          }));
        } else if (this.type === 'secondary') {
          domClasses.push(...convertDomClassesPropToArray({
            '!bg-gray-900': this.modelValue,
            'focus:ring-gray-900': !this.disabled,
          }));
        }

        if (this.switchDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(this.switchDomClasses));
        }

        return domClasses;
      },
    },

    methods: {
      updateModelValue(enabled: boolean): void {
        if (this.disabled === false) {
          this.$emit('update:modelValue', enabled);
        }
      },
    },
  });
