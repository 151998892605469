import { pick } from 'lodash';
import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { Training as StudentTraining } from '@/views/LMS/Users/Show/Trainings/index.d';
import type {
  State as TrainingState,
  FetchExistingTrainingNamesPayload,
  UpdateTrainingParticipantsPayload,
  ExistingTrainingName,
  Training,
} from './vuex.d';

export default {
  async fetchTraining(
    context: ActionContext<TrainingState, State>,
    trainingId: number,
  ): Promise<Training> {
    const training = await api.fetchTraining(trainingId);

    context.commit('setTraining', training);

    return training;
  },

  async fetchExistingTrainingNames(
    context: ActionContext<TrainingState, State>,
    payload?: FetchExistingTrainingNamesPayload,
  ): Promise<ExistingTrainingName[]> {
    return api.fetchExistingTrainingNames(payload?.searchString, payload?.forceFresh);
  },

  async getTrainings(
    context: ActionContext<TrainingState, State>,
    userId: number,
  ): Promise<StudentTraining[]> {
    const { searchString } = context.state;
    const trainings = await api.fetchTrainings(userId, searchString);

    context.commit('setTrainings', trainings);

    return trainings;
  },

  resetSearchAndFilter(context: ActionContext<TrainingState, State>): void {
    context.commit('setSearchString', '');
    context.commit('setFilter', 'showAll');
  },

  async updateTrainingParticipants(
    context: ActionContext<TrainingState, State>,
    payload: UpdateTrainingParticipantsPayload,
  ): Promise<void> {
    const trainingId = payload.trainingId ?? context.state.training?.id;

    if (typeof trainingId === 'number') {
      const participants = pick(payload, 'companyUserIds', 'groupIds');
      const training = await api.updateTrainingParticipants(trainingId, participants);

      context.commit('setTrainingParticipants', training);
    }
  },
};
