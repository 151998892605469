import type { DomClassesPerType } from '.';

const domClassesPerType: DomClassesPerType = {
  info: ['bg-blue-200', 'text-blue-900', 'border-blue-500'],
  warning: ['bg-orange-100', 'text-orange-900', 'border-orange-500'],
  danger: ['bg-red-100', 'text-red-900', 'border-red-500'],
  success: ['bg-green-100', 'text-green-900', 'border-green-500'],
};

export default domClassesPerType;
