import type { PropType } from 'vue';

export default {
  modelValue: {
    type: String as PropType<string>,
    default: null,
  },

  min: {
    type: String as PropType<string>,
    default: undefined,
  },

  max: {
    type: String as PropType<string>,
    default: undefined,
  },

  step: {
    type: String as PropType<string>,
    default: undefined,
  },
};
