import { http } from '@/plugins/http';

import type { SubmitPayload } from '@/views/Trainings/Create/index.d';
import type { Training } from '../../vuex';

export default {
  async fetchTraining(trainingId: number): Promise<Training> {
    return http.fetch(`/trainings/${trainingId}`);
  },

  async createTraining(payload: { payload: SubmitPayload; precognition: boolean }): Promise<void> {
    return http.post<SubmitPayload, void>('/trainings', payload.payload, { precognition: payload.precognition });
  },

  async updateTraining(payload: { payload: SubmitPayload; precognition: boolean }): Promise<void> {
    return http.patch<SubmitPayload, void>(
      `/trainings/${payload.payload?.id}`,
      payload.payload,
      { precognition: payload.precognition },
    );
  },
};
