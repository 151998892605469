import { cloneDeep } from 'lodash';

import type { RouteLocationRaw } from 'vue-router';
import type { State as RootState, RootGetters, GetGettersState } from '@/typings/vuex';
import type { State } from './vuex';
import type { Training } from '@/views/LMS/Users/Show/Trainings/index.d';

export default {
  currentUserIsInstructor(state: State, getters: GetGettersState<typeof this>, rootState: RootState): boolean {
    return (state.training?.instructors ?? []).some(trainingUser => {
      return trainingUser.id === rootState.user?.companyUserId;
    }) ?? false;
  },

  trainingsIndexRoute(
    state: State,
    getters: GetGettersState<typeof this>,
    rootState: RootState,
    rootGetters: RootGetters,
  ): RouteLocationRaw {
    return { name: rootGetters.userHasTrainerOrGroupLeaderRole ? 'trainings.list' : 'my-trainings' };
  },

  filteredTrainings(state: State): Array<Training | undefined> {
    const { trainings, filter } = state;
    const trainingsClone = cloneDeep(trainings);

    const result = trainingsClone
      .map(training => {
        if (filter === 'showAll') {
          return training;
        }

        if (state.filter in training.attendances) {
          if (filter === 'assigned' && training.attendances.assigned.length > 0) {
            return training;
          }

          if (filter === 'present' && training.attendances.present.length > 0) {
            return training;
          }

          if (filter === 'absent' && training.attendances.absent.length > 0) {
            return training;
          }
        }

        return undefined;
      });

    return result.filter(training => training !== undefined);
  },

  trainingParticipantIds(state: State): number[] {
    return state.training?.participants.map(({ id }) => id) ?? [];
  },
};
