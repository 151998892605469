import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInputBase = _resolveComponent("AppInputBase")!

  return (_openBlock(), _createBlock(_component_AppInputBase, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, { type: _ctx.inputType }), _createSlots({ _: 2 }, [
    (_ctx.$slots.leadingAddon)
      ? {
          name: "leadingAddon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "leadingAddon")
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.$slots.trailingAddon || _ctx.mayViewPassword)
      ? {
          name: "trailingAddon",
          fn: _withCtx(() => [
            (_ctx.$slots.trailingAddon)
              ? _renderSlot(_ctx.$slots, "trailingAddon", { key: 0 })
              : _createCommentVNode("", true),
            (_ctx.mayViewPassword)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "pr-4 h-full flex items-center outline-none",
                  type: "button",
                  tabindex: "-1",
                  "data-test-password-icon-wrapper": "",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleInputType && _ctx.toggleInputType(...args)))
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.passwordIconComponent), {
                    class: "w-5 h-5 text-gray-900",
                    "data-test-password-icon": ""
                  }))
                ]))
              : _createCommentVNode("", true)
          ]),
          key: "1"
        }
      : undefined
  ]), 1040, ["type"]))
}