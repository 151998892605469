
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';
  import type { AddonPosition, AddonType } from '.';

  const domClassesPerPosition: Record<AddonPosition, string[]> = {
    leading: ['ml-px', 'rounded-r-none', 'border-r'],
    trailing: ['mr-px', 'rounded-l-none', 'border-l'],
  };
  const domClassesPerType: Record<AddonType, string[]> = {
    primary: ['bg-gray-300', 'text-gray-900'],
    secondary: ['bg-gray-100', 'text-gray-700'],
    dark: ['bg-gray-900', 'text-white'],
  };

  export default defineComponent({
    name: 'AppInputAddon',

    props: {
      position: {
        type: String as PropType<AddonPosition>,
        required: true,
      },

      type: {
        type: String as PropType<AddonType>,
        default: 'primary',
      },
    },

    computed: {
      dynamicDomClasses(): string[] {
        return [
          ...domClassesPerPosition[this.position],
          ...domClassesPerType[this.type],
        ];
      },
    },
  });
