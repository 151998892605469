import api from './api';

import type { ActionContext } from 'vuex';
import type { State } from '@/typings/vuex';
import type { SubmitPayload } from '@/views/Trainings/Create/index.d';
import type { State as TrainingState } from './vuex.d';
import type { Training } from '../../vuex';

export default {
  async fetchTraining(
    context: ActionContext<TrainingState, State>,
    trainingId: number,
  ): Promise<Training> {
    const training = await api.fetchTraining(trainingId);

    context.commit('setTraining', training);

    return training;
  },

  async createTraining(
    context: ActionContext<TrainingState, State>,
    payload: SubmitPayload,
  ): Promise<void> {
    return api.createTraining({
      payload,
      precognition: !(payload.isLastStep && payload.direction === 'next'),
    });
  },

  async updateTraining(
    context: ActionContext<TrainingState, State>,
    payload: SubmitPayload,
  ): Promise<void> {
    return api.updateTraining({
      payload,
      precognition: !(payload.isLastStep && payload.direction === 'next'),
    });
  },
};
