
  import {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  } from '@headlessui/vue';
  import { defineComponent } from 'vue';
  import { xor, isFunction } from 'lodash';
  import { CheckIcon } from '@heroicons/vue/solid';
  import ComputedPositionMixin from '@/mixins/computed-position-mixin';
  import { AppDynamicIcon } from '@/components';
  import {
    convertDomClassesPropToArray,
    validateUniquePropertyInCollection,
    getFirstElementChildWithDimensions,
    elementHasDimensions,
  } from '@/helpers';

  import type { PropType, Component } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { DropdownItem, Selected } from '.';

  const defaultMenuItemsDomClasses = [
    'origin-top-right', 'absolute', 'right-0', 'w-56', 'rounded-sm', 'shadow-lg',
    'bg-white', 'ring-1', 'ring-gray-200', 'focus:outline-none', 'z-60',
  ];
  const defaultMenuItemDomClasses = [
    'group', 'flex', 'items-center', 'p-4', 'text-sm',
    'text-gray-900', 'font-medium', 'whitespace-nowrap',
  ];
  const defaultIconClasses = [
    'mr-3', 'h-5', 'w-5', 'text-gray-900', 'group-hover:text-black', 'flex-0',
  ];

  export default defineComponent({
    name: 'AppDropdownMenu',

    components: {
      HeadlessUiMenu: Menu,
      AppDynamicIcon,
      MenuButton,
      MenuItems,
      MenuItem,
      CheckIcon,
    },

    mixins: [
      ComputedPositionMixin,
    ],

    props: {
      modelValue: {
        type: [String, Array] as PropType<Selected>,
        default: null,
      },

      items: {
        type: Array as PropType<DropdownItem[]>,
        required: true,
        validator(this: void, items: DropdownItem[]) {
          if (items.length === 0) {
            return false;
          }

          return validateUniquePropertyInCollection(items, 'name');
        },
      },

      multiple: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      clearable: {
        type: Boolean as PropType<boolean>,
        default: true,
      },

      renderMenuAs: {
        type: [String, Object] as PropType<string | Component>,
        default: 'div',
      },

      renderMenuItemsAs: {
        type: [String, Object] as PropType<string | Component>,
        default: 'div',
      },

      renderMenuItemAs: {
        type: [String, Object] as PropType<string | Component>,
        default: 'template',
      },

      extendedMenuButtonDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedMenuItemsDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedMenuItemDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedIconDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    emits: [
      'update:modelValue',
    ],

    data() {
      return {
        isOpen: false,
        observer: null as MutationObserver | null,
      };
    },

    computed: {
      menuId(): string {
        return `app-dropdown-menu-${this.componentId}`;
      },

      mustBeClearable(): boolean {
        return this.clearable || this.multiple;
      },

      menuItemsDomClasses(): string[] {
        const extendedDomClasses = this.extendedMenuItemsDomClasses
          ? convertDomClassesPropToArray(this.extendedMenuItemsDomClasses)
          : [];

        return [...defaultMenuItemsDomClasses, ...extendedDomClasses];
      },

      iconDomClasses(): string[] {
        const domClasses = [...defaultIconClasses];

        if (this.extendedIconDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedIconDomClasses,
          ));
        }

        return domClasses;
      },

      visibleItems(): DropdownItem[] {
        return this.items.filter(item => {
          if (typeof item.visible === 'function') {
            return item.visible();
          }

          return item.visible ?? true;
        });
      },
    },

    watch: {
      isOpen(value) {
        const dropdownItemsWrapperElement = (this.$refs.dropdownItems as typeof MenuItems).$el;
        let triggerElement = (this.$refs.trigger as typeof MenuButton).$el;

        if (triggerElement && !elementHasDimensions(triggerElement)) {
          triggerElement = getFirstElementChildWithDimensions(triggerElement);
        }

        this.setComputedPosition(value, triggerElement, dropdownItemsWrapperElement);
      },
    },

    mounted() {
      this.startObserver();
    },

    beforeUnmount() {
      this.stopObserver();
      this.setComputedPosition(false);
    },

    methods: {
      startObserver(): void {
        const trigger = this.$refs.trigger as typeof MenuButton;

        this.observer = new MutationObserver(mutations => {
          const expended = (mutations[0].target as HTMLElement).getAttribute('aria-expanded');

          this.isOpen = expended === 'true';
        });

        if (trigger?.$el) {
          this.observer.observe(trigger.$el, {
            attributes: true,
            attributeFilter: ['aria-expanded'],
          });
        }
      },

      stopObserver(): void {
        this.observer?.disconnect();
      },

      select(item: DropdownItem, event: MouseEvent): void {
        if (item.disabled) {
          event.stopImmediatePropagation();
          return;
        }

        if (isFunction(item.onSelectCallback)) {
          item.onSelectCallback();
        }

        const clearable = this.mustBeClearable;
        let payload: Selected = null;

        if (this.multiple) {
          event.stopImmediatePropagation();
          payload = xor(this.modelValue, [item.name]);
        } else if (this.modelValue !== item.name || !clearable) {
          payload = item.name;
        }

        this.$emit('update:modelValue', payload);
      },

      isSelected(item: DropdownItem): boolean {
        if (Array.isArray(this.modelValue)) {
          return this.modelValue.includes(item.name);
        }

        return item.name === this.modelValue;
      },

      getMenuItemClasses(item: DropdownItem, active: boolean): string[] {
        const menuItemDomClasses = [...defaultMenuItemDomClasses];
        const isLastItem = this.visibleItems.indexOf(item) === (this.visibleItems.length - 1);

        if (active) {
          menuItemDomClasses.push('bg-gray-100', '!text-black');
        }

        if (item.disabled) {
          menuItemDomClasses.push('!text-gray-500', 'cursor-not-allowed');
        }

        if (item.hasTrailingDivider && !isLastItem) {
          menuItemDomClasses.push('border-b', 'border-gray-200');
        }

        if (this.extendedMenuItemDomClasses) {
          menuItemDomClasses.push(...convertDomClassesPropToArray(
            this.extendedMenuItemDomClasses,
          ));
        }

        return menuItemDomClasses;
      },
    },
  });
