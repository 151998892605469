import Course from './modules/Course';
import Company from './modules/Company';
import Cockpit from './modules/Cockpit';
import Training from './modules/Training';
import Enrollment from './modules/Enrollment';
import CourseGroup from './modules/CourseGroup';
import EnrollmentGroup from './modules/EnrollmentGroup';

export default {
  Course,
  Company,
  Cockpit,
  Training,
  Enrollment,
  CourseGroup,
  EnrollmentGroup,
};
