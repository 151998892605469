import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FallbackThumbnail = _resolveComponent("FallbackThumbnail")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.media.thumb)
      ? (_openBlock(), _createElementBlock("img", _mergeProps({ key: 0 }, { ..._ctx.$props, ..._ctx.$attrs }, {
          src: _ctx.media.thumb,
          alt: _ctx.media.name,
          class: [{ 'cursor-pointer': _ctx.canOpenMedia }, "media-image object-cover h-32 w-56"],
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMedia && _ctx.openMedia(...args)))
        }), null, 16, _hoisted_1))
      : (_openBlock(), _createBlock(_component_FallbackThumbnail, _mergeProps({ key: 1 }, { ..._ctx.$props, ..._ctx.$attrs }, {
          media: _ctx.media,
          class: "bg-gray-100 media-image object-cover h-32 w-56"
        }), null, 16, ["media"]))
  ]))
}