import { createToastInterface } from 'vue-toastification';
import Toast from './Toast.vue';

import type { App } from 'vue';
import type { ToastInterface } from 'vue-toastification';
import type { ToastID } from 'vue-toastification/dist/types/types';
import type {
  Options,
  Toast as ToastType,
  ToastContent,
  TriggerToast,
} from './index.d';

const toast: ToastInterface = createToastInterface({
  toastClassName: 'toast-wrapper',
  hideProgressBar: true,
  closeOnClick: false,
  maxToasts: 10,
});

const triggerToast: TriggerToast = (content, options, type) => {
  return toast[type ?? 'info']({
    component: Toast,
    props: {
      type,
      title: typeof content === 'object' ? content.title : content,
      description: typeof content === 'object' ? content.description : null,
      buttons: typeof content === 'object' ? content.buttons : null,
    },
  }, options);
};

const toastWrapper: ToastType = {
  info: (content: ToastContent, options: Options) => triggerToast(content, options, 'info'),
  success: (content: ToastContent, options: Options) => triggerToast(content, options, 'success'),
  warning: (content: ToastContent, options: Options) => triggerToast(content, options, 'warning'),
  error: (content: ToastContent, options: Options) => triggerToast(content, options, 'error'),
  dismiss: (id: ToastID) => toast.dismiss(id),
  clear: () => toast.clear(),
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$toast = toastWrapper;
    app.provide('toast', toastWrapper);
  },
};
