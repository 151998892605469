import { http } from '@/plugins/http';

import type { PatchParams } from '@/plugins/http/index.d';
import type { Enrollment } from '@/views/Enrollments/Show';
import type { CourseActionPayload } from './vuex';
import type {
  Course,
  Version,
  CreateCoursePayload,
  UpdateCoursePayload,
} from '@/views/Courses/Create';

export default {
  async fetchCourse(
    courseId: number,
    versionId: number,
    forceFresh = false,
  ): Promise<Course> {
    return http.fetch(
      `/courses/${courseId}/versions/${versionId}`,
      undefined,
      undefined,
      undefined,
      forceFresh,
    );
  },

  async createCourse(payload: CreateCoursePayload): Promise<Course> {
    return http.post<CreateCoursePayload, Course>('/courses', payload);
  },

  async updateCourse({ courseId, versionId, ...payload }: UpdateCoursePayload): Promise<Course> {
    return http.patch<PatchParams, Course>(`/courses/${courseId}/versions/${versionId}`, payload);
  },

  async createVersion(courseId: number): Promise<Version> {
    return http.post(`/courses/${courseId}/versions`);
  },

  async previewCourse(courseId: number, versionId: number): Promise<Enrollment> {
    return http.post(`/courses/${courseId}/versions/${versionId}/preview`);
  },

  async copyCourse(courseId: number, versionId: number): Promise<Course> {
    return http.post(`/courses/${courseId}/versions/${versionId}/copy`);
  },

  async previewLibraryCourse(courseId: number): Promise<Enrollment> {
    return http.post(`/course-libraries/${courseId}/preview`);
  },

  async hasVersionBlocks({ courseId, versionId }: CourseActionPayload): Promise<boolean> {
    return http.fetch(
      `/courses/${courseId}/versions/${versionId}/has-blocks`,
      undefined,
      undefined,
      undefined,
      true,
    );
  },
};
