import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block font-semibold text-gray-900"
}
const _hoisted_2 = { class: "space-y-1 text-center" }
const _hoisted_3 = { class: "block text-sm text-gray-600 md:flex" }
const _hoisted_4 = ["id", "name", "multiple", "disabled", "required", "accept"]
const _hoisted_5 = { class: "pl-1" }
const _hoisted_6 = {
  class: "text-xs text-gray-500",
  "data-test-file-extensions": ""
}
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadIcon = _resolveComponent("UploadIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "label", {}, () => [
      (_ctx.labelText)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.labelText), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.dynamicDropContainerClasses, "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md hover:border-gray-400"]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.fileInput.click())),
      onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropMedia && _ctx.dropMedia(...args)), ["prevent"])),
      onDragover: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.dragEntered = true), ["prevent"])),
      onDragleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dragEntered = false))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UploadIcon, {
          class: "mx-auto h-12 w-12 text-gray-400",
          "aria-hidden": "true"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", {
            class: _normalizeClass([_ctx.dynamicLabelClasses, "relative bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 select-none"]),
            for: "file-upload"
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('uploadFile')), 1),
            _createElementVNode("input", {
              id: _ctx.inputId,
              ref: "fileInput",
              name: _ctx.name,
              multiple: _ctx.multiple,
              disabled: _ctx.disabled,
              required: _ctx.required,
              accept: _ctx.accept,
              type: "file",
              class: "sr-only",
              onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChanged && _ctx.onFileChanged(...args)))
            }, null, 40, _hoisted_4)
          ], 2),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('orDragDropInBox')), 1)
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.extensions), 1)
      ])
    ], 34),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          id: _ctx.errorMessageId,
          class: "mt-2 text-sm text-red-500",
          "data-test-error-message": ""
        }, _toDisplayString(_ctx.errorMessage || _ctx.fileExtensionsError), 9, _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}