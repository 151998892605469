import type { App } from 'vue';
import type { Store } from 'vuex';
import type { Router } from 'vue-router';
import type { AxiosInstance } from 'axios';
import type { Toast } from '@/plugins/toasts/index.d';
import type { Confirm } from '@/plugins/confirm/index.d';
import type { State as VuexState } from '../../typings/vuex';

export default (http: AxiosInstance, app: App): void => {
  const router = app.config.globalProperties.$router as Router;
  const store = app.config.globalProperties.$store as Store<VuexState>;
  const toast = app.config.globalProperties.$toast as Toast;
  const confirm = app.config.globalProperties.$confirm as Confirm;

  http.interceptors.response.use(
    response => response,

    async error => {
      const { request, response } = error;
      const responseUrl = request?.responseURL;

      switch (response?.status) {
        case 307: {
          router.replace(response.headers['x-location']);

          break;
        }
        case 401: {
          let redirectUrl = router.currentRoute.value.fullPath;
          const parsedUrl = new URL(response.config.url, response.config.baseURL);

          confirm('close');

          if (parsedUrl.pathname !== '/logout') {
            await store.dispatch('logout');
          }

          if (['/login', '/logout', '/not-found'].includes(redirectUrl)) {
            redirectUrl = '/';
          }

          router.replace({
            name: 'auth.login',
            query: {
              redirect: encodeURIComponent(redirectUrl),
            },
          });

          break;
        }
        case 403: {
          if (responseUrl?.endsWith('/broadcasting/auth')) {
            break;
          }

          toast.error({
            title: window.$t('interceptors.403.title'),
            description: window.$t('interceptors.403.description'),
          });

          break;
        }
        case 404: {
          if (error.config.method === 'get') {
            router.replace({
              name: 'page-not-found',
            });
          }

          break;
        }
        default: {
          break;
        }
      }

      return Promise.reject(error);
    },
  );

  http.interceptors.request.use(
    config => {
      config.headers['X-Request-From'] = window.location.href;
      config.headers['X-Request-Sent-At'] = Date.now();

      if (store?.state?.user?.accessToken) {
        config.headers.Authorization = `Bearer ${store.state.user.accessToken}`;
      }

      return config;
    },
    async error => Promise.reject(error),
  );
};
