import {
  PaperClipIcon,
  UserGroupIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
  VideoCameraIcon,
  ClipboardListIcon,
} from '@heroicons/vue/solid';
import { DateTime } from 'luxon';
import { stringToLuxon } from '@/helpers';

import type { RenderFunction } from 'vue';
import type {
  Cockpit,
  CockpitItem,
  CockpitCompanyUser,
  CockpitTarget,
  CockpitTargetSort,
  CockpitItemCourse,
  CockpitItemTraining,
  CockpitItemDocument,
  CockpitItemCertificate,
} from './index.d';

export {
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from '@heroicons/vue/solid';

export const courseTypes = ['skill', 'microlearning', 'video', 'test'] as const;
export const documentTypes = ['document', 'certificate'] as const;
export const trainingTypes = ['training'] as const;

export const targetTypeIcons: Record<CockpitTarget['type'], RenderFunction> = {
  microlearning: AcademicCapIcon,
  certificate: ShieldCheckIcon,
  training: UserGroupIcon,
  document: PaperClipIcon,
  test: ClipboardListIcon,
  skill: AcademicCapIcon,
  video: VideoCameraIcon,
};

export const courseItemOrderedStatuses: string[] = [
  'passed',
  'failed',
  'completed',
  'active',
  'assigned',
  'unassigned',
];

export const trainingItemOrderedStatuses: string[] = [
  'present',
  'partiallyPresent',
  'absent',
  'assigned',
  'unassigned',
];

export const documentItemOrderedStatuses: string[] = [
  'signed',
  'viewed',
  'assigned',
  'unassigned',
];

export const certificateItemOrderedStatuses: string[] = [
  'not-expired',
  'viewed',
  'owned',
  'notOwned',
  'expired',
];

export function getTargetType(
  cockpitTarget: CockpitTarget | CockpitTarget['type'],
): CockpitTarget['type'] {
  if (typeof cockpitTarget === 'string') {
    return cockpitTarget;
  }

  return cockpitTarget.type;
}

export function isCourseTarget(cockpitTarget: CockpitTarget | CockpitTarget['type']): boolean {
  return (courseTypes as unknown as string[]).includes(getTargetType(cockpitTarget));
}

export function isTrainingTarget(cockpitTarget: CockpitTarget | CockpitTarget['type']): boolean {
  return getTargetType(cockpitTarget) === 'training';
}

export function isDocumentTarget(cockpitTarget: CockpitTarget | CockpitTarget['type']): boolean {
  return getTargetType(cockpitTarget) === 'document';
}

export function isCertificateTarget(cockpitTarget: CockpitTarget | CockpitTarget['type']): boolean {
  return getTargetType(cockpitTarget) === 'certificate';
}

export function isDocumentOrCertificateTarget(
  cockpitTarget: CockpitTarget | CockpitTarget['type'],
): boolean {
  return ['document', 'certificate'].includes(getTargetType(cockpitTarget));
}

export function isCourseItem(cockpitItem: CockpitItem): cockpitItem is CockpitItemCourse {
  return isCourseTarget(cockpitItem.targetType);
}

export function isTrainingItem(cockpitItem: CockpitItem): cockpitItem is CockpitItemTraining {
  return isTrainingTarget(cockpitItem.targetType);
}

export function isDocumentItem(cockpitItem: CockpitItem): cockpitItem is CockpitItemDocument {
  return isDocumentTarget(cockpitItem.targetType);
}

export function isCertificateItem(cockpitItem: CockpitItem): cockpitItem is CockpitItemCertificate {
  return isCertificateTarget(cockpitItem.targetType);
}

export function certificateIsExpired(cockpitItem: CockpitItemCertificate): boolean {
  const itemStatus = cockpitItem.status;

  if (itemStatus.expiredAt) {
    return stringToLuxon(itemStatus.expiredAt) < DateTime.local();
  }

  return false;
}

export function defaultCompanyUserSortFunction(
  companyUserA: CockpitCompanyUser,
  companyUserB: CockpitCompanyUser,
): number {
  return companyUserA.name.localeCompare(companyUserB.name);
}

export function getCourseItemStatusKey(cockpitItem?: CockpitItemCourse): string {
  if (cockpitItem) {
    const itemStatus = cockpitItem.status;
    let statusKey = 'assigned';

    if (itemStatus.status === 'in-progress') {
      statusKey = 'active';
    } else if (itemStatus.status === 'done') {
      statusKey = 'completed';

      if (itemStatus.hasScore) {
        statusKey = itemStatus.isPassed ? 'passed' : 'failed';
      }
    }

    return statusKey;
  }

  return 'unassigned';
}

export function getTrainingItemStatusKey(cockpitItem?: CockpitItemTraining): string {
  if (cockpitItem) {
    const itemStatus = cockpitItem.status;

    if (itemStatus.isPartiallyPresent) {
      return 'partiallyPresent';
    }

    if (itemStatus.isPresent !== null) {
      return itemStatus.isPresent ? 'present' : 'absent';
    }

    return 'assigned';
  }

  return 'unassigned';
}

export function getDocumentItemStatusKey(cockpitItem?: CockpitItemDocument): string {
  if (cockpitItem) {
    const itemStatus = cockpitItem.status;
    const showViewedAt = itemStatus.viewedAt && !itemStatus.mustSign;
    const statusKey = showViewedAt ? 'viewed' : 'assigned';

    return itemStatus.signedAt ? 'signed' : statusKey;
  }

  return 'unassigned';
}

export function getCertificateItemStatusKey(cockpitItem?: CockpitItemCertificate): string {
  if (cockpitItem) {
    if (cockpitItem.status.expiredAt) {
      return certificateIsExpired(cockpitItem) ? 'expired' : 'not-expired';
    }

    return 'owned';
  }

  return 'notOwned';
}

export function getSortedItemOrderedStatuses(
  orderedItemStatuses: string[],
  sort: NonNullable<CockpitTargetSort>,
): string[] {
  return sort === 'desc'
    ? [...orderedItemStatuses].reverse()
    : orderedItemStatuses;
}

export function calculateEqualItemSortDiff(
  cockpitCompanyUsers: CockpitCompanyUser[],
  itemA: CockpitItem,
  itemB: CockpitItem,
): number {
  const companyUserA = cockpitCompanyUsers.find(companyUser => {
    return companyUser.id === itemA.companyUserId;
  });
  const companyUserB = cockpitCompanyUsers.find(companyUser => {
    return companyUser.id === itemB.companyUserId;
  });

  if (companyUserA && companyUserB) {
    return companyUserA.name.localeCompare(companyUserB.name);
  }

  return 0;
}

export function getSortedCockpitCompanyUsers(
  cockpit: Cockpit,
  sortedTarget: CockpitTarget,
  sort: NonNullable<CockpitTargetSort>,
): CockpitCompanyUser[] {
  const targetItems = cockpit.items.filter(item => {
    return [item.targetId, item.correspondingTargetId].includes(sortedTarget.id);
  });

  const sortedItems = targetItems.sort((itemA, itemB) => {
    if (
      [itemA.targetId, itemA.correspondingTargetId].includes(sortedTarget.id)
      && [itemB.targetId, itemB.correspondingTargetId].includes(sortedTarget.id)
    ) {
      const target = sortedTarget;
      let indexA = -1;
      let indexB = -1;

      if (isCourseTarget(target) && isCourseItem(itemA) && isCourseItem(itemB)) {
        const statusOrder = getSortedItemOrderedStatuses(courseItemOrderedStatuses, sort);

        indexA = statusOrder.indexOf(getCourseItemStatusKey(itemA));
        indexB = statusOrder.indexOf(getCourseItemStatusKey(itemB));
      }

      if (isTrainingTarget(target) && isTrainingItem(itemA) && isTrainingItem(itemB)) {
        const statusOrder = getSortedItemOrderedStatuses(trainingItemOrderedStatuses, sort);

        indexA = statusOrder.indexOf(getTrainingItemStatusKey(itemA));
        indexB = statusOrder.indexOf(getTrainingItemStatusKey(itemB));
      }

      if (isDocumentTarget(target) && isDocumentItem(itemA) && isDocumentItem(itemB)) {
        const statusOrder = getSortedItemOrderedStatuses(documentItemOrderedStatuses, sort);

        indexA = statusOrder.indexOf(getDocumentItemStatusKey(itemA));
        indexB = statusOrder.indexOf(getDocumentItemStatusKey(itemB));
      }

      if (isCertificateTarget(target) && isCertificateItem(itemA) && isCertificateItem(itemB)) {
        const statusOrder = getSortedItemOrderedStatuses(certificateItemOrderedStatuses, sort);

        indexA = statusOrder.indexOf(getCertificateItemStatusKey(itemA));
        indexB = statusOrder.indexOf(getCertificateItemStatusKey(itemB));

        if ((indexA - indexB) === 0 && itemA.status.expiredAt && itemB.status.expiredAt) {
          const luxonA = stringToLuxon(itemB.status.expiredAt);
          const luxonB = stringToLuxon(itemA.status.expiredAt);
          const expiredDiff = sort === 'asc'
            ? luxonA.toMillis() - luxonB.toMillis()
            : luxonB.toMillis() - luxonA.toMillis();

          if (expiredDiff === 0) {
            return calculateEqualItemSortDiff(cockpit.companyUsers, itemA, itemB);
          }

          return expiredDiff;
        }
      }

      if ((indexA - indexB) === 0) {
        return calculateEqualItemSortDiff(cockpit.companyUsers, itemA, itemB);
      }

      return indexA - indexB;
    }

    return 0;
  });

  const sortedCompanyUserIds = sortedItems.map(item => item.companyUserId);
  const targetUsers = cockpit.companyUsers.filter(companyUser => {
    return sortedCompanyUserIds.includes(companyUser.id);
  });
  const nonTargetUsers = cockpit.companyUsers.filter(companyUser => {
    return !sortedCompanyUserIds.includes(companyUser.id);
  });

  const sortedNonTargetCompanyUsers = nonTargetUsers.sort(defaultCompanyUserSortFunction);
  const sortedTargetCompanyUsers = targetUsers.sort((companyUserA, companyUserB) => {
    if (
      sortedCompanyUserIds.includes(companyUserA.id)
      && sortedCompanyUserIds.includes(companyUserB.id)
    ) {
      const indexA = sortedCompanyUserIds.indexOf(companyUserA.id);
      const indexB = sortedCompanyUserIds.indexOf(companyUserB.id);

      return indexA - indexB;
    }

    return 0;
  });

  return [...sortedTargetCompanyUsers, ...sortedNonTargetCompanyUsers];
}
