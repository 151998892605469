/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { User } from '@/typings/user';
import type { State } from '@/typings/vuex';
import type { Company } from '@/typings/company';

export const user = (state: State): User | null => state.user;

export const company = (state: State): Company | null => state.company;

export const userHasRole = (state: State) => (role: string): boolean => {
  return state.user?.roles.includes(role) ?? false;
};

export const userHasAnyRole = (state: State) => (roles: string[]): boolean => {
  return state.user?.roles?.some((role: string) => roles.includes(role)) ?? false;
};

export const userHasAllRoles = (state: State) => (roles: string[]): boolean => {
  return roles.every((role: string) => (state.user?.roles?.includes(role) ?? false));
};

export const userHasPermission = (state: State) => (permission: string): boolean => {
  return state.user?.permissions?.includes(permission) ?? false;
};

export const userHasAnyPermission = (state: State) => (permissions: string[]): boolean => {
  return state.user?.permissions?.some((permission: string) => permissions.includes(permission)) ?? false;
};

export const userHasAllPermissions = (state: State) => (permissions: string[]): boolean => {
  return permissions.every((permission: string) => (state.user?.permissions?.includes(permission) ?? false));
};

export const isCompany = (state: State): boolean => state.company?.type === 'company';

export const isKennissAdmin = (state: State): boolean => {
  return userHasRole(state)('admin-kenniss');
};

export const isTrainer = (state: State): boolean => {
  return userHasRole(state)('admin-trainer');
};

export const userHasTrainerOrGroupLeaderRole = (state: State): boolean => {
  return userHasAnyRole(state)([
    'admin-trainer',
    'admin-group-leader',
    'sub-admin-trainer',
    'sub-admin-group-leader',
  ]);
};

export const canCreateUsersInChecklist = (state: State): boolean => {
  return !userHasRole(state)('admin-kenniss') && userHasPermission(state)('lms.users.create');
};

export const companyRole = (state: State) => (
  plural = false,
  companyTypeForce = null as null | string | undefined,
): string => {
  const pluralCount = plural ? 2 : 1;
  const companyType = companyTypeForce ?? state.company?.type;

  if (companyType === 'administrator') {
    return window.$tc('roles.administrator', pluralCount);
  }

  if (companyType === 'company') {
    return window.$tc('roles.groupLeader', pluralCount);
  }

  if (companyType === 'educator') {
    return window.$tc('roles.trainer', pluralCount);
  }

  return window.$tc('roles.user', pluralCount);
};

export const traineeRole = (state: State) => (
  plural = false,
  companyTypeForce = null as null | string | undefined,
): string => {
  const pluralCount = plural ? 2 : 1;
  const companyType = companyTypeForce ?? state.company?.type;

  if (companyType === 'company') {
    return window.$tc('roles.trainee', pluralCount);
  }

  return window.$tc('roles.student', pluralCount);
};
