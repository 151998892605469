/* eslint-disable vue/one-component-per-file */
import { createApp, defineComponent } from 'vue';
import NoAccessModal from '@/layouts/NoAccessModal.vue';
import lang from '@/plugins/lang';
import store from '@/store';
import { mapGettersTyped } from '@/store/helpers';

import type { RouteLocationNormalized } from 'vue-router';
import type { NoAccessModalContent } from '@/layouts';
import type { RootGettersMapped } from '@/typings/vuex';
import type { DomClassesProp } from '@/typings/class-style-binding';

const guardModalElements: HTMLDivElement[] = [];
let modalComponent: typeof NoAccessModal | null = null;

export default defineComponent({
  computed: {
    ...mapGettersTyped<RootGettersMapped>([
        'isKennissAdmin',
        'userHasAnyRole',
        'userHasPermission',
        'userHasAnyPermission',
    ]),
  },

  methods: {
    before(): boolean {
      return this.isKennissAdmin;
    },

    can(permission: string): boolean {
      return this.before() || this.userHasPermission(permission);
    },

    cannot(permission: string): boolean {
      return !this.can(permission);
    },

    canAny(permissions: string[]): boolean {
      return this.before() || this.userHasAnyPermission(permissions);
    },

    cannotAny(permissions: string[]): boolean {
      return !this.canAny(permissions);
    },

    guardCheck(route: RouteLocationNormalized, showNoAccessOverlay = false): boolean {
      const userRoleShowAccessOverlay = this.userHasAnyRole(route.meta.showNoAccessOverlayForRoles ?? []);
      const mustShowNoAccessOverlay = !showNoAccessOverlay || !userRoleShowAccessOverlay;
      const permissions = route.matched.flatMap(routeRecord => {
        return routeRecord.meta?.permissions as string[] ?? [];
      });

      return !(permissions.length > 0 && this.cannotAny(permissions) && mustShowNoAccessOverlay);
    },

    openGuardModal(content: NoAccessModalContent, dialogDomClasses?: DomClassesProp): void {
      const guardModalApp = createApp(NoAccessModal, {
        title: content?.title ?? undefined,
        description: content?.description ?? undefined,
        bookTitle: content?.book?.title ?? undefined,
        bookDescription: content?.book?.description ?? undefined,
        extendedDialogDomClasses: dialogDomClasses,
        onClose: () => setTimeout(() => {
          guardModalApp?.unmount();

          const guardModalElement = guardModalElements.shift();
          guardModalElement?.remove();
        }, 500),
      });

      guardModalApp.use(lang);
      guardModalApp.use(store({
        company: this.$store.state.company,
        user: this.$store.state.user,
      }, false));

      const guardModalRootElement = document.getElementById('guard-modal-holder');

      if (guardModalRootElement) {
        const guardModalElement = document.createElement('div');

        guardModalRootElement.append(guardModalElement);
        guardModalElements.push(guardModalElement);

        modalComponent = guardModalApp
          .mount(guardModalElement) as unknown as typeof NoAccessModal;

        modalComponent.open();
      }
    },

    closeGuardModal(): void {
      modalComponent?.close();
    },
  },
});
