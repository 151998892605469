
  import { computed, defineComponent } from 'vue';
  import { Disclosure } from '@headlessui/vue';

  export default defineComponent({
    name: 'AppDisclosure',

    components: {
      Disclosure,
    },

    provide() {
      return {
        isOpen: computed(() => this.isOpen),
        open: this.open,
        close: this.close,
        toggle: this.toggle,
      };
    },

    inheritAttrs: false,

    emits: [
      'open',
      'close',
    ],

    data() {
      return {
        observer: null as MutationObserver | null,
        isOpen: this.$attrs['default-open'] as boolean,
        disclosureInnerElement: null as HTMLElement | null,
      };
    },

    computed: {
      disclosureInnerId(): string {
        return `disclosure-${this.componentId}`;
      },
    },

    watch: {
      isOpen(): void {
        if (this.isOpen) {
          this.$emit('open');
        } else {
          this.$emit('close');
        }
      },
    },

    mounted() {
      this.disclosureInnerElement = document.getElementById(this.disclosureInnerId);

      this.startObserver();
    },

    beforeUnmount() {
      this.stopObserver();
    },

    methods: {
      startObserver(): void {
        this.observer = new MutationObserver(mutations => {
          this.isOpen = (mutations[0].target as HTMLDivElement).dataset.open !== 'false';
        });

        const disclosureInner = this.disclosureInnerElement as HTMLElement;

        this.observer.observe(disclosureInner, {
          attributeFilter: ['data-open'],
          attributes: true,
        });
      },

      stopObserver(): void {
        this.observer?.disconnect();
      },

      open(): void {
        if (!this.isOpen) {
          this.toggle();
        }
      },

      close(): void {
        if (this.isOpen) {
          this.toggle();
        }
      },

      toggle(): void {
        const disclosureButton = (this.disclosureInnerElement as HTMLElement)
          .querySelector('[data-disclosure-button]') as HTMLElement;

        disclosureButton?.click();
      },
    },
  });
