import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a88e720c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled", "disabled-with-events"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIconSlot = _resolveComponent("ButtonIconSlot")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.buttonType,
    disabled: _ctx.disabled,
    "disabled-with-events": _ctx.disabledWithEvents,
    class: _normalizeClass([_ctx.buttonDynamicDomClasses, "app-button inline-flex items-center border rounded font-medium shadow-sm focus:outline-none disabled:cursor-not-allowed disabled:select-none"])
  }, [
    (_ctx.$slots.leadingIcon)
      ? (_openBlock(), _createBlock(_component_ButtonIconSlot, {
          key: 0,
          "button-type": _ctx.type,
          "button-size": _ctx.size,
          "icon-position": "leading"
        }, {
          leadingIcon: _withCtx(() => [
            _renderSlot(_ctx.$slots, "leadingIcon", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["button-type", "button-size"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.$slots.trailingIcon)
      ? (_openBlock(), _createBlock(_component_ButtonIconSlot, {
          key: 1,
          "button-type": _ctx.type,
          "button-size": _ctx.size,
          "icon-position": "trailing"
        }, {
          trailingIcon: _withCtx(() => [
            _renderSlot(_ctx.$slots, "trailingIcon", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["button-type", "button-size"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}