/* eslint-disable max-len */
export default {
  pageTitle: 'Leerplatform',
  companyPageTitle: '{companyName} {\'|\'} Leerplatform',
  loginToKenniss: 'Inloggen op het leerplatform',
  AuthorTool: 'Auteurstool',
  educationLibrary: 'Opleidingsbibliotheek',
  email: 'E-mailadres',
  yourEmail: 'Je e-mailadres',
  password: 'Wachtwoord',
  login: 'Inloggen',
  logout: 'Uitloggen',
  save: 'Opslaan',
  usersManagement: '{role} beheer',
  backToLogin: 'Terug naar inloggen',
  forgotPassword: 'Wachtwoord vergeten',
  forgotLoginDetails: 'Wachtwoord vergeten',
  resetPasswordEmailSent: 'Wachtwoord herstellink verzonden',
  resetPasswordEmailSentHelpText: 'Je wachtwoord herstellink is verzonden. Klik op de link in de e-mail om je wachtwoord opnieuw in te stellen.',
  forgotPasswordHelpText: 'Vul hieronder je e-mailadres in. Je ontvangt van ons binnen enkele minuten een e-mail waarmee je een nieuw wachtwoord kunt aanmaken.',
  invalidLogin: 'Dit e-mailadres en/of wachtwoord is onjuist',
  useAnotherDevice: 'Gebruik een ander device om in de auteurstool aan de slag te gaan',
  useAnotherDeviceHelpText: 'We willen jou de beste gebruikerservaring bieden. De auteurstool is daarom alleen te gebruiken op een computer, laptop of tablet met een minimale breedte van 1024px.',
  rotateYourScreen: 'Draai je scherm om in de auteurstool aan de slag te gaan',
  rotateYourScreenHelpText: 'We willen jou de beste gebruikerservaring bieden. Draai daarom je scherm horizontaal om in de auteurstool aan de slag te gaan.',
  onlyAvailableOnDesktop: 'Via desktop beschikbaar',
  somethingWentWrong: 'Er ging iets mis',
  toHave: 'heeft | hebben',
  hour: 'uur',
  and: 'en',
  at: 'op',
  yes: 'Ja',
  no: 'Nee',
  edit: 'Wijzigen',
  edited: 'Gewijzigd',
  review: 'Beoordelen',
  assessment: 'Beoordeling',
  send: 'Versturen',
  preview: 'Voorvertoning',
  more: 'Meer',
  close: 'Sluiten',
  new: 'Nieuw',
  duplicate: 'Dupliceren',
  delete: 'Verwijderen',
  copy: 'Kopiëren',
  previous: 'Vorige',
  next: 'Volgende',
  back: 'Terug',
  cancel: 'Annuleren',
  publish: 'Publiceren',
  statusLabel: 'Status',
  replica: 'Kopie',
  passed: 'Gehaald',
  failed: 'Niet gehaald',
  add: 'Toevoegen',
  deleted: 'Verwijderd',
  deletedAt: 'Verwijderd op {0}',
  noResultsFound: 'Er zijn geen resultaten gevonden',
  searchFor: 'Zoeken naar {name}',
  nothingFound: 'Er zijn geen {name} gevonden.',
  selected: 'geselecteerd',
  selectAllItems: 'Alle {name} selecteren',
  deselectAllItems: 'Alle {name} deselecteren',
  loading: 'Bezig met laden...',
  showReview: 'Beoordeling tonen',
  version: 'Versie',
  loadMore: 'Laad meer',
  resultsCount: '{0} van de {1}',
  actions: 'Acties',
  action: 'Actie',
  again: 'Opnieuw',
  download: 'Downloaden',
  training: 'Training | Trainingen',
  navigation: {
    profile: 'Profiel',
    authorTool: 'Auteurstool',
    lms: 'LMS',
    onboarding: 'Onboarding',
    courseLibrary: 'Bibliotheek',
    trainings: 'Praktijkagenda',
    myTrainings: 'Mijn trainingen',
    myDocuments: 'Certificaten en documenten',
    components: 'Componenten',
  },
  NoAccessOverlay: {
    title: 'Je hebt nog geen toegang tot deze functionaliteit. Maar geen zorgen!',
    book: {
      title: 'Boek een demo',
    },
  },
  courseTypes: {
    skill: 'Vaardigheidstraining',
    elearning: 'Training',
    microlearning: 'Training',
    document: 'Document',
    video: 'Video',
    test: 'Toets',
  },
  courseTypesAbbreviation: {
    skill: 'Training',
    elearning: 'Training',
    microlearning: 'Training',
    document: 'Document',
    video: 'Video',
    test: 'Toets',
  },
  courseTypesNameWithPrefix: {
    skill: 'de vaardigheidstraining',
    elearning: 'de elearning',
    microlearning: 'de microlearning',
    document: 'het document',
    video: 'de video',
    test: 'de toets',
  },
  auth: {
    password: {
      reset: {
        success: {
          title: 'Wachtwoord gewijzigd',
          description: 'Je wachtwoord is succesvol gewijzigd.',
        },
        invalidToken: {
          title: 'Wachtwoord herstellink verlopen',
          description: 'Je wachtwoord herstellink is verlopen. Vraag een nieuwe wachtwoord herstellink aan.',
        },
        welcomeMessage: 'Welkom {name}!',
        page: {
          heading: 'Wachtwoord opnieuw instellen',
          description: 'Maak hieronder je nieuwe wachtwoord aan.',
        },
        form: {
          password: {
            label: 'Voer je wachtwoord in',
            placeholder: 'Wachtwoord',
          },
          passwordConfirmation: {
            label: 'Voer nogmaals je wachtwoord in',
            placeholder: 'Wachtwoord bevestigen',
          },
          submit: 'Wachtwoord opnieuw instellen',
        },
        goBackToForgotPasswordPage: 'Ga terug naar de wachtwoord vergeten pagina',
      },
    },
    invite: {
      fetchError: {
        title: 'Ongeldige uitnodiging',
        content: 'De uitnodiging kon niet worden gevonden of is inmiddels verlopen.',
      },
      activationSuccess: {
        title: 'Account geactiveerd',
        content: 'Je account is succesvol geactiveerd.',
      },
      goBackToLoginPage: 'Ga terug naar de inlogpagina',
      welcomeMessage: 'Welkom {name}!',
      activateAccount: {
        heading: 'Activeer je account',
        description: 'Maak hieronder je wachtwoord aan om je account te activeren.',
      },
      form: {
        password: {
          label: 'Voer je wachtwoord in',
          placeholder: 'Wachtwoord',
        },
        passwordConfirmation: {
          label: 'Voer nogmaals je wachtwoord in',
          placeholder: 'Wachtwoord bevestigen',
        },
        submit: 'Account activeren',
      },
    },
  },
  status: {
    concept: 'Concept',
    review: 'In beoordeling',
    reviewed: 'Beoordeeld',
    published: 'Gepubliceerd',
    accepted: 'Goedgekeurd',
    rejected: 'Afgekeurd',
    'publicly-published': 'Openbaar gepubliceerd',
  },
  interceptors: {
    403: {
      title: 'Geen rechten',
      description: 'Je hebt geen rechten voor deze handeling',
    },
  },
  authorToolNoAccessOverlay: {
    educatorDescription: 'Wil je jouw leeraanbod digitaliseren? In de auteurstool maak je makkelijk en snel eigen content. Vervolgens geef je jouw cursisten toegang tot eigen trainingen en maak je blended leren binnen {companyName} mogelijk.',
    companyDescription: 'Wil je het aanbod in de bibliotheek verrijken met eigen content? In de auteurstool maak je makkelijk en snel eigen content, zoals het digitaliseren van het bedrijfshandboek of de veiligheidsinstructies binnen {companyName}.',
    book: {
      description: 'Wil je de mogelijkheden binnen de auteurstool zelf ervaren? Boek een demo bij KENNISS.',
    },
  },
  couldNotDuplicateElement: 'Er ging iets mis tijdens het dupliceren van het element.',
  bewareQueueIsRunning: 'Pas op! Er zijn nog onvoltooide processen op de achtergrond aan het draaien. Een moment geduld alstublieft.',
  loadingCourses: 'Bezig met het ophalen van de trainingen',
  loadingFilters: 'Bezig met het ophalen van de filters',
  author: 'Auteur',
  authors: 'Auteurs',
  createElearning: 'E-learning maken',
  noCourses: 'Start met het maken van je digitale leeraanbod!',
  noCoursesFiltersEnabled: 'Er zijn geen cursussen gevonden met de door u geselecteerde filters.',
  courseEditedOn: 'Laatst gewijzigd op {date}',
  courseEditedBy: 'door {userName}',
  search: 'Zoeken',
  searchOnCourses: 'Zoeken naar trainingen...',
  searchOnName: 'Zoek op naam',
  category: 'Categorie',
  sortBy: 'Sorteer op',
  sort: {
    dateFirstChanged: 'Wijzigingsdatum: nieuwste',
    dateLastChanged: 'Wijzigingsdatum: oudste',
  },
  editCourse: {
    unableToEditToast: {
      title: 'Deze training is in beoordeling en kan daarom tijdelijk niet worden gewijzigd.',
    },
    newVersionToast: {
      title: 'Nieuwe versie aanmaken?',
      description: '<p>Als je grote wijzigingen in deze gepubliceerde training wilt doen is het belangrijk dat je een nieuwe versie aanmaakt. Zo krijgen nieuwe deelnemers de nieuwe training te zien en ondervinden huidige deelnemers geen hinder van de wijzigingen.</p><p>Wil je alleen tekstuele wijzigingen doorvoeren? Dan kun je doorgaan zonder een nieuwe versie aan te maken. Deze wijzigingen krijgen alle deelnemers te zien.</p>',
      confirmButtonText: 'Ja, ik ga meer dan alleen tekstuele wijzigingen doen',
      cancelButtonText: 'Nee, ik ga alleen tekstuele wijzingen doen',
    },
    test: 'Losse toets',
    training: 'Training',
    noSectionsFoundToast: {
      title: 'Voeg een element toe om de {0} te bekijken',
      description: 'Voeg minimaal één element toe om de {0} als voorvertoning te bekijken.',
    },
  },
  duplicateCourse: {
    busyDuplicating: 'Bezig met dupliceren...',
    duplicated: '{courseType} gedupliceerd',
    pressHereToOpen: 'Klik hier om {courseTypeWithPrefix} te openen',
    duplicatedButton: '{courseType} openen',
    destroyError: {
      title: 'Kopieëren mislukt',
      content: 'Het kopieëren van "{name}" is mislukt.',
    },
  },
  courseTypeIsRequired: 'U heeft geen trainingstype geselecteerd.',
  generalCourseInformation: 'Algemene informatie over de e-learing',
  requiredFieldsHelpText: 'Velden met een * zijn vereist.',
  courseName: 'Trainingsnaam',
  descriptionOfCourse: 'Beschrijving van de training',
  descriptionPlaceholder: 'Korte introductie over de training',
  descriptionOfCourseHelperText: 'Deze beschrijving komt terug op de trainings overzichtpagina.',
  thisIsWhatYouWillLearn: 'Dit ga je leren',
  whatYouWillLearnExample: 'Bijvoorbeeld: De basis van boomverzorging',
  addRule: 'Regel toevoegen',
  generalInformation: 'Algemene informatie',
  categories: 'Categoriseren',
  create: 'Maken',
  settings: 'Instellingen',
  questionOverview: 'Vragenoverzicht',
  minTwoWhatToLearnItemsAreRequired: 'Er moeten minimaal twee "Dit ga je leren" items zijn toegevoegd.',
  media: 'Media',
  upload: 'Uploaden',
  uploadFile: 'Upload een bestand',
  addImage: 'Voeg afbeelding toe',
  addVideo: 'Voeg video toe',
  watchVideo: 'Bekijk video',
  downloadDocument: 'Document downloaden',
  noMediaUploaded: 'Er is nog geen media geüpload',
  busyWithUploading: 'Bezig met uploaden...',
  orDragDropInBox: 'of sleep het in dit vak',
  addMedia: 'Voeg media toe',
  uploading: 'Bezig met uploaden',
  processing: 'Bezig met converteren',
  mediaBeingProcessed: 'Wij zijn {mediaType} aan het klaarmaken voor gebruik.',
  mediaTypesWithPrefix: {
    image: 'de afbeelding',
    document: 'het document',
    video: 'de video',
  },
  featuredImage: 'Uitgelichte afbeelding',
  featuredVideo: 'Uitgelichte video',
  featuredVideoDescription: 'Deze video komt terug op de startpagina van dit leeraanbod. Voeg een video toe als korte teaser om deelnemers te enthousiasmeren.',
  thisImageAppearsInTheOverview: 'Deze afbeelding komt terug in het overzicht.',
  fieldRequired: 'Voeg een {0} toe om verder te gaan.',
  areaOfExpertise: 'Vakgebied',
  areasOfExpertise: 'Vakgebieden',
  oneOrMorePossible: '(1 of meerdere mogelijk)',
  loadingAreasOfExpertise: 'Bezig met het ophalen van de vakgebieden',
  educationLevelsRequiredOption: 'Opleidingsniveau (1 of meerdere mogelijk)',
  loadingEducationLevels: 'Bezig met het ophalen van de opleidingsniveaus',
  learningOfferType: 'Type leeraanbod',
  addChapter: 'Hoofdstuk toevoegen',
  addPage: 'Paragraaf toevoegen',
  loadingCourse: 'Bezig met het ophalen van de training',
  loadingModule: 'Bezig met het ophalen van de hoofdstukken en pagina\'s',
  element: 'Element',
  thisElement: 'dit element',
  extensionAreNotAllowed: {
    title: 'Bestand niet toegestaan',
    description: 'Het bestand dat je probeert te uploaden is niet toegestaan. Upload een bestand van het type: {extensions}.',
  },
  course: {
    edit: {
      documentDefaultName: 'Naamloos document',
    },
    review: {
      approve: 'Goedkeuren',
      disapprove: 'Afkeuren',
      approveTraining: 'Leeraanbod goedkeuren',
      disapproveTraining: 'Leeraanbod afkeuren',
      typeHereTheReason: 'Type hier de reden van afkeuring',
      notifications: {
        courseReviewed: {
          published: {
            title: 'De {courseType} is gepubliceerd',
            description: 'De {courseType} is goedgekeurd en in de openbare bibliotheek gepubliceerd.',
          },
          rejected: {
            title: 'De {courseType} is afgewezen',
            description: 'De {courseType} is afgewezen. De aanbieder zal de {courseType} opnieuw moeten aanbieden.',
          },
          error: {
            title: 'Er is iets fout gegaan.',
            description: 'Er is iets fout gegaan tijdens het beoordelen.',
          },
        },
      },
    },
    destroy: {
      confirm: {
        title: '{courseType} verwijderen?',
        content: 'Weet je zeker dat je "{name}" wilt verwijderen? Eventueel onderliggende inhoud zal ook verloren gaan.',
        confirmButtonText: 'Ja, {courseType} verwijderen',
        cancelButtonText: 'Nee, annuleren',
      },
      destroyError: {
        title: 'Verwijderen mislukt',
        content: 'Het verwijderen van "{name}" is mislukt.',
      },
      destroyForbidden: {
        title: 'Verwijderen niet mogelijk',
        contentTraining: 'Het verwijderen van deze openbare {courseType} is niet mogelijk. Neem contact op met KENNISS.',
        contentDocument: 'Het verwijderen van dit openbare document is niet mogelijk. Neem contact op met KENNISS.',
      },
      destroySuccess: {
        title: '{courseType} verwijderd',
        content: 'Het verwijderen van "{name}" is geslaagd.',
      },
    },
    publish: {
      publishInOwnLibrary: 'Publiceren in eigen bibliotheek',
      publishInPublicLibrary: 'Publiceren in openbare bibliotheek',
      courseWillBeReviewed: 'Het leeraanbod zal vóór openbare publicatie door KENNISS worden gereviewd.',
      publishPublicSuccess: {
        title: 'In beoordeling',
        description: 'We gaan de {courseTypeName} beoordelen.',
      },
      publishSuccess: {
        title: 'Publiceren geslaagd',
        description: '{courseTypeName} is succesvol gepubliceerd.',
      },
      publishError: {
        title: 'Publiceren mislukt',
        description: 'Het publiceren van "{name}" is mislukt.',
      },
      noSectionsFoundToast: {
        title: 'Voeg een element toe om verder te gaan',
        description: 'Voeg minimaal één element toe om verder te gaan naar de volgende stap.',
      },
    },
    version: {
      actionDeniedToast: {
        title: 'Actie niet mogelijk',
        description: 'Maak een nieuwe versie aan om deze actie uit te voeren.',
        button: {
          text: 'Nieuwe versie aanmaken',
        },
      },
    },
  },
  section: {
    chapters: '0 Hoofdstukken | 1 Hoofdstuk | {count} Hoofdstukken',
    exams: '0 Toetsen | 1 Toets | {count} Toetsen',
    noSectionsFound: {
      content: 'Je training is op dit moment nog helemaal leeg.',
      actionMessage: 'Klik op de knop hieronder om een hoofdstuk toe te voegen.',
    },
    noExamOrParagraphFound: {
      content: 'Deze training bevat nog geen inhoud. Voeg via het linkermenu een hoofdstuk of toets toe om elementen toe te voegen.',
    },
    destroy: {
      confirm: {
        title: {
          default: 'Sectie verwijderen?',
          chapter: 'Hoofdstuk verwijderen?',
          paragraph: 'Paragraaf verwijderen?',
          exam: 'Toets verwijderen?',
        },
        content: {
          chapter: 'Weet je zeker dat je "{sectionName}" wilt verwijderen? Eventueel onderliggende pagina\'s en inhoud zal ook verloren gaan.',
          paragraph: 'Weet je zeker dat je "{sectionName}" wilt verwijderen? Eventueel onderliggende inhoud zal ook verloren gaan.',
          exam: 'Weet je zeker dat je "{sectionName}" wilt verwijderen? Eventueel onderliggende inhoud zal ook verloren gaan.',
        },
        confirmButtonText: {
          default: 'Ja, verwijderen',
          chapter: 'Ja, hoofdstuk verwijderen',
          paragraph: 'Ja, paragraaf verwijderen',
          exam: 'Ja, toets verwijderen',
        },
        cancelButtonText: 'Nee, annuleren',
      },
      toast: {
        deleteError: {
          title: 'Verwijderen mislukt',
          content: 'Het verwijderen van "{sectionName}" is mislukt.',
        },
        deleteSuccess: {
          title: 'Verwijderen geslaagd',
          content: '{sectionName} is succesvol verwijderd.',
        },
      },
    },
    leavingRoute: {
      confirm: {
        title: 'Weet je zeker dat je deze pagina wilt verlaten?',
        content: 'Er zijn één of meerdere elementen die foutmeldingen bevatten. Je wijzigingen in elementen met foutmeldingen zullen verloren gaan.',
        confirmButtonText: 'Ja, elementen met foutmeldingen verwijderen',
        cancelButtonText: 'Nee, annuleren',
      },
    },
  },
  paragraph: {
    delete: {
      title: 'Weet u zeker dat u deze paragraaf wilt verwijderen?',
      content: 'Weet je zeker dat je {name} wilt verwijderen? Eventueel onderliggende inhoud zal ook verloren gaan',
      confirmButtonText: 'Ja, verwijderen',
      cancelButtonText: 'Nee, annuleren',
    },
  },
  block: {
    loadingSectionBlocks: 'Bezig met het ophalen van de elementen',
    autoSave: {
      savingChanges: 'Bezig met opslaan...',
      changesSaved: 'Wijzigingen opgeslagen',
    },
    addNewBlock: {
      paragraph: 'Nieuw element toevoegen',
      exam: 'Nieuwe vraag toevoegen',
    },
    noBlocksFound: {
      paragraph: {
        content: 'Je paragraaf is op dit moment nog helemaal leeg.',
        actionMessage: 'Klik op de knop hieronder om een element aan de paragraaf toe te voegen.',
      },
      exam: {
        content: 'Je toets is op dit moment nog helemaal leeg.',
        actionMessage: 'Klik op de knop hieronder om een vraag aan de toets toe te voegen.',
      },
    },
    form: {
      title: {
        placeholder: 'Titel',
        placeholderOptional: 'Titel (optioneel)',
      },
      description: {
        placeholder: 'Vul hier een beschrijving in...',
        textPlaceholder: 'Vul hier de tekst in...',
        optionalPlaceholder: 'Vul hier een beschrijving in... (optioneel)',
      },
      image: {
        addMediaButtonText: 'Voeg afbeelding toe',
      },
      video: {
        addMediaButtonText: 'Voeg video toe',
      },
      document: {
        addMediaButtonText: 'Voeg document toe',
        mustBeSigned: 'Document dient ondertekend te worden',
      },
    },
    destroy: {
      confirm: {
        title: 'Element verwijderen?',
        content: 'Weet je zeker dat je {name} wilt verwijderen? De inhoud van dit element zal verloren gaan.',
        confirmButtonText: 'Ja, element verwijderen',
        cancelButtonText: 'Nee, annuleren',
      },
      toast: {
        deleteSuccess: {
          title: 'Verwijderen geslaagd',
          content: '{blockName} is succesvol verwijderd.',
        },
        deleteError: {
          title: 'Verwijderen mislukt',
          content: '{blockName} is niet verwijderd. We zijn ervan op de hoogte gebracht.',
        },
      },
    },
    sort: {
      error: {
        title: 'Opslaan mislukt',
        content: 'Er ging iets mis tijdens het opslaan van de nieuwe volgorde.',
      },
    },
  },
  addPageTitle: 'Voer een paragraaftitel in...',
  addDocumentTitle: 'Voer een documenttitel in...',
  loadingSectionContent: 'Bezig met het ophalen van de pagina inhoud',
  duplicationSectionContent: 'Bezig met het ophalen van de inhoud. Dit kan afhankelijk van de hoeveelheid even duren.',
  noTitle: 'Geen titel',
  addExam: 'Toets binnen e-learning toevoegen',
  examWithinCourse: 'Voer een toetstitel in...',
  questions: {
    question: '0 vragen | 1 vraag | {count} vragen',
    insertQuestion: 'Vul hier de vraag in...',
    showCommentsFields: 'Voeg een antwoordtoelichting toe na het beantwoorden',
    correctAnswer: {
      label: 'Juiste antwoord',
      placeholder: 'Vul hier het juiste antwoord in...',
    },
    comments: {
      placeholder: 'Toelichting...',
      wrongAnswer: 'Bij een fout antwoord',
      goodAnswer: 'Bij een goed antwoord',
    },
    choice: {
      addAnswer: 'Antwoordkeuze toevoegen',
      selectRightAnswer: 'Selecteer hieronder het juiste antwoord',
      selectRightAnswers: 'Selecteer hieronder het juiste antwoord/ de juiste antwoorden',
      answerPlaceholder: 'Vul een antwoordkeuze in...',
      allCorrectFieldsAreUnchecked: 'Selecteer minimaal 1 juiste antwoordkeuze',
    },
  },
  publicationSettings: 'Publicatie instellingen',
  courseType: 'Type training',
  recommendedBasedOnContents: 'Aanbevolen op basis van inhoud',
  loadingSettings: 'Bezig met ophalen van instellingen...',
  chooseCourseTypes: {
    skill: {
      pageTitle: 'E-learning maken',
      generalInformation: {
        formTitle: 'Algemene informatie over de e-learning',
      },
    },
    microlearning: {
      pageTitle: 'E-learning maken',
      generalInformation: {
        formTitle: 'Algemene informatie over de e-learning',
      },
    },
    video: {
      name: 'Video',
      description: 'Kies voor losse video om één video uit te lichten. Dit kun je gebruiken om bijvoorbeeld een uitleg te geven over een specifiek onderwerp.',
      pageTitle: 'Video maken',
      generalInformation: {
        formTitle: 'Algemene informatie over de video',
      },
    },
    document: {
      name: 'Document',
      description: 'Kies voor document om een los document beschikbaar te stellen of deze te laten ondertekenen.',
      pageTitle: 'Document uploaden',
      generalInformation: {
        formTitle: 'Algemene informatie over de video',
      },
    },
    test: {
      name: 'Losse toets',
      description: 'Kies voor losse toets om meerdere soorten vraagtypes met elkaar af te wisselen binnen één toets.',
      pageTitle: 'Losse toets maken',
      generalInformation: {
        formTitle: 'Algemene informatie over de toets',
        form: {
          name: {
            label: 'Toetsnaam',
          },
          description: {
            label: 'Beschrijving van de toets',
            placeholder: 'Korte introductie over de toets',
          },
          goals: {
            label: 'Dit gaan we toetsen',
            minimumLengthError: 'Er moeten minimaal twee "Dit gaan we toetsen" items zijn toegevoegd.',
          },
        },
      },
      settings: {
        form: {
          scoringMethods: {
            exam: 'Score op basis van vragen in de toets (gehaald/ niet gehaald)',
          },
        },
      },
      questionOverview: {
        exam: {
          weightingChangeConfirm: {
            enable: {
              title: 'Wil je score toepassen in deze toets?',
              content: 'Het is mogelijk om score toe te passen in deze toets. De scoringswijze kun je aanpassen in stap 4.',
            },
            disable: {
              title: 'Wil je geen score toepassen in deze toets?',
              content: 'Het is mogelijk om geen score toe te passen in deze toets. De scoringswijze kun je aanpassen in stap 4.',
            },
          },
        },
      },
    },
    elearning: {
      name: 'E-learning',
      description: 'Kies voor e-learning (vaardigheidstraining of microlearning) om teksten, afbeeldingen en video\'s af wilt wisselen met verschillende soorten vraagtypes. ',
      pageTitle: 'E-learning maken',
      generalInformation: {
        formTitle: 'Algemene informatie over de e-learning',
      },
    },
  },
  backToOverview: 'Terug naar overzicht',
  yesUnderstood: 'Ja, ik snap het',
  select: 'Selecteren',
  question: 'Vraag',
  type: 'Type',
  weighting: 'Weging',
  low: 'laag',
  medium: 'gemiddeld',
  high: 'hoog',
  points: 'punten',
  numberOfPoints: '0 punten | 1 punt | {count} punten',
  completedExamAt: 'Toets gehaald bij',
  completedCourseAt: 'Training gehaald bij',
  totalNumberOfPointsToAchieve: 'Totaal aantal te behalen punten',
  countWeightInScoreOverview: 'Weging meetellen in scoreoverzicht',
  loadingQuestions: 'Bezig met het ophalen van de vragen...',
  scoreOverview: 'Scoreoverzicht',
  scoringMethod: 'Scoringswijze',
  scoringMethods: {
    noScore: 'Geen score toepassen',
    exam: 'Score op basis van toets(en) tijdens de training (gehaald/ niet gehaald)',
    sectionQuestions: 'Score op basis van vragen tijdens de training (gehaald/ niet gehaald)',
    confirmPreviousStep: {
      title: 'Wil je score toepassen binnen deze training?',
      content: 'Het is mogelijk om score toe te passen binnen deze training. De scoringswijze kun je aanpassen in stap 4.',
      confirmButtonText: 'Ja, scoringswijze aanpassen in stap 4',
      cancelButtonText: 'Nee, annuleren',
    },
    changeExamScoringWeight: 'Scoringswijze aanpassen',
  },
  specifyTheMinimumPointsToPassExam: 'De totaal aantal te behalen punten zijn automatische ingevuld. Vul hier in hoeveel procent van de punten een cursist moet halen om de toets te halen.',
  specifyTheMinimumPointsToPassCourse: 'De totaal aantal te behalen punten zijn automatische ingevuld. Vul hier in hoeveel procent van de punten een cursist moet halen om de training te halen.',
  timeLimitMustBeHigherThanZero: 'Je hebt gekozen om een tijdslimiet toe te voegen voor deze toets. Voer een tijdslimiet in hoger dan nul of vink deze optie uit.',
  addTimeLimit: 'Voeg een tijdslimiet toe',
  noTimeLimit: 'Geen tijdslimiet',
  timeLimit: 'Tijdslimiet',
  variableMinutes: '0 minuten | 1 minuut | {count} minuten',
  minutes: 'minuten',
  days: 'dagen',
  courseLibrary: {
    list: {
      noCoursesFound: 'Er zijn geen trainingen gevonden.',
      nrOfTrainingsFound: '{count} training gevonden | {count} trainingen gevonden',
      sort: {
        dateFirstChanged: 'Nieuwste eerst',
        dateLastChanged: 'Oudste eerst',
      },
      filters: {
        closeButtonText: 'Filters sluiten',
        coursesButtonText: {
          hasActiveFilters: 'Filters aanpassen',
          noActiveFilters: 'Filter trainingen',
        },
      },
    },
    show: {
      previewCourse: '{courseType} bekijken',
      nthPeopleHaveAccess: '0 {role} hebben toegang | 1 {role} heeft toegang | {count} {role} hebben toegang',
    },
  },
  trainings: {
    trainings: 'trainingen | training',
    list: {
      planned: 'Ingepland',
      fromThePast: 'Uit het verleden',
      loadingTrainings: 'Bezig met het ophalen van de trainingen',
      nrOfTrainingsFound: '{count} training gevonden | {count} trainingen gevonden',
      noTrainingsFound: 'Er zijn geen trainingen gevonden.',
      filters: {
        submitFiltersButtonText: {
          noActiveFilters: 'Filter trainingen',
          hasActiveFilters: 'Filters aanpassen',
        },
        closeButtonText: 'Filters sluiten',
        date: {
          title: 'Datum',
          placeholder: 'dd-mm-jjjj',
          from: 'Van',
          to: 'Tot',
        },
      },
    },
    show: {
      description: 'Omschrijving',
      trainers: 'Trainer(s)',
      participant: 'Deelnemer',
      participants: 'Deelnemers',
      manageParticipants: {
        add: 'Deelnemerslijst invullen',
        edit: 'Deelnemerslijst wijzigen',
        modal: {
          assignTitle: 'Opslaan',
          updateTitle: 'Wijzigen',
        },
        savedToast: {
          title: 'Deelnemerslijst opgeslagen',
          description: 'De deelnemerslijst is succesvol opgeslagen.',
        },
        updatedToast: {
          title: 'Deelnemerslijst gewijzigd',
          description: 'De deelnemerslijst is succesvol gewijzigd.',
        },
        cannotRemoveToast: {
          title: 'Verwijderen niet mogelijk',
          description: 'Het verwijderen van deelnemers uit deze training is niet mogelijk omdat de presentielijst al is ingevuld.',
        },
      },
      destroy: {
        confirm: {
          title: 'Praktijktraining verwijderen?',
          content: 'Weet je zeker dat je deze praktijktraining wilt verwijderen? De inhoud zal verloren gaan.',
          contentNotFinished: 'Weet je zeker dat je deze praktijktraining wilt verwijderen? De uitnodigingsmail voor deze training is al verzonden. Deelnemers worden na het verwijderen direct op de hoogte gesteld dat de training wordt geannuleerd.',
          confirmButtonText: 'Ja, praktijktraining verwijderen',
          cancelButtonText: 'Nee, annuleren',
        },
        destroySuccess: {
          title: 'Praktijktraining verwijderd',
          content: 'Het verwijderen van "{name}" is geslaagd.',
        },
        destroyError: {
          title: 'Verwijderen mislukt',
          content: 'Het verwijderen van "{name}" is mislukt.',
        },
      },
      viewAttendanceList: 'Presentielijst',
      fillInAttendanceList: 'Presentielijst invullen',
      updateAttendanceList: 'Presentielijst wijzigen',
      loadingAttendanceList: 'Bezig met ophalen van de deelnemers...',
      attendance: {
        presence: 'Aanwezigheid',
        absent: 'Afwezig',
        present: 'Aanwezig',
        assigned: 'Toegewezen',
        passed: 'Gehaald',
        failed: 'Niet gehaald',
        reward: 'Beloning',
        addRemark: 'Voeg opmerking toe',
        addRemarkPlaceholder: 'Vul hier de opmerking in...',
        certificate: 'Certificaat',
        proofOfParticipation: 'Bewijs van deelname',
        notFilledYet: 'Nog niet ingevuld',
        createdToast: {
          title: 'Presentielijst opgeslagen',
          description: 'De presentielijst is succesvol opgeslagen.',
        },
        updatedToast: {
          title: 'Presentielijst gewijzigd',
          description: 'De presentielijst is succesvol gewijzigd.',
        },
        addParticipant: 'Extra {userType} toevoegen',
        addCertificate: 'Upload een PDF',
        saveTemporarily: 'Tussentijds opslaan',
        submit: 'Indienen',
      },
      certification: {
        passed: 'Training gehaald',
        certificateType: {
          label: 'Type beloning',
          certificate: 'Certificaat',
          proofOfParticipation: 'Bewijs van deelname',
        },
        workUnderSupervision: 'Werken onder begeleiding verplicht',
      },
    },
    form: {
      newTraining: 'Nieuwe training',
      editTraining: 'Training wijzigen',
      create: 'Aanmaken',

      steps: {
        generalInformation: 'Algemene informatie',
        trainer: 'Trainer',
        participants: 'Deelnemers',
        settings: 'Instellingen',
      },
      generalInformation: {
        title: 'Algemene informatie over de training',
        name: 'Trainingsnaam',
        description: 'Beschrijving van de training',
        date: 'Datum',
        dateMinimumLengthError: 'Er moet minimaal een "datum" zijn toegevoegd.',
        addDate: 'Datum toevoegen',
        from: 'Van',
        to: 'Tot',
        location: 'Locatie',
        destroyDateForbidden: {
          title: 'Verwijderen niet mogelijk',
          description: 'Het verwijderen van deze datum is niet mogelijk omdat de presentielijst al is ingevuld.',
        },
        changeForbidden: {
          title: 'Wijzigen niet mogelijk',
          description: 'Het wijzigen van deze datum is niet mogelijk omdat de presentielijst al is ingevuld.',
        },
      },
      trainer: {
        title: 'Trainer(s) toevoegen',
        trainer: 'Trainer | Trainers',
      },
      participants: {
        title: 'Deelnemers',
        delegator: {
          label: 'Hoe wil je deelnemers toevoegen?',
          internal: 'Zelf deelnemers toevoegen',
          external: 'Externe organisatie deelnemers laten toevoegen',
          externalDescription: 'Selecteer een organisatie die de deelnemerslijst moet invullen. Selecteer vervolgens één of meerdere contactpersonen die dit verzoek per mail moeten ontvangen.',
          subEducatorHasNoAdmins: 'Maak een beheerder aan om het verzoek tot deelnemerslijst te versturen.',
          organizationSearchInputPlaceholder: 'Zoeken naar organisatie',
        },
        destroyUserForbiddenToast: {
          title: 'Verwijderen niet mogelijk',
          description: 'Het verwijderen van deelnemers uit deze training is niet mogelijk omdat de presentielijst al is ingevuld.',
        },
      },
      settings: {
        title: 'Instellingen',
        sendParticipantsInvitation: '{role} een uitnodiging sturen',
        daysBeforeTraining: 'voor de training',
        inviteDisabledDescription: {
          alreadySend: 'Dit veld kan niet meer worden gewijzigd, omdat de eerste uitnodigingsmail is verzonden naar de deelnemers. Eventueel nieuw toegevoegde medewerkers ontvangen de uitnodigingsmail direct na de wijziging.',
          trainingDayIsPast: 'Dit veld kan niet meer worden gewijzigd, omdat de trainingsdatum(s) in het verleden liggen.',
        },
        keepAttendanceList: 'Digitale presentielijst opstellen',
        attendanceListDisabledDescription: 'Dit veld kan niet meer worden gewijzigd, omdat de presentielijst al is ingevuld.',
        certificates: {
          generateCertificates: 'Automatisch certificaten genereren',
          certificateExpiryDate: {
            label: 'Verloopdatum',
            placeholder: 'dd-mm-jjjj',
          },
          certificateReminderDate: {
            label: 'Reminderdatum',
            placeholder: 'dd-mm-jjjj',
          },
        },
      },
      trainingCreatedToast: {
        title: 'Praktijktraining aangemaakt',
        description: 'De praktijktraining is succesvol aangemaakt.',
      },
      trainingUpdatedToast: {
        title: 'Praktijktraining gewijzigd',
        description: 'De praktijktraining is succesvol gewijzigd.',
      },
      trainingLeaveConfirm: {
        title: 'Weet je zeker dat de de wijziging niet wilt opslaan?',
        content: 'Je hebt de wijzigingen nog niet opgeslagen. Weet je zeker dat je deze pagina wilt verlaten zonder de wijzigingen op te slaan in stap 4?',
        cancelButtonText: 'Nee, annuleren',
        confirmButtonText: 'Ja, wijzigingen niet opslaan',
      },
    },
  },
  myTrainings: {
    filters: {
      closeButtonText: 'Filters sluiten',
      enrollmentsButtonText: {
        hasActiveFilters: 'Filters aanpassen',
        noActiveFilters: 'Filter trainingen',
      },
    },
    myAgenda: 'Mijn agenda',
    trainings: 'trainingen | training',
    onboarding: 'Onboardingstraject',
    practicalTraining: 'Praktijktraining',
    completedTrajectories: 'Afgeronde trajecten',
    activeOnlineTrainings: 'Actieve online trainingen',
    completedOnlineTrainings: 'Afgeronde online trainingen',
    trainingsFromThePast: 'Praktijktrainingen uit het verleden',
    noEnrollmentsFiltersEnabled: 'Er zijn geen trainingen gevonden.',
    noEnrollments: 'Er zijn geen trainingen toegewezen.',
    nrOfTrainingsFound: '{count} training gevonden | {count} trainingen gevonden',
    sort: {
      dateFirstChanged: 'Nieuwste eerst',
      dateLastChanged: 'Oudste eerst',
    },
    show: {
      courseDescription: 'Omschrijving',
      testDescription: 'Omschrijving toets',
      thisIsWhatYouWillLearn: 'Dit ga je leren',
      thisIsWhatWeWillTest: 'Dit gaan we toetsen',
      thisIsWhatYouAlsoWillLearn: 'Dit ga je onder andere leren',
      thisIsWhatWeAlsoWillTest: 'Dit gaan we onder andere toetsen',
      trainingImage: 'Training afbeelding',
      courseContent: 'Inhoud',
      loading: 'Bezig met het ophalen van de training',
      actionMenu: {
        learning: 'Training',
        test: 'Toets',
        video: 'Video',
        document: 'Document',
        open: '{type} openen',
        startTraining: '{type} starten',
        resumeTraining: '{type} hervatten',
        showTraining: 'Afgeronde {type} bekijken',
        watchTraining: '{type} bekijken',
        rewatchTraining: '{type} opnieuw bekijken',
        assign: 'Toewijzen',
        notifications: {
          assignSuccess: {
            title: '{selectionName} toegewezen',
            description: 'De {selectionName} {verb} toegang tot de {courseTypeName} en {verb} hiervan een automatisch bericht ontvangen.',
          },
          assignErrorEmpty: {
            title: 'Selecteer een groep of gebruiker',
            description: 'Selecteer een groep of gebruiker om toe te wijzen.',
          },
        },
      },
      assignCompaniesModal: {
        organizations: 'organisatie | organisaties',
        noCompaniesFound: 'Er zijn geen organisaties gevonden.',
        select: 'Alle organisaties selecteren',
        deselect: 'Alle organisaties deselecteren',
        allCompaniesSelected: 'Alle organisaties hebben {courseTypeWithPrefix} toegewezen.',
        searchFor: 'Zoeken naar organisaties',
        notifications: {
          assignSuccess: {
            title: '{selectionName} toegewezen',
            description: 'De {selectionName} {verb} toegang tot de {courseTypeName} en {verb} hiervan een automatisch bericht ontvangen.',
          },
          assignErrorEmpty: {
            title: 'Selecteer een groep of gebruiker',
            description: 'Selecteer een groep of gebruiker om toe te wijzen.',
          },
        },
      },
    },
    walkThrough: {
      training: 'Training',
      exam: 'Toets',
      leaveTraining: '{0} verlaten',
      endTraining: '{0} beëindigen',
      finishTraining: '{0} afronden',
      continueTraining: 'Verder met training',
      finish: 'Afronden',
      toEdit: 'Naar bewerken',
      loading: 'Bezig met het ophalen van de training',
      loadingBlock: 'Bezig met het ophalen van de content',
      showCompletedExam: 'Afgeronde toets bekijken',
      startExam: 'Toets starten',
      examQuestionProgress: 'Vraag {0} van {1}',
      checkAnswer: 'Antwoord controleren',
      startExamDescription: 'Je staat op het punt om van start te gaan met de toets \'{examName}\'. Na het beantwoorden van alle vragen krijg je jouw resultaat te zien. Ben je er klaar voor? Klik dan op toets starten.',
      downloadDocument: 'Document downloaden',
      examResult: {
        loading: 'Bezig met berekenen van het resultaat',
        test: 'toets',
        training: 'training',
        completed: 'Goed bezig! Je hebt de {0} afgerond!',
        congratulations: 'Gefeliciteerd!',
        passed: 'Je hebt de {0} gehaald!',
        failed: 'Helaas! Je hebt de {0} niet gehaald!',
        pointsResult: 'Je hebt {0} van de {1} punten gescoord',
        pointsResultShort: '{0} van {1} punten',
        QuestionsResult: 'Je hebt {0} van de {1} vragen goed beantwoord',
        completedProgressPercentage: '{0}% afgerond',
        showResults: 'Resultaten inzien',
      },
      blocks: {
        choice: {
          defaultCorrectAnswerCommentTitle: 'Juist!',
          defaultCorrectAnswerCommentDescription: '<p>Je gegeven antwoord is goed. Ga zo door!</p>',
          defaultIncorrectAnswerCommentTitle: 'Helaas!',
          defaultIncorrectAnswerCommentDescription: '<p>Je gegeven antwoord klopt niet. Bekijk hierboven het juiste antwoord.</p>',
          noGivenAnswerTitle: 'Beantwoord de vraag om verder te gaan.',
          noGivenAnswerDescription: 'Je hebt de vraag nog niet beantwoord. Beantwoord de vraag om verder te gaan.',
        },
        video: {
          watchVideoToContinueError: {
            title: 'Bekijk de video om verder te gaan.',
            description: 'Je hebt de video nog niet bekeken. Bekijk de video om verder te gaan.',
          },
          watchVideoFurtherToContinueError: {
            title: 'Bekijk de hele video om verder te gaan.',
            description: 'Je hebt de video nog niet afgekeken. Bekijk de hele video om verder te gaan.',
          },
        },
        openQuestion: {
          fillInTheAnswer: 'Vul hier je antwoord in...',
        },
      },
      enrollmentResults: {
        progressOverview: {
          status: 'Status',
          score: 'Score',
          requiredScore: 'Benodigde score',
          scoringMethods: {
            exam: 'Op basis van toets(en) tijdens de training',
            sectionQuestions: 'Op basis van vragen tijdens de training',
          },
        },
        examResult: {
          courseTypeTestTitle: 'Vragen tijdens de toets',
        },
      },
    },
  },
  myDocuments: {
    noDocuments: '',
    mustSign: 'Nog ondertekenen',
    signDocument: 'Document ondertekenen',
    certificate: 'certificaat | certificaten',
    document: 'document | documenten',
    course: 'training | trainingen',
    noDocumentsFound: 'Er zijn geen {documentType} gevonden',
    loadingDocuments: 'Bezig met ophalen van de certificaten en documenten',
    expireDate: 'Verloopdatum',
    willExpireAt: 'Verloopt op:',
    expiredAt: 'Verlopen op:',
    download: 'Downloaden',
    signedToast: {
      title: 'Document ondertekend',
      description: 'Het document is succesvol ondertekend.',
    },
    downloadDocumentErrorToast: {
      title: 'Bezig met verwerken',
      description: 'De ondertekening van het document wordt op dit moment verwerkt. Dit kan even duren.',
    },
    documentSignModal: {
      title: 'Document tekenen',
      submit: 'Indienen',
      signature: {
        label: 'Zet handtekening',
      },
      documentDownloadError: 'Bekijk eerst het document om te kunnen tekenen.',
    },
  },
  enrollment: {
    status: {
      open: 'Toegewezen',
      inProgress: 'Actief',
      done: 'Afgerond',
    },
  },
  errorPages: {
    notFound: {
      heading: 'Oeps!',
      description: 'Sorry, we kunnen de pagina die je zoekt niet meer vinden. Misschien bestaat de pagina niet meer, of is deze verhuisd. Maar geen zorgen, je bent zo weer op de goede weg.',
      goBack: 'Terug',
      backToDashboardPage: 'Naar het startoverzicht',
    },
  },
  name: 'naam',
  phone: 'telefoon',
  roles: {
    administrator: 'beheerder | beheerders',
    groupLeader: 'beheerder | beheerders',
    trainer: 'beheerder | beheerders',
    student: 'cursist | cursisten',
    trainee: 'medewerker | medewerkers',
    user: 'gebruiker | gebruikers',
  },
  companies: 'Bedrijven',
  pagination: {
    metaResults: 'Weergave {from} tot {to} van {total} resultaten',
  },
  profile: {
    title: 'Persoonlijk profiel',
    navigation: {
      preferences: 'Voorkeuren',
    },
    loadingUser: 'Bezig met ophalen van de gebruiker.',
    loadingPreferences: 'Bezig met ophalen van de voorkeuren.',
    save: 'Opslaan',
    preferences: {
      notifications: {
        title: 'Notificaties',
        description: 'Ontvang notificaties via e-mail.',
        options: {
          0: 'Geen notificaties ontvangen',
          1: 'Wekelijks',
        },
      },
      toastSuccess: {
        title: 'Voorkeuren opgeslagen',
        description: 'De voorkeuren zijn succesvol opgeslagen.',
      },
    },
  },
  lms: {
    title: 'LMS',
    users: {
      list: {
        subCompany: 'Subomgeving',
      },
      form: {
        firstName: {
          label: 'Naam {0}',
          placeholder: 'Voornaam',
        },
        middleName: {
          placeholder: 'Tussenvoegsel',
        },
        lastName: {
          placeholder: 'Achternaam',
        },
        email: {
          label: 'E-mailadres',
          placeholder: 'E-mail invoeren',
        },
        phone: {
          label: 'Telefoonnummer',
          placeholder: 'Telefoonnummer invoeren',
        },
        signature: {
          label: 'Handtekening',
        },
        companySelect: {
          type: {
            company: 'bedrijf',
            subCompany: 'subomgeving',
          },
          placeholder: '{type} selecteren',
          editEmptyPlaceholder: 'Deze {traineeRole} is niet gekoppeld aan een {type}',
        },
        onboarding: {
          label: 'Onboardingstraject',
          placeholder: 'Onboardingstraject selecteren',
        },
      },
      addNewRole: 'Nieuwe {0} uitnodigen',
      addNewRoleTitle: '{0} uitnodigen',
      editRoleTitle: '{0} bewerken',
      deleteRoleTitle: '{0} verwijderen?',
      deleteRoleContent: 'Weet je zeker dat je {0} wilt verwijderen? Na het verwijderen heb je geen toegang meer tot de gegevens en kan deze {1} niet meer inloggen.',
      deleteRoleConfirmButtonText: 'Ja, {0} verwijderen',
      loadingUsers: 'Bezig met het ophalen van de {0}',
      noUsersFound: 'Er zijn geen {0} gevonden.',
      noUsersFoundFiltersEnabled: 'Er zijn geen {0} gevonden binnen uw zoekopdracht.',
      accountNotActivated: 'Account nog niet geactiveerd',
      noEmailAddress: 'Vul het e-mailadres in om een account aan te maken',
      createNewUserLabelName: 'Naam {0}',
      invite: 'Uitnodigen',
      save: 'Wijzigen',
      inviteAndNew: 'Uitnodigen + nieuwe {0}',
      notifications: {
        userCreatedSuccess: {
          title: '{0} uitgenodigd!',
          description: 'De {0} heeft een mail ontvangen om het account te activeren.',
        },
        userCreatedNoEmail: {
          title: '{0} aangemaakt',
          description: 'De {0} is succesvol aangemaakt.',
        },
        userDeletedSuccess: {
          title: '{0} verwijderd',
          description: 'Het account van {0} is succesvol verwijderd.',
        },
        userDeletedError: {
          title: '{0} niet verwijderd',
          description: 'Het account van {0} is niet verwijderd.',
        },
        userUpdatedSuccess: {
          title: '{0} gewijzigd',
          description: 'De gegevens van {0} zijn succesvol gewijzigd.',
        },
        userDeletedAt: {
          title: 'Gebruiker verwijderd',
          description: 'Deze {role} is op {date} verwijderd. De {role} heeft geen toegang meer tot het account.',
        },
        listCompaniesError: {
          title: 'Ophalen mislukt',
          description: 'Het ophalen van bedrijven is mislukt.',
        },
        listOnboardingError: {
          title: 'Ophalen mislukt',
          description: 'Het ophalen van onboardingstrajecten is mislukt.',
        },
        enrollmentDeletedError: {
          title: 'Verwijderen niet mogelijk',
          description: 'De {0} is op {1} verwijderd. De {2} heeft geen toegang meer tot de {0}.',
        },
        enrollmentRevokedAccessError: {
          title: 'Verwijderen niet mogelijk',
          description: 'De toegang tot de {0} is op {1} verwijderd. De {2} heeft geen toegang meer tot de {0}.',
        },
        enrollmentDeleteSuccess: {
          title: 'Toegang tot de {0} verwijderd',
          description: 'De toegang tot de {0} is succesvol verwijderd.',
        },
        courseGroupDeletedError: {
          title: 'Verwijderen niet mogelijk',
          description: 'Het onboardingstraject is op {0} verwijderd. De {1} heeft geen toegang meer tot de onderdelen van dit onboardingstraject.',
        },
        courseGroupRevokedAccessError: {
          title: 'Verwijderen niet mogelijk',
          description: 'De toegang tot het onboardingstraject is op {0} verwijderd. De {1} heeft geen toegang meer tot de onderdelen van dit onboardingstraject.',
        },
        courseGroupCourseDeletedError: {
          title: 'Verwijderen niet mogelijk',
          description: 'Het onboardingstraject is op {0} verwijderd. De {1} heeft geen toegang meer tot dit onderdeel van het onboardingstraject.',
        },
        courseGroupCourseRevokedAccessError: {
          title: 'Verwijderen niet mogelijk',
          description: 'De toegang tot het onboardingstraject is op {0} verwijderd. De {1} heeft geen toegang meer tot dit onderdeel van het onboardingstraject.',
        },
        courseGroupDeleteSuccess: {
          title: 'Toegang tot het onboardingstraject verwijderd',
          description: 'De toegang tot het onboardingstraject is succesvol verwijderd. ',
        },
      },
      enrollments: {
        documents: 'Certificaten en documenten',
        enrollments: 'online trainingen',
        trainings: 'praktijktrainingen',
        assignNewCourse: 'Nieuwe training toewijzen',
        trainingName: 'Trainingsnaam',
        category: 'Categorie',
        status: 'Status',
        dates: 'Datum(s)',
        passed: 'Gehaald',
        failed: 'Niet gehaald',
        active: 'Actief',
        assigned: 'Toegewezen',
        loadingEnrollments: 'Bezig met het ophalen van de online trainingen',
        loadingTrainings: 'Bezig met het ophalen van de praktijktrainingen',
        completed: 'Afgerond',
        noEnrollmentsAssigned: 'Er zijn geen online trainingen toegewezen.',
        noEnrollmentGroupsAssigned: 'Er zijn geen online trainingen toegewezen.',
        noTrainingsAssigned: 'Er zijn geen praktijktrainingen toegewezen.',
        noEnrollmentsFound: 'Er zijn geen online trainingen gevonden.',
        noEnrollmentGroupsFound: 'Er zijn geen online trainingen gevonden.',
        noTrainingsFound: 'Er zijn geen praktijktrainingen gevonden.',
        allGroupsSelected: 'Alle {userType} in de groepen hebben {courseTypeWithPrefix} toegewezen.',
        allUsersSelected: 'Alle {userType} hebben {courseTypeWithPrefix} toegewezen.',
        delete: 'Verwijderen',
        deleted: 'Verwijderd',
        filterOptions: {
          showAll: 'Toon alles',
          open: 'Toegewezen',
          inProgress: 'Actief',
          done: 'Afgerond',
        },
        confirm: {
          deleteEnrollment: {
            title: 'Toegang tot {0} verwijderen?',
            content: 'Weet je zeker dat je de toegang wilt verwijderen? Na het verwijderen heeft de {0} geen toegang meer tot de {1}.',
            cancelButtonText: 'Nee, annuleren',
            confirmButtonText: 'Ja, toegang verwijderen',
          },
        },
      },
      courseGroups: {
        delete: 'Verwijderen',
        deleted: 'Verwijderd',
        confirm: {
          deleteCourseGroup: {
            title: 'Toegang tot het onboardingstraject verwijderen?',
            content: 'Weet je zeker dat je de toegang wilt verwijderen? Na het verwijderen heeft de {0} geen toegang meer tot de inhoud van het onboardingstraject.',
            cancelButtonText: 'Nee, annuleren',
            confirmButtonText: 'Ja, toegang verwijderen',
          },
        },
      },
      enrollment: {
        loadingEnrollment: 'Bezig met het ophalen van de training',
      },
      documents: {
        certificate: 'certificaat | certificaten',
        document: 'document | documenten',
        noDocumentsFound: 'Er zijn geen {documentType} gevonden',
        loadingDocuments: 'Bezig met ophalen van de certificaten en documenten',
        expireDate: 'Verloopdatum',
        willExpireAt: 'Verloopt op:',
        expiredAt: 'Verlopen op:',
        reminder: 'Reminder',
        action: 'Actie',
        userMustSign: '{role} moet het document nog ondertekenen',
        download: 'Downloaden',
        create: {
          pageTitle: '{documentType} toevoegen',
          addButtonText: '{documentType} toevoegen',
          documentName: '{documentType}naam',
          fileInputLabel: '{documentType} toevoegen',
          mustBeSigned: 'Document dient ondertekend te worden',
          expiredDate: 'Verloopdatum',
          reminderDate: 'Reminderdatum',
          backButtonText: 'Terug',
          submitButtonText: '{documentType} toevoegen',
          toastSuccessTitle: '{documentType} toegevoegd',
          toastSuccessDescription: 'Het {documentType} is succesvol toegevoegd.',
        },
        edit: {
          buttonText: 'Wijzigen',
          cannotEditDocument: 'Wijzigen niet mogelijk',
          cannotEditSignedDocument: 'Het document kan niet worden gewijzigd omdat deze door de {traineeRole} is ondertekend.',
          cannotEditEnrollmentDocument: 'Het document kan niet worden gewijzigd omdat deze vanuit de bibliotheek is toegevoegd.',
          pageTitle: '{documentType} wijzigen',
          documentName: '{documentType}naam',
          fileInputLabel: '{documentType} toevoegen',
          mustBeSigned: 'Document dient ondertekend te worden',
          expiredDate: 'Verloopdatum',
          reminderDate: 'Reminderdatum',
          backButtonText: 'Terug',
          submitButtonText: '{documentType} wijzigen',
          toastSuccessTitle: '{documentType} gewijzigd',
          loaderText: 'Bezig met het ophalen van het document',
          toastSuccessDescription: 'Het {documentType} is succesvol gewijzigd.',
        },
        delete: {
          buttonText: 'Verwijderen',
          confirm: {
            title: '{documentType} verwijderen?',
            content: 'Weet je zeker dat je dit {documentType} wilt verwijderen? Hierna hebben de {traineeType}, andere beheerders en jij geen toegang meer tot het {documentType} en gaan bijbehorende gegevens verloren.',
            confirmButtonText: 'Ja, {documentType} verwijderen',
            cancelButtonText: 'Nee, annuleren',
          },
          successToast: {
            title: '{documentType} verwijderd',
            description: 'Het {documentType} is succesvol verwijderd.',
          },
        },
      },
      noEmailConfirm: {
        title: 'Geen account maken voor {name}?',
        content: 'Je hebt geen e-mailadres ingevoerd. Weet je zeker dat je door wilt gaan? Hierdoor kunnen er geen digitale trainingen worden toegewezen en kan {name} zijn/haar eigen gegevens niet terugvinden.',
        confirmButtonText: 'Ja, doorgaan',
        cancelButtonText: 'Nee, e-mailadres invoeren',
      },
      trainings: {
        filterOptions: {
          showAll: 'Toon alles',
          assigned: 'Toegewezen',
          present: 'Aanwezig',
          absent: 'Afwezig',
        },
      },
    },
    groups: {
      groups: 'groep | groepen',
      searchFor: 'Zoeken naar groepen...',
      addNewGroup: 'Nieuwe groep maken',
      newGroup: 'Groep maken',
      editGroup: 'Groep wijzigen',
      createGroup: 'Groep aanmaken',
      loadingGroup: 'Bezig met het ophalen van de groep',
      loadingGroups: 'Bezig met het ophalen van de groepen',
      groupCount: 'aantal {0}',
      noGroupsFound: 'Er zijn geen groepen gevonden.',
      noGroupsFoundFiltersEnabled: 'Er zijn geen groepen gevonden binnen uw zoekopdracht.',
      deleteGroupConfirmTitle: 'Groep verwijderen?',
      deleteGroupConfirmContent: 'Weet je zeker dat je de groep "{groupName}" wilt verwijderen? Na het verwijderen heb je geen toegang meer tot de groep. De {usersRoleTitle} blijven bestaan. ',
      deleteGroupCancelButtonText: 'Nee, annuleren',
      deleteGroupConfirmButtonText: 'Ja, groep verwijderen',
      enrollmentsProgressDoneText: '{amountDone} van de {totalAmount} afgerond',
      enrollmentsProgressDoneMobileText: '{amountDone} van de {totalAmount}',
      enrollmentInGroup: 'Bevat {itemAmount} {itemType}',
      component: 'Onderdeel | Onderdelen',
      form: {
        groupName: 'Groepsnaam',
      },
      notifications: {
        groupCreatedSuccess: {
          title: 'Groep aangemaakt',
          description: 'De groep is succesvol aangemaakt.',
        },
        groupUpdatedSuccess: {
          title: 'Groep gewijzigd',
          description: 'De groep is succesvol gewijzigd.',
        },
        groupDeletedSuccess: {
          title: 'Groep verwijderd',
          description: 'De groep is succesvol verwijderd.',
        },
        groupDeletedError: {
          title: 'Groep niet verwijderd',
          description: 'Er is iets misgegaan bij het verwijderen van de groep.',
        },
      },
    },
    cockpit: {
      types: {
        course: 'training | trainingen',
        training: 'training | trainingen',
        document: 'document | documenten',
      },
      noAccessOverlay: {
        title: 'Je hebt nog geen toegang tot deze functionaliteit. Maar geen zorgen!',
        description: 'Wil jij alle informatie van jouw {traineeRole} in één oogopslag kunnen zien? Zie direct de status van trainingen, certificaten en (ondertekende) documenten. Iedere beheerder binnen {companyName} kan makkelijk en snel een eigen dashboard samenstellen. Kortom, maak een einde aan de papieren stapel en Excel lijstjes.',
        book: {
          title: 'Boek een demo',
          description: 'Benieuwd hoeveel tijd jij kan besparen met het dashboard? Boek een demo bij KENNISS.',
        },
      },
      targetTypeNames: {
        document: 'Document',
        training: 'Praktijktraining',
        certificate: 'Certificaat',
        skill: 'Vaardigheidstraining',
        microlearning: 'Microlearning',
        elearning: 'E-learning',
        test: 'Losse toets',
        video: 'Video',
      },
      itemStatuses: {
        viewed: 'Bekeken',
        signed: 'Ondertekend',
        assigned: 'Toegewezen',
        unassigned: 'Niet toegewezen',
        completed: 'Afgerond',
        passed: 'Gehaald',
        failed: 'Niet gehaald',
        active: 'Actief',
        owned: 'In bezit',
        notOwned: 'Niet in bezit',
        partiallyPresent: 'Gedeeltelijk aanwezig',
        present: 'Aanwezig',
        absent: 'Afwezig',
      },
      list: {
        menu: {
          title: 'Dashboard',
        },
        table: {
          addUsers: '{userType} toevoegen',
          addCourses: 'Online trainingen toevoegen',
          addTrainings: 'Praktijktrainingen toevoegen',
          addDocuments: 'Documenten toevoegen',
          addCertificates: 'Certificaten toevoegen',
          emptyStateText: 'Start met het samenstellen van je persoonlijke dashboard.',
          loaderText: 'Bezig met het ophalen van de inhoud. Dit kan afhankelijk van de hoeveelheid even duren.',
        },
      },
      assignUsersModal: {
        users: {
          select: 'Alle {userType} selecteren',
          deselect: 'Alle {userType} deselecteren',
          allUsersSelected: 'Alle {userType} zijn aan het dashboard toegevoegd.',
        },
        groups: {
          select: 'Alle groepen selecteren',
          deselect: 'Alle groepen deselecteren',
          allUsersSelected: 'Alle {userType} in de groepen zijn aan het dashboard toegevoegd.',
        },
      },
      assignCoursesModal: {
        allCoursesSelected: 'Alle trainingen zijn aan het dashboard toegevoegd.',
      },
      assignTrainingsModal: {
        allTrainingsSelected: 'Alle trainingen zijn aan het dashboard toegevoegd.',
      },
      assignDocumentsModal: {
        allTargetsSelected: 'Alle {documentType} zijn aan het dashboard toegevoegd.',
      },
      smallScreenDialogTexts: {
        title: 'Gebruik een ander device om in het dashboard aan de slag te gaan',
        description: 'We willen jou de beste gebruikerservaring bieden. Het dashboard is daarom alleen te gebruiken op een computer, laptop of tablet met een minimale breedte van 1024px.',
        rotateTitle: 'Draai je scherm om in het dashboard aan de slag te gaan',
        rotateDescription: 'We willen jou de beste gebruikerservaring bieden. Draai daarom je scherm horizontaal om in het dashboard aan de slag te gaan.',
      },
    },
    documents: {
      notifications: {
        userDeleted: {
          title: '{action} niet mogelijk',
          description: 'Het {documentType} kan niet worden {action} omdat de medewerker is verwijderd.',
        },
      },
    },
  },
  onboarding: {
    title: 'Onboarding',
    noAccessOverlay: {
      description: 'Geef nieuwe medewerkers een warm welkom met een onboardingstraject die je helemaal zelf kunt samenstellen. Laat nieuwe medewerkers alvast kennis maken met {companyName} door het bedrijfshandboek te delen en bespaar tijd door ze alvast documenten te laten ondertekenen.',
      book: {
        description: 'Benieuwd naar de mogelijkheden van onboarding binnen {companyName}? Boek een demo bij KENNISS.',
      },
    },
    searchPlaceholder: 'Zoeken naar onboardingstrajecten',
    numberOfItems: 'Aantal onderdelen',
    containsItems: 'Bevat 0 onderdelen | Bevat 1 onderdeel | Bevat {count} onderdelen',
    loading: 'Bezig met het ophalen van de onboardingstrajecten',
    noOnboardingFound: 'Er zijn geen onboardingstrajecten gevonden.',
    deleteOnboardingConfirm: {
      title: 'Onboardingstraject verwijderen?',
      content: 'Weet je zeker dat je het onboardingstraject "{groupName}" wilt verwijderen? Na het verwijderen hebben medewerkers met dit toegewezen onboardingstraject geen toegang meer tot de inhoud.',
      cancelButtonText: 'Nee, annuleren',
      confirmButtonText: 'Ja, onboarding verwijderen',
    },
    deletedOnboardingToast: {
      title: 'Onboardingstraject verwijderd',
      description: 'Het onboardingstraject is succesvol verwijderd. Medewerkers hebben geen toegang meer tot de inhoud.',
    },
    create: {
      createButtonText: 'Nieuw onboardingstraject aanmaken',
      title: 'Onboardingstraject maken',
      form: {
        generalInformation: 'Algemene informatie',
        name: 'Naam onboardingstraject',
        description: 'Beschrijving',
        courses: 'Onderdelen',
        orderExplanation: 'Sleep de onderdelen van het onboardingstraject in de gewenste volgorde. De medewerker is niet verplicht zich aan deze volgorde te houden.',
        addCourses: 'Onderdelen toevoegen',
        noCoursesAdded: 'Er zijn nog geen onderdelen toegevoegd.',
        modal: {
          add: 'Toevoegen | Toevoegen ({count}) | Toevoegen ({count})',
          close: 'Sluiten',
        },
      },
      successToast: {
        title: 'Onboardingstraject aangemaakt',
        description: 'Het onboardingstraject is succesvol aangemaakt',
      },
      footer: {
        backButtonText: 'Terug',
        submitButtonText: 'Onboardingstraject aanmaken',
      },
    },
    show: {
      title: 'Trainingen',
      courses: {
        title: 'Titel',
        courseType: 'Categorie',
        show: 'Bekijken',
      },
      loadingText: 'Bezig met het ophalen van de onboarding',
      emptyStateMessage: 'Dit onboardingstraject bevat geen onderdelen.',
    },
    update: {
      title: 'Onboardingstraject wijzigen',
      loading: 'Bezig met het ophalen van het onboardingstraject',
      confirm: {
        title: 'Onboardingstraject wijzigen?',
        content: 'Weet je zeker dat je de inhoud van dit onboardingstraject wilt aanpassen? Voor medewerkers waar dit onboardingstraject aan is toegewezen wijzigt de inhoud niet. De wijzigingen zijn alleen zichtbaar voor nieuwe toewijzingen.',
        cancelButtonText: 'Nee, annuleren',
        confirmButtonText: 'Ja, onboarding wijzigen',
      },
      successToast: {
        title: 'Onboardingstraject gewijzigd',
        description: 'Het onboardingstraject is succesvol gewijzigd.',
      },
      footer: {
        backButtonText: 'Terug',
        submitButtonText: 'Wijzigen',
      },
    },
  },
  assignUsersModal: {
    createUsers: {
      newUser: 'Nieuwe {0}',
    },
  },
};
