import { config, Credentials } from 'aws-sdk';

export default function updateAwsConfig(): void {
  config.update({
    region: process.env.VUE_APP_AWS_REGION,
    credentials: new Credentials({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY as string,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY as string,
    }),
  });
}
