
  /* eslint-disable @typescript-eslint/naming-convention */
  import { defineComponent } from 'vue';
  import TinyMceEditor from '@tinymce/tinymce-vue';
  import { ExclamationCircleIcon } from '@heroicons/vue/solid';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { Editor } from 'tinymce';
  import type { DomClassesProp } from '@/typings/class-style-binding';

  export default defineComponent({
    name: 'AppRichText',

    components: {
      TinyMceEditor,
      ExclamationCircleIcon,
    },

    props: {
      modelValue: {
        type: String as PropType<string>,
        default: '',
      },

      labelText: {
        type: String as PropType<string>,
        default: null,
      },

      labelIsHidden: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      cornerHint: {
        type: String as PropType<string>,
        default: null,
      },

      leadingHelpText: {
        type: String as PropType<string>,
        default: null,
      },

      trailingHelpText: {
        type: String as PropType<string>,
        default: null,
      },

      hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      errorMessage: {
        type: String as PropType<string>,
        default: null,
      },

      extendedRichTextDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedRichTextWrapperDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedLabelDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      placeholder: {
        type: String as PropType<string>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      readonly: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    emits: [
      'update:modelValue',
      'blur',
    ],

    data() {
      return {
        content: '',
        apiKey: process.env.VUE_APP_TINYMCE_API_KEY,
        editor: null as Editor | null,
        tinyMCEInit: {
          setup: this.onEditorSetup,
          placeholder: this.placeholder,
          menubar: false,
          statusbar: false,
          inline: true,
          plugins: 'lists advlist autolink',
          toolbar: [
            'bold',
            'italic',
            'underline',
            'bullist',
            'numlist',
          ].join(' '),
          fixed_toolbar_container: '',
          toolbar_persist: true,
          toolbar_sticky: true,
          paste_as_text: true,
          paste_data_images: false,
          paste_block_drop: true,
          link_default_target: '_blank',
        },
      };
    },

    computed: {
      richTextId(): string {
        return `app-rich-text-${this.componentId}`;
      },

      toolbarId(): string {
        return `app-rich-text-toolbar-${this.componentId}`;
      },

      errorMessageId(): string {
        return `${this.richTextId}-error-message`;
      },

      richTextWrapperDomClasses(): string[] {
        let domClasses: string[] = [];

        if (this.hasError) {
          domClasses = [
            '!border-red-500',
            'focus-within:!ring-red-500',
          ];
        }

        if (this.labelText && !this.labelIsHidden) {
          domClasses.push('mt-1');
        }

        if (this.extendedRichTextWrapperDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedRichTextWrapperDomClasses,
          ));
        }

        return domClasses;
      },

      richTextDomClasses(): string[] {
        const domClasses = [
          'tinymce-editor',
          'mce-content-body',
          'mce-edit-focus',
        ];

        if (this.hasError) {
          domClasses.push('placeholder-red-500');
        }

        if (this.extendedRichTextDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedRichTextDomClasses,
          ));
        }

        return domClasses;
      },

      dynamicLabelDomClasses(): string[] {
        const domClasses = [];

        if (this.labelIsHidden) {
          domClasses.push('sr-only');
        }

        if (this.extendedLabelDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedLabelDomClasses,
          ));
        }

        return domClasses;
      },
    },

    watch: {
      modelValue(): void {
        if (this.modelValue !== this.content) {
          this.content = this.modelValue;

          if (this.editor) {
            this.editor.setContent(this.content);
          }
        }
      },

      content(): void {
        if (this.modelValue !== this.content) {
          this.$emit('update:modelValue', this.content);
        }
      },
    },

    created() {
      this.content = this.modelValue;
      this.tinyMCEInit.fixed_toolbar_container = `#${this.toolbarId}`;
    },

    methods: {
      onEditorSetup(editor: Editor): void {
        this.editor = editor;

        editor.on('change input undo redo', (): void => {
          this.content = editor.getContent();
        });

        editor.on('blur', (): void => {
          this.$emit('blur');
        });
      },

      focus(): void {
        this.editor?.focus();

        const tinyMceContainer = this.$refs.tinyMceContainer as HTMLDivElement;

        tinyMceContainer?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      },
    },
  });
