import { defineComponent } from 'vue';
import { mapGettersTyped } from '@/store/helpers';

import type { RouteLocationRaw } from 'vue-router';
import type { RootGettersMapped } from '@/typings/vuex';

let componentId = 0;

export default defineComponent({
  data() {
    componentId += 1;

    return {
      componentId,
    };
  },

  computed: {
    ...mapGettersTyped<RootGettersMapped>([
      'userHasRole',
      'userHasAnyRole',
      'userHasPermission',
      'isTrainer',
    ]),

    dashboardRoute(): RouteLocationRaw {
      if (this.userHasRole('admin-kenniss') || this.userHasPermission('educator.author_tool.list')) {
        return { name: 'dashboard' };
      }

      if (this.userHasAnyRole(['sub-admin-group-leader', 'sub-admin-trainer'])) {
        return { name: 'lms.users.list' };
      }

      if (this.userHasPermission('training.my_trainings.list')) {
        return { name: 'my-trainings' };
      }

      return { name: 'page-not-found' };
    },
  },
});
