
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToObject } from '@/helpers';
  import CheckboxLabel from './CheckboxLabel.vue';

  import type { PropType } from 'vue';
  import type { DomClassesProp, DynamicDomClassesObject } from '@/typings/class-style-binding';
  import type { CheckboxPositions } from '.';

  export default defineComponent({
    name: 'AppCheckbox',

    components: {
      CheckboxLabel,
    },

    props: {
      modelValue: {
        type: Boolean as PropType<boolean>,
        default: null,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      checkboxPosition: {
        type: String as PropType<CheckboxPositions>,
        default: 'left',
        validator(position: string): boolean {
          return ['left', 'right'].includes(position);
        },
      },

      extendedInputDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      extendedLabelDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },

      hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },

    emits: [
      'update:modelValue',
    ],

    computed: {
      checkboxId(): string {
        return `app-checkbox-${this.componentId}`;
      },

      dynamicInputDomClasses(): DynamicDomClassesObject {
        const extendedDomClasses = this.extendedInputDomClasses ?? {};

        return {
          'cursor-pointer': !this.disabled,
          'cursor-not-allowed': this.disabled,
          'border-gray-400 text-blue focus:ring-blue': !this.hasError,
          'border-red-500 text-red-500 focus:ring-red-500': this.hasError,
          ...convertDomClassesPropToObject(extendedDomClasses),
        };
      },
    },

    methods: {
      select(withFocus = true): void {
        this.$emit('update:modelValue', !this.modelValue);

        if (withFocus) {
          (this.$refs.checkboxInput as HTMLInputElement)?.focus();
        }
      },
    },
  });
