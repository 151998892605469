import type { State } from './vuex';
import type { Section, SectionTree, Block } from '@/typings/LMS/section';
import type { BlockSubmitTriggeredBy, BlockTransition, BlockUserInput } from '@/views/Enrollments/WalkThrough';

export default {
  setSections(state: State, sections: Section[]): void {
    state.sections = sections;
  },

  setSectionTree(state: State, sectionTree: SectionTree): void {
    state.sectionTree = sectionTree;
  },

  setBlocks(state: State, blocks: Block[]): void {
    state.blocks = blocks;
  },

  setLoadingBlocks(state: State, loadingBlocks: boolean): void {
    state.loadingBlocks = loadingBlocks;
  },

  setBlockUserInput(state: State, blockUserInput: BlockUserInput): void {
    state.blockUserInput = blockUserInput;
  },

  setBlockSubmitting(state: State, blockSubmitting: boolean): void {
    state.blockSubmitting = blockSubmitting;
  },

  disableBlockSubmit(state: State, disabled: boolean): void {
    state.blockSubmittingDisabled = disabled;
  },

  setIsCheckAnswer(state: State, isCheckAnswer: boolean): void {
    state.isCheckAnswer = isCheckAnswer;
  },

  setBlockSubmitTriggeredBy(state: State, blockSubmitTriggeredBy: BlockSubmitTriggeredBy): void {
    state.blockSubmitTriggeredBy = blockSubmitTriggeredBy;
  },

  setBlockTransition(state: State, transitionName: BlockTransition): void {
    if (transitionName !== state.blockTransition) {
      state.blockTransition = transitionName;
    }
  },
};
