import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.domClassesPerSize[_ctx.size], "w-full bg-gray-400"])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(`width: ${_ctx.progress}%`),
      class: _normalizeClass([_ctx.extendedBarDomClasses, "h-full bg-primary min-w-[2px] transition-all duration-300 relative"]),
      "data-test-progress": ""
    }, null, 6)
  ], 2))
}