
  import { defineComponent } from 'vue';

  import type { PropType } from 'vue';

  export default defineComponent({
    name: 'AppPaginationItem',

    props: {
      as: {
        type: String as PropType<string>,
        default: 'a',
      },

      active: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },
  });
