import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInputBase = _resolveComponent("AppInputBase")!

  return (_openBlock(), _createBlock(_component_AppInputBase, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, { type: "email" }), _createSlots({ _: 2 }, [
    (_ctx.$slots.leadingAddon)
      ? {
          name: "leadingAddon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "leadingAddon")
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.$slots.trailingAddon)
      ? {
          name: "trailingAddon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "trailingAddon")
          ]),
          key: "1"
        }
      : undefined
  ]), 1040))
}