import { isEmpty, isPlainObject } from 'lodash';
import { http } from '@/plugins/http';
import api from './api';

import type { ActionContext } from 'vuex';
import type { RouteLocationRaw } from 'vue-router';
import type { Company } from '@/typings/company';
import type { State } from '@/typings/vuex';
import type { LoginPayload, ResetPasswordPayload, GetUserByPasswordResetTokenPayload } from '@/views/Auth';

export default {
  async getCurrentUser(context: ActionContext<State, State>): Promise<void> {
    const fetchedUser = await api.getCurrentUser();
    const data = fetchedUser?.data?.data ?? null;

    if (isPlainObject(data) && !isEmpty(data)) {
      const { onAdministrativeDomain = false } = data;

      context.commit('setUser', data);
      context.commit('setOnAdministrativeDomain', onAdministrativeDomain);
    } else {
      context.commit('setUser', null);
      context.commit('setOnAdministrativeDomain', false);
    }
  },

  async login(context: ActionContext<State, State>, payload: LoginPayload | string): Promise<void> {
    await api.login(payload);
    await context.dispatch('getCurrentUser');
  },

  async logout(context: ActionContext<State, State>): Promise<void> {
    try {
      await api.logout();
    } catch {
      //
    }

    context.commit('setUser', null);
    context.commit('setOnAdministrativeDomain', false);
    http.clearCache();

    try {
      // Fetch the current user, so we get a valid CSRF token
      api.getCurrentUser();
    } catch {
      //
    }
  },

  async sendPasswordResetEmail(
    context: ActionContext<State, State>,
    toEmail: string,
  ): ReturnType<typeof api.sendPasswordResetEmail> {
    return api.sendPasswordResetEmail(toEmail);
  },

  async getUserByPasswordResetToken(
    context: ActionContext<State, State>,
    payload: GetUserByPasswordResetTokenPayload,
  ): ReturnType<typeof api.getUserByPasswordResetToken> {
    return api.getUserByPasswordResetToken(payload);
  },

  async resetPassword(
    context: ActionContext<State, State>,
    payload: ResetPasswordPayload,
  ): ReturnType<typeof api.resetPassword> {
    return api.resetPassword(payload);
  },

  setCompany(context: ActionContext<State, State>, company: Company): void {
    context.commit('setCompany', company);
  },

  setGoBackRoute(context: ActionContext<State, State>, goBackRoute: RouteLocationRaw): void {
    context.commit('setGoBackRoute', goBackRoute);
  },

  updateSignDocumentsCount(context: ActionContext<State, State>, documentCount: number): void {
    context.commit('updateSignDocumentsCount', documentCount);
  },

  decreaseSignDocumentsCount(context: ActionContext<State, State>): void {
    const documentCount = context.state.user?.signDocumentsCount ?? 1;
    context.commit('updateSignDocumentsCount', documentCount - 1);
  },
};
