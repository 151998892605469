import { cloneDeep } from 'lodash';
import { clearCache } from '@/plugins/http';
import { generateSectionTree } from '@/helpers';
import api from './api';

import type { ActionContext } from 'vuex';
import type { State as TrainingWalkThroughState } from './vuex';
import type { SectionTree, Block } from '@/typings/LMS/section';
import type { State } from '@/typings/vuex';
import type {
  BlockUserInput,
  GetBlocksPayload,
  BlockSubmitTriggeredBy,
  UpdateProgressActionPayload,
  UpdateProgressPostResponse,
  BlockTransition,
} from '@/views/Enrollments/WalkThrough';

export default {
  async getSectionTree(
    context: ActionContext<TrainingWalkThroughState, State>,
    enrollmentId: number,
  ): Promise<SectionTree> {
    const sections = await api.getSections(enrollmentId);
    const sectionTree = generateSectionTree(sections);

    context.commit('setSections', sections);
    context.commit('setSectionTree', sectionTree);

    return sectionTree;
  },

  async getBlocks(
    context: ActionContext<TrainingWalkThroughState, State>,
    payload: GetBlocksPayload,
  ): Promise<Block[]> {
    context.commit('setLoadingBlocks', true);

    const blocks = await api.getBlocks(payload);

    context.commit('setBlocks', blocks);
    context.commit('setLoadingBlocks', false);

    return blocks;
  },

  preFetchBlocks(
    context: ActionContext<TrainingWalkThroughState, State>,
    payload: GetBlocksPayload,
  ): void {
    api.getBlocks(payload);
  },

  async updateProgress(
    context: ActionContext<TrainingWalkThroughState, State>,
    progressPayload: UpdateProgressActionPayload,
  ): Promise<UpdateProgressPostResponse> {
    context.commit('setBlockSubmitting', true);

    const enrollment = context.rootState.Enrollment?.enrollment;
    const isExam = enrollment?.course.courseTypeKey === 'test';
    const isCheckAnswer = isExam ? false : context.state.isCheckAnswer;

    const payload = { ...progressPayload, isCheckAnswer };

    const result = await api.updateProgress(payload);

    if (result?.enrollment) {
      context.commit('Enrollment/setEnrollment', result.enrollment, { root: true });
    }

    if (payload.blockUuid && result?.examQuestionResult) {
      const blocks = cloneDeep(context.state.blocks);
      const blockIndex = blocks.findIndex(block => {
        return block.uuid === payload.blockUuid;
      });
      const block = blocks[blockIndex];

      if (block.question) {
        block.question.examQuestionResult = result.examQuestionResult;
      }

      context.commit('setBlocks', blocks);

      clearCache(`enrollments/${payload.enrollmentId}/sections/${payload.sectionId}/blocks`);
    }

    clearCache(`enrollments/${payload.enrollmentId}`);
    clearCache('enrollments/me', true);

    context.commit('setBlockSubmitting', false);

    return result;
  },

  setBlockUserInput(
    context: ActionContext<TrainingWalkThroughState, State>,
    payload: BlockUserInput,
  ): void {
    context.commit('setBlockUserInput', payload);
  },

  disableBlockSubmit(
    context: ActionContext<TrainingWalkThroughState, State>,
    payload = true,
  ): void {
    context.commit('disableBlockSubmit', payload);
  },

  setBlockSubmitTriggeredBy(
    context: ActionContext<TrainingWalkThroughState, State>,
    blockSubmitTriggeredBy: BlockSubmitTriggeredBy,
  ): void {
    context.commit('setBlockSubmitTriggeredBy', blockSubmitTriggeredBy);
  },

  setIsCheckAnswer(
    context: ActionContext<TrainingWalkThroughState, State>,
    isCheckAnswer: boolean,
  ): void {
    context.commit('setIsCheckAnswer', isCheckAnswer);
  },

  setBlockTransition(context: ActionContext<State, State>, transitionName: BlockTransition): void {
    context.commit('setBlockTransition', transitionName);
  },
};
