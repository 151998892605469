
  import { defineComponent } from 'vue';
  import { XIcon as CloseIcon } from '@heroicons/vue/solid';
  import AppButton from '@/components/AppButton/AppButton.vue';
  import { getIconComponent } from '@/helpers';

  import type { PropType, RenderFunction } from 'vue';
  import type {
    Type,
    IconNameByType,
    IconDomClassesByType,
    ToastButton,
  } from './index.d';

  const iconNameByType: IconNameByType = {
    info: 'InformationCircleIcon',
    success: 'CheckCircleIcon',
    warning: 'ExclamationIcon',
    error: 'ExclamationCircleIcon',
  };
  const iconDomClassesByType: IconDomClassesByType = {
    info: ['text-blue-500'],
    success: ['text-green-500'],
    warning: ['text-yellow-500'],
    error: ['text-red-500'],
  };

  export default defineComponent({
    name: 'Toast',

    components: {
      AppButton,
      CloseIcon,
    },

    props: {
      type: {
        type: String as PropType<Type>,
        default: 'info',
        validator(this: void, type: Type): boolean {
          return ['info', 'success', 'warning', 'error'].includes(type);
        },
      },

      title: {
        type: String as PropType<string>,
        required: true,
      },

      description: {
        type: String as PropType<string>,
        default: null,
      },

      buttons: {
        type: Array as PropType<ToastButton[]>,
        default: null,
      },
    },

    emits: [
      'closeToast',
    ],

    computed: {
      iconComponent(): RenderFunction {
        return getIconComponent({
          name: iconNameByType[this.type],
          type: 'outline',
        });
      },

      iconDomClasses(): string[] {
        return iconDomClassesByType[this.type];
      },
    },

    methods: {
      buttonClick(button: ToastButton): void {
        if (typeof button.onClick === 'function') {
          const closeToast = button.onClick();

          if (closeToast) {
            this.$emit('closeToast');
          }
        } else {
          this.$emit('closeToast');
        }
      },
    },
  });
