import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  ref: "dialogChild",
  tabindex: "0",
  class: "fixed inset-0 top-[inherit] z-10 flex flex-col min-h-screen min-h-[-webkit-fill-available] max-h-full overflow-y-auto md:relative",
  "data-test-dialog-child": ""
}
const _hoisted_2 = { class: "sticky top-0 h-0 hidden md:block" }
const _hoisted_3 = { class: "flex flex-1 md:flex-0 my-auto bg-gray-100 md:bg-transparent align-items-center" }
const _hoisted_4 = { class: "sticky top-0 flex justify-between bg-gray-900 p-2 z-50 text-white md:hidden" }
const _hoisted_5 = { class: "flex justify-between md:justify-end w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_HeadlessUiDialog = _resolveComponent("HeadlessUiDialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.isOpen,
    unmount: _ctx.unmount
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeadlessUiDialog, {
        as: "div",
        static: _ctx.static,
        unmount: _ctx.unmount,
        class: "fixed inset-0 flex md:block z-60 text-gray-900",
        onClose: _ctx.close
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransitionChild, {
              as: "template",
              unmount: _ctx.unmount,
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-300",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_DialogOverlay, { class: "absolute inset-0 bottom-auto h-screen bg-gray-700 opacity-70 transition-opacity z-1" })
                ])
              ]),
              _: 1
            }, 8, ["unmount"]),
            _createVNode(_component_TransitionChild, {
              as: "template",
              unmount: _ctx.unmount,
              enter: _ctx.transitionProperties.enter,
              "enter-from": _ctx.transitionProperties.enterFrom,
              "enter-to": _ctx.transitionProperties.enterTo,
              leave: _ctx.transitionProperties.leave,
              "leave-from": _ctx.transitionProperties.leaveFrom,
              "leave-to": _ctx.transitionProperties.leaveTo
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.domClassesPerSize[_ctx.size], "relative flex flex-col mx-auto w-full focus:outline-none md:rounded-lg z-10 text-left shadow-xl transform transition-all md:my-8 md:overflow-hidden"]),
                    "data-test-dialog-content-wrapper": ""
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", {
                        class: "inline-flex items-center justify-center !p-2 text-gray-400 bg-black hover:text-white hover:bg-gray-700 focus:outline-none rounded ml-auto md:hidden",
                        "data-test-close-button": "",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                      }, [
                        _createVNode(_component_CloseIcon, {
                          class: "block h-6 w-6",
                          "aria-hidden": "true"
                        })
                      ])
                    ]),
                    (_ctx.$slots.header || _ctx.title)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass([_ctx.extendedHeaderDomClasses, "px-2 py-3 md:px-12 md:flex bg-white shadow-sm sticky top-0"]),
                          "data-test-header": ""
                        }, [
                          (_ctx.$slots.header)
                            ? _renderSlot(_ctx.$slots, "header", { key: 0 })
                            : (_ctx.title)
                              ? (_openBlock(), _createBlock(_component_DialogTitle, {
                                  key: 1,
                                  as: "h3",
                                  class: "text-lg font-semibold md:text-2xl md:font-bold"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, "headerAddition")
                        ], 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.extendedBodyDomClasses, "bg-gray-100 px-2 pt-3 pb-4 md:p-16 h-full"]),
                      "data-test-body": ""
                    }, [
                      _renderSlot(_ctx.$slots, "default")
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.extendedFooterDomClasses, "bg-white shadow-sm px-2 py-4 hidden md:flex w-full"]),
                      style: {"box-shadow":"0 -1px 2px 0 rgb(0 0 0 / 0.05)"},
                      "data-test-footer": ""
                    }, [
                      _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({ ..._ctx.$props, open: _ctx.open, close: _ctx.close })), () => [
                        _createElementVNode("div", _hoisted_5, [
                          (_ctx.withCloseButton)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 0,
                                disabled: _ctx.closeButtonDisabled,
                                size: "large",
                                type: "secondary",
                                class: "hidden md:flex",
                                "data-test-close-button": "",
                                onClick: _ctx.close
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.closeButtonText ?? _ctx.$t('close')), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled", "onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.withSubmitButton)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 1,
                                disabled: _ctx.submitButtonDisabled,
                                class: _normalizeClass([_ctx.extendedSubmitButtonDomClasses, "md:ml-4 w-full md:w-[28.25rem] !block"]),
                                size: "large",
                                type: "primary",
                                "data-test-submit-button": "",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit')))
                              }, _createSlots({
                                default: _withCtx(() => [
                                  _createTextVNode(" " + _toDisplayString(_ctx.submitButtonText ?? _ctx.$t('send')), 1)
                                ]),
                                _: 2
                              }, [
                                (_ctx.submitting)
                                  ? {
                                      name: "leadingIcon",
                                      fn: _withCtx(() => [
                                        _createVNode(_component_AppLoader, {
                                          type: "secondary",
                                          size: "xs",
                                          class: "relative top-1"
                                        })
                                      ]),
                                      key: "0"
                                    }
                                  : undefined
                              ]), 1032, ["disabled", "class"]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ], 2)
                  ], 2)
                ])
              ]),
              _: 3
            }, 8, ["unmount", "enter", "enter-from", "enter-to", "leave", "leave-from", "leave-to"])
          ], 512)
        ]),
        _: 3
      }, 8, ["static", "unmount", "onClose"])
    ]),
    _: 3
  }, 8, ["show", "unmount"]))
}