import api from './api';

import type { ActionContext } from 'vuex';
import type { Enrollment } from '@/views/Enrollments/Show';
import type { State as CourseState, CourseActionPayload } from './vuex.d';
import type { State } from '@/typings/vuex';
import type {
  Course,
  Version,
  CreateCoursePayload,
  UpdateCoursePayload,
} from '@/views/Courses/Create';

export default {
  async fetchCourse(
    context: ActionContext<CourseState, State>,
    payload: CourseActionPayload,
  ): Promise<Course> {
    const course = await api.fetchCourse(payload.courseId, payload.versionId, payload.forceFresh);

    context.commit('setCourse', course);

    return course;
  },

  async createCourse(
    context: ActionContext<CourseState, State>,
    payload: CreateCoursePayload,
  ): Promise<Course> {
    return api.createCourse(payload);
  },

  async updateCourse(
    context: ActionContext<CourseState, State>,
    payload: UpdateCoursePayload,
  ): Promise<Course> {
    const course = await api.updateCourse(payload);

    context.commit('setCourse', course);

    return course;
  },

  async createVersion(
    context: ActionContext<CourseState, State>,
    courseId: number,
  ): Promise<Version> {
    return api.createVersion(courseId);
  },

  async previewCourse(
    context: ActionContext<CourseState, State>,
    payload: CourseActionPayload,
  ): Promise<Enrollment> {
    return api.previewCourse(payload.courseId, payload.versionId);
  },

  async copyCourse(
    context: ActionContext<CourseState, State>,
    payload: CourseActionPayload,
  ): Promise<Course> {
    return api.copyCourse(payload.courseId, payload.versionId);
  },

  async previewLibraryCourse(
    context: ActionContext<CourseState, State>,
    courseId: number,
  ): Promise<Enrollment> {
    return api.previewLibraryCourse(courseId);
  },

  async hasVersionBlocks(
    context: ActionContext<CourseState, State>,
    payload: CourseActionPayload,
  ): Promise<boolean> {
    return api.hasVersionBlocks(payload);
  },
};
