import mitt from 'mitt';

import type { App } from 'vue';

export default {
  install: (app: App): void => {
    const emitter = mitt();

    app.config.globalProperties.$bus = emitter;
    app.provide('bus', emitter);
  },
};
