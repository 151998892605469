
  import { defineComponent } from 'vue';
  import { getIconComponent, convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { DynamicIcon } from '.';

  export default defineComponent({
    name: 'AppDynamicIcon',

    props: {
      icon: {
        type: Object as PropType<DynamicIcon>,
        required: true,
      },

      extendedDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    computed: {
      iconDomClasses(): string[] {
        const domClasses = ['h-5', 'w-5'];

        if (this.extendedDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(this.extendedDomClasses));
        }

        if (this.icon.extendedDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(this.icon.extendedDomClasses));
        }

        return domClasses;
      },
    },

    methods: {
      getIconComponent,
    },
  });
