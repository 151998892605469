import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overflow-hidden relative pt-[calc(100%/23*12)]" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "w-full absolute inset-0 flex items-center justify-center border-b border-gray-300",
  "data-test-course-type-icon": ""
}
const _hoisted_4 = {
  key: 2,
  class: "flex justify-center content-center w-full h-full absolute z-20 top-0 before:absolute before:w-full before:h-full before:bg-primary before:opacity-50",
  "data-test-check-icon": ""
}
const _hoisted_5 = {
  class: "p-3 md:p-4",
  "data-test-course-information-wrapper": ""
}
const _hoisted_6 = {
  class: "text-lg text-gray-900 font-medium",
  "data-test-course-name": ""
}
const _hoisted_7 = {
  class: "text-sm text-gray-700 mt-1",
  "data-test-company-name": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppCourseTypeIcon = _resolveComponent("AppCourseTypeIcon")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_AppProgressBar = _resolveComponent("AppProgressBar")!
  const _component_CourseTypeBadge = _resolveComponent("CourseTypeBadge")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: _ctx.routeTo,
    class: "bg-white rounded border border-gray-300 flex flex-col overflow-hidden cursor-pointer pointer-events-auto transition-all duration-500 hover:border-gray-400 hover:shadow-md group-hover:hover:opacity-100 transition-all ease-in-out group-hover:opacity-100 duration-500 hover:!duration-100 group-hover:hover:!duration-100"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.course.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.course.image,
              alt: _ctx.course.name,
              class: "w-full absolute inset-0",
              "data-test-course-image": ""
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_AppCourseTypeIcon, {
                "course-type": _ctx.course.courseTypeKey
              }, null, 8, ["course-type"])
            ])),
        (_ctx.isMarkedAsDone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_CheckIcon, { class: "check-icon h-20 w-20 text-white my-auto z-10" })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showProgressBar)
        ? (_openBlock(), _createBlock(_component_AppProgressBar, {
            key: 0,
            progress: _ctx.progressPercentage,
            class: "-mt-2 z-10"
          }, null, 8, ["progress"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.course.name), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.course.company.name), 1),
        _createVNode(_component_CourseTypeBadge, {
          "course-type": _ctx.course.courseType ?? _ctx.course.courseTypes?.[0],
          class: "mt-2 mb-0"
        }, null, 8, ["course-type"])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}