import { isString, isPlainObject } from 'lodash';

import type { Component, PropType } from 'vue';
import type { DynamicIcon } from '@/components/AppDynamicIcon';
import type { DomClassesProp } from '@/typings/class-style-binding';
import type { Option, ModelValue } from '.';

export default {
  modelValue: {
    type: [
      String,
      Number,
      Boolean,
      Object,
      Array,
    ] as PropType<ModelValue | ModelValue[]>,
    default: null,
  },

  options: {
    type: Array as PropType<Option[]>,
    required: true,
    validator(this: void, options: Option[]): boolean {
      return options.every(option => isString(option))
        || options.every(option => isPlainObject(option));
    },
  },

  multiple: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  clearable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  optionLabelKey: {
    type: String as PropType<string>,
    default: null,
  },

  trackBy: {
    type: String as PropType<string>,
    default: null,
  },

  labelText: {
    type: String as PropType<string>,
    default: null,
  },

  placeholder: {
    type: String as PropType<string>,
    default: null,
  },

  valueLeadingIcon: {
    type: Object as PropType<DynamicIcon>,
    default: null,
  },

  selectedIcon: {
    type: Object as PropType<DynamicIcon>,
    default(this: void): DynamicIcon {
      return {
        name: 'CheckIcon',
        type: 'solid',
      };
    },
  },

  extendedMenuItemsDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedMenuItemDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedIconDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedLabelDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  extendedInputDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },

  renderSelectWrapperAs: {
    type: [String, Object] as PropType<string | Component>,
    default: 'div',
  },

  renderOptionsWrapperAs: {
    type: [String, Object] as PropType<string | Component>,
    default: 'ul',
  },

  renderOptionsAs: {
    type: [String, Object] as PropType<string | Component>,
    default: 'li',
  },

  hasError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  errorMessage: {
    type: String as PropType<string>,
    default: null,
  },
};
