import { http } from '@/plugins/http/index';

import type { Training, ExistingTrainingName } from './vuex';
import type { SelectedUsersAndGroups } from '@/views/CourseLibrary/Show';
import type { Training as StudentTraining } from '@/views/LMS/Users/Show/Trainings/index.d';

export default {
  async fetchTraining(trainingId: number, forceFresh = false): Promise<Training> {
    return http.fetch(`/trainings/${trainingId}`, undefined, undefined, undefined, forceFresh);
  },

  async fetchExistingTrainingNames(searchString?: string, forceFresh?: boolean): Promise<ExistingTrainingName[]> {
    const params = { sort: 'name', paginate: false, fields: 'id,name', filter: { queryString: searchString ?? null } };

    return http.fetch<ExistingTrainingName[]>('existing-trainings', params, { unique: true }, undefined, forceFresh);
  },

  async fetchTrainings(userId: number, searchString: string, forceFresh = false): Promise<StudentTraining[]> {
    return http.fetch(`/users/${userId}/trainings`, {
      sort: '-date',
      filter: { queryString: searchString },
    }, undefined, undefined, forceFresh);
  },

  async updateTrainingParticipants(trainingId: Training['id'], payload: SelectedUsersAndGroups): Promise<Training> {
    return http.patch<SelectedUsersAndGroups, Training>(`trainings/${trainingId}/participants`, payload);
  },
};
