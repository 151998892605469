
  import { defineComponent } from 'vue';
  import { AppInputBase } from '@/components';
  import inputMethods from '@/components/AppInputBase/methods-mixin';
  import { stringToLuxon, dateFormat } from '@/helpers';
  import { defaultInputDateFormat } from '.';
  import props from './props';

  import type { DateTime } from 'luxon';

  export default defineComponent({
    name: 'AppInputDate',

    components: {
      AppInputBase,
    },

    mixins: [
      inputMethods,
    ],

    inheritAttrs: false,

    props: {
      ...AppInputBase.props,
      ...props,
    },

    emits: AppInputBase.emits,

    computed: {
      inputBindings(): Record<string, unknown> {
        const castMinMaxValue = (value: string | DateTime): string => {
          return typeof value === 'string' ? value : dateFormat(value, defaultInputDateFormat);
        };

        return {
          min: this.min ? castMinMaxValue(this.min) : undefined,
          max: this.max ? castMinMaxValue(this.max) : undefined,
          step: this.step,
        };
      },

      modelValueStringCasted(): string | undefined {
        return this.modelValue ? dateFormat(this.modelValue, defaultInputDateFormat) : undefined;
      },
    },

    methods: {
      update(value: string): void {
        const date = value ? stringToLuxon(value, defaultInputDateFormat) : null;

        this.$emit('update:modelValue', date);
      },
    },
  });
