
  import { defineComponent } from 'vue';
  import { CheckCircleIcon, XCircleIcon as CircleCloseIcon } from '@heroicons/vue/solid';

  import type { PropType, Component } from 'vue';
  import type {
    Sizes,
    DomClassesPerSize,
    Status,
    DomClassesPerStatus,
  } from '.';

  const iconDomClassesPerSize: DomClassesPerSize = {
    small: ['h-4', 'w-4'],
    medium: ['h-5', 'w-5'],
    large: ['h-6', 'w-6'],
  };

  const wrapperDomClassesPerStatus: DomClassesPerStatus = {
    success: ['text-green-500'],
    error: ['text-red-500'],
    neutral: ['text-orange-500'],
  };

  export default defineComponent({
    name: 'AppStatusCircle',

    components: {
      CheckCircleIcon,
      CircleCloseIcon,
    },

    props: {
      size: {
        type: String as PropType<Sizes>,
        default: 'large',
        validator(this: void, size: string): boolean {
          return ['small', 'medium', 'large'].includes(size);
        },
      },

      status: {
        type: String as PropType<Status>,
        default: null,
      },
    },

    computed: {
      wrapperDomClasses(): string[] {
        return [
          ...wrapperDomClassesPerStatus[this.status],
        ];
      },

      iconDomClasses(): string[] {
        return [
          ...iconDomClassesPerSize[this.size],
        ];
      },

      iconComponent(): Component | null {
        if (this.status === 'success' || this.status === 'neutral') {
          return CheckCircleIcon;
        }

        if (this.status === 'error') {
          return CircleCloseIcon;
        }

        return null;
      },
    },
  });
