import type { PropType } from 'vue';
import type { DomClassesProp } from '@/typings/class-style-binding';

export default {
  labelText: {
    type: String as PropType<string>,
    default: null,
  },

  inputId: {
    type: String as PropType<string>,
    default: null,
  },

  name: {
    type: String as PropType<string>,
    default: null,
  },

  multiple: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  required: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  accept: {
    type: String as PropType<string>,
    default: null,
  },

  extensionsText: {
    type: String as PropType<string>,
    default: null,
  },

  errorMessage: {
    type: String as PropType<string>,
    default: null,
  },

  mustShowFileExtensionsError: {
    type: Boolean as PropType<boolean>,
    default: true,
  },

  extendedLabelDomClasses: {
    type: [String, Array, Object] as PropType<DomClassesProp>,
    default: null,
  },
};
