import { http } from '@/plugins/http';

import type { FetchParams } from '@/plugins/http/index.d';
import type { ListedCompany, GetCompaniesParams } from './vuex';

export default {
  async getCompanies(payload?: GetCompaniesParams): Promise<ListedCompany[]> {
    const { forceFresh = false, ...params } = payload ?? {};

    return http.fetch<ListedCompany[]>(
      'companies',
      params as FetchParams,
      undefined,
      undefined,
      forceFresh,
    );
  },
};
