import type { State } from './vuex';
import type { Enrollment } from '@/views/Enrollments/Show';
import type { UserEnrollment } from '@/typings/LMS/enrollment';

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  progressPercentage: (state: State) => (enrollment?: Enrollment | UserEnrollment): number => {
    if (enrollment) {
      return enrollment.progress?.percentage ?? 0;
    }

    if (state.enrollment) {
      return state.enrollment.progress?.percentage ?? 0;
    }

    return 0;
  },
};
