
  import { defineComponent } from 'vue';
  import { convertDomClassesPropToArray } from '@/helpers';

  import type { PropType } from 'vue';
  import type { DomClassesProp } from '@/typings/class-style-binding';
  import type { Sizes, DomClassesPerSize } from '.';

  const domClassesPerSize: DomClassesPerSize = {
    small: ['text-xs', 'px-1.5', 'py-px'],
    medium: ['text-xs', 'px-1.5', 'py-0.5'],
    large: ['text-sm', 'px-2.5', 'py-0.5'],
  };
  const indicatorDomClassesPerSize: DomClassesPerSize = {
    small: ['h-[.3125rem]', 'w-[.3125rem]'],
    medium: ['h-1.5', 'w-1.5'],
    large: ['h-1.5', 'w-1.5'],
  };

  export default defineComponent({
    name: 'AppBadge',

    props: {
      size: {
        type: String as PropType<Sizes>,
        default: 'large',
        validator(this: void, size: string): boolean {
          return ['small', 'medium', 'large'].includes(size);
        },
      },

      withIndicator: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      extendedIndicatorDomClasses: {
        type: [String, Array, Object] as PropType<DomClassesProp>,
        default: null,
      },
    },

    data() {
      return {
        domClassesPerSize,
      };
    },

    computed: {
      indicatorDomClasses(): string[] {
        const domClasses = [...indicatorDomClassesPerSize[this.size]];

        if (this.extendedIndicatorDomClasses) {
          domClasses.push(...convertDomClassesPropToArray(
            this.extendedIndicatorDomClasses,
          ));
        }

        return domClasses;
      },
    },
  });
