
  import localforage from 'localforage';
  import { defineComponent } from 'vue';
  import { TransitionRoot } from '@headlessui/vue';
  import CompanyLogo from '@/layouts/CompanyLogo.vue';
  import { AppLoader } from '@/components';

  import type { PropType } from 'vue';
  import type { PersistedItems } from '@/typings/vuex';
  import type { Company } from '@/typings/company';

  export default defineComponent({
    name: 'InitialLoad',

    components: {
      TransitionRoot,
      CompanyLogo,
      AppLoader,
    },

    props: {
      loaded: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
    },

    data() {
      return {
        company: null as Company | null,
      };
    },

    computed: {
      companyLogoHtml(): string | undefined {
        return this.company?.logoHtml?.dark ?? this.company?.logoHtml.light;
      },
    },

    async created() {
      const vuexPersistedState = await localforage.getItem<PersistedItems>('vuex');

      if (typeof vuexPersistedState === 'object' && vuexPersistedState?.company) {
        this.company = vuexPersistedState.company as Company;
      }
    },
  });
