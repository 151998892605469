import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconComponent)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass([_ctx.wrapperDomClasses, "inline-flex items-center rounded-full font-medium cursor-default"]),
        "data-test-status-circle-wrapper": ""
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
          class: _normalizeClass(_ctx.iconDomClasses),
          "data-test-status-circle-icon": ""
        }, null, 8, ["class"]))
      ], 2))
    : _createCommentVNode("", true)
}