
  import { mapState } from 'vuex';
  import { defineComponent } from 'vue';
  import WindowDimensionsMixin from '@/mixins/window-dimensions-mixin';

  import type { ScreenDimensionsDialogTexts } from '.';

  const disableKeyboardKeys = [
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'Backspace',
    'Delete',
    'Enter',
    'Escape',
    'Space',
    'Tab',
  ];

  export default defineComponent({
    name: 'ScreenDimensionsDialog',

    mixins: [
      WindowDimensionsMixin,
    ],

    computed: {
      ...mapState([
        'user',
      ]),

      potentiallyRotatable(): boolean {
        return this.windowOuterHeight > 1024;
      },

      dialogTexts(): ScreenDimensionsDialogTexts {
        const { smallScreenDialogTexts } = this.$route.meta;
        const {
          title,
          description,
          rotateTitle,
          rotateDescription,
        } = smallScreenDialogTexts ?? {};

        return {
          title: title ? this.$t(title) : '',
          description: description ? this.$t(description) : '',
          rotateTitle: rotateTitle ? this.$t(rotateTitle) : '',
          rotateDescription: rotateDescription ? this.$t(rotateDescription) : '',
        };
      },
    },

    mounted() {
      document.addEventListener('keydown', this.onKeyDown);
    },

    beforeUnmount() {
      document.removeEventListener('keydown', this.onKeyDown);
    },

    methods: {
      onKeyDown(event: KeyboardEvent): void {
        if (
          this.windowInnerWidth < 1024
          && disableKeyboardKeys.includes(event.code)
        ) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      },
    },
  });
