import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    class: _normalizeClass([{
      'bg-gray-100 border-gray-400 text-gray-700': _ctx.active,
      'bg-white border-gray-300 text-gray-500 hover:bg-gray-100': !_ctx.active,
      'cursor-not-allowed': _ctx.disabled,
    }, "item relative inline-flex items-center px-4 py-2 border text-sm font-medium"]),
    href: "#"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}