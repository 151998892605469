
  import { defineComponent } from 'vue';
  import { CheckIcon } from '@heroicons/vue/solid';
  import CourseTypeBadge from './CourseTypeBadge.vue';
  import { AppProgressBar, AppCourseTypeIcon } from '@/components';

  import type { PropType } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';
  import type { Course } from '@/typings/course';

  export default defineComponent({
    name: 'AppCourseTile',

    components: {
      AppCourseTypeIcon,
      CourseTypeBadge,
      AppProgressBar,
      CheckIcon,
    },

    props: {
      course: {
        type: Object as PropType<Course>,
        required: true,
      },

      routeTo: {
        type: [String, Object] as PropType<RouteLocationRaw>,
        required: true,
      },

      isMarkedAsDone: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      showProgressBar: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      progressPercentage: {
        type: Number as PropType<number>,
        default: null,
      },
    },
  });
