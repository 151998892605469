import { isEqual } from 'lodash';

import type { RouteLocationRaw } from 'vue-router';
import type { State } from '@/typings/vuex';
import type { Company } from '@/typings/company';
import type { User } from '@/typings/user';

export default {
  setUser(state: State, user: User): void {
    if (!isEqual(state.user, user)) {
      state.user = user;
    }
  },

  setOnAdministrativeDomain(state: State, onAdministrativeDomain: boolean): void {
    state.onAdministrativeDomain = onAdministrativeDomain;
  },

  setCompany(state: State, company: Company): void {
    if (!isEqual(state.company, company)) {
      state.company = company;
    }
  },

  updateSignDocumentsCount(state: State, signDocumentsCount: number): void {
    if (state.user) {
      state.user.signDocumentsCount = signDocumentsCount;
    }
  },

  setGoBackRoute(state: State, goBackRoute: RouteLocationRaw): void {
    state.goBackRoute = goBackRoute;
  },
};
