import { findLastKey } from 'lodash';
import { defineComponent } from 'vue';
import screens from '@/tailwind/screens.json';

export default defineComponent({
  data() {
    return {
      windowOuterHeight: 0,
      windowOuterWidth: 0,
      windowInnerHeight: 0,
      windowInnerWidth: 0,
      breakpoint: 'xxs',
    };
  },

  computed: {
    onMobile(): boolean {
      return this.breakpointLowerThan('md');
    },

    onDesktop(): boolean {
      return !this.onMobile;
    },
  },

  mounted() {
    this.setWindowDimensions();

    window.addEventListener('resize', this.setWindowDimensions);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.setWindowDimensions);
  },

  methods: {
    setWindowDimensions(): void {
      this.windowOuterHeight = window.outerHeight;
      this.windowOuterWidth = window.outerWidth;
      this.windowInnerHeight = window.innerHeight;
      this.windowInnerWidth = window.innerWidth;
      this.breakpoint = this.getCurrentBreakpoint();
    },

    getCurrentBreakpoint(): string {
      const breakpoint = findLastKey(screens, size => {
        const sizeWidth = parseInt(size.replace('px', '') ?? 0, 10);

        return window.innerWidth > sizeWidth;
      });

      return breakpoint ?? 'xxs';
    },

    breakpointHigherThan(breakpoint: string): boolean {
      const currentBreakpointIndex = Object.keys(screens).indexOf(this.breakpoint);
      const breakpointIndex = Object.keys(screens).indexOf(breakpoint);

      return currentBreakpointIndex > breakpointIndex;
    },

    breakpointLowerThan(breakpoint: string): boolean {
      const currentBreakpointIndex = Object.keys(screens).indexOf(this.breakpoint);
      const breakpointIndex = Object.keys(screens).indexOf(breakpoint);

      return currentBreakpointIndex < breakpointIndex;
    },
  },
});
