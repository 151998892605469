
  import { defineComponent } from 'vue';
  import ResponsiveImageHtmlMixin from '@/mixins/responsive-image-html-mixin';

  import type { PropType } from 'vue';

  export default defineComponent({
    name: 'CompanyLogo',

    mixins: [
      ResponsiveImageHtmlMixin,
    ],

    props: {
      logoHtml: {
        type: String as PropType<string>,
        required: true,
      },
    },

    render() {
      return this.renderImage(this.logoHtml, {
        class: 'h-full w-full object-contain object-left',
      });
    },
  });
