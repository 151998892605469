import {
  keys,
  pickBy,
  invert,
  uniqBy,
  mapValues,
  isString,
  isArray,
  isObject,
} from 'lodash';
import { DateTime } from 'luxon';

import type { RenderFunction } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import type { DateTimeFormatOptions } from 'luxon';
import type { DynamicIcon } from '@/components/AppDynamicIcon';
import type { Section, SectionNode, SectionTree } from '@/typings/LMS/section';
import type { DomClassesProp, DynamicDomClassesObject } from '@/typings/class-style-binding';

export function convertDomClassesPropToArray(domClassesProp: DomClassesProp): string[] {
  if (isArray(domClassesProp)) {
    return domClassesProp;
  }

  if (isObject(domClassesProp)) {
    return keys(pickBy(domClassesProp));
  }

  return domClassesProp.trim().split(' ');
}

function convertDomClassesArrayToObject(arrayDomClassesProp: string[]): DynamicDomClassesObject {
  return mapValues(invert(pickBy(arrayDomClassesProp)), () => true);
}

export function convertDomClassesPropToObject(
  domClassesProp: DomClassesProp,
): DynamicDomClassesObject {
  if (isArray(domClassesProp)) {
    return convertDomClassesArrayToObject(domClassesProp);
  }

  if (isString(domClassesProp)) {
    return convertDomClassesArrayToObject(domClassesProp.trim().split(' '));
  }

  return domClassesProp;
}

export function validateUniquePropertyInCollection<T, TKey extends keyof T>(
  collection: T[],
  propertyName: TKey,
): boolean {
  return uniqBy(collection, propertyName).length === collection.length;
}

export function stripTags(htmlString: string): string {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = htmlString;

  return wrapper.textContent ?? '';
}

export function getIconComponent(icon: DynamicIcon): RenderFunction {
  // Global requiring a JavaScript file was the least bad solution.
  // Otherwise, I had to import all icons and then loop over them to find it.
  // eslint-disable-next-line global-require, import/no-dynamic-require, @typescript-eslint/no-require-imports
  return require(`@heroicons/vue/${icon.type}/${icon.name}.js`);
}

export function htmlStringToNodes(htmlString: string): Node[] {
  const template = document.createElement('template');

  template.innerHTML = htmlString.trim();

  return [...template.content.childNodes];
}

export function joinWithCommasPlusAnd<T, TKey extends keyof T>(
  collection: T[],
  propertyName: TKey,
): string {
  const andTranslation = window.$t('and');
  const mappedCollection = collection.map(item => {
    return item[propertyName];
  });

  if (mappedCollection.length < 3) {
    return mappedCollection.join(` ${andTranslation} `);
  }

  const lastItem = mappedCollection[mappedCollection.length - 1];
  return `${mappedCollection.slice(0, -1).join(', ')}, ${andTranslation} ${lastItem}`;
}

export function getHumanFileSize(
  bytes: number,
  maximumNumberOfDecimals = 1,
): string {
  const threshold = 1024;
  let result = bytes;

  if (Math.abs(bytes) < threshold) {
    return `${result} B`;
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const roundingFactor = 10 ** maximumNumberOfDecimals;
  let unit = -1;

  do {
    result /= threshold;
    unit += 1;
  } while (
    Math.round(Math.abs(result) * roundingFactor)
    / roundingFactor >= threshold && unit < units.length - 1
  );

  return `${result.toFixed(maximumNumberOfDecimals)} ${units[unit]}`;
}

export function getRouteQueryParameter(
  route: RouteLocationNormalized,
  parameterName: string,
): string | null {
  const parameter = route.query[parameterName];

  if (isString(parameter) && parameter.length > 0) {
    return parameter;
  }

  return null;
}

export function routeRequiresAuth(route: RouteLocationNormalized): boolean {
  return route?.meta?.requiresAuth ?? true;
}

export function mustShowNavigationBars(route: RouteLocationNormalized): boolean {
  return route?.meta?.showNavigationBars ?? true;
}

export function generateSectionTree(sections: Section[]): SectionTree {
  const sectionTree: SectionTree = [];
  let lastParentNode: SectionNode | null = null;
  let rootSectionNode: SectionNode | null = null;

  sections.forEach(section => {
    const hasChildren = (section.rightBower - section.leftBower) > 1;
    const isRootNode = section.parentSectionId === null;
    const sectionNode: SectionNode = {
      value: section,
      children: [],
      parent: null,
    };

    if (isRootNode) {
      if (rootSectionNode) {
        sectionTree.push(rootSectionNode);
      }

      rootSectionNode = sectionNode;
      lastParentNode = sectionNode;
    } else {
      if (lastParentNode) {
        sectionNode.parent = lastParentNode;
        lastParentNode.children.push(sectionNode);
      }

      if (hasChildren) {
        lastParentNode = sectionNode;
      }
    }
  });

  if (rootSectionNode) {
    sectionTree.push(rootSectionNode);
  }

  return sectionTree;
}

export function stringToLuxon(date: string | DateTime, fromFormat?: string): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  return fromFormat
    ? DateTime.fromFormat(date, fromFormat)
    : DateTime.fromISO(date);
}

export function dateFormat(
  date: DateTime | string,
  // eslint-disable-next-line default-param-last
  format = 'dd-MM-yyyy',
  fromFormat?: string,
): string {
  return stringToLuxon(date, fromFormat).toFormat(format);
}

export function dateFormatLocale(
  date: DateTime | string,
  // eslint-disable-next-line default-param-last
  format: DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' },
  fromFormat?: string,
): string {
  return stringToLuxon(date, fromFormat).toLocaleString(format);
}

export function getFormattedDeletedAt(datetime: string, withTime = false): string {
  const format = withTime ? DateTime.DATETIME_FULL : DateTime.DATE_FULL;

  return dateFormatLocale(datetime, { ...format, timeZoneName: undefined });
}

export function elementHasDimensions(element: Element): boolean {
  const dimensions = element.getBoundingClientRect();
  let dimensionValues: number[];

  if (typeof dimensions.toJSON === 'function') {
    dimensionValues = Object.values(dimensions.toJSON());
  } else {
    // eslint-disable-next-line id-length
    const { height, width, top, right, bottom, left, x, y } = dimensions;
    dimensionValues = [height, width, top, right, bottom, left, x, y];
  }

  return dimensionValues.some(dimension => dimension > 0);
}

export function getFirstElementChildWithDimensions(element: Element): Element | null {
  if (element.childElementCount < 1) {
    return null;
  }

  return [...element.children].find(elementChild => {
    return elementHasDimensions(elementChild);
  }) ?? null;
}
