
  import { defineComponent } from 'vue';
  import { AppInputBase } from '@/components';
  import props from '@/components/AppInputBase/props';
  import methods from '@/components/AppInputBase/methods-mixin';

  export default defineComponent({
    name: 'AppInputTelephone',

    components: {
      AppInputBase,
    },

    mixins: [
      methods,
    ],

    inheritAttrs: false,

    props,
  });
