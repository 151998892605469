import { http } from '@/plugins/http';

import type { Block, Section } from '@/typings/LMS/section';
import type {
  GetBlocksPayload,
  UpdateProgressActionPayload,
  UpdateProgressPostResponse,
} from '@/views/Enrollments/WalkThrough';

export default {
  async getSections(enrollmentId: number): Promise<Section[]> {
    return http.fetch<Section[]>(`enrollments/${enrollmentId}/sections`);
  },

  async getBlocks({ enrollmentId, sectionId }: GetBlocksPayload): Promise<Block[]> {
    return http.fetch<Block[]>(`enrollments/${enrollmentId}/sections/${sectionId}/blocks`);
  },

  async updateProgress(
    { enrollmentId, sectionId, ...body }: UpdateProgressActionPayload,
  ): Promise<UpdateProgressPostResponse> {
    const url = `enrollments/${enrollmentId}/sections/${sectionId}/progress`;
    return http.post<typeof body, UpdateProgressPostResponse>(url, body);
  },
};
