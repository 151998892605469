import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "mt-2 text-sm text-gray-700",
  "data-test-leading-text": ""
}
const _hoisted_4 = {
  key: 0,
  ref: "leadingAddon",
  class: "absolute inset-y-0 left-0 flex items-center"
}
const _hoisted_5 = ["id", "name", "disabled", "readonly", "placeholder", "minlength", "maxlength", "cols", "rows", "dirname", "wrap", "autofocus", "tabindex", "aria-invalid", "aria-describedby", "textContent"]
const _hoisted_6 = {
  key: 1,
  ref: "trailingAddon",
  class: "absolute inset-y-0 right-0 flex items-center"
}
const _hoisted_7 = {
  key: 2,
  class: "mt-2 text-sm text-gray-700",
  "data-test-trailing-text": ""
}
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.labelText && !_ctx.labelIsHidden) || _ctx.cornerHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.textareaId,
            class: _normalizeClass([_ctx.dynamicLabelDomClasses, "block font-semibold text-gray-900"]),
            "data-test-label": ""
          }, _toDisplayString(_ctx.labelText), 11, _hoisted_2),
          (_ctx.cornerHint)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([{ 'justify-self-right': !_ctx.labelText || _ctx.labelIsHidden }, "text-gray-700"]),
                "data-test-corner-hint": ""
              }, _toDisplayString(_ctx.cornerHint), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.leadingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.leadingHelpText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.textareaWrapperDomClasses, "relative"]),
      "data-test-textarea-wrapper": ""
    }, [
      (_ctx.$slots.leadingAddon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "leadingAddon")
          ], 512))
        : _createCommentVNode("", true),
      _createElementVNode("textarea", {
        id: _ctx.textareaId,
        ref: "inputField",
        name: _ctx.name,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        placeholder: _ctx.placeholder,
        minlength: _ctx.minlength,
        maxlength: _ctx.maxlength,
        cols: _ctx.cols,
        rows: _ctx.rows,
        dirname: _ctx.dirname,
        wrap: _ctx.wrap,
        autofocus: _ctx.autofocus,
        tabindex: _ctx.tabindex,
        "aria-invalid": _ctx.hasError,
        "aria-describedby": _ctx.hasError && _ctx.errorMessageId,
        class: _normalizeClass([_ctx.textareaDomClasses, "shadow-sm block w-full min-h-[2.625rem] placeholder-gray-700 placeholder-opacity-100 rounded disabled:cursor-not-allowed"]),
        style: _normalizeStyle(_ctx.textareaStyling),
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        textContent: _toDisplayString(_ctx.modelValue)
      }, null, 46, _hoisted_5),
      (_ctx.$slots.trailingAddon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "trailingAddon")
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            ref: "errorIcon",
            style: _normalizeStyle(_ctx.errorIconStyling),
            class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none",
            "data-test-error-icon": ""
          }, [
            _createVNode(_component_ExclamationCircleIcon, {
              class: "h-5 w-5 text-red-500",
              "aria-hidden": "true"
            })
          ], 4))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.trailingHelpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.trailingHelpText), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", {
          key: 3,
          id: _ctx.errorMessageId,
          class: "mt-2 text-sm text-red-500",
          "data-test-error-message": ""
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_8))
      : _createCommentVNode("", true)
  ]))
}